import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { getCouncils } from 'modules/administratives/redux/councilActions';

export const useGetCouncilsList = (societyId: string | undefined) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchCouncilsList = useCallback(async () => {
    if (!societyId) {
      return;
    }

    try {
      setIsLoading(true);
      await dispatch(getCouncils(societyId));
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [societyId, dispatch]);

  useEffect(() => {
    fetchCouncilsList();
  }, [fetchCouncilsList]);

  return { isLoading, isError };
};
