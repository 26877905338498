import { FC } from 'react';

import { EnvelopeSigner } from 'types';

import SignersStatusTableHeader from './SignersStatusTableHeader';
import SignersStatusTableBody from './SignersStatusTableBody';

type Props = {
  signers: EnvelopeSigner[];
};

const SignersStatusTable: FC<Props> = ({ signers }) => (
  <div className="card card-bordered">
    <table className="table my-2 nk-tb-list nk-tb-ulist" id="act-signers-table">
      <SignersStatusTableHeader />
      <SignersStatusTableBody signers={signers} />
    </table>
  </div>
);

export default SignersStatusTable;
