import { axiosV1 } from 'interceptors/axios';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { addAlert } from '../../_shared/redux/alertActions';

export const createHoldingClass =
  (holdingClass, showAlert = true) =>
  async (dispatch) => {
    try {
      const { data } = await axiosV1.post(`/holdingClasses`, holdingClass);

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.CREATE_HOLDING_CLASS,
        holdingClass: decodedData,
      });
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.HOLDING_CLASS_ADDED));
      }
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const getHoldingClasses = (societyId) => async (dispatch) => {
  try {
    const { data } = await axiosV1.get(`/holdingClasses`, {
      params: {
        societyId,
      },
    });

    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_HOLDING_CLASSES,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updateHoldingClass =
  (holdingClassId, updateHoldingClassData) => async (dispatch) => {
    const body = {
      _id: holdingClassId,
      ...updateHoldingClassData,
    };

    try {
      const { data } = await axiosV1.put(`/holdingClasses`, body);

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_HOLDING_CLASS,
        holdingClass: decodedData,
      });
      dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };
