import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { store } from 'redux/store';

import { Council, Document } from 'types';

import { setModal } from 'modules/_shared/redux/modalActions';
import downloadDocument from 'utils/downloadDocument';

import DocumentsHeader from './DocumentsHeader';
import DocumentsList from './DocumentsList';
import AddCouncilDocumentModal from './AddCouncilDocumentModal';

type Props = {
  council: Council;
  announcementDocument: Document | undefined;
  fetchCouncilData: () => void;
};

const AnnouncementDocumentCard: FC<Props> = ({
  council,
  announcementDocument,
  fetchCouncilData,
}: Props) => {
  const { t } = useTranslate();

  const buttonTitle = announcementDocument ? t('Replace') : t('Add');

  const isReadOnly = false; // TODO: Implement permissions

  const handleDocumentAction = () => {
    store.dispatch(
      setModal(
        <AddCouncilDocumentModal
          council={council}
          replaceAnnouncementDocument
          fetchCouncilData={fetchCouncilData}
        />
      )
    );
  };

  // eslint-disable-next-line no-unused-vars
  const handleDownloadDocument = async (index: number) => {
    downloadDocument({
      documentId: announcementDocument?.['_id'],
      documentName: announcementDocument?.name,
      documents: [],
    });
  };

  return (
    <div className="card-inner">
      <div className="sp-plan-head">
        <div className="row sp-plan-head mb-2">
          <h6 className="col-md-6 title">{t('Announcement')}</h6>

          <div className="col-md-6 text-right">
            {!isReadOnly && (
              <ul>
                <li>
                  <button
                    type="button"
                    className="link text-primary"
                    onClick={handleDocumentAction}
                  >
                    <span className="title">{buttonTitle}</span>
                  </button>
                </li>
              </ul>
            )}
          </div>

          <span className="col-12 ff-regular text-soft">
            {t('CouncilAnnouncementDocumentCardInfo')}
          </span>
        </div>
      </div>

      <div className="nk-files nk-files-view-list">
        {announcementDocument && (
          <>
            <DocumentsHeader hasOptions={false} />

            <DocumentsList
              documents={[announcementDocument]}
              hasOptions={false}
              handleDownloadDocument={handleDownloadDocument}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AnnouncementDocumentCard;
