import { store } from 'redux/store';
import { FC } from 'react';

import { CouncilParticipant } from 'types';

import { useTranslate } from 'hooks/useTranslate';

import { updateCouncil } from 'modules/administratives/redux/councilActions';
import CellData from 'modules/_shared/components/Tables/CellData';
import { getAdminMemberTypeLabel } from '../../AdminMembers/utils/getAdminMemberTypeLabel';
import { useGetParticipantAttendance } from '../hooks/useGetParticipantAttendance';

type Props = {
  index: number;
  societyId: string;
  councilId: string;
  participants: CouncilParticipant[];
  participant: CouncilParticipant;
};

const AttendanceTableRow: FC<Props> = ({
  index,
  societyId,
  councilId,
  participants,
  participant,
}) => {
  const { t } = useTranslate();

  const isReadOnly = false;

  const participantId = participant?.member;
  const participantName = participant?.name || '-';
  const participantImage = participant?.image;
  const participantEmail = participant?.email;

  const participantAttendanceResult = useGetParticipantAttendance({
    participant,
  });

  const {
    participantAttendance,
    attendanceStatusLabel,
    attendanceStatusBadge,
  } = participantAttendanceResult.data;

  const { setParticipantAttendance } = participantAttendanceResult.actions;

  const participantPositionLabel = getAdminMemberTypeLabel(
    participant?.participantPosition
  );

  const handleChangeAttendance = async () => {
    setParticipantAttendance((prev) => !prev);

    const updatedParticipants = participants.map((participant) => {
      if (participant.member === participantId) {
        return {
          ...participant,
          assists: !participantAttendance,
          isAbsent: participantAttendance,
        };
      }

      return participant;
    });

    const councilUpdateData = {
      _id: councilId,
      participants: updatedParticipants,
    };

    await store.dispatch(updateCouncil(councilUpdateData));
  };

  return (
    <>
      <td className="nk-tb-col" key={`participant-name-${index}`}>
        <span className="text-primary font-weight-bold cursor-pointer">
          {CellData.Adminstrative({
            name: participantName,
            image: participantImage,
            email: participantEmail,
            url: `/detalle-miembro/${societyId}/${participantId}`,
          })}
        </span>
      </td>

      <td className="nk-tb-col" key={`participant-type-${index}`}>
        <span>{t(participantPositionLabel)}</span>
      </td>

      <td className="nk-tb-col" key={`participant-status-${index}`}>
        <span className={`badge badge-dot badge-${attendanceStatusBadge}`}>
          {t(attendanceStatusLabel)}
        </span>
      </td>

      {!isReadOnly && (
        <td className="nk-tb-col" key={`participant-checkbox-${index}`}>
          <div className="custom-control custom-control-xs custom-checkbox">
            <input
              type="checkbox"
              id={`participant-${index}`}
              className="custom-control-input"
              checked={participantAttendance}
              onChange={handleChangeAttendance}
            />
            <label
              className="custom-control-label"
              htmlFor={`participant-${index}`}
            />
          </div>
        </td>
      )}
    </>
  );
};

export default AttendanceTableRow;
