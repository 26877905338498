import { FC } from 'react';
import { legendModes } from '../WidgetChartDoughnut';

type WidgetChartDoughnutLoaderProps = {
  legendMode: keyof typeof legendModes;
};

const WidgetChartDoughnutLoader: FC<WidgetChartDoughnutLoaderProps> = ({
  legendMode = legendModes.VERTICAL,
}) => {
  const outerCircleStyle = { transform: 'translate(-50%, 0%)' };
  const innerCircleStyle = { transform: 'translate(50%, 50%)' };

  return (
    <div className="col-12 col-xl-6 mb-4">
      <div className="card card-bordered h-100">
        <div className="card-inner">
          <p className="placeholder-glow ">
            <span className="placeholder col-5    h-15px mb-4" />
            <span className="placeholder offset-4 h-15px mb-4" />
            <span className="placeholder col-3    h-15px mb-4" />
          </p>

          <div className="placeholder-glow text-center relative mb-4">
            <div className="inline-block relative h-250px w-250px mx-auto">
              <div
                className="position-absolute rounded-circle placeholder h-250px w-250px"
                style={outerCircleStyle}
              />
              <div
                className="position-absolute rounded-circle bg-white h-125px w-125px"
                style={innerCircleStyle}
              />
            </div>
          </div>

          {legendMode === legendModes.VERTICAL && (
            <p
              className="placeholder-glow d-flex flex-wrap mt-2 mx-auto mw-100 justify-content-between"
              style={{ maxWidth: 340, rowGap: 24 }}
            >
              <span className="placeholder w-160px mb-0">
                <span className="placeholder w-100 h-40px" />
              </span>
              <span className="placeholder w-160px mb-0">
                <span className="placeholder w-100 h-40px" />
              </span>
              <span className="placeholder w-160px mb-0">
                <span className="placeholder w-100 h-40px" />
              </span>

              <span className="placeholder w-160px mb-0">
                <span className="placeholder w-100 h-40px" />
              </span>
              <span className="placeholder w-160px mb-0">
                <span className="placeholder w-100 h-40px" />
              </span>
              <span className="placeholder w-160px mb-0">
                <span className="placeholder w-100 h-40px" />
              </span>
            </p>
          )}

          {legendMode === legendModes.HORIZONTAL && (
            <p
              className="placeholder-glow d-flex text-center mt-3 mx-auto mw-100 justify-content-between"
              style={{ rowGap: 24 }}
            >
              <span className="w-80px mb-0">
                <span className="placeholder w-100 h-40px" />
                <span className="placeholder w-100 h-40px mt-3" />
              </span>
              <span className=" w-80px mb-0">
                <span className="placeholder w-100 h-40px" />
                <span className="placeholder w-100 h-40px mt-3" />
              </span>
              <span className=" w-80px mb-0">
                <span className="placeholder w-100 h-40px" />
                <span className="placeholder w-100 h-40px mt-3" />
              </span>
              <span className="w-80px mb-0">
                <span className="placeholder w-100 h-40px" />
                <span className="placeholder w-100 h-40px mt-3" />
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default WidgetChartDoughnutLoader;
