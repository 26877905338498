/* eslint-disable no-undef */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';

import { useAddRealtyModal } from '../context/AddRealtyModalContext';
import EncumbrancesViewForm from './EncumbrancesViewForm';
import EncumbrancesTable from './EncumbrancesTable/EncumbrancesTable';

const EncumbrancesView: FC = () => {
  const { t } = useTranslate();

  const [expandedView, setExpandedView] = useState<boolean>(false);

  const { data, actions } = useAddRealtyModal();

  const { encumbrances } = data;

  const handleChangeView = () => {
    setExpandedView((prev) => !prev);
  };

  const handleRemoveEncumbrance = (index: number) => {
    const newEncumbrances = encumbrances.filter(
      (_: string, i: number) => i !== index
    );

    actions.handleChangeEncumbrances(newEncumbrances);
  };

  return (
    <>
      <div className="row gy-4">
        <div className="col-6 d-flex align-items-center">
          <h5 className="mb-0">{t('Encumbrances')}</h5>
        </div>
        <div className="col-6 text-right">
          <button
            type="button"
            className="badge btn-primary"
            onClick={handleChangeView}
          >
            <em
              className={`icon ni ni-chevron-${expandedView ? 'up' : 'down'}`}
            />
          </button>
        </div>
      </div>

      {expandedView && (
        <>
          <EncumbrancesViewForm />

          {encumbrances?.length > 0 && (
            <EncumbrancesTable
              encumbrances={encumbrances}
              handleRemoveEncumbrance={handleRemoveEncumbrance}
            />
          )}
        </>
      )}
    </>
  );
};

export default EncumbrancesView;
