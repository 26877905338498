import { FC } from 'react';
import { RealtyLicense } from 'types';

import LicensesTableHeader from './LisencesTableHeader';
import LicensesTableBody from './LicensesTableBody';

type Props = {
  licenses: RealtyLicense[];
  // eslint-disable-next-line no-unused-vars
  handleRemoveLicense: (index: number) => void;
};

const LicensesTable: FC<Props> = ({ licenses, handleRemoveLicense }) => (
  <>
    <table className="table my-2">
      <LicensesTableHeader />
      <LicensesTableBody
        licenses={licenses}
        handleRemoveLicense={handleRemoveLicense}
      />
    </table>
  </>
);

export default LicensesTable;
