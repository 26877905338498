import { store } from 'redux/store';

import { Council } from 'types';

import { updateCouncil } from 'modules/administratives/redux/councilActions';
import { updateDocumentToDeleted } from './updateDocumentToDeleted';

type Props = {
  documentId: string;
  council: Council;
  privateDocument: boolean;
};

const getCouncilUpdatedData = ({
  council,
  documentId,
  privateDocument,
}: Props) => {
  let councilUpdateData = {};

  const documentsKey = privateDocument ? 'privateDocuments' : 'publicDocuments';

  const documents = council[documentsKey] || [];
  const filteredDocuments = documents.filter(
    (document) => document !== documentId
  );

  councilUpdateData = {
    _id: council._id,
    [documentsKey]: filteredDocuments,
  };

  return councilUpdateData;
};

export const removeDocumentFromCouncil = async ({
  documentId,
  council,
  privateDocument,
}: Props) => {
  const councilUpdateData = getCouncilUpdatedData({
    council,
    documentId,
    privateDocument,
  });

  await updateDocumentToDeleted(documentId);
  await store.dispatch(updateCouncil(councilUpdateData));
};
