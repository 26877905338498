import { FC } from 'react';
import spinner from 'assets/images/css-loading-white.png';

import './Loading.scss';

const Loading: FC = () => (
  <div className="loading-spinner nk-spinner">
    <div className="spinner-grow text-primary" role="status">
      <div className="loading-wrapper d-flex justify-content-center align-items-center">
        <div className="loading-pulse">
          <img src={spinner} alt="Processing..." srcSet="" />
        </div>
      </div>
    </div>
  </div>
);

export default Loading;
