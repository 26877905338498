import { axiosV1 } from 'interceptors/axios';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';

import { dispatchError } from 'utils/dispatchError';
import { decryptResponse } from 'utils/token';

import { addAlert } from './alertActions';

export const createGlobalNotification =
  (globalNotification, showAlert = true) =>
  async (dispatch) => {
    try {
      const { data } = await axiosV1.post(`/notifications`, globalNotification);

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.CREATE_GLOBAL_NOTIFICATION,
        globalNotification: decodedData,
      });
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.GLOBAL_NOTIFICATION_CREATED));
      }
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const getGlobalNotifications = () => async (dispatch) => {
  try {
    const { data } = await axiosV1.get(`/notifications`);

    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_GLOBAL_NOTIFICATIONS,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const getGlobalNotificationsBySociety =
  (societyId) => async (dispatch) => {
    try {
      const query = societyId ? { params: { societyId } } : { params: {} };

      const { data } = await axiosV1.get(`/notifications`, query);

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.GET_GLOBAL_NOTIFICATIONS,
        data: decodedData,
      });
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const updateGlobalNotification =
  (globalNotificationId, updateGlobalNotificationData) => async (dispatch) => {
    const body = {
      _id: globalNotificationId,
      ...updateGlobalNotificationData,
    };

    try {
      const { data } = await axiosV1.put(
        `/notifications/${globalNotificationId}`,
        body
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_GLOBAL_NOTIFICATION,
        globalNotification: decodedData,
      });
      dispatch(addAlert(alertBodyTypes.GLOBAL_NOTIFICATION_UPDATED));
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deleteGlobalNotification =
  (globalNotificationId) => async (dispatch) => {
    try {
      await axiosV1.delete(`/notifications/${globalNotificationId}`);

      dispatch({
        type: types.DELETE_GLOBAL_NOTIFICATION,
        globalNotificationId,
      });
      dispatch(addAlert(alertBodyTypes.GLOBAL_NOTIFICATION_DELETED));
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };
