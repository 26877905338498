import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { SocietyAuditor } from 'types';

import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';
import SocietyAuditorsTableRow from './SocietyAuditorsTableRow';

type Props = {
  societyId: string;
  societyAuditors: SocietyAuditor[];
  isReadOnly?: boolean;
};

const SocietyAuditorsTableBody: FC<Props> = ({
  societyId,
  societyAuditors,
  isReadOnly,
}) => {
  const { t } = useTranslate();
  const hasData = societyAuditors?.length > 0;

  return (
    <>
      <tbody>
        {hasData ? (
          societyAuditors?.map((auditor: SocietyAuditor, index: number) => (
            <tr className="nk-tb-item" key={`auditor-row-${auditor?._id}`}>
              <SocietyAuditorsTableRow
                index={index}
                societyId={societyId}
                auditor={auditor}
                isReadOnly={isReadOnly}
              />
            </tr>
          ))
        ) : (
          <NoItemsAvailable mainMessage={t('NoAuditorsAvailable')} />
        )}
      </tbody>
    </>
  );
};

export default SocietyAuditorsTableBody;
