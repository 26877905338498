import { useEffect, useState } from 'react';
import { store } from 'redux/store';

import { Document } from 'types';
import { getDocuments } from 'modules/documents/redux/documentActions';

type Props = {
  societyId: string;
  representativeId?: string;
};

export default function useGetSocietyRepresentativeDocuments({
  societyId,
  representativeId,
}: Props) {
  const [representativeDocuments, setRepresentativeDocuments] = useState<
    Document[]
  >([]);

  useEffect(() => {
    if (societyId && representativeId) {
      const getDocumentsData = async () => {
        try {
          store.dispatch(
            getDocuments(
              { representative: representativeId, society: societyId },
              setRepresentativeDocuments
            )
          );
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      };

      getDocumentsData();
    }
  }, [societyId, representativeId]);

  return {
    data: { representativeDocuments },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
