import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { CouncilParticipant } from 'types';

import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';
import AttendanceTableRow from './AttendanceTableRow';

type Props = {
  societyId: string | undefined;
  councilId: string;
  participants: CouncilParticipant[];
};

const AttendanceTableBody: FC<Props> = ({
  societyId,
  councilId,
  participants,
}) => {
  const { t } = useTranslate();
  const hasData = participants?.length > 0 && societyId;

  return (
    <>
      <tbody>
        {hasData ? (
          participants?.map(
            (participant: CouncilParticipant, index: number) => (
              <tr
                className="nk-tb-item"
                key={`participant-row-${participant?.member}`}
              >
                <AttendanceTableRow
                  index={index}
                  societyId={societyId}
                  councilId={councilId}
                  participants={participants}
                  participant={participant}
                />
              </tr>
            )
          )
        ) : (
          <NoItemsAvailable mainMessage={t('NoAssistantsAvailable')} />
        )}
      </tbody>
    </>
  );
};

export default AttendanceTableBody;
