/* eslint-disable react/jsx-one-expression-per-line */

import { useTranslate } from 'hooks/useTranslate';

import menuTypes from 'constants/menuTypes';

import Page from 'modules/_shared/components/Pages/Page';
import PageWithTabs from 'modules/_shared/components/Pages/PageWithTabs';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';

import { useDirectorDetail } from './hooks/useDirectorDetail';

import './DirectorDetail.scss';

const DirectorDetail = () => {
  const { t } = useTranslate();

  const {
    data: { pages, director, isOutdated, date },
  } = useDirectorDetail();

  useGetMenu(menuTypes.EXTENDED);

  return director ? (
    <Page>
      <PageListHeader
        title={t('AdminMember')}
        subTitle={director?.socialDenomination || ''}
        subTitleClassName="text-primary fw-normal"
        description={
          isOutdated ? `${t('StartDate')}: ${date}` : `${t('EndDate')}: ${date}`
        }
        actions={[<GoBackButton key="go-back-button" />]}
      />

      <PageWithTabs
        tabs={[
          {
            key: 'summary-tab',
            icon: 'ni ni-user-circle',
            title: t('Directorship'),
            content: pages[0],
          },
          {
            key: 'data-tab',
            icon: 'ni ni-list',
            title: t('Data'),
            content: pages[1],
          },
          {
            key: 'roaming-tab',
            icon: 'ni ni-building',
            title: t('Societies'),
            content: pages[2],
          },
          {
            key: 'documents-tab',
            icon: 'ni ni-file-text',
            title: t('Documents'),
            content: pages[3],
          },
        ]}
        isError={false}
        isLoading={false}
      />
    </Page>
  ) : (
    <CustomLoading />
  );
};

export default DirectorDetail;
