import types from 'redux/actions/actionTypes';

import { axiosV2 } from 'interceptors/axios';
import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { Committee as OriginalCommittee } from 'types';

type Committee = Omit<OriginalCommittee, '_id'>;

export const getCommittee = (committeeId: string) => async (dispatch: any) => {
  try {
    const { data } = await axiosV2.get(`/committees/${committeeId}`);
    const decodedData = await decryptResponse(data);

    return decodedData;
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const getCommittees = (societyId: string) => async (dispatch: any) => {
  const query = { params: { societyId } };
  try {
    const { data } = await axiosV2.get(`/committees`, query);
    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_COMMITTEES,
      committees: decodedData,
    });

    return decodedData;
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const createCommittee =
  (committeeData: Committee) => async (dispatch: any) => {
    try {
      const body = { ...committeeData };
      const { data } = await axiosV2.post(`/committees`, body);
      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.CREATE_COMMITTEE,
        committee: decodedData,
      });
      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };
