import { axiosV2 } from 'interceptors/axios';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { addAlert } from '../../_shared/redux/alertActions';

export async function getBeneficiaryData(
  { beneficiaryId, societyId, userId, source },
  setter
) {
  try {
    const { data } = await axiosV2.get(`/beneficiaries`, {
      params: { beneficiaryId, societyId, userId, source },
    });

    const decodedData = await decryptResponse(data);
    if (setter) setter(decodedData);
    return decodedData;
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
}

export const updateBeneficiary =
  ({ id, data, showAlert = true }) =>
  async (dispatch) => {
    const body = {
      beneficiaryId: id,
      ...data,
    };

    try {
      await axiosV2.put(`/beneficiaries`, body);

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.BENEFICIARY_UPDATED));
      }
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_UPDATING_BENEFICIARY));
    }
  };

export const deleteBeneficiary =
  ({ beneficiaryId, societyId }) =>
  async (dispatch) => {
    try {
      await axiosV2.delete(`/beneficiaries/${beneficiaryId}`, {
        params: { societyId, beneficiaryId },
      });
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deleteAllBeneficiariesFromPlan =
  ({ societyId, planId }) =>
  async (dispatch) => {
    try {
      await axiosV2.delete(`/beneficiaries/${planId}`, {
        params: { societyId, planId },
      });
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };
