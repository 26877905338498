import { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import { Society } from 'types';

import { getSocietyEmailConfiguration } from 'utils/emails';
import { getAnnouncementCommunication } from 'utils/getAnnouncementCommunication';
import { getCouncilAnnouncementCommunication } from 'modules/administratives/modals/CouncilAnnouncementModal/utils/getCouncilAnnouncementCommunication';
import { AnnouncementType } from '..';

export default function useGetAnnouncementCommunication(
  i18n: any,
  announcementData: any,
  society: Society,
  announcementType: AnnouncementType
) {
  const [announcementCommunication, setAnnouncementCommunication] =
    useState<any>('');

  useEffect(() => {
    const societyTheme = getSocietyEmailConfiguration(society, {
      addSuffix: false,
    })?.theme;

    const hasWhiteBranding = society?.configuration?.hasWhiteBranding;

    const announcement =
      announcementType === AnnouncementType.BOARD
        ? getAnnouncementCommunication(
            societyTheme,
            hasWhiteBranding,
            announcementData,
            i18n?.language || 'es'
          )
        : getCouncilAnnouncementCommunication(
            societyTheme,
            hasWhiteBranding,
            announcementData,
            i18n?.language || 'es'
          );

    const parsedResult = parse(announcement);

    setAnnouncementCommunication(parsedResult);
  }, [i18n, announcementData, society, announcementType]);

  return { announcementCommunication };
}
