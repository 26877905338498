/* eslint-disable no-console */
import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { Board, Council } from 'types';

import { setModal } from 'modules/_shared/redux/modalActions';

import '../Modals.scss';

type EditAnnouncementModalProps = {
  source: Board | Council;
  onSave: (announcement: string) => void; // eslint-disable-line
  description?: string;
};

const EditAnnouncementModal: FC<EditAnnouncementModalProps> = ({
  source,
  onSave,
  description,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [announcement, setAnnouncement] = useState('');

  const handleSaveAnnouncement = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    try {
      await onSave(announcement);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setModal(null));
    }
  };

  useEffect(() => {
    const text: string =
      'announcement' in source
        ? source.announcement
        : source?.announcementText || '';
    setAnnouncement(text);
  }, [source]);

  return (
    <>
      <form
        className="form-validate is-alter"
        onSubmit={handleSaveAnnouncement}
      >
        <Modal.Header
          style={{ borderBottom: 'none', padding: '36px 40px 0 40px' }}
        >
          <h5 className="modal-title">{t('CommunicationTextTitle')}</h5>
          <a
            className="close cursor-pointer"
            onClick={() => dispatch(setModal(null))}
          >
            <em className="icon ni ni-cross" />
          </a>
        </Modal.Header>

        <Modal.Body style={{ margin: '0px 16px 0px 16px' }}>
          <div className="form-group">
            <p className="text-soft">{description}</p>
            <div className="form-control-wrap">
              <div className="form-editor-custom">
                <textarea
                  className="form-control form-control-lg no-resize"
                  placeholder={t('CommunicationTextPlaceholder')}
                  value={announcement || ''}
                  onChange={(event) => setAnnouncement(event.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer
          style={{ borderTop: 'none', padding: '0px 40px 36px 40px' }}
        >
          <button
            type="button"
            className="btn btn-lg btn-outline-primary border-0"
            onClick={() => dispatch(setModal(null))}
          >
            {t('Cancel')}
          </button>
          <button type="submit" className="btn btn-lg btn-primary">
            {t('Save')}
          </button>
        </Modal.Footer>
      </form>
    </>
  );
};

export default EditAnnouncementModal;
