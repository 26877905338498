import { useTranslate } from 'hooks/useTranslate';
import { getCouncilDetailsUrl } from 'modules/administratives/utils/getCouncilDetailsUrl';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Council } from 'types';

type CouncilsCardActionsProps = {
  council: Council;
};

const CouncilsCardActions: FC<CouncilsCardActionsProps> = ({ council }) => {
  const { t } = useTranslate();

  return (
    <div className="col-4">
      <div className="sp-plan-action card-inner justify-content-center">
        <div className="sp-plan-btn d-flex flex-column">
          <Link
            to={getCouncilDetailsUrl(council)}
            className="btn btn-dim btn-white btn-outline-primary"
          >
            <span>{t('SeeAsAdmin')}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CouncilsCardActions;
