/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

import { RealtyTitle } from 'types';
import TitlesTableRow from './TitlesTableRow';

type Props = {
  titles: RealtyTitle[];
  // eslint-disable-next-line no-unused-vars
  handleRemoveTitle: (index: number) => void;
};

const TitlesTableBody: FC<Props> = ({ titles, handleRemoveTitle }) => (
  <>
    <tbody>
      {titles?.map((title: RealtyTitle, index: number) => (
        <tr className="nk-tb-item" key={`title-row-${index}`}>
          <TitlesTableRow
            index={index}
            title={title}
            handleRemoveTitle={handleRemoveTitle}
          />
        </tr>
      ))}
    </tbody>
  </>
);

export default TitlesTableBody;
