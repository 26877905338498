import { SocietyAuditor } from 'types';

import { getSocietyAuditorStatus } from './getSocietyAuditorStatus';

export const getSocietyAuditorStatusData = (auditor: SocietyAuditor) => {
  const status = getSocietyAuditorStatus(auditor);

  const statusLabel = status ? status.label : '-';
  const statusBadge = status ? status.badge : '-';

  return { statusLabel, statusBadge };
};
