import { axiosV1 } from 'interceptors/axios';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import types from 'redux/actions/actionTypes';

import { dispatchError } from 'utils/dispatchError';
import { decryptResponse } from 'utils/token';

import { addAlert } from '../../_shared/redux/alertActions';

export function createTenderOffer(tenderOffer) {
  return {
    type: types.CREATE_TENDER_OFFER,
    tenderOffer,
  };
}

export const getTenderOffers = (societyId) => async (dispatch) => {
  try {
    const query = societyId ? { params: { societyId } } : { params: {} };

    const { data } = await axiosV1.get(`/tenderOffers`, query);

    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.GET_TENDER_OFFERS,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updateTenderOffer =
  (tenderOfferId, updateTenderOfferData) => async (dispatch) => {
    const body = {
      _id: tenderOfferId,
      ...updateTenderOfferData,
    };

    try {
      const { data } = await axiosV1.put(`/tenderOffers`, body);

      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.UPDATE_TENDER_OFFER,
        tenderOffer: decodedData,
      });
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deleteTenderOffer = (tenderOffer) => async (dispatch) => {
  try {
    const { data } = await axiosV1.delete(`/tenderOffers/${tenderOffer}`);

    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.DELETE_TENDER_OFFER,
      tenderOffer: decodedData,
    });
    dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
    return decodedData['_id'];
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};
