import { SocietyAuditor } from 'types';
import societyAuditorStatus from '../constants/societyAuditorStatus';

export const getSocietyAuditorStatus = (auditor: SocietyAuditor) => {
  const activeStatus = societyAuditorStatus[0];
  const inactiveStatus = societyAuditorStatus[1];

  const datesDiff = new Date(auditor?.endDate).getTime() - new Date().getTime();
  if (!auditor?.endDate || datesDiff > 0) return activeStatus;
  return inactiveStatus;
};
