import { FC } from 'react';

import { Document } from 'types';

import DownloadDocument from './components/DownloadDocument';
import DocumentSpinner from './components/DocumentsSpinner';
import DocumentsOptionsList from './components/DocumentsOptionsList';

type Props = {
  index: number;
  documentsCount: number;
  documents: Document[];
};

const DocumentsColumn: FC<Props> = ({ index, documentsCount, documents }) => {
  const showLoadedDocuments = () => {
    if (documents?.length === 1) {
      return <DownloadDocument documentId={documents[0]?._id} />;
    }
    if (documentsCount > 1) {
      if (documents.length === 1) {
        return <DocumentSpinner />;
      }
      return <DocumentsOptionsList documents={documents} />;
    }
    return <span>-</span>;
  };
  return (
    <td
      className="nk-tb-col tb-col-xl text-center"
      key={`member-documents-${index}`}
    >
      {documentsCount > 0 ? (
        showLoadedDocuments()
      ) : (
        <span className="btn btn-icon disabled">
          <em className="icon ni ni-file-download" />
        </span>
      )}
    </td>
  );
};

export default DocumentsColumn;
