import { FC } from 'react';
import { format } from 'date-fns';

import { RealtyLicense } from 'types';

import { DATE_FORMAT } from 'constants/defaultConstants';

type Props = {
  index: number;
  license: RealtyLicense;
  // eslint-disable-next-line no-unused-vars
  handleRemoveLicense: (index: number) => void;
};

const LicensesTableRow: FC<Props> = ({
  index,
  license,
  handleRemoveLicense,
}) => (
  <>
    <td className="text-left" key={`license-name-${index}`}>
      {license?.name}
    </td>

    <td className="text-left" key={`license-date-${index}`}>
      {license?.date && `${format(new Date(license.date), DATE_FORMAT)}`}
    </td>

    <td className="text-center" key={`license-remove-${index}`}>
      <button
        type="button"
        className="text-soft btn btn-icon pt-0 pb-0"
        onClick={() => handleRemoveLicense(index)}
      >
        <em className="icon ni ni-trash" />
      </button>
    </td>
  </>
);

export default LicensesTableRow;
