import { FC } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { CouncilParticipant } from 'types';

import AttendanceTable from './AttendanceTable';

type Props = {
  councilId: string;
};

const CouncilDetailsAttendance: FC<Props> = ({ councilId }: Props) => {
  const councils = useSelector((state: State) => state.councils);

  const council = councils.find((council) => council._id === councilId);

  const societyId = council?.society;
  const participants = council?.participants || [];

  return (
    <div className="nk-content-body pt-4">
      <AttendanceTable
        societyId={societyId}
        councilId={councilId}
        participants={participants as CouncilParticipant[]}
      />
    </div>
  );
};

export default CouncilDetailsAttendance;
