import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  hasMissingSigners: boolean;
  handleRequestSignature: () => void;
};

const RequestSignatureModalFooter: FC<Props> = ({
  hasMissingSigners,
  handleRequestSignature,
}: Props) => {
  const { t } = useTranslate();

  return (
    <Modal.Footer>
      <button
        type="button"
        className="btn btn-lg btn-primary"
        onClick={handleRequestSignature}
        disabled={hasMissingSigners}
      >
        {t('RequestSignature')}
      </button>
    </Modal.Footer>
  );
};

export default RequestSignatureModalFooter;
