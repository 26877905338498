import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Realty } from 'types';

import DetailsHeaderSection from 'modules/_shared/components/Sections/DetailsHeaderSection';
import DetailsListSection from 'modules/_shared/components/Sections/DetailsListSection';

import { getPersonalDetailsList } from '../utils/getPersonalDetailsList';

type RealtyDetailsPersonalTabProps = {
  realty: Realty;
};

const RealtyDetailsPersonalTab: FC<RealtyDetailsPersonalTabProps> = ({
  realty,
}) => {
  const { t } = useTranslate();

  const details = getPersonalDetailsList(realty, t);

  return (
    <>
      <DetailsHeaderSection
        title={t('Information')}
        description={t('BasicRealtyInformation')}
      />

      <DetailsListSection data={details} />
    </>
  );
};

export default RealtyDetailsPersonalTab;
