import { SocietyRepresentative } from 'types';
import societyRepresentativeStatus from '../constants/societyRepresentativeStatus';

export const getSocietyRepresentativeStatus = (
  representative: SocietyRepresentative
) => {
  const activeStatus = societyRepresentativeStatus[0];
  const inactiveStatus = societyRepresentativeStatus[1];

  const datesDiff =
    new Date(representative?.endDate).getTime() - new Date().getTime();
  if (!representative?.endDate || datesDiff > 0) return activeStatus;
  return inactiveStatus;
};
