import { DATE_FORMAT } from 'constants/defaultConstants';
import { format } from 'date-fns';
import i18n from 'i18n/config';
import { getAdminMemberStatusData } from 'modules/administratives/pages/AdminMembers/utils/getAdminMemberStatusData';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import { Society, SocietyDirector } from 'types';

export const getDirectorRoamingList = (
  director: SocietyDirector,
  roamingDirectors: any[],
  society: Society
) => {
  const ACTIVE_STATUS_LABEL = 'Valid';

  const filteredDirectors = roamingDirectors?.filter(
    (roamingDirector) =>
      roamingDirector?.email === director?.email ||
      (roamingDirector?.cif === director?.cif && director?.cif !== '')
  );

  const societyDirectors = society?.directors || [];

  const directors: any = [];

  filteredDirectors.forEach((roamingDirector) => {
    const director = societyDirectors.find(
      (societyDirector) => societyDirector._id === roamingDirector.id
    );

    const { statusLabel, statusBadge } = getAdminMemberStatusData(director);

    if (statusLabel === ACTIVE_STATUS_LABEL) {
      directors.push({
        society: roamingDirector?.society,
        role:
          directorTypes(i18n).name?.[
            director?.directorType as keyof typeof directorTypes
          ] || '-',
        startDate: director?.initDate
          ? format(new Date(director.initDate), DATE_FORMAT)
          : '-',
        status: statusLabel,
        statusBadge,
      });
    }
  });

  return directors;
};
