import { axiosV1 } from 'interceptors/axios';

import { decryptResponse } from 'utils/token';

export const getPartnersPrimaryEmails = async (societyId: string) => {
  const query = { params: { primaryEmails: societyId } };
  try {
    const { data } = await axiosV1.get(`/partners`, query);

    const decodedData = await decryptResponse(data);

    return decodedData;
  } catch (error) {
    throw new Error();
  }
};
