import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { State } from 'redux/initialState';

import CustomLoading from 'modules/_shared/components/CustomLoading';

import { useGetActualSociety } from 'hooks/useGetActualSociety';
import { useGetMenu } from '../../hooks/useGetMenu';
import RealtiesPageHeader from './components/RealtiesPageHeader';
import RealtiesPageBody from './components/RealtiesPageBody';
import { useRealties } from './hooks/useRealties';

function Realties() {
  const { societyId } = useParams();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const realties = useSelector((state: State) => state.realties);

  useGetMenu();
  useGetActualSociety(societyId);

  const { isLoading } = useRealties();

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <RealtiesPageHeader
            societyId={societyId}
            societyName={actualSociety?.name}
            realtiesCount={realties?.length || 0}
          />

          <RealtiesPageBody realties={realties} />
        </div>
      </div>

      {/* {isLoading && <CustomLoading />} */}
    </>
  ) : (
    <CustomLoading />
  );
}

export default Realties;
