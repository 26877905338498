/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type DetailsTableItem = {
  label?: string;
  value: string | number | Record<string, any> | string[] | undefined;
};

type DetailsTableSectionProps = {
  title?: string;
  columns?: string[];
  data?: DetailsTableItem[];
};

type ObjectRowProps = {
  item: DetailsTableItem;
  index: number;
};

type SingleRowProps = {
  item: DetailsTableItem;
};

const DetailsTableSection: FC<DetailsTableSectionProps> = ({
  title,
  columns,
  data,
}) => {
  const { t } = useTranslate();

  const ObjectRow = ({ item, index }: ObjectRowProps) => (
    <td className="ws-pre-wrap">
      {Object.entries(item.value as Record<string, any>).map(([key, value]) => (
        <p key={`${index}-${key}`}>{`${t(key)}: ${value || '-'}`}</p>
      ))}
    </td>
  );

  const SingleRow = ({ item }: SingleRowProps) => (
    <td className="ws-pre-wrap">
      {(item.value as string | number | string[]) || '-'}
    </td>
  );

  return (
    <section className="card-inner">
      {title && <h6 className="overline-title-alt mb-2">{title}</h6>}

      <div className="row card card-bordered card-preview">
        <table className="table table-striped">
          {columns && columns.length > 0 && (
            <thead>
              <tr>
                {columns.map((column) => (
                  <th scope="col" key={column}>
                    {t(column)}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          {data && data.length > 0 && (
            <tbody>
              {data?.map((item: DetailsTableItem, index: number) => (
                <tr key={`realty-details-row-${index}`}>
                  <td className="fw-bold">{item.label}</td>
                  {typeof item.value === 'object' ? (
                    <ObjectRow item={item} index={index} />
                  ) : (
                    <SingleRow item={item} />
                  )}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </section>
  );
};

export default DetailsTableSection;
