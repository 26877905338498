// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react';

type PageProps = {
  id?: string;
  className?: string;
  children: React.ReactNode;
};

const Page: FC<PageProps> = ({ id, children, className }) => (
  <div className={`nk-content-body page-wrapper ${className}`} id={id}>
    <div className="nk-content-wrap">{children}</div>
  </div>
);

export default Page;
