import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { Document } from 'types';

type TemplateViewerHeaderProps = {
  template: Document;
  handleCloseModal: () => void;
};

const TemplateViewerHeader: FC<TemplateViewerHeaderProps> = ({
  template,
  handleCloseModal,
}) => {
  const { t } = useTranslate();

  return (
    <Modal.Header>
      <h5 className="modal-title" style={{ color: '#364a63' }}>
        {t('Template')} &nbsp;
        <span className="text-muted ff-alt fs-14px text-right">
          ({template.name})
        </span>
      </h5>

      <a className="close cursor-pointer" onClick={handleCloseModal}>
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default TemplateViewerHeader;
