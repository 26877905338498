import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

type Props = {
  page: number;
  handleChangePage: (page: number) => void; // eslint-disable-line
};

const CouncilDetailsSignatureNavigationHeader: FC<Props> = ({
  page,
  handleChangePage,
}: Props) => {
  const { t } = useTranslate();

  const navigationOptions = [
    { key: 'act', title: t('Act'), enabled: true },
    { key: 'certification', title: t('Certification'), enabled: true },
  ];

  return (
    <ul className="nk-nav nav nav-tabs">
      {navigationOptions.map((option, index) => (
        <li key={option.key} className="nav-item">
          <p
            className={`nav-link ${page === index ? 'active' : ''} ${
              option.enabled ? '' : 'disabled text-muted'
            }`}
            onClick={() => handleChangePage(index)}
          >
            <span>{option.title}</span>
          </p>
        </li>
      ))}
    </ul>
  );
};

export default CouncilDetailsSignatureNavigationHeader;
