import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { getActualSociety } from 'utils/filters';

export const useGetActualSociety = (societyId: string | undefined) => {
  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  useEffect(() => {
    const hasRequiredData = !!(user && societyId);

    if (hasRequiredData && !actualSociety) {
      getActualSociety(user, societyId);
    }

    const isSocietyDifferent = actualSociety?._id !== societyId;

    if (isSocietyDifferent) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);
};
