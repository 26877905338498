import { FC } from 'react';

import { Realty } from 'types';
import RealtiesTable from './RealtiesTable';

type Props = {
  realties: Realty[];
};

const RealtiesPageBody: FC<Props> = ({ realties }) => (
  <div className="nk-block">
    <RealtiesTable realties={realties} />
  </div>
);

export default RealtiesPageBody;
