import types from 'redux/actions/actionTypes';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { Realty } from 'types';
import { axiosV2 } from 'interceptors/axios';

export const getRealty = (realtyId: string) => async (dispatch: any) => {
  try {
    const { data } = await axiosV2.get(`/realties/${realtyId}`);
    const decodedData = await decryptResponse(data);

    return decodedData;
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const getRealties = (societyId: string) => async (dispatch: any) => {
  const query = { params: { societyId } };
  try {
    const { data } = await axiosV2.get(`/realties`, query);
    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.GET_REALTIES,
      realties: decodedData,
    });

    return decodedData;
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const createRealty =
  (realtyData: Partial<Realty>) => async (dispatch: any) => {
    try {
      const body = { ...realtyData };
      const { data } = await axiosV2.post(`/realties`, body);
      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.CREATE_REALTY,
        realty: decodedData,
      });
      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };

export const updateRealty =
  (realtyData: Partial<Realty>) => async (dispatch: any) => {
    try {
      const body = { ...realtyData };

      const { data } = await axiosV2.put(`/realties`, body);
      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_REALTY,
        realty: decodedData,
      });

      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };

export const deleteRealty = (realtyId: string) => async (dispatch: any) => {
  try {
    await axiosV2.delete(`/realties/${realtyId}`);

    dispatch({
      type: types.DELETE_REALTY,
      realty: { _id: realtyId },
    });
  } catch (error) {
    dispatchError(error, dispatch);
  }
};
