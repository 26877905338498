/* eslint-disable no-undef */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';

import { RealtyPlot } from 'types';
import PlotsViewForm from './PlotsViewForm';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';
import PlotsTable from './PlotsTable/PlotsTable';

const PlotsView: FC = () => {
  const { t } = useTranslate();

  const [expandedView, setExpandedView] = useState<boolean>(false);

  const { data, actions } = useAddRealtyModal();

  const { plots } = data;

  const handleChangeView = () => {
    setExpandedView((prev) => !prev);
  };

  const handleRemovePlot = (index: number) => {
    const newPlots = plots.filter((_: RealtyPlot, i: number) => i !== index);

    actions.handleChangePlots(newPlots);
  };

  return (
    <>
      <div className="row gy-4">
        <div className="col-6 d-flex align-items-center">
          <h5 className="mb-0">{t('Plots')}</h5>
        </div>
        <div className="col-6 text-right">
          <button
            type="button"
            className="badge btn-primary"
            onClick={handleChangeView}
          >
            <em
              className={`icon ni ni-chevron-${expandedView ? 'up' : 'down'}`}
            />
          </button>
        </div>
      </div>

      {expandedView && (
        <>
          <PlotsViewForm />

          {plots?.length > 0 && (
            <PlotsTable plots={plots} handleRemovePlot={handleRemovePlot} />
          )}
        </>
      )}
    </>
  );
};

export default PlotsView;
