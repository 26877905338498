export default [
  {
    value: 'ACT',
    label: 'Act',
  },
  {
    value: 'CERTIFICATION',
    label: 'Certification',
  },
];
