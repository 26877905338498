import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { setModal } from 'modules/_shared/redux/modalActions';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import NoDocumentsAlert from 'modules/_shared/components/NoDocumentsAlert';
import { useGetCouncil } from '../hooks/useGetCouncil';

import PrivateDocumentsSection from './PrivateDocumentsSection';
import MembersDocumentsSection from './MembersDocumentsSection';
import AddCouncilDocumentModal from './AddCouncilDocumentModal';

type Props = {
  councilId: string;
  isAdminView?: boolean;
};

const CouncilDetailsDocuments: FC<Props> = ({
  councilId,
  isAdminView = true,
}: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const isAdmin = true; // TODO: Implement permissions
  const isReadOnly = false; // TODO: Implement permissions

  const {
    data: council,
    isLoading,
    action: fetchCouncilData,
  } = useGetCouncil(councilId);

  const hasNoDocuments =
    !isLoading &&
    (council?.publicDocuments?.length ?? 0) === 0 &&
    (council?.privateDocuments?.length ?? 0) === 0;

  const handleAddDocument = () => {
    if (council) {
      dispatch(
        setModal(
          <AddCouncilDocumentModal
            council={council}
            fetchCouncilData={fetchCouncilData}
          />
        )
      );
    }
  };

  return (
    <div className="nk-content-body pt-4">
      {hasNoDocuments ? (
        <div className="nk-block">
          {isAdmin && (
            <div className="nk-block-head-content text-right mb-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddDocument}
                disabled={isReadOnly}
              >
                <em className="icon ni ni-upload mr-1" />
                {t('UpDocument')}
              </button>
            </div>
          )}
          <NoDocumentsAlert title={t('NoCouncilDocuments')} />
        </div>
      ) : (
        <div className="card card-bordered">
          <div className="card-inner-group">
            {council && (
              <>
                <MembersDocumentsSection
                  council={council}
                  fetchCouncilData={fetchCouncilData}
                />

                {isAdminView && (
                  <PrivateDocumentsSection
                    council={council}
                    fetchCouncilData={fetchCouncilData}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      {isLoading && <CustomLoading />}
    </div>
  );
};

export default CouncilDetailsDocuments;
