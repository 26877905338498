import { FC } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { State } from 'redux/initialState';

import { dateFormat } from 'constants/formats';

import { SocietyAuditor } from 'types';

import CellData from 'modules/_shared/components/Tables/CellData';
import DocumentsColumn from 'modules/_shared/components/DocumentsColumn';
import ToggleMenu from 'modules/_shared/components/ToggleMenu';
import { setModal } from 'modules/_shared/redux/modalActions';

import Swal from 'sweetalert2';
import successSwal from 'utils/successSwal';

import AddSocietyAuditor from 'modules/_shared/components/Modals/AddSocietyAuditor';

import useGetSelectedValues from '../hooks/useGetSelectedValues';
import useGetSocietyAuditorDocuments from '../hooks/useGetSocietyAuditorDocuments';
import { removeSocietyAuditor } from '../utils/removeSocietyAuditor';

type Props = {
  index: number;
  societyId: string;
  auditor: SocietyAuditor;
  isReadOnly?: boolean;
};

const SocietyAuditorsTableRow: FC<Props> = ({
  index,
  societyId,
  auditor,
  isReadOnly,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const auditorId = auditor?._id;
  const auditorName = auditor?.socialDenomination || '-';
  const auditorImage = auditor?.image;
  const auditorEmail = auditor?.email;
  const auditorInitDate = auditor?.initDate;
  const auditorEndDate = auditor?.endDate;

  const { auditorStatusLabel, auditorStatusBadge } = useGetSelectedValues({
    auditor,
  }).data;

  const { auditorDocuments } = useGetSocietyAuditorDocuments({
    societyId,
    auditorId: auditor?._id,
  }).data;

  const handleDeleteAuditor = () => {
    Swal.fire({
      icon: 'warning',
      title: `<h4 className="nk-block-title page-title">${t(
        'AreYouSureAboutDelAuditor'
      )}  `,
      html: `<h3 class="text-primary fw-normal">${auditorName} </h3><br /><div class="caption-text">${t(
        'ThisProcedureCantUndo'
      )}</div>`,
      confirmButtonText: t('OkDelete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await removeSocietyAuditor(
          societyId,
          actualSociety?.auditors,
          auditor?._id
        );
        successSwal(t('AuditorSuccessfullyRemoved'));
      }
    });
  };

  const handleEditAuditor = () => {
    const auditorData = {
      socialDenomination: auditorName,
      cif: auditor?.cif,
      nationality: auditor?.nationality,
      address: auditor?.address,
      email: auditorEmail,
      initDate: auditorInitDate,
      endDate: auditorEndDate,
      documents: auditorDocuments,
      image: auditorImage,
    };

    dispatch(
      setModal(
        <AddSocietyAuditor
          societyId={societyId}
          action="EDIT"
          auditorData={auditorData}
          auditorId={auditorId}
          userId={user['_id']}
        />
      )
    );
  };

  const toggleOptions = [
    <a
      className="d-flex cursor-pointer"
      key={`auditor-edit-option-${index}`}
      onClick={handleEditAuditor}
    >
      <em className="icon ni ni-edit fs-20px" />
      <span className="ml-1">{t('Edit')}</span>
    </a>,
    <a
      className="d-flex cursor-pointer"
      key={`auditor-delete-option-${index}`}
      onClick={handleDeleteAuditor}
    >
      <em className="icon ni ni-trash fs-20px" />
      <span className="ml-1">{t('Delete')}</span>
    </a>,
  ];

  return (
    <>
      <td className="nk-tb-col tb-col-xl" key={`auditor-name-${index}`}>
        <span className="text-primary font-weight-bold cursor-pointer">
          {CellData.Adminstrative({
            name: auditorName,
            image: auditorImage,
            email: auditorEmail,
            url: undefined,
          })}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl" key={`auditor-initdate-${index}`}>
        <span>
          {auditorInitDate
            ? format(new Date(auditorInitDate), dateFormat)
            : '-'}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl" key={`auditor-enddate-${index}`}>
        <span>
          {auditorEndDate
            ? format(new Date(auditorEndDate), dateFormat)
            : t('Indefinite')}
        </span>
      </td>

      <td className="nk-tb-col" key={`auditor-status-${index}`}>
        <span className={`badge badge-dot badge-${auditorStatusBadge}`}>
          {t(auditorStatusLabel)}
        </span>
      </td>

      <DocumentsColumn
        index={index}
        documentsCount={auditorDocuments?.length || 0}
        documents={auditorDocuments}
      />

      {!isReadOnly && (
        <td className="nk-tb-col" key={`auditor-options-${index}`}>
          <ToggleMenu toggleOptions={toggleOptions} />
        </td>
      )}
    </>
  );
};

export default SocietyAuditorsTableRow;
