/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getExcelPartners } from 'utils/getExcelData';
import { read } from 'xlsx';

import { setModal } from 'modules/_shared/redux/modalActions';
import {
  addPartners,
  capitalIncreaseAction,
  constitution,
} from 'modules/_shared/redux/modalsActions';
import { createPartnerCategory } from 'modules/partners/redux/partnerCategoryActions';
import { store } from 'redux/store';

import {
  generateEmail,
  getActualSociety,
  hasSocietyConstitutionOperation,
} from 'utils/filters';
import trackEvent from 'utils/trackEvent';

import { OLDEST_DATE } from 'constants/defaultConstants';
import eventTypes from 'constants/eventTypes';
import {
  getAddressColumn,
  getBankAccountColumn,
  getBirthDateColumn,
  getCivilStatusColumn,
  getEmailColumn,
  getFiscalResidenceColumn,
  getHoldingClassColumn,
  getInvestorTypeColumn,
  getLegalPersonColumn,
  getNameColumn,
  getNationalityColumn,
  getNroEmployeeColumn,
  getNroIdColumn,
  getNroPPSSColumn,
  getPrimaPSColumn,
  getTypeIdColumn,
} from 'constants/getExcelColumns';
import operationTypes from 'constants/operationTypes';
import sizes from 'constants/sizes';
import transactionTypes from 'constants/transactionTypes';

import UploadDocument from 'modules/documents/components/UploadDocument';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import PartnersPreview from './components/PartnersPreview';
import errors from './constants/errors';
import getIsNaturalPerson from './utils/getIsNaturalPerson';

import '../Modals.scss';

import getInvalidEmails from './utils/getInvalidEmails';

const templateURL = process.env.REACT_APP_TEMPLATE_PARTNERS_URL;

const TYPE_FILES_ALLOWED = '.csv,.xls,.xlsx';

const UploadPartnerModal = () => {
  const { t } = useTranslate();
  const { societyId } = useParams();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const buttonRef = useRef(null);

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const holdingClasses = useSelector((state) => state.holdingClasses);
  const partnerCategories = useSelector((state) => state.partnerCategories);

  const [file, setFile] = useState();
  const [isValidFile, setIsValidFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewOpen, setIsReviewOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');

  const [partners, setPartners] = useState([]);
  const [newCategories, setNewCategories] = useState([]);
  const [actualFrom, setActualFrom] = useState();
  const [numerationRefs, setNumerationRefs] = useState();
  const [operationType, setOperationType] = useState('');
  const [operationDate, setOperationDate] = useState('');
  const [nominalValue, setNominalValue] = useState(0);
  const [ordinaryClass] = useState(holdingClasses?.[0]?.['_id']);

  const getPartnerCategory = (newPartner, categories) => {
    const category = getInvestorTypeColumn(newPartner);

    const isExistingCategory = partnerCategories?.find(
      (partnerCategory) =>
        partnerCategory?.name?.toUpperCase() === category?.toUpperCase()
    );

    if (isExistingCategory) return isExistingCategory._id;

    return categories?.[category] || null;
  };

  const uploadNewCategories = async (categories) => {
    try {
      const newCategories = categories.map((category) => ({
        name: category,
        description: '',
        society: actualSociety['_id'],
      }));

      const uploadPromises = newCategories.map((category) =>
        store.dispatch(createPartnerCategory(category, false))
      );

      const uploadedCategories = await Promise.all(uploadPromises);

      const categoriesMap = uploadedCategories.reduce((acc, curr) => {
        if (curr && curr.name && curr['_id']) {
          acc[curr.name] = curr['_id'];
        }
        return acc;
      }, {});

      return categoriesMap;
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleChangeFile = (newFile) => {
    setFile(newFile);
  };

  async function uploadList() {
    try {
      let categories = {};
      if (newCategories.length > 0) {
        categories = await uploadNewCategories(newCategories);
      }

      const uniqueCifs = [
        ...new Set(partners.map((partner) => getNroIdColumn(partner))),
      ];

      const uniquePartners = uniqueCifs.map((cif) =>
        partners.find((partner) => getNroIdColumn(partner) === cif)
      );

      const existingCifs = actualSociety?.partners
        ?.map((partner) => partner?.cif || null)
        ?.filter((cif) => cif);

      const formattedPartners = uniquePartners
        ?.filter((partner) => !existingCifs.includes(getNroIdColumn(partner)))
        ?.map((newPartner, index) => ({
          society: actualSociety['_id'],
          date: operationDate,
          user: user['_id'],
          societyName: actualSociety?.name,
          userEmail: user.email,
          source: 'UPLOAD',
          email:
            getEmailColumn(newPartner) ||
            generateEmail(
              actualSociety,
              actualSociety?.currentPartner + index + 1
            ),
          cif: getNroIdColumn(newPartner),
          idType: getTypeIdColumn(newPartner),
          name: getNameColumn(newPartner),
          category: getPartnerCategory(newPartner, categories),
          birthdate: getBirthDateColumn(newPartner),
          civilStatus: getCivilStatusColumn(newPartner),
          shares: getNroPPSSColumn(newPartner),
          nationality: getNationalityColumn(newPartner),
          address: getAddressColumn(newPartner),
          shareClass: getHoldingClassColumn(newPartner) || ordinaryClass,
          employeeNumber: getNroEmployeeColumn(newPartner),
          bankAccountNumber: getBankAccountColumn(newPartner),
          taxAddress: getFiscalResidenceColumn(newPartner),
          isNaturalPerson: getIsNaturalPerson(getLegalPersonColumn(newPartner)),
        }));

      const uploadedPartners = dispatch(addPartners(formattedPartners));

      uploadedPartners.then(
        (response) => {
          if (
            operationType === operationTypes.CONSTITUTION ||
            operationType === operationTypes.CAPITAL_INCREASE
          ) {
            const transactionType =
              operationType === operationTypes.CONSTITUTION
                ? transactionTypes.CONSTITUTION
                : transactionTypes.CAPITAL_INCREASE;
            let finalMovements = [];
            let finalTransactions = [];
            let currentFrom = actualFrom;
            partners.forEach((partner) => {
              let currentPartner = response?.find(
                (resp) => resp?.cif === getNroIdColumn(partner)
              );
              const holdingClass = holdingClasses?.find(
                (currClass) =>
                  currClass?.name?.toUpperCase() ===
                  getHoldingClassColumn(partner)?.toUpperCase()
              )?.['_id'];
              finalMovements.push({
                partner: currentPartner?.['_id'],
                shareFrom: currentFrom[numerationRefs[holdingClass]],
                shareTo:
                  currentFrom[numerationRefs[holdingClass]] +
                  getNroPPSSColumn(partner) -
                  1,
                shareClass: holdingClass || ordinaryClass,
                sharePremium: getPrimaPSColumn(partner) || 0,
                operation: 'THIS',
              });
              finalTransactions.push({
                partner: currentPartner?.['_id'],
                shareFrom: currentFrom[numerationRefs[holdingClass]],
                shareTo:
                  currentFrom[numerationRefs[holdingClass]] +
                  getNroPPSSColumn(partner) -
                  1,
                shareClass: holdingClass || ordinaryClass,
                sharePremium: getPrimaPSColumn(partner) || 0,
                transactionType,
              });
              currentFrom[numerationRefs[holdingClass]] +=
                getNroPPSSColumn(partner);
            });
            if (operationType === operationTypes.CONSTITUTION) {
              dispatch(
                constitution({
                  movements: finalMovements,
                  transactions: finalTransactions,
                  date: operationDate,
                  nominalValue: +nominalValue,
                  society: actualSociety['_id'],
                  user: user['_id'],
                  documents: [],
                })
              );
            } else {
              dispatch(
                capitalIncreaseAction({
                  movements: finalMovements,
                  transactions: finalTransactions,
                  date: operationDate,
                  nominalValue: actualSociety?.nominalValue,
                  society: actualSociety['_id'],
                  user: user['_id'],
                  documents: [],
                })
              );
            }
          }
          dispatch(setModal(null));

          trackEvent(mixpanel, eventTypes.ADD_PARTNERS, {
            userId: user?._id,
            userName: user?.name,
            userEmail: user?.email,
            societyId: actualSociety?._id,
            societyName: actualSociety?.name,
            operation: eventTypes.ADD_PARTNERS,
            totalPartners: formattedPartners?.length,
          });
        },
        (error) => {
          console.log('ERROR UPLOADING PARTNERS', error);
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const initConstitutionNumerations = () => {
    if (holdingClasses?.length) {
      const initial = holdingClasses?.reduce(
        (acc, currentClass) => {
          acc.from[currentClass['_id']] = 1;
          acc.refs[currentClass['_id']] =
            currentClass.numerationOption || currentClass['_id'];
          return acc;
        },
        {
          from: {},
          refs: {},
        }
      );
      const finalRefs = Object.entries(initial.refs).reduce(
        (acc, [id, ref]) => {
          acc[id] = acc[initial.refs[ref]] || initial.refs[ref];
          return acc;
        },
        {}
      );
      setActualFrom(initial.from);
      setNumerationRefs(finalRefs);
    }
  };

  const initCapitalIncreaseNumerations = () => {
    if (holdingClasses?.length) {
      const initial = holdingClasses?.reduce(
        (acc, currentClass) => {
          acc.from[currentClass['_id']] = 1;
          acc.refs[currentClass['_id']] =
            currentClass.numerationOption || currentClass['_id'];
          return acc;
        },
        {
          from: {},
          refs: {},
        }
      );
      const refs = Object.entries(initial.refs).reduce((acc, [id, ref]) => {
        acc[id] = acc[initial.refs[ref]] || initial.refs[ref];
        return acc;
      }, {});
      const from = actualSociety?.shares?.reduce((acc, share) => {
        const classId = share.shareClass?.['_id'];
        if (share?.isActive && acc[refs[classId]] <= share.to)
          acc[refs[classId]] = share.to + 1;
        return acc;
      }, initial.from);
      setNumerationRefs(refs);
      setActualFrom(from);
    }
  };

  const getOperationDate = () => {
    const excludedOps = [
      operationTypes.ADD_PARTNERS,
      operationTypes.ADD_PARTNER,
      operationTypes.ADD_BENEFICIARY,
      operationTypes.STOCK_OPTION,
      operationTypes.PHANTOM_SHARE,
    ];
    const filteredOperations = actualSociety?.operations.filter(
      (operation) => !excludedOps.includes(operation.operationType)
    );
    if (!filteredOperations.length) return OLDEST_DATE;
    let lastOperation = filteredOperations.reduce((acc, op) =>
      new Date(acc.date) > new Date(op.date) ? acc : op
    );
    // Get last operation date from UTC to local timezone with new Date()
    lastOperation = new Date(lastOperation.date);
    lastOperation.setMinutes(lastOperation.getMinutes() + 1);
    return lastOperation;
  };

  const findDuplicatedCifsInSociety = (partnerList, society) => {
    const existingCifs = society?.partners?.map((partner) => partner?.cif);
    const result = partnerList
      .filter((partner) => existingCifs.includes(getNroIdColumn(partner)))
      .map((partner) => getNroIdColumn(partner));
    return result;
  };

  const findDuplicateCifInTemplate = (partnerList) => {
    const result = [];
    for (let i = 0; i < partnerList.length - 1; i += 1) {
      for (let j = i + 1; j < partnerList.length; j += 1) {
        if (getNroIdColumn(partnerList[i]) === getNroIdColumn(partnerList[j])) {
          result.push(getNroIdColumn(partnerList[i]));
        }
      }
    }
    return result;
  };

  const findNewPartnerCategoriesInTemplate = (partnerList) => {
    const existingCategoriesInSociety = partnerCategories?.map((category) =>
      category?.name?.toUpperCase()
    );
    const existingCategoriesInExcel = partnerList
      .map((partner) => getInvestorTypeColumn(partner))
      .filter((category) => category);

    const uniqueCategoriesInExcel = [...new Set(existingCategoriesInExcel)];

    const result = uniqueCategoriesInExcel.filter(
      (category) =>
        !existingCategoriesInSociety.includes(category?.toUpperCase())
    );

    return result;
  };

  const HasDuplicatedFields = (partnerList) => {
    for (let i = 0; i < partnerList.length - 1; i += 1) {
      for (let j = i + 1; j < partnerList.length; j += 1) {
        if (
          getNroIdColumn(partnerList[i]) === getNroIdColumn(partnerList[j]) &&
          (getNameColumn(partnerList[i])?.toUpperCase() !==
            getNameColumn(partnerList[j])?.toUpperCase() ||
            getEmailColumn(partnerList[i]) !== getEmailColumn(partnerList[j]) ||
            (getEmailColumn(partnerList[i]) === '' &&
              getEmailColumn(partnerList[j]) === ''))
        )
          return true;
      }
    }
    return false;
  };

  const formatKeyForReplaceAccents = (key) => {
    if (!key) return '';
    return key
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')
      ?.toUpperCase();
  };

  const mapObjectsToUppercaseKeys = (objectsList) => {
    if (!objectsList) return [];

    const result = objectsList?.map((object) => {
      const newObject = {};
      Object.keys(object).forEach((key) => {
        const formattedKey = formatKeyForReplaceAccents(key);
        newObject[formattedKey] = object[key];
      });
      return newObject;
    });
    return result;
  };

  const tagExistingPartners = (partnersList) => {
    if (!actualSociety?.partners?.length) return partnersList;

    const existingCifs = actualSociety?.partners
      ?.map((partner) => partner?.cif || null)
      ?.filter((cif) => cif);

    const result = partnersList.map((partner) => {
      if (existingCifs.includes(getNroIdColumn(partner))) {
        return { ...partner, isExisting: true };
      }
      return partner;
    });
    return result;
  };

  async function handleSelectFile(currentFile) {
    if (!currentFile) return null;

    setFile(currentFile);
    setOperationType(null);

    const data = await currentFile.arrayBuffer();
    const workbook = read(data);
    const excelPartners = getExcelPartners(workbook);
    const newPartners = mapObjectsToUppercaseKeys(excelPartners);

    if (!newPartners?.error) {
      const invalidEmails = getInvalidEmails(newPartners);

      if (newPartners.length === 0) {
        setIsValidFile(false);
        setErrorMessage(errors.DATA_NOT_FOUND);
      } else if (invalidEmails?.length > 0) {
        setIsValidFile(false);
        setErrorMessage(`${errors.INVALID_EMAILS} (${invalidEmails.join(
          ' | '
        )}).
            ${t('UpdateInfoAndTryAgain')}`);
      } else if (HasDuplicatedFields(newPartners)) {
        setIsValidFile(false);
        setErrorMessage(errors.DUPLICATED_FIELDS);
      } else {
        setIsValidFile(true);
        setPartners(tagExistingPartners(newPartners));
        setNewCategories(findNewPartnerCategoriesInTemplate(newPartners));
      }
    } else {
      setIsValidFile(false);
      setErrorMessage(errors.DATA_WRONG_MODEL);
    }
  }

  const handleUpload = () => {
    buttonRef.current.disabled = true;

    setTimeout(() => {
      uploadList();
    }, 500);
  };

  const hasMissingKeyFields = (partners) =>
    partners.some(
      (partner) =>
        !getNroIdColumn(partner) ||
        !getNameColumn(partner) ||
        !getNroPPSSColumn(partner)
    );

  const isAddButtonDisabled = () =>
    !file ||
    !isValidFile ||
    !operationType ||
    (operationType === operationTypes.CONSTITUTION && !+nominalValue) ||
    (operationType === operationTypes.CONSTITUTION && operationDate === '') ||
    (operationType === operationTypes.CAPITAL_INCREASE &&
      operationDate === '') ||
    (operationType === operationTypes.ADD_PARTNER && !partners);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (operationType === operationTypes.ADD_PARTNER) {
      const hasDuplicatedCifInSociety = [
        ...new Set([...findDuplicatedCifsInSociety(partners, actualSociety)]),
      ];
      const hasDuplicatedCifInTemplate = [
        ...new Set([...findDuplicateCifInTemplate(partners)]),
      ];
      if (hasDuplicatedCifInSociety.length > 0) {
        setWarningMessage(
          `${
            errors.SOCIETY_DUPLICATED_PARTNERS
          } (${hasDuplicatedCifInSociety.join(' | ')}).
          ${t('TheseShareholdersWontBeUploaded')}`
        );
      } else if (hasDuplicatedCifInTemplate.length > 0) {
        setErrorMessage(
          `${
            errors.TEMPLATE_DUPLICATED_PARTNERS
          } (${hasDuplicatedCifInTemplate.join(' | ')}).
          ${t('UpdateInfoAndTryAgain')}`
        );
        setIsValidFile(false);
      } else {
        const date = getOperationDate();
        setOperationDate(date);
        setIsValidFile(true);
      }
    }
    if (operationType === operationTypes.CONSTITUTION) {
      if (actualSociety?.partners?.length > 0) {
        setIsValidFile(false);
        setErrorMessage(errors.EXISTING_PARTNERS);
      } else {
        setIsValidFile(true);
        initConstitutionNumerations();
      }
    }
    if (operationType === operationTypes.CAPITAL_INCREASE) {
      if (hasMissingKeyFields(partners)) {
        setIsValidFile(false);
        setErrorMessage(errors.DATA_INCOMPLETE);
      } else {
        setIsValidFile(true);
        initCapitalIncreaseNumerations();
      }
    }
    setOperationDate('');
    setNominalValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationType]);

  return !isLoading ? (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('List')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col mb-4">
            <a
              href={templateURL}
              target="_blank"
              className="btn btn-block btn-white btn-outline-light"
              rel="noopener noreferrer"
            >
              <em className="icon ni ni-file-download" />
              <span className="fs-16px"> {t('DownloadExampleTemplateTo')}</span>
            </a>
          </div>
        </div>

        <form className="form-validate is-alter">
          <div className="row mb-4">
            <div className="col">
              <UploadDocument
                label="ListOfPartners"
                inputLabel={file?.name || t('DragFileToUpload')}
                file={file}
                setFile={handleChangeFile}
                accept={TYPE_FILES_ALLOWED}
              />

              <div className="form-group">
                <label className="form-label" htmlFor="default-01">
                  {t('SelectTheTypeOfOperation')}
                </label>

                <div className="row">
                  {hasSocietyConstitutionOperation(actualSociety) ? (
                    <div className="col-md-6">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="operation-capital-increase"
                          id="operation-capital-increase"
                          value={operationTypes.CAPITAL_INCREASE}
                          checked={
                            operationType === operationTypes.CAPITAL_INCREASE
                          }
                          onChange={(event) =>
                            setOperationType(event.target.value)
                          }
                          disabled={!file || !isValidFile}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="operation-capital-increase"
                        >
                          {t('CapitalExpansion')}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="operation-constitution"
                          id="operation-constitution"
                          value={operationTypes.CONSTITUTION}
                          checked={
                            operationType === operationTypes.CONSTITUTION
                          }
                          onChange={(event) =>
                            setOperationType(event.target.value)
                          }
                          disabled={!file || !isValidFile}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="operation-constitution"
                        >
                          {t('Constitution')}
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="operation-add-partner"
                        id="operation-add-partner"
                        value={operationTypes.ADD_PARTNER}
                        checked={operationType === operationTypes.ADD_PARTNER}
                        onChange={(event) =>
                          setOperationType(event.target.value)
                        }
                        disabled={!file || !isValidFile}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="operation-add-partner"
                      >
                        {t('JustAddPartner')}
                      </label>
                    </div>
                  </div>
                </div>

                {isValidFile &&
                  operationType === operationTypes.CONSTITUTION && (
                    <>
                      <hr />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="pay-amount">
                              {t('NominalValue')}
                            </label>
                            <div className="form-control-wrap">
                              <div className="form-text-hint">
                                <span className="overline-title">Eur</span>
                              </div>
                              <input
                                type="number"
                                className="form-control"
                                placeholder={t('NominalValueOfParticipati')}
                                value={nominalValue}
                                onChange={(event) =>
                                  setNominalValue(event.target.value)
                                }
                                onWheel={(event) => event.target.blur()}
                                min="0.000000001"
                                step="0.000000001"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="pay-amount">
                              {t('ConstitutionDate')}
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="date"
                                className="form-control date-picker"
                                value={operationDate}
                                onChange={(event) =>
                                  setOperationDate(event.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {isValidFile &&
                  operationType === operationTypes.CAPITAL_INCREASE && (
                    <>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="pay-amount">
                              {t('DateOfExpansion')}
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="date"
                                className="form-control date-picker"
                                value={operationDate}
                                onChange={(event) =>
                                  setOperationDate(event.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {isValidFile &&
                  operationType === operationTypes.ADD_PARTNER && (
                    <>
                      <hr />
                      <div className="row">
                        <div className="col">
                          <div
                            className="alert alert-icon alert-warning"
                            role="alert"
                          >
                            <em className="icon ni ni-alert-circle" />
                            <strong>{t('Important')}</strong>:{' '}
                            {t('ImportantOnlyGeneralInfor')}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>

              {file && isValidFile && !warningMessage && (
                <div className="alert alert-icon alert-success" role="alert">
                  <em className="icon ni ni-check-circle" />
                  <strong>{t('ValidatedFile')}</strong>.&nbsp;
                  {partners?.length > 0 &&
                    newCategories.length === 0 &&
                    t('FoundPartnersUpload', {
                      partners: partners.length,
                    })}
                  {partners?.length > 0 &&
                    newCategories.length > 0 &&
                    t('FoundPartnersAndCategoriesUpload', {
                      partners: partners.length,
                      categories: newCategories.length,
                    })}
                </div>
              )}

              {file && isValidFile && warningMessage && (
                <div className="alert alert-icon alert-warning" role="alert">
                  <em className="icon ni ni-alert-circle" />
                  <strong>{t('Warning')}</strong>. {warningMessage}
                </div>
              )}

              {file && !isValidFile && errorMessage && (
                <div className="alert alert-icon alert-danger" role="alert">
                  <em className="icon ni ni-alert-circle" />
                  <strong>{t('Non-ValidFile')}</strong>. {errorMessage}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between w-100">
            <button
              type="button"
              ref={buttonRef}
              className="btn btn-lg btn-primary"
              onClick={handleUpload}
              disabled={isAddButtonDisabled()}
            >
              {t('AddPartners')}
            </button>

            <button
              type="button"
              className="btn btn-lg btn-link"
              onClick={() => setIsReviewOpen(true)}
              disabled={!partners?.length || !isValidFile}
            >
              <em className="icon ni ni-eye mr-1" />
              {t('PartnersPreview')}
            </button>
          </div>
        </form>
      </Modal.Body>

      {actualSociety?.sendNotifications && (
        <Modal.Footer className="bg-light">
          <span className="sub-text">{t('ThePartnersWillReceiveNot')}</span>
        </Modal.Footer>
      )}

      {isReviewOpen && (
        <Modal
          size={sizes.XL}
          show
          onHide={() => setIsReviewOpen(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <PartnersPreview partners={partners} onClose={setIsReviewOpen} />
        </Modal>
      )}
    </>
  ) : (
    <CustomLoading />
  );
};

export default UploadPartnerModal;
