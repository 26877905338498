/* eslint-disable no-await-in-loop */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';

type GetTokenSilently = () => Promise<string>;
type LogoutFunction = () => Promise<void>;

interface RetryConfig extends AxiosRequestConfig {
  _retry?: boolean;
  _logoutAttempted?: boolean; // Add this to prevent infinite logout loops
  headers: any;
}

const axiosV1 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosV2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V2,
});

let tokenPromise: Promise<string> | null = null;
let isLoggingOut = false; // Add this to prevent multiple concurrent logout attempts

const redirectToLogin = () => {
  window.location.href = `#/login?returnUrl=${encodeURIComponent(
    window.location.pathname
  )}`;
};

export const setupInterceptors = (
  getTokenSilently: GetTokenSilently,
  logout: LogoutFunction
) => {
  const addAuthInterceptor = (axiosInstance: AxiosInstance) => {
    /* Request Interceptor */
    axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        try {
          if (!tokenPromise) {
            tokenPromise = getTokenSilently();

            setTimeout(() => {
              tokenPromise = null;
            }, 50000);
          }

          const token = await tokenPromise;

          config.headers = config.headers || {}; // eslint-disable-line no-param-reassign
          config.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign

          return config;
        } catch (error) {
          console.error('Error getting access token:', error); // eslint-disable-line no-console
          tokenPromise = null;

          redirectToLogin();

          return Promise.reject(error);
        }
      },
      (error: AxiosError) => {
        redirectToLogin();

        return Promise.reject(error);
      }
    );

    /* Response Interceptor */
    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,

      async (error: AxiosError) => {
        if (!error.config) {
          redirectToLogin();
          return Promise.reject(error);
        }
        const originalConfig = error.config as RetryConfig;
        const config = originalConfig as RetryConfig;
        const errorData = error.response?.data;

        if (
          (!originalConfig._logoutAttempted &&
            errorData?.code === 'SESSION_EXPIRED') ||
          errorData?.code === 'SESSION_INVALID' ||
          errorData?.code === 'TOKEN_EXPIRED'
        ) {
          originalConfig._logoutAttempted = true;

          // Prevent multiple concurrent logout attempts
          if (!isLoggingOut) {
            isLoggingOut = true;
            try {
              await logout();
              redirectToLogin();
            } catch (logoutError) {
              console.error('Logout failed:', logoutError);
              redirectToLogin();
            } finally {
              isLoggingOut = false;
            }
          } else {
            // Wait for ongoing logout to complete
            while (isLoggingOut) {
              await new Promise((resolve) => setTimeout(resolve, 100));
            }
            redirectToLogin();
          }

          throw new Error('Session expired. Please log in again.');
        }

        if (error.response?.status === 401 && !config._retry) {
          config._retry = true;

          try {
            tokenPromise = null;
            const token = await getTokenSilently();

            if (config.headers) {
              config.headers.Authorization = `Bearer ${token}`;
            }

            return axiosInstance(originalConfig);
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError); // eslint-disable-line no-console
            redirectToLogin();
            return Promise.reject(refreshError);
          }
        }

        if (error.response?.status === 401 || error.response?.status === 403) {
          redirectToLogin();
        }

        throw new Error(errorData?.error || 'API request failed');
      }
    );
  };

  addAuthInterceptor(axiosV1);
  addAuthInterceptor(axiosV2);
};

export { axiosV1, axiosV2 };
