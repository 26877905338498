import { store } from 'redux/store';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';

import errorSwal from 'utils/errorSwal';
import successSwal from 'utils/successSwal';

import CKEditor from 'modules/_shared/components/CKEditor';
import { updateDocument } from 'modules/documents/redux/documentActions';
import Loading from 'modules/_shared/components/Loading';
import { setModal } from 'modules/_shared/redux/modalActions';
import RequestSignatureModal from 'modules/administratives/modals/RequestSignatureModal';
import signatureTypes from 'constants/signatureTypes';
import EditorModuleHeader from './EditorModuleHeader';

import editorModuleSections from '../constants/editorModuleSections';
import { saveTemplate } from '../utils/saveTemplate';

import useGetTemplateData from '../hooks/useGetDocumentData';
import councilTemplateTypes from '../constants/councilTemplateTypes';

type Props = {
  councilId: string;
};

const CouncilDetailsAct: FC<Props> = ({ councilId }: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const councils = useSelector((state: State) => state.councils);

  const council = councils.find((council) => council._id === councilId);

  const section = editorModuleSections[0].value;

  const actDocumentId = council?.actDocument;
  const actTemplateId = council?.actTemplate;

  const templateData = useGetTemplateData({
    documentId: actDocumentId,
    templateId: actTemplateId,
  });

  const { templateName, document, htmlContent } = templateData.data;
  const { isLoading } = templateData;
  const { handleIsLoading } = templateData.actions;

  const documentName = document?.name;
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [editorInstance, setEditorInstance] = useState<any>(null);

  const handleChangeIsEditing = (value: boolean) => {
    setIsEditing(value);
  };

  const handleRequestSignature = () => {
    dispatch(
      setModal(
        <RequestSignatureModal
          councilId={councilId}
          templateHtmlContent={htmlContent || ''}
          templateType={councilTemplateTypes.ACT}
          signatureType={signatureTypes.COUNCIL_ACT}
        />
      )
    );
  };

  const handleSaveTemplate = async () => {
    try {
      handleIsLoading(true);
      const newContent = saveTemplate({
        editorInstance,
        handleChangeIsEditing,
      });

      await store.dispatch(
        updateDocument({ ...document, editorModel: newContent }, false)
      );

      successSwal(t('ActSavedSuccessfully'));
    } catch (error) {
      errorSwal(t('ErrorSavingAct'));
    } finally {
      handleIsLoading(false);
    }
  };

  useEffect(() => {
    if (htmlContent && editorInstance) {
      editorInstance.setData(htmlContent);
    }
  }, [htmlContent, editorInstance]);

  return (
    <div className="nk-content-body pt-4">
      <EditorModuleHeader
        section={section}
        templateName={templateName}
        isDraft
        isEditing={isEditing}
        editorInstance={editorInstance}
        councilId={councilId}
        isLoading={isLoading}
        handleChangeIsEditing={handleChangeIsEditing}
        handleRequestSignature={handleRequestSignature}
        handleSaveTemplate={handleSaveTemplate}
      />

      {documentName && htmlContent && (
        <div className="avoid-default-font">
          <CKEditor
            editorInstance={editorInstance}
            setEditorInstance={setEditorInstance}
            documentName={documentName}
            documentModel={htmlContent}
            enableMentions={false}
            isReadOnly={!isEditing}
          />
        </div>
      )}

      {isLoading && <Loading />}
    </div>
  );
};

export default CouncilDetailsAct;
