/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import menuTypes from 'constants/menuTypes';
import getRedirectURL from 'utils/getRedirectURL';

import { setMenuType } from 'modules/_shared/redux/menuActions';
import { resetStateSociety } from 'modules/_shared/redux/resetActions';

import AdministratedList from 'modules/society/pages/AdministratedList';
import ParticipatedList from 'modules/society/pages/ParticipatedList';
import BeneficiatedList from 'modules/beneficiaries/pages/BeneficiatedList';
import RepresentedList from 'modules/society/pages/RepresentedList';
import InvestedList from 'modules/partners/pages/InvestedList';
import InvitedList from 'modules/society/pages/InvitedList';
import DirectedList from 'modules/society/pages/DirectedList';

import 'assets/styles/Misc.scss';
import 'assets/styles/Layouts.scss';
import 'assets/styles/Components.scss';
import 'assets/styles/Bootstrap.scss';
import 'assets/styles/Print.scss';
import './Dashboard.scss';

const dashboardTypes = {
  ADMIN: 'ADMIN',
  PARTNER: 'PARTNER',
  BENEFICIARY: 'BENEFICIARY',
  INVESTOR: 'INVESTOR',
  REPRESENTANT: 'REPRESENTANT',
  GUEST: 'GUEST',
  DIRECTOR: 'DIRECTOR',
};

function Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [dashboardType, setDashboardType] = useState('');
  const [isCompletedRedirections, setIsCompletedRedirections] = useState(false);

  const [hasAdministrated, setHasAdministrated] = useState(false);
  const [hasParticipated, setHasParticipated] = useState(false);
  const [hasBeneficiated, setHasBeneficiated] = useState(false);
  const [hasInvested, setHasInvested] = useState(false);
  const [hasRepresented, setHasRepresented] = useState(false);
  const [hasInvited, setHasInvited] = useState(false);
  const [hasDirected, setHasDirected] = useState(false);

  const getGlobalRole = (currentUser) => {
    const {
      participated,
      beneficiated,
      administrated,
      invested,
      represented,
      invited,
      directed,
    } = currentUser.societies || {};

    const isPartner = !!participated?.length > 0;
    const isBeneficiary = !!beneficiated?.length > 0;
    const isAdministrator = !!administrated?.length > 0;
    const isInvestor = !!invested?.length > 0;
    const isRepresentant = !!represented?.length > 0;
    const isGuest = !!invited?.length > 0;
    const isDirector = !!directed?.length > 0;

    setHasParticipated(isPartner);
    setHasBeneficiated(isBeneficiary);
    setHasAdministrated(isAdministrator);
    setHasInvested(isInvestor);
    setHasRepresented(isRepresentant);
    setHasInvited(isGuest);
    setHasDirected(isDirector);

    if (isAdministrator) return dashboardTypes.ADMIN;
    if (isPartner) return dashboardTypes.PARTNER;
    if (isBeneficiary) return dashboardTypes.BENEFICIARY;
    if (isInvestor) return dashboardTypes.INVESTOR;
    if (isGuest) return dashboardTypes.GUEST;
    if (isDirector) return dashboardTypes.DIRECTOR;

    return dashboardTypes.PARTNER;
  };

  const checkRedirections = async () => {
    const defaultRoute = '#/';
    const { prefix, redirectUrl } = await getRedirectURL();

    window.location.href = prefix ? `${prefix}${redirectUrl}` : defaultRoute;

    setIsCompletedRedirections(true);
  };

  useEffect(() => {
    if (!user) return;
    setDashboardType(getGlobalRole(user));
  }, [user]);

  useEffect(() => {
    dispatch(setMenuType(menuTypes.MAIN));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompletedRedirections]);

  useEffect(() => {
    checkRedirections();
    dispatch(resetStateSociety());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isCompletedRedirections ? (
    <div className="nk-content-inner">
      {dashboardType === dashboardTypes.ADMIN && (
        <>
          <AdministratedList items={5} />
          {hasParticipated && <ParticipatedList items={5} />}
          {hasBeneficiated && <BeneficiatedList items={5} />}
          {hasInvested && <InvestedList items={5} />}
          {hasRepresented && <RepresentedList items={5} />}
          {hasInvited && <InvitedList items={5} />}
          {hasDirected && <DirectedList items={5} />}
        </>
      )}

      {dashboardType === dashboardTypes.PARTNER && (
        <>
          <ParticipatedList items={5} />
          {hasAdministrated && <AdministratedList items={5} />}
          {hasBeneficiated && <BeneficiatedList items={5} />}
          {hasInvested && <InvestedList items={5} />}
          {hasRepresented && <RepresentedList items={5} />}
          {hasInvited && <InvitedList items={5} />}
          {hasDirected && <DirectedList items={5} />}
        </>
      )}

      {dashboardType === dashboardTypes.BENEFICIARY && (
        <>
          <BeneficiatedList items={5} />
          {hasParticipated && <ParticipatedList items={5} />}
          {hasInvested && <InvestedList items={5} />}
          {hasAdministrated && <AdministratedList items={5} />}
          {hasRepresented && <RepresentedList items={5} />}
          {hasInvited && <InvitedList items={5} />}
          {hasDirected && <DirectedList items={5} />}
        </>
      )}

      {dashboardType === dashboardTypes.INVESTOR && (
        <>
          <InvestedList items={5} />
          {hasParticipated && <ParticipatedList items={5} />}
          {hasBeneficiated && <BeneficiatedList items={5} />}
          {hasAdministrated && <AdministratedList items={5} />}
          {hasRepresented && <RepresentedList items={5} />}
          {hasInvited && <InvitedList items={5} />}
          {hasDirected && <DirectedList items={5} />}
        </>
      )}

      {dashboardType === dashboardTypes.REPRESENTANT && (
        <>
          <RepresentedList items={5} />
          {hasAdministrated && <AdministratedList items={5} />}
          {hasParticipated && <ParticipatedList items={5} />}
          {hasBeneficiated && <BeneficiatedList items={5} />}
          {hasInvested && <InvestedList items={5} />}
          {hasInvited && <InvitedList items={5} />}
          {hasDirected && <DirectedList items={5} />}
        </>
      )}

      {dashboardType === dashboardTypes.GUEST && (
        <>
          <InvitedList items={5} />
          {hasParticipated && <ParticipatedList items={5} />}
          {hasBeneficiated && <BeneficiatedList items={5} />}
          {hasInvested && <InvestedList items={5} />}
          {hasRepresented && <RepresentedList items={5} />}
          {hasAdministrated && <AdministratedList items={5} />}
          {hasDirected && <DirectedList items={5} />}
        </>
      )}
      {dashboardType === dashboardTypes.DIRECTOR && (
        <>
          <DirectedList items={5} />
          {hasParticipated && <ParticipatedList items={5} />}
          {hasBeneficiated && <BeneficiatedList items={5} />}
          {hasInvested && <InvestedList items={5} />}
          {hasRepresented && <RepresentedList items={5} />}
          {hasInvited && <InvitedList items={5} />}
          {hasAdministrated && <AdministratedList items={5} />}
        </>
      )}
    </div>
  ) : null;
}

export default Dashboard;
