/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { FC, createContext, useContext, useState } from 'react';
import { Realty, RealtyLicense, RealtyPlot, RealtyTitle } from 'types';
import { AddRealtyModalContextType } from '../types/AddRealtyModalContextType';
import { addRealtyModalContextInitValue } from '../constants/addRealtyModalContextInitValue';

const AddRealtyModalContext = createContext<AddRealtyModalContextType>(
  addRealtyModalContextInitValue
);

export const AddRealtyModalProvider: FC<{
  children: React.ReactNode;
  initialRealtyData?: Partial<Realty>;
}> = ({ children, initialRealtyData = {} }) => {
  const [realtyDetails, setRealtyDetails] = useState({
    name: initialRealtyData?.name || '',
    totalArea: initialRealtyData?.area?.total || 0,
    builtArea: initialRealtyData?.area?.built || 0,
    address: initialRealtyData?.location?.address || '',
    city: initialRealtyData?.location?.city || '',
    zipCode: initialRealtyData?.location?.zipCode || '',
    province: initialRealtyData?.location?.province || '',
    owner: initialRealtyData?.ownership || '',
    lessee: initialRealtyData?.lessee || '',
    description: '',
    label: '',
    usage: initialRealtyData?.usage || '',
    plots: initialRealtyData?.plots || [],
    titles: initialRealtyData?.titles || [],
    licenses: initialRealtyData?.licenses || [],
    encumbrances: initialRealtyData?.encumbrances || [],
    cadastralAmount: initialRealtyData?.cadastralValue?.amount || 0,
    cadastralYear: initialRealtyData?.cadastralValue?.year || 0,
    cadastralReference: initialRealtyData?.references?.cadastral || '',
    registryReference: initialRealtyData?.references?.registry || '',
    salePriceAmount: initialRealtyData?.salePrice?.amount || 0,
    salePriceCurrency: initialRealtyData?.salePrice?.currency || '',
  });

  const handleChange = (field: string, value: any) => {
    setRealtyDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('name', event.target.value);
  };

  const handleChangeTotalArea = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange('totalArea', Number(event.target.value));
  };

  const handleChangeBuiltArea = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange('builtArea', Number(event.target.value));
  };

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('address', event.target.value);
  };

  const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('city', event.target.value);
  };

  const handleChangeZipCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('zipCode', event.target.value);
  };

  const handleChangeProvince = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('province', event.target.value);
  };

  const handleChangeOwner = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('owner', event.target.value);
  };

  const handleChangeLessee = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('lessee', event.target.value);
  };

  const handleChangeUsage = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange('usage', event.target.value);
  };

  const handleChangeEncumbrances = (newEncumbrances: string[]) => {
    handleChange('encumbrances', newEncumbrances);
  };

  const handleChangeCadastralAmount = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange('cadastralAmount', Number(event.target.value));
  };

  const handleChangeCadastralYear = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange('cadastralYear', Number(event.target.value));
  };

  const handleChangeCadastralReference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange('cadastralReference', event.target.value);
  };

  const handleChangeRegistryReference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange('registryReference', event.target.value);
  };

  const handleChangeSalePriceAmount = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange('salePriceAmount', Number(event.target.value));
  };

  const handleChangeSalePriceCurrency = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange('salePriceCurrency', event.target.value);
  };

  const handleChangePlots = (newPlots: RealtyPlot[]) => {
    handleChange('plots', newPlots);
  };

  const handleChangeTitles = (newTitles: RealtyTitle[]) => {
    handleChange('titles', newTitles);
  };

  const handleChangeLicenses = (newLicenses: RealtyLicense[]) => {
    handleChange('licenses', newLicenses);
  };

  return (
    <AddRealtyModalContext.Provider
      value={{
        data: {
          ...realtyDetails,
        },
        actions: {
          handleChangeName,
          handleChangeTotalArea,
          handleChangeBuiltArea,
          handleChangeAddress,
          handleChangeCity,
          handleChangeZipCode,
          handleChangeProvince,
          handleChangeOwner,
          handleChangeLessee,
          handleChangeUsage,
          handleChangeEncumbrances,
          handleChangeCadastralAmount,
          handleChangeCadastralYear,
          handleChangeCadastralReference,
          handleChangeRegistryReference,
          handleChangeSalePriceAmount,
          handleChangeSalePriceCurrency,
          handleChangePlots,
          handleChangeLicenses,
          handleChangeTitles,
        },
      }}
    >
      {children}
    </AddRealtyModalContext.Provider>
  );
};

export const useAddRealtyModal = () => useContext(AddRealtyModalContext);
