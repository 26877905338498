import { store } from 'redux/store';
import { translate } from 'hooks/useTranslate';

import documentTypes from 'constants/documentTypes';
import { Document, Society, User } from 'types';
import createDocumentFromHtml from 'utils/createDocumentFromHtml';

import { updateDocument } from 'modules/documents/redux/documentActions';
import { AddCouncilFormState } from 'modules/_shared/components/Modals/AddCouncil/types/AddCouncilForm';
import { updateCouncil } from '../redux/councilActions';

type EditCouncilProps = {
  councilData: AddCouncilFormState & {
    _id?: string;
    society: string;
    date: Date;
  };
  user: User;
  actualSociety: Society;
  societyDocuments: Document[];
};

const updateCurrentTemplateName = async (id: string, name: string) => {
  await store.dispatch(updateDocument({ _id: id, name }, false));
};

const deleteExistingDocument = async (id: string) => {
  await store.dispatch(
    updateDocument({ _id: id, category: documentTypes.DELETED }, false)
  );
};

export const editCouncil = async ({
  user,
  councilData,
  actualSociety,
  societyDocuments,
}: EditCouncilProps) => {
  const hasAnnouncementDocument = !!(
    councilData?.announcementDocument && councilData?.announcementTemplate
  );

  if (!hasAnnouncementDocument || !societyDocuments?.length) {
    await store.dispatch(updateCouncil(councilData));
    return;
  }

  const announcementDocument = societyDocuments.find(
    (doc: Document) => doc['_id'] === councilData?.announcementDocument
  );

  if (announcementDocument?.subcategory === documentTypes.COUNCILMINUTES) {
    await store.dispatch(updateCouncil(councilData));
  } else {
    const announcementTemplate = societyDocuments.find(
      (doc) => doc['_id'] === councilData?.announcementTemplate
    );

    if (announcementTemplate) {
      const announcementDocName = `${translate(
        'AnnouncementTemplate'
      )}_${name}`;
      const announcementDocDescription = `${translate(
        'GeneratedByTheTemplate'
      )} ${translate('AnnouncementTemplate')}_${name}`;

      await updateCurrentTemplateName(
        councilData.announcementTemplate as string,
        announcementDocName
      );

      await deleteExistingDocument(councilData.announcementDocument as string);

      const documentId = await createDocumentFromHtml({
        html: announcementTemplate.editorModel,
        documentName: announcementDocName,
        documentDescription: announcementDocDescription,
        tagsData: { society: actualSociety, council: councilData },
        society: actualSociety,
        user,
      });

      if (documentId) {
        councilData.announcementDocument = documentId; // eslint-disable-line
      }
    }

    store.dispatch(updateCouncil(councilData));
  }
};
