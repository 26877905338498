export default [
  {
    value: 'ACTIVE',
    label: 'Valid',
    badge: 'success',
  },
  {
    value: 'INACTIVE',
    label: 'TimeOut',
    badge: 'danger',
  },
  {
    value: 'FINISHED',
    label: 'NotValid',
    badge: 'light',
  },
];
