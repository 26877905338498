/* eslint-disable react/require-default-props */
import { useTranslate } from 'hooks/useTranslate';
import { Tooltip } from 'react-tooltip';

import { TYPE_FILES_ALLOWED } from 'constants/defaultConstants';

import { Document } from 'types';

import { allowedFileDocument } from 'utils/allowedFileDocument';
import errorSwal from 'utils/errorSwal';

import DocumentsListLoader from 'modules/documents/components/DocumentsListLoader';
import UploadDocumentsSectionList from './components/UploadDocumentsSectionList';

type Props = {
  initialDocumentsCount: number;
  documents: Document[];
  setDocuments: any;
  tooltip?: string;
};

const UploadDocumentsSection = (props: Props) => {
  const { t } = useTranslate();
  const { initialDocumentsCount, documents, setDocuments, tooltip } = props;

  const handleChangeInputFile = async (event: any) => {
    const newFiles = [...event.target.files];

    const validationResults = await Promise.all(
      newFiles.map(async (file) => {
        const allowedFile = await allowedFileDocument(file);
        return allowedFile;
      })
    );

    const hasInvalidFiles = validationResults.some((isValid) => !isValid);
    if (hasInvalidFiles) {
      errorSwal(t('InvalidFile'));
      return;
    }
    setDocuments([...documents, ...newFiles]);
  };

  return (
    <div className="g-2">
      <div className="nk-block-head-content col-12">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <h5 className="nk-block-title mb-0">{t('Documentation')}</h5>
            {tooltip && (
              <>
                <em
                  className="icon ni ni-info text-gray ml-2"
                  id="documentation-title"
                />
                <Tooltip anchorId="documentation-title" place="top">
                  {t('TheseDocumentsBeAvailablePartners')}
                </Tooltip>
              </>
            )}
          </div>

          <div className="col-md-6 text-right">
            <div className="form-group">
              <label
                htmlFor="custom-file"
                className="btn btn-sm btn-dim btn-primary mb-0"
              >
                <em className="icon ni ni-plus" />
                <span>{t('AddDocumentation')}</span>
              </label>

              <input
                type="file"
                id="custom-file"
                name="custom-file"
                style={{ display: 'none' }}
                accept={TYPE_FILES_ALLOWED}
                onChange={handleChangeInputFile}
                multiple
              />
            </div>
          </div>
        </div>
      </div>

      {documents?.length > 0 ? (
        <UploadDocumentsSectionList
          documents={documents}
          setDocuments={setDocuments}
        />
      ) : (
        initialDocumentsCount > 0 && (
          <DocumentsListLoader rowsCount={initialDocumentsCount} />
        )
      )}
    </div>
  );
};

export default UploadDocumentsSection;
