import { State } from 'redux/initialState';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import successSwal from 'utils/successSwal';
import errorSwal from 'utils/errorSwal';
import { useGetCouncilEnvelopes } from 'modules/administratives/pages/CouncilDetails/hooks/useGetCouncilEnvelopes';
import useGetEnvelopeFromType from 'modules/_shared/components/EnvelopeSignatureStatus/hooks/useGetEnvelopeFromType';
import RequestSignatureModalBody from './components/RequestSignatureModalBody';
import RequestSignatureModalFooter from './components/RequestSignatureModalFooter';
import RequestSignatureModalHeader from './components/RequestSignatureModalHeader';
import { requestSignature } from './utils/requestSignature';
import { useGetMissingSigners } from './hooks/useGetMissingSigners';
import { updateInactiveEnvelope } from './utils/updateInactiveEnvelope';

type Props = {
  councilId: string;
  templateType: string;
  templateHtmlContent: string;
  signatureType: string;
};

const RequestSignatureModal: FC<Props> = ({
  councilId,
  templateType,
  templateHtmlContent,
  signatureType,
}: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const councils = useSelector((state: State) => state.councils);
  const council = councils.find((council) => council._id === councilId);

  const actualSociety = useSelector(
    (state: State) => state.society.actualSociety
  );
  const user = useSelector((state: State) => state.user);

  const { hasMissingSigners, missingText } = useGetMissingSigners({
    council,
  }).data;

  const { councilEnvelopes } = useGetCouncilEnvelopes({ councilId }).data;
  const { envelope } = useGetEnvelopeFromType(councilEnvelopes, signatureType);

  const [includeAssistants, setIncludeAssistants] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangeIncludeAssistants = () => {
    setIncludeAssistants((prev) => !prev);
  };

  const handleRequestSignature = async () => {
    try {
      setIsLoading(true);
      if (!council) return;
      await updateInactiveEnvelope(envelope?._id);

      await requestSignature({
        council,
        actualSociety,
        signatureType,
        htmlContent: templateHtmlContent,
        userId: user?._id,
        includeAssistants,
      });

      successSwal(t('SignatureRequestedSuccessfully'));
    } catch {
      errorSwal(t('ErrorRequestingSignature'));
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <RequestSignatureModalHeader
        templateType={templateType}
        handleCloseModal={handleCloseModal}
      />

      <RequestSignatureModalBody
        hasMissingSigners={hasMissingSigners}
        missingText={missingText}
        includeAssistants={includeAssistants}
        templateType={templateType}
        handleChangeIncludeAssistants={handleChangeIncludeAssistants}
      />

      <RequestSignatureModalFooter
        hasMissingSigners={hasMissingSigners}
        handleRequestSignature={handleRequestSignature}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default RequestSignatureModal;
