import { FC, FormEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';

import { store } from 'redux/store';
import { State } from 'redux/initialState';
import { getActualSociety } from 'utils/filters';
import { Council, CouncilParticipant, Document, Society } from 'types';

import { setModal } from 'modules/_shared/redux/modalActions';
import { addAlert } from 'modules/_shared/redux/alertActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { updateBoard } from 'modules/boards/redux/boardActions';
import { updateSociety } from 'modules/society/redux/societyActions';

import SendCouncilAnnouncementModal from '../CouncilAnnouncementModal/SendCouncilAnnouncementModal';

type EditParticipantModalProps = {
  currentCouncil: Council;
  actualSociety: Society;
  participant: CouncilParticipant;
  announcementTemplateDoc: Document;
};

const EditParticipantModal: FC<EditParticipantModalProps> = ({
  currentCouncil,
  actualSociety,
  participant,
  announcementTemplateDoc,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);

  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSaveParticipant = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<any> => {
    event.preventDefault();
    try {
      const director = actualSociety?.directors.find(
        (director) => director?._id === participant?.member
      );

      if (director) {
        setIsLoading(true);

        const directorId = director._id;
        const societyId = actualSociety._id;

        const newDirector = { ...director, name, email };
        const newSocietyData = {
          directors: {
            edit: [{ directorId, newDirector }],
          },
        };

        await store.dispatch(updateSociety(societyId, newSocietyData, false));

        const newParticipants = currentCouncil?.participants.filter(
          (currentParticipant: CouncilParticipant) =>
            currentParticipant?.['_id'] !== participant?.['_id']
        );

        const newParticipant = { ...participant, name, email };

        const newBoard = await store.dispatch(
          updateBoard(
            currentCouncil['_id'],
            {
              participants: [newParticipant, ...newParticipants],
            },
            false
          )
        );

        await getActualSociety(user, actualSociety['_id']);

        setIsLoading(false);

        store.dispatch(addAlert(alertBodyTypes.ERROR_UPLOADING_IMAGE));
        store.dispatch(setModal(null));

        dispatch(
          setModal(
            <SendCouncilAnnouncementModal
              currentCouncil={newBoard}
              actualSociety={actualSociety}
              announcementTemplateDoc={announcementTemplateDoc}
              hasAlreadyUpdatedParticipants
            />
          )
        );
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = (): any => {
    store.dispatch(setModal(null));
    dispatch(
      setModal(
        <SendCouncilAnnouncementModal
          currentCouncil={currentCouncil}
          actualSociety={actualSociety}
          announcementTemplateDoc={announcementTemplateDoc}
        />
      )
    );
  };

  useEffect(() => {
    if (participant) {
      setName(participant?.name);
      setEmail(participant?.email);
    }
  }, [participant]);

  return (
    <>
      <Modal.Header>
        <h5>{t('UpdateParticipant')}</h5>
        <a className="close" onClick={handleClose}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form
          className="form-validate is-alter"
          onSubmit={handleSaveParticipant}
        >
          <div className="form-group">
            <label className="form-label" htmlFor="name">
              {t('Name')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              {t('Email')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name?.length || !email?.length}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>

      {isLoading && <CustomLoading />}
    </>
  );
};

export default EditParticipantModal;
