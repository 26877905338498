import { useTranslate } from 'hooks/useTranslate';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { dateFormat } from 'constants/formats';

import Page from 'modules/_shared/components/Pages/Page';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import PageWithTabs from 'modules/_shared/components/Pages/PageWithTabs';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';

import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';
import { useRealtyDetails } from './hooks/useRealtyDetails';

import RealtyDetailsSummaryTab from './components/RealtyDetailsSummaryTab';
import RealtyDetailsPersonalTab from './components/RealtyDetailsPersonalTab';
import RealtyDetailsDocumentsTab from './components/RealtyDetailsDocumentsTab';

const RealtyDetails = () => {
  const { t } = useTranslate();
  const { data: realty, isLoading, isError } = useRealtyDetails();

  const society = useSelector((state: State) => state.society?.actualSociety);

  const getRealtyDescription = () => {
    const startDateText = realty?.startDate
      ? format(new Date(realty.startDate), dateFormat)
      : '-';

    return `${t('ActiveFrom')}: ${startDateText}`;
  };

  useGetMenu();

  return (
    <Page>
      <PageListHeader
        title={t('Realty')}
        subTitle={realty?.name || '-'}
        subTitleClassName="text-primary fw-normal"
        description={getRealtyDescription()}
        actions={[<GoBackButton />]}
      />

      <PageWithTabs
        tabs={[
          {
            key: 'summary-tab',
            icon: 'ni-user-circle',
            title: t('Summary'),
            content: realty && (
              <RealtyDetailsSummaryTab realty={realty} society={society} />
            ),
          },
          {
            key: 'data-tab',
            icon: 'ni-list',
            title: t('Data'),
            content: realty && <RealtyDetailsPersonalTab realty={realty} />,
          },
          {
            key: 'documents-tab',
            icon: 'ni-file-text',
            title: t('Documents'),
            content: realty && (
              <RealtyDetailsDocumentsTab realty={realty} society={society} />
            ),
          },
        ]}
        isError={isError}
        isLoading={isLoading}
      />
    </Page>
  );
};

export default RealtyDetails;
