import i18n from 'i18n/config';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useTranslate } from 'hooks/useTranslate';
import { Society, SocietyDirector, SocietyDirectorType } from 'types';

import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import { getNameInitials } from 'utils/filters';
import { getDirectorImage } from 'modules/society/utils/getDirectorImage';
import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { getDirectorDetailsUrl } from '../utils/getDirectorDetailsUrl';

type CouncilMembersDirectorCardProps = {
  society: Society;
};

const MemberAvatar = ({
  director,
  society,
}: {
  director: SocietyDirector;
  society: Society;
}) => {
  const getDirectorType = (director: SocietyDirector) => {
    const directorType =
      directorTypes(i18n).name[director.directorType as SocietyDirectorType];

    const firstWordOfDirectorType = directorType?.split(' ')[0];
    return firstWordOfDirectorType || '';
  };

  const directorImage = getDirectorImage(director, society);

  return (
    <>
      <div className="user-avatar sm bg-primary mr-3">
        {directorImage && directorImage !== BLANK_PROFILE_PICTURE ? (
          <img src={directorImage} alt="member of board" />
        ) : (
          <span>{getNameInitials(director?.socialDenomination)}</span>
        )}
      </div>
      <div className="data-col flex-grow-1 flex-column align-items-start">
        <div className="data-value fw-medium">
          {director?.socialDenomination}
        </div>
        <div className="data-label small text-muted w-100">
          {director.email}
        </div>
      </div>
      <div className="data-col d-flex justify-content-end text-ellipsis">
        <span className="badge bg-outline-primary">
          {getDirectorType(director)}
        </span>
      </div>
    </>
  );
};

const CouncilMembersDirectorCard: FC<CouncilMembersDirectorCardProps> = ({
  society,
}) => {
  const { t } = useTranslate();

  return (
    <section className="council-member-basic-info-card col">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title mb-1">{t('CouncilMembers')}</h5>
          <p>{t('CouncilMembersList')}</p>
        </div>
      </div>

      <div className="card rounded-xl">
        <ul className="data-list is-compact">
          {society?.directors?.map((director) => (
            <li className="data-item" key={director._id}>
              <Link
                to={getDirectorDetailsUrl(director, society)}
                className="data-col d-flex align-items-center text-reset text-decoration-none"
              >
                <MemberAvatar director={director} society={society} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default CouncilMembersDirectorCard;
