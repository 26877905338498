/* eslint-disable no-undef */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';

import MandatoryField from 'modules/_shared/components/MandatoryField';

type Props = {
  buildingsCount: number;
  // eslint-disable-next-line no-unused-vars
  handleAddBuilding: (newBuilding: any) => void;
};
const PlotBuildingsView: FC<Props> = ({
  buildingsCount,
  handleAddBuilding,
}) => {
  const { t } = useTranslate();

  const [description, setDescription] = useState<string>('');
  const [area, setArea] = useState<number>(0);

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleChangeArea = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArea(Number(e.target.value));
  };

  const handleSaveBuilding = () => {
    const newBuilding = {
      area,
      description,
    };

    setArea(0);
    setDescription('');
    handleAddBuilding(newBuilding);
  };

  return (
    <div className="row gy-4">
      <div className="col-md-12 d-flex align-items-center">
        <h6 className="mb-0">{t('PlotBuildings')}</h6>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-area">
            {t('Area')} (m²) <MandatoryField />
          </label>

          <input
            id="realty-area"
            type="number"
            className="form-control form-control-lg"
            value={area}
            onChange={handleChangeArea}
            placeholder={t('IntroduceArea')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-description">
            {t('Description')}
          </label>

          <input
            id="realty-description"
            type="text"
            className="form-control form-control-lg"
            value={description}
            onChange={handleChangeDescription}
            placeholder={t('IntroduceDescription')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="sub-text mt-1">
          {t('BuildingsOnThisPlot')} = {buildingsCount}
        </div>
      </div>

      <div className="col-6 text-right">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSaveBuilding}
          disabled={!area}
        >
          {t('AddBuilding')}
        </button>
      </div>
    </div>
  );
};

export default PlotBuildingsView;
