import { useLocation } from 'react-router-dom';

import { getThemeBackgroundColor, getThemeHeaderFullWidth } from 'utils/theme';

import './Header.scss';

import useHeader from './useHeader';
import HeaderLogo from '../HeaderLogo';
import HeaderMenu from '../HeaderMenu';
import HeaderTools from '../HeaderTools';

function Header({ isFullScreen }) {
  const location = useLocation();

  const { data } = useHeader(location);

  const headerWidth = getThemeHeaderFullWidth(data)
    ? 'container-fluid'
    : 'container-lg wide-xl';

  const getWrapperStyle = () => ({
    backgroundColor: getThemeBackgroundColor(data),
  });

  const getHeaderStyle = () => ({
    height: data?.theme?.headerHeight,
  });

  return !isFullScreen ? (
    <div
      className="app-header nk-header nk-header-fixed"
      style={getWrapperStyle()}
    >
      <div className={headerWidth}>
        <div className="nk-header-wrap" style={getHeaderStyle()}>
          <HeaderLogo data={data} />
          <HeaderMenu data={data} />
          <HeaderTools data={data} />
        </div>
      </div>
    </div>
  ) : null;
}

export default Header;
