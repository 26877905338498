/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from 'react';
import { store } from 'redux/store';
import { getDocument } from 'modules/documents/redux/documentActions';

import { Document } from 'types';

async function getDocumentById(documentId: string) {
  const document = await store.dispatch(getDocument(documentId));

  return document;
}

type Props = {
  documentsIds: string[];
};

export default function useGetDocuments({ documentsIds }: Props) {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchDocuments = async () => {
    setIsLoading(true);

    try {
      if (documentsIds.length > 0) {
        const getDocuments = async () => {
          const documents = [];

          for (const documentId of documentsIds) {
            const document = await getDocumentById(documentId);
            if (document) {
              documents.push(document);
            }
          }
          setDocuments(documents);
        };

        getDocuments();
      } else {
        setDocuments([]);
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [documentsIds]);

  return { data: { documents }, isLoading, isError, actions: { setIsLoading } };
}
