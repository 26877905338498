/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

type TableSkeletonProps = {
  rows: number;
};

const TableSkeleton: FC<TableSkeletonProps> = ({ rows }) => (
  <div className="card-inner">
    <p className="placeholder-glow text-left">
      <span className="placeholder col-12 h-40px mb-3" />
      <span className="placeholder col-12 h-30px mb-3" />

      {Array.from({ length: rows }).map((_, index) => (
        <span key={`table-skeleton-row-${index}`}>
          <span className="placeholder col-3 h-25px mb-4" />
          <span className="placeholder offset-1 h-25px mb-4" />
          <span className="placeholder col-2 h-25px mb-4" />
          <span className="placeholder offset-1 h-25px mb-4" />
          <span className="placeholder col-2 h-25px mb-4" />
          <span className="placeholder offset-1 h-25px mb-4" />
          <span className="placeholder col-2 h-25px mb-4" />
        </span>
      ))}
    </p>
  </div>
);

export default TableSkeleton;
