import { FC } from 'react';

import { RealtyPlot } from 'types';

type Props = {
  index: number;
  plot: RealtyPlot;
  // eslint-disable-next-line no-unused-vars
  handleRemovePlot: (index: number) => void;
};

const PlotsTableRow: FC<Props> = ({ index, plot, handleRemovePlot }) => (
  <>
    <td className="text-left" key={`plot-number-${index}`}>
      {plot?.plotNumber}
    </td>

    <td className="text-left" key={`plot-area-${index}`}>
      {plot?.area} m²
    </td>

    <td className="text-left" key={`plot-type-${index}`}>
      {plot?.plotType}
    </td>

    <td className="text-left" key={`plot-subType-${index}`}>
      {plot?.plotSubType}
    </td>

    <td className="text-left" key={`plot-buildings-${index}`}>
      {plot?.buildings?.length || 0}
    </td>

    <td className="text-center" key={`plot-remove-${index}`}>
      <button
        type="button"
        className="text-soft btn btn-icon pt-0 pb-0"
        onClick={() => handleRemovePlot(index)}
      >
        <em className="icon ni ni-trash" />
      </button>
    </td>
  </>
);

export default PlotsTableRow;
