import { Council } from 'types';

const attendanceTypes = {
  HYBRID: 'HYBRID',
  FACE_TO_FACE: 'FACE_TO_FACE',
};

export const isPhysicalAttendanceType = (council: Council) => {
  const { attendanceType } = council;

  const hybridAttendance = attendanceTypes.HYBRID;
  const faceToFaceAttendance = attendanceTypes.FACE_TO_FACE;

  return (
    attendanceType === hybridAttendance ||
    attendanceType === faceToFaceAttendance
  );
};
