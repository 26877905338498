import i18n from 'i18n/config';
import { Council, CouncilParticipant, Society } from 'types';
import { getPartnerNDPercent } from 'utils/filters';

const getAttend = (participant: CouncilParticipant) => {
  if (participant.assists) return i18n.t('Yes');
  if (participant.isAbsent) return i18n.t('Nope');
  return '';
};

const getCouncilParticipantsTableData = (
  council: Council,
  actualSociety: Society
) => {
  council.participants.map((participant: CouncilParticipant, index: number) => {
    const partner = actualSociety?.partners.find(
      (partner: any) => partner['_id'] === participant.member
    );

    return {
      ...participant,
      index: index + 1,
      percentage: getPartnerNDPercent(partner, actualSociety),
      sharesCount: partner?.sharesCount?.actual || 0,
      societyId: actualSociety['_id'],
      attend: getAttend(participant),
    };
  });
};

export const getCouncilParticipantsTable = async (
  council: Council,
  actualSociety: Society
) => {
  const participantsData = getCouncilParticipantsTableData(
    council,
    actualSociety
  );

  const certificate = {
    header: i18n.t('AttendanceCertificateTitle', {
      societyName: actualSociety?.name,
    }),
    subHeader: '',
    tableColumns: [
      'Nº',
      i18n.t('Name'),
      `Nº ${i18n.t('Share.')}`,
      '% ND',
      i18n.t('Attend'),
      i18n.t('Signature'),
    ],
    tableData: participantsData,
  };

  return certificate;
};
