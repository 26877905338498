import { useEffect, useState } from 'react';
import { store } from 'redux/store';
import { getDocument } from 'modules/documents/redux/documentActions';
import { Document } from 'types';

async function getDocumentById(documentId: string) {
  const document = await store.dispatch(getDocument(documentId));

  return document;
}

type Props = {
  documentId: string | undefined;
  templateId: string | undefined;
};

export default function useGetDocumentData({ documentId, templateId }: Props) {
  const [htmlContent, setHtmlContent] = useState<string>();
  const [templateName, setTemplateName] = useState<string>('');
  const [document, setDocument] = useState<Document>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const handleIsLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const fetchDocumentData = async () => {
    if (documentId && templateId) {
      try {
        setIsLoading(true);

        const getTemplateDocument = async () => {
          const document = await getDocumentById(documentId);
          const htmlContent = document?.editorModel || '';

          const templateDocument = await getDocumentById(templateId);
          const templateName = templateDocument?.name || '';

          setHtmlContent(htmlContent);
          setTemplateName(templateName);
          setDocument(document);

          setIsLoading(false);
        };

        getTemplateDocument();
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDocumentData();
  }, [documentId, templateId]);

  return {
    data: { htmlContent, templateName, document },
    isLoading,
    isError,
    actions: { handleIsLoading },
  };
}
