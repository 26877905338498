import { translate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { CouncilAnnouncementData, Society } from 'types';
import { getSocietyEmailConfiguration } from 'utils/emails';
import { getAnnouncementCommunication } from 'utils/getAnnouncementCommunication';

type CouncilAnnouncementEmailDataProps = {
  announcementData: CouncilAnnouncementData;
  userId: string;
  societyId: string;
  society: Society;
  userEmail?: string;
};

const getTemplateData = (announcementData: CouncilAnnouncementData) => [
  {
    name: 'societyName',
    content: announcementData?.societyName,
  },
  {
    name: 'councilName',
    content: announcementData?.councilName,
  },
  {
    name: 'councilDate',
    content: announcementData?.date,
  },
  {
    name: 'councilTime',
    content: announcementData?.time,
  },
  {
    name: 'onlineAccess',
    content: announcementData?.onlineAccess,
  },
  {
    name: 'councilPlace',
    content: announcementData?.place,
  },
  {
    name: 'announcementText',
    content: announcementData?.announcement,
  },
  {
    name: 'councilType',
    content: announcementData?.councilType,
  },
  {
    name: 'buttonText',
    content: translate('AccessCouncil'),
  },
];

export const createCouncilAnnouncementEmailData = ({
  announcementData,
  societyId,
  society,
  userId,
  userEmail,
}: CouncilAnnouncementEmailDataProps) => {
  const societyTheme = getSocietyEmailConfiguration(society, {
    addSuffix: false,
  })?.theme;

  const hasWhiteBranding = society?.configuration?.hasWhiteBranding;

  const emailData = {
    subject: i18n.t('CouncilAnnouncement'),
    bodySummary: announcementData.announcement,
    template: 'council-announcement-es',
    templateData: getTemplateData(announcementData),
    templateBody: getAnnouncementCommunication(
      societyTheme,
      hasWhiteBranding,
      announcementData,
      i18n.language || 'es'
    ),
    user: userId,
    society: societyId,
    date: new Date(),
    status: 'SENT',
    iCal: {
      societyEmail: userEmail,
      societyName: announcementData.societyName,
      boardName: announcementData.councilName,
      boardDate: announcementData.date,
      boardLink: announcementData.onlineAccess,
    },
  };

  return emailData;
};
