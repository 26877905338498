import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Realty, Society } from 'types';

import DetailsProfileSection from 'modules/_shared/components/Sections/DetailsProfileSection';
import DetailsTableSection from 'modules/_shared/components/Sections/DetailsTableSection';
import { getSummaryDetailsList } from '../utils/getSummaryDetailsList';
import { getSummaryProfileData } from '../utils/getSummaryProfileData';

type RealtyDetailsSummaryTabProps = {
  realty: Realty;
  society: Society;
};

const RealtyDetailsSummaryTab: FC<RealtyDetailsSummaryTabProps> = ({
  realty,
  society,
}) => {
  const { t } = useTranslate();

  const { name, image, position, description } = getSummaryProfileData(
    realty,
    society,
    t
  );

  const details = getSummaryDetailsList(realty, t);

  return (
    <>
      <DetailsProfileSection
        name={name}
        image={image}
        position={position}
        description={description}
      />

      <div className="nk-divider divider my-0" />

      <DetailsTableSection title={t('Details')} data={details} />
    </>
  );
};

export default RealtyDetailsSummaryTab;
