/* eslint-disable arrow-body-style */
import boardTypes from 'constants/boardTypes';
import {
  dateFormat,
  dateFormatLong,
  formatDateToTime,
} from 'constants/formats';
import { format } from 'date-fns';
import i18n from 'i18n/config';
import {
  getFullAddress,
  getPartnerNDPercent,
  getPartnerPremium,
  getVoteInstructions,
} from 'utils/filters';
import {
  formatCurrency,
  formatNumber,
  formatNumberDecimals,
  formatNumberDecimalsLong,
} from 'utils/formats';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import attendanceTypes from 'constants/attendanceTypes';
import {
  getAttendeesSellers,
  getAttendeesBuyers,
  getParticipantsNaturalSellers,
  getParticipantsNaturalBuyers,
  getParticipantsLegalSellers,
  getParticipantsLegalBuyers,
  getFullNameSellers,
  getOwnershipSellers,
  getPerfectionSellers,
  getParticipantsNaturalAndLegalSellers,
  getParticipantsNaturalAndLegalBuyers,
  getInvestorsDetailsCapitalIncrease,
  getHoldingClassesList,
} from 'utils/scriptureGenerators';
import { dayMonthYear } from 'utils/formatDate';
import DefaultLogo from 'assets/images/logo-placeholder.jpg';
import {
  getBoardAgreements,
  getBoardAssistantsTable,
  getBoardOrdersString,
  getBoardPlace,
} from 'modules/boards/utils';
import { getAdminMemberStatus } from 'modules/administratives/pages/AdminMembers/utils/getAdminMemberStatus';
import adminMemberStatus from 'modules/administratives/pages/AdminMembers/constants/adminMemberStatus';
import getHoldingClassesFromDraft from './utils/getHoldingClassesFromDraft';
import getSharesRangeFromDraft from './utils/getSharesRangeFromDraft';
import months from './utils/months';
import getDirectorSocialDenomination from './utils/getDirector';

function tags(entities) {
  const {
    plan,
    society,
    partner,
    beneficiary,
    board,
    partnerRepresentants,
    signatures,
    tenderOffer,
    tenderOfferShares,
    operation,
    holdingClasses,
    annualValue,
  } = entities;

  const currDirectorTypes = directorTypes(i18n);
  const boardDate = board?.date ? new Date(board.date) : new Date();

  const societyDirectors = society?.directors;

  const mainDirector = societyDirectors?.find(
    (director) =>
      director?.directorType === currDirectorTypes.var.UNIQUE_ADMIN &&
      getAdminMemberStatus(director) === adminMemberStatus[0]
  );

  const ebitdaSum = annualValue?.ebitda?.reduce(
    (acc, curr) => (curr > 0 ? acc + curr : acc),
    0
  );

  const ebitdaCount = annualValue?.ebitda?.length;

  const societyTags = society && {
    title: 'Sociedad',
    menu: [
      {
        value: 'SOCIEDAD_NOMBRE',
        title: 'SOCIEDAD_NOMBRE',
        url: society?.name,
      },
      {
        value: 'SOCIEDAD_CIF',
        title: 'SOCIEDAD_CIF',
        url: society?.cif,
      },

      {
        value: 'SOCIEDAD_VALOR_NOMINAL_PART',
        title: 'SOCIEDAD_VALOR_NOMINAL_PART',
        url: formatCurrency(society?.nominalValue),
      },
      {
        value: 'SOCIEDAD_VALOR_NOMINAL_TOTAL',
        title: 'SOCIEDAD_VALOR_NOMINAL_TOTAL',
        url: formatCurrency(
          +(society?.sharesCount?.actual * society?.nominalValue)
        ),
      },
      {
        value: 'SOCIEDAD_FECHA_CONSTITUCION',
        title: 'SOCIEDAD_FECHA_CONSTITUCION',
        url: society?.creationDate
          ? format(new Date(society?.creationDate), dateFormatLong)
          : '',
      },
      {
        value: 'SOCIEDAD_DIRECCION',
        title: 'SOCIEDAD_DIRECCION',
        url: society?.legalAddress?.line1,
      },
      {
        value: 'SOCIEDAD_CODIGO_POSTAL',
        title: 'SOCIEDAD_CODIGO_POSTAL',
        url: society?.legalAddress?.zip,
      },
      {
        value: 'SOCIEDAD_CIUDAD',
        title: 'SOCIEDAD_CIUDAD',
        url: society?.legalAddress?.city,
      },
      {
        value: 'SOCIEDAD_PAIS',
        title: 'SOCIEDAD_PAIS',
        level: 2,
      },
      {
        value: 'SOCIEDAD_DIRECCION_COMPLETA',
        title: 'SOCIEDAD_DIRECCION_COMPLETA',
        url: getFullAddress(society?.legalAddress),
      },
      {
        value: 'SOCIEDAD_LOGO',
        title: 'SOCIEDAD_LOGO',
        url: society?.additional?.logo
          ? `<img src=${society.additional.logo} />`
          : `<img src=${DefaultLogo} />`,
      },
      {
        value: 'SOCIEDAD_ADMINISTRADOR',
        title: 'SOCIEDAD_ADMINISTRADOR',
        url: mainDirector?.socialDenomination || '',
      },
      {
        value: 'SOCIEDAD_ADMINISTRADOR_CARGO',
        title: 'SOCIEDAD_ADMINISTRADOR_CARGO',
        url: mainDirector
          ? currDirectorTypes.name?.[mainDirector.directorType]
          : '',
      },
      {
        value: 'FIRMA_CERTIFICADO_PARTICIPACIONES',
        title: 'FIRMA_CERTIFICADO_PARTICIPACIONES',
        url: society?.additional?.certificateSign
          ? `<img src=${society.additional.certificateSign} />`
          : '',
      },
      {
        value: 'FIRMA_SOCIEDAD',
        title: 'FIRMA_SOCIEDAD',
        url: '@A___________________',
      },
      {
        value: 'SOCIEDAD_CIUDAD_NOTARIO',
        title: 'SOCIEDAD_CIUDAD_NOTARIO',
        url: '',
      },
      {
        value: 'SOCIEDAD_NOMBRE_Y_APELLIDOS_NOTARIO',
        title: 'SOCIEDAD_NOMBRE_Y_APELLIDOS_NOTARIO',
        url: '',
      },
      {
        value: 'SOCIEDAD_NUMERO_PROTOCOLO',
        title: 'SOCIEDAD_NUMERO_PROTOCOLO',
        url: '',
      },
      {
        value: 'SOCIEDAD_REGISTRO_MERCANTIL',
        title: 'SOCIEDAD_REGISTRO_MERCANTIL',
        url: '',
      },
      {
        value: 'SOCIEDAD_REGISTRO_TOMO',
        title: 'SOCIEDAD_REGISTRO_TOMO',
        url: '',
      },
      {
        value: 'SOCIEDAD_REGISTRO_FOLIO',
        title: 'SOCIEDAD_REGISTRO_FOLIO',
        url: '',
      },
      {
        value: 'SOCIEDAD_REGISTRO_HOJAS',
        title: 'SOCIEDAD_REGISTRO_HOJAS',
        url: '',
      },
      {
        value: 'SOCIEDAD_NUMERO_FINAL_PARTICIPACIONES',
        title: 'SOCIEDAD_NUMERO_FINAL_PARTICIPACIONES',
        url: '[SOCIEDAD_NUMERO_FINAL_PARTICIPACIONES]',
      },
    ],
  };

  const planTags = plan && {
    title: 'Plan',
    menu: [
      {
        title: 'PLAN_TIPO',
        value: 'PLAN_TIPO',
        url: plan?.planType,
      },
      {
        title: 'PLAN_NOMBRE',
        value: 'PLAN_NOMBRE',
        url: plan?.name,
      },
      {
        title: 'PLAN_FECHA_INICIO',
        value: 'PLAN_FECHA_INICIO',
        url: plan?.startDate
          ? format(new Date(plan?.startDate), dateFormat)
          : '',
      },
      {
        title: 'PLAN_PRECIO_ACCION',
        value: 'PLAN_PRECIO_ACCION',
        url:
          beneficiary?.customConditions &&
          'sharePrice' in beneficiary?.customConditions
            ? formatNumberDecimalsLong(
                beneficiary?.customConditions?.sharePrice
              )
            : formatNumberDecimalsLong(plan?.sharePrice),
      },
      {
        title: 'PLAN_PRECIO_ACCION_FIJO',
        value: 'PLAN_PRECIO_ACCION_FIJO',
        url:
          beneficiary?.customConditions &&
          'isFixedPrice' in beneficiary?.customConditions
            ? beneficiary?.customConditions?.isFixedPrice
            : plan?.isFixedPrice,
      },
      {
        title: 'PLAN_CLIFF',
        value: 'PLAN_CLIFF',
        url:
          beneficiary?.customConditions &&
          'cliff' in beneficiary?.customConditions
            ? beneficiary?.customConditions?.cliff || i18n.t('NoCliff')
            : plan?.cliff || i18n.t('NoCliff'),
      },
      {
        title: 'PLAN_VESTING',
        value: 'PLAN_VESTING',
        url:
          beneficiary?.customConditions &&
          'vestingPeriod' in beneficiary?.customConditions
            ? beneficiary?.customConditions?.vestingPeriod
            : plan?.vestingPeriod,
      },
      {
        title: 'PLAN_CONSOLIDACION',
        value: 'PLAN_CONSOLIDACION',
        url:
          beneficiary?.customConditions &&
          'consolidation' in beneficiary?.customConditions
            ? beneficiary?.customConditions?.consolidation
            : plan?.consolidation,
      },
      {
        title: 'PLAN_CONSOLIDACION_PERSONALIZADA',
        value: 'PLAN_CONSOLIDACION_PERSONALIZADA',
        url: i18n.t('SeeAnnexConsolidationCalendar'),
      },
      {
        title: 'PLAN_CONSOLIDACION_PERSONALIZADA_SIN_ESTADO',
        value: 'PLAN_CONSOLIDACION_PERSONALIZADA_SIN_ESTADO',
        url: i18n.t('SeeAnnexConsolidationCalendar'),
      },
      {
        title: 'PLAN_CONSOLIDACION_PERSONALIZADA_SIN_ESTADO_EN_ES',
        value: 'PLAN_CONSOLIDACION_PERSONALIZADA_SIN_ESTADO_EN_ES',
        url: ' ',
      },
    ],
  };

  const partnerTags = partner && {
    title: 'Socio',
    menu: [
      {
        title: 'SOCIO_NOMBRE_Y_APELLIDOS',
        value: 'SOCIO_NOMBRE_Y_APELLIDOS',
        url: partner?.name,
      },
      {
        title: 'SOCIO_TAX_ID',
        value: 'SOCIO_TAX_ID',
        url: partner?.cif,
      },
      {
        title: 'SOCIO_DIRECCION',
        value: 'SOCIO_DIRECCION',
        url: getFullAddress(partner?.address),
      },
      {
        title: 'SOCIO_EMAIL',
        value: 'SOCIO_EMAIL',
        url: partner?.email,
      },
      {
        title: 'SOCIO_No_PARTICIPACIONES',
        value: 'SOCIO_No_PARTICIPACIONES',
        url: partner?.sharesCount?.actual,
      },
      {
        title: 'SOCIO_VALOR_NOMINAL_PARTICIPACIONES',
        value: 'SOCIO_VALOR_NOMINAL_PARTICIPACIONES',
        url:
          partner && society
            ? formatCurrency(
                partner.sharesCount?.actual * society?.nominalValue
              )
            : 0,
      },
      {
        title: 'SOCIO_PORCENTAJE_ND',
        value: 'SOCIO_PORCENTAJE_ND',
        url:
          partner && society
            ? getPartnerNDPercent(partner, society).toFixed(2)
            : 0,
      },
      {
        title: 'SOCIO_VALOR_PARTICIPACION',
        value: 'SOCIO_VALOR_PARTICIPACION',
        url:
          partner?.shares && society
            ? parseFloat(getPartnerPremium(partner?.shares, society).toFixed(2))
            : 0,
      },
      {
        title: 'SOCIO_NACIONALIDAD',
        value: 'SOCIO_NACIONALIDAD',
        url: partner?.nationality,
      },
      {
        title: 'SOCIO_VALOR_CONTABLE',
        value: 'SOCIO_VALOR_CONTABLE',
        url: formatCurrency(
          +(
            (annualValue?.contableValue / society?.sharesCount?.actual || 0) *
            partner.sharesCount?.actual
          ).toFixed(2)
        ),
      },
      {
        title: 'SOCIO_VALOR_CONTABLE_PROMEDIO',
        value: 'SOCIO_VALOR_CONTABLE_PROMEDIO',
        url: formatCurrency(
          +(
            (((ebitdaSum / ebitdaCount) * 5) / society?.sharesCount?.actual) *
            partner.sharesCount?.actual
          ).toFixed(2)
        ),
      },
    ],
  };

  const boardTags = board && {
    title: 'Juntas',
    menu: [
      {
        title: 'JUNTA_TIPO',
        value: 'JUNTA_TIPO',
        url: boardTypes?.[board?.boardType]?.text,
      },
      {
        title: 'JUNTA_FECHA_CONVOCATORIA',
        value: 'JUNTA_FECHA_CONVOCATORIA',
        url: `${boardDate.getDate()} de ${
          months[boardDate.getMonth()]
        } de ${boardDate.getFullYear()} a las ${formatDateToTime(boardDate)}`,
      },
      {
        title: 'JUNTA_FECHA_ACTUAL',
        value: 'JUNTA_FECHA_ACTUAL',
        url: `${new Date().getDate()} de ${
          months[new Date().getMonth()]
        } de ${new Date().getFullYear()}`,
      },
      {
        title: 'JUNTA_LUGAR',
        value: 'JUNTA_LUGAR',
        url: getBoardPlace(board),
      },
      {
        title: 'JUNTA_RECORDATORIO',
        value: 'JUNTA_RECORDATORIO',
        url:
          board?.attendanceType === attendanceTypes.HYBRID.value ||
          board?.attendanceType === attendanceTypes.ONLINE.value
            ? 'Finalmente, se recuerda a los Socios que, en el correo electrónico por el cual se hace llegar esta convocatoria figuran los medios y canales a través de los cuales los socios podrán conectarse y hacer el correcto seguimiento de la celebración de la Junta General de Socios, en el día y hora señalados.'
            : ' ',
      },
      {
        title: 'JUNTA_ORDENES',
        value: 'JUNTA_ORDENES',
        url: getBoardOrdersString(board),
      },
      {
        title: 'JUNTA_DELEGACION_SOCIO_NOMBRE',
        value: 'JUNTA_DELEGACION_SOCIO_NOMBRE',
        url: board?.delegation?.partner?.name,
      },
      {
        title: 'JUNTA_DELEGACION_SOCIO_CIF',
        value: 'JUNTA_DELEGACION_SOCIO_CIF',
        url: board?.delegation?.partner?.cif,
      },
      {
        title: 'JUNTA_DELEGACION_SOCIO_DIRECCION',
        value: 'JUNTA_DELEGACION_SOCIO_DIRECCION',
        url: getFullAddress(board?.delegation?.partner?.address),
      },
      {
        title: 'JUNTA_DELEGACION_SOCIO_NACIONALIDAD',
        value: 'JUNTA_DELEGACION_SOCIO_NACIONALIDAD',
        url: board?.delegation?.partner?.nationality,
      },
      {
        title: 'JUNTA_DELEGACION_DELEGADO_NOMBRE',
        value: 'JUNTA_DELEGACION_DELEGADO_NOMBRE',
        url: board?.delegation?.representative?.name,
      },
      {
        title: 'JUNTA_DELEGACION_DELEGADO_CIF',
        value: 'JUNTA_DELEGACION_DELEGADO_CIF',
        url: board?.delegation?.representative?.cif,
      },
      {
        title: 'JUNTA_DELEGACION_DELEGADO_DIRECCION',
        value: 'JUNTA_DELEGACION_DELEGADO_DIRECCION',
        url: getFullAddress(board?.delegation?.representative?.address),
      },
      {
        title: 'JUNTA_DELEGACION_INSTRUCCIONES',
        value: 'JUNTA_DELEGACION_INSTRUCCIONES',
        url: getVoteInstructions(board),
      },
      {
        title: 'JUNTA_ASISTENTES_TABLA',
        value: 'JUNTA_ASISTENTES_TABLA',
        url: getBoardAssistantsTable(board, society, partnerRepresentants),
      },
      {
        title: 'JUNTA_ACUERDOS_ACTA',
        value: 'JUNTA_ACUERDOS_ACTA',
        url: getBoardAgreements(board, society),
      },
      {
        title: 'JUNTA_ACTA',
        value: 'JUNTA_ACTA',
        url: board?.actTemplateHtml,
      },
      {
        title: 'JUNTA_PRESIDENTE',
        value: 'JUNTA_PRESIDENTE',
        url: societyDirectors?.find(
          (director) => director?.['_id'] === board?.president
        )?.socialDenomination,
      },
      {
        title: 'JUNTA_SECRETARIO',
        value: 'JUNTA_SECRETARIO',
        url: societyDirectors?.find(
          (director) => director?.['_id'] === board?.secretary
        )?.socialDenomination,
      },
    ],
  };

  const signaturesTags = signatures && {
    title: 'Firmas',
    menu: [
      {
        title: 'FIRMA_SOCIO',
        value: 'FIRMA_SOCIO',
        url: signatures?.partnerSignature
          ? `<img src=${signatures?.partnerSignature} />`
          : '',
      },
    ],
  };

  const beneficiaryTags = beneficiary && {
    title: 'Beneficiario',
    menu: [
      {
        title: 'BENEFICIARIO_PARTICIPACIONES',
        value: 'BENEFICIARIO_PARTICIPACIONES',
        url: formatNumber(beneficiary?.sharesCount?.future),
      },
      {
        title: 'BENEFICIARIO_CATEGORIA_LABORAL',
        value: 'BENEFICIARIO_CATEGORIA_LABORAL',
        url: beneficiary?.laboralCategory?.name,
      },
      {
        title: 'BENEFICIARIO_CIF',
        value: 'BENEFICIARIO_CIF',
        url: beneficiary?.cif,
      },
      {
        title: 'BENEFICIARIO_NOMBRE',
        value: 'BENEFICIARIO_NOMBRE',
        url: beneficiary?.name,
      },
      {
        title: 'BENEFICIARIO_EMAIL',
        value: 'BENEFICIARIO_EMAIL',
        url: beneficiary?.email,
      },
      {
        title: 'BENEFICIARIO_DIRECCION',
        value: 'BENEFICIARIO_DIRECCION',
        url: getFullAddress(beneficiary?.address),
      },
      {
        title: 'BENEFICIARIO_PLAN',
        value: 'BENEFICIARIO_PLAN',
        url: beneficiary?.plan,
      },
      {
        title: 'BENEFICIARIO_TIPO_PLAN',
        value: 'BENEFICIARIO_TIPO_PLAN',
        url: beneficiary?.planType,
      },
      {
        title: 'BENEFICIARIO_FECHA_INICIO_PLAN',
        value: 'BENEFICIARIO_FECHA_INICIO_PLAN',
        url: beneficiary?.planStartDate
          ? format(new Date(beneficiary?.planStartDate), dateFormat)
          : '',
      },
      {
        title: 'BENEFICIARIO_CANTIDAD',
        value: 'BENEFICIARIO_CANTIDAD',
        url: beneficiary?.amount,
      },
      {
        title: 'BENEFICIARIO_SALARIO',
        value: 'BENEFICIARIO_SALARIO',
        url: beneficiary?.salary,
      },
      {
        title: 'BENEFICIARIO_FECHA_CONSOLIDACION',
        value: 'BENEFICIARIO_FECHA_CONSOLIDACION',
        url: beneficiary?.consolidationDate
          ? format(new Date(beneficiary?.consolidationDate), dateFormat)
          : '',
      },
      {
        title: 'BENEFICIARIO_FECHA_INVITACION',
        value: 'BENEFICIARIO_FECHA_INVITACION',
        url: beneficiary?.invitationDate
          ? format(new Date(beneficiary?.invitationDate), dateFormat)
          : '',
      },
      {
        text: 'FIRMA_BENEFICIARIO',
        title: 'FIRMA_BENEFICIARIO',
        value: 'FIRMA_BENEFICIARIO',
        url: '@B___________________',
      },
      {
        text: 'SOLICITAR_EMAIL_NOTIFICACION',
        title: 'SOLICITAR_EMAIL_NOTIFICACION',
        value: 'SOLICITAR_EMAIL_NOTIFICACION',
        url: '@:__________________________',
      },
    ],
  };

  const tenderOfferTags = tenderOffer && {
    title: 'Tender Offer',
    menu: [
      {
        text: 'FECHA_VENTA_TENDER_OFFER',
        title: 'FECHA_VENTA_TENDER_OFFER',
        value: 'FECHA_VENTA_TENDER_OFFER',
        url: format(new Date(), dateFormatLong),
      },
      {
        text: 'CUADRO_RESUMEN_TENDER_OFFER',
        title: 'CUADRO_RESUMEN_TENDER_OFFER',
        value: 'CUADRO_RESUMEN_TENDER_OFFER',
        url: i18n.t('SeeAppendixI'),
      },
    ],
  };

  const tenderOfferSharesTags = tenderOfferShares && {
    title: 'Tender Offer Shares',
    menu: [
      {
        text: 'TENDER_VENTA_FECHA',
        title: 'TENDER_VENTA_FECHA',
        value: 'TENDER_VENTA_FECHA',
        url: format(new Date(), dateFormatLong),
      },
      {
        text: 'TENDER_COMPRA_FECHA',
        title: 'TENDER_COMPRA_FECHA',
        value: 'TENDER_COMPRA_FECHA',
        url: format(new Date(), dateFormatLong),
      },
      {
        text: 'TENDER_NOMBRE_APELLIDOS_VENDEDOR',
        title: 'TENDER_NOMBRE_APELLIDOS_VENDEDOR',
        value: 'TENDER_NOMBRE_APELLIDOS_VENDEDOR',
        url: tenderOfferShares?.sellerName,
      },
      {
        text: 'TENDER_NOMBRE_APELLIDOS_COMPRADOR',
        title: 'TENDER_NOMBRE_APELLIDOS_COMPRADOR',
        value: 'TENDER_NOMBRE_APELLIDOS_COMPRADOR',
        url: tenderOfferShares?.buyerName,
      },
      {
        text: 'TENDER_SOCIO_DIRECCION_VENDEDOR',
        title: 'TENDER_SOCIO_DIRECCION_VENDEDOR',
        value: 'TENDER_SOCIO_DIRECCION_VENDEDOR',
        url: getFullAddress(tenderOfferShares?.sellerAddress),
      },
      {
        text: 'TENDER_SOCIO_DIRECCION_COMPRADOR',
        title: 'TENDER_SOCIO_DIRECCION_COMPRADOR',
        value: 'TENDER_SOCIO_DIRECCION_COMPRADOR',
        url: getFullAddress(tenderOfferShares?.buyerAddress),
      },
      {
        text: 'TENDER_SOCIO_TAX_ID_VENDEDOR',
        title: 'TENDER_SOCIO_TAX_ID_VENDEDOR',
        value: 'TENDER_SOCIO_TAX_ID_VENDEDOR',
        url: tenderOfferShares?.sellerCif,
      },
      {
        text: 'TENDER_SOCIO_TAX_ID_COMPRADOR',
        title: 'TENDER_SOCIO_TAX_ID_COMPRADOR',
        value: 'TENDER_SOCIO_TAX_ID_COMPRADOR',
        url: tenderOfferShares?.buyerCif,
      },
      {
        text: 'TENDER_TOTAL_PARTICIPACIONES_VENTA',
        title: 'TENDER_TOTAL_PARTICIPACIONES_VENTA',
        value: 'TENDER_TOTAL_PARTICIPACIONES_VENTA',
        url: tenderOfferShares?.totalShares,
      },
      {
        text: 'TENDER_PARTICIPACIONES_DE_LA_VENTA',
        title: 'TENDER_PARTICIPACIONES_DE_LA_VENTA',
        value: 'TENDER_PARTICIPACIONES_DE_LA_VENTA',
        url: tenderOfferShares?.sharesFrom,
      },
      {
        text: 'TENDER_PARTICIPACIONES_A_LA_VENTA',
        title: 'TENDER_PARTICIPACIONES_A_LA_VENTA',
        value: 'TENDER_PARTICIPACIONES_A_LA_VENTA',
        url: tenderOfferShares?.sharesTo,
      },
      {
        text: 'TENDER_PRECIO_VENTA_PARTICIPACION',
        title: 'TENDER_PRECIO_VENTA_PARTICIPACION',
        value: 'TENDER_PRECIO_VENTA_PARTICIPACION',
        url: formatNumberDecimalsLong(tenderOfferShares?.sharePrice),
      },
      {
        text: 'TENDER_PRECIO_TOTAL_VENTA',
        title: 'TENDER_PRECIO_TOTAL_VENTA',
        value: 'TENDER_PRECIO_TOTAL_VENTA',
        url: formatNumberDecimalsLong(tenderOfferShares?.totalPrice),
      },
      {
        text: 'TENDER_ANEXO_RESUMEN',
        title: 'TENDER_ANEXO_RESUMEN',
        value: 'TENDER_ANEXO_RESUMEN',
        url: '',
      },
      {
        text: 'TENDER_FIRMA_COMPRADOR',
        title: 'TENDER_FIRMA_COMPRADOR',
        value: 'TENDER_FIRMA_COMPRADOR',
        url: '@C___________________',
      },
      {
        text: 'TENDER_FIRMA_VENDEDOR',
        title: 'TENDER_FIRMA_VENDEDOR',
        value: 'TENDER_FIRMA_VENDEDOR',
        url: '@V___________________',
      },
    ],
  };

  const operationTags = operation && {
    title: 'Operaciones',
    menu: [
      {
        text: 'FECHA_OPERACION',
        title: 'FECHA_OPERACION',
        value: 'FECHA_OPERACION',
        url: operation?.operationDate
          ? format(new Date(operation?.operationDate), dateFormat)
          : '[FECHA_OPERACION]',
      },
      {
        text: 'NOMBRE_Y_APELLIDOS_NOTARIO',
        title: 'NOMBRE_Y_APELLIDOS_NOTARIO',
        value: 'NOMBRE_Y_APELLIDOS_NOTARIO',
        url: operation?.notary?.name || '[NOMBRE_Y_APELLIDOS_NOTARIO]',
      },
      {
        text: 'POBLACION_NOTARIO',
        title: 'POBLACION_NOTARIO',
        value: 'POBLACION_NOTARIO',
        url: operation?.notary?.city || '[POBLACION_NOTARIO]',
      },
      {
        text: 'NUMERO_PROTOCOLO',
        title: 'NUMERO_PROTOCOLO',
        value: 'NUMERO_PROTOCOLO',
        url: operation?.notary?.protocolNumber || '[NUMERO_PROTOCOLO]',
      },
      {
        text: 'COMPARECIENTES_VENDEDORES',
        title: 'COMPARECIENTES_VENDEDORES',
        value: 'COMPARECIENTES_VENDEDORES',
        url:
          getAttendeesSellers(operation?.sellers) ||
          '[COMPARECIENTES_VENDEDORES]',
      },
      {
        text: 'COMPARECIENTES_COMPRADORES',
        title: 'COMPARECIENTES_COMPRADORES',
        value: 'COMPARECIENTES_COMPRADORES',
        url:
          getAttendeesBuyers(operation?.buyers) ||
          '[COMPARECIENTES_COMPRADORES]',
      },
      {
        text: 'VENDEDORES_PERSONAS_FISICAS_Y_JURIDICAS',
        title: 'VENDEDORES_PERSONAS_FISICAS_Y_JURIDICAS',
        value: 'VENDEDORES_PERSONAS_FISICAS_Y_JURIDICAS',
        url:
          getParticipantsNaturalAndLegalSellers(operation?.sellers) ||
          '[VENDEDORES_PERSONAS_FISICAS_Y_JURIDICAS]',
      },
      {
        text: 'VENDEDORES_PERSONAS_FISICAS',
        title: 'VENDEDORES_PERSONAS_FISICAS',
        value: 'VENDEDORES_PERSONAS_FISICAS',
        url:
          getParticipantsNaturalSellers(operation?.sellers) ||
          '[VENDEDORES_PERSONAS_FISICAS]',
      },
      {
        text: 'VENDEDORES_PERSONAS_JURIDICAS',
        title: 'VENDEDORES_PERSONAS_JURIDICAS',
        value: 'VENDEDORES_PERSONAS_JURIDICAS',
        url:
          getParticipantsLegalSellers(operation?.sellers) ||
          '[VENDEDORES_PERSONAS_JURIDICAS]',
      },
      {
        text: 'COMPRADORES_PERSONAS_FISICAS',
        title: 'COMPRADORES_PERSONAS_FISICAS',
        value: 'COMPRADORES_PERSONAS_FISICAS',
        url:
          getParticipantsNaturalBuyers(operation?.buyers) ||
          '[COMPRADORES_PERSONAS_FISICAS]',
      },
      {
        text: 'COMPRADORES_PERSONAS_JURIDICAS',
        title: 'COMPRADORES_PERSONAS_JURIDICAS',
        value: 'COMPRADORES_PERSONAS_JURIDICAS',
        url:
          getParticipantsLegalBuyers(operation?.buyers) ||
          '[COMPRADORES_PERSONAS_JURIDICAS]',
      },
      {
        text: 'COMPRADORES_PERSONAS_FISICAS_Y_JURIDICAS',
        title: 'COMPRADORES_PERSONAS_FISICAS_Y_JURIDICAS',
        value: 'COMPRADORES_PERSONAS_FISICAS_Y_JURIDICAS',
        url:
          getParticipantsNaturalAndLegalBuyers(operation?.buyers) ||
          '[COMPRADORES_PERSONAS_FISICAS_Y_JURIDICAS]',
      },
      {
        text: 'VENDEDORES_PERSONAS_TODAS',
        title: 'VENDEDORES_PERSONAS_TODAS',
        value: 'VENDEDORES_PERSONAS_TODAS',
        url:
          getFullNameSellers(operation?.sellers) ||
          '[VENDEDORES_PERSONAS_TODAS]',
      },
      {
        text: 'COMPRAVENTA_PORCENTAJE_VENTA_TOTAL',
        title: 'COMPRAVENTA_PORCENTAJE_VENTA_TOTAL',
        value: 'COMPRAVENTA_PORCENTAJE_VENTA_TOTAL',
        url:
          operation?.summaryRow?.totalND ||
          '[COMPRAVENTA_PORCENTAJE_VENTA_TOTAL]',
      },
      {
        text: 'TITULARIDAD_VENDEDORES',
        title: 'TITULARIDAD_VENDEDORES',
        value: 'TITULARIDAD_VENDEDORES',
        url: getOwnershipSellers(operation) || '[TITULARIDAD_VENDEDORES]',
      },
      {
        text: 'PERFECCIONAMIENTO_VENDEDORES',
        title: 'PERFECCIONAMIENTO_VENDEDORES',
        value: 'PERFECCIONAMIENTO_VENDEDORES',
        url:
          getPerfectionSellers(operation) || '[PERFECCIONAMIENTO_VENDEDORES]',
      },
      {
        text: 'VALORACION_PRE_MONEY',
        title: 'VALORACION_PRE_MONEY',
        value: 'VALORACION_PRE_MONEY',
        url:
          formatNumberDecimals(operation?.valuation) ||
          '[VALORACION_PRE_MONEY]',
      },
      {
        text: 'IMPORTE_AMPLIACION',
        title: 'IMPORTE_AMPLIACION',
        value: 'IMPORTE_AMPLIACION',
        url:
          formatNumberDecimals(operation?.totalContribution) ||
          '[IMPORTE_AMPLIACION]',
      },
      {
        text: 'VALORACION_POST_MONEY',
        title: 'VALORACION_POST_MONEY',
        value: 'VALORACION_POST_MONEY',
        url:
          formatNumberDecimals(
            operation?.valuation + operation?.totalContribution
          ) || '[VALORACION_POST_MONEY]',
      },
      {
        text: 'TOTAL_NUEVAS_PARTICIPACIONES',
        title: 'TOTAL_NUEVAS_PARTICIPACIONES',
        value: 'TOTAL_NUEVAS_PARTICIPACIONES',
        url:
          formatNumberDecimals(operation?.totalNewShares) ||
          '[TOTAL_NUEVAS_PARTICIPACIONES]',
      },
      {
        text: 'NUMERO_DE_PARTICIPACIONES',
        title: 'NUMERO_DE_PARTICIPACIONES',
        value: 'NUMERO_DE_PARTICIPACIONES',
        url:
          formatNumberDecimals(operation?.societyTotalShares) ||
          '[NUMERO_DE_PARTICIPACIONES]',
      },
      {
        text: 'CLASE_PARTICIPACIONES',
        title: 'CLASE_PARTICIPACIONES',
        value: 'CLASE_PARTICIPACIONES',
        url:
          getHoldingClassesFromDraft(operation, holdingClasses) ||
          '[CLASE_PARTICIPACIONES]',
      },
      {
        text: 'VALOR_NOMINAL',
        title: 'VALOR_NOMINAL',
        value: 'VALOR_NOMINAL',
        url: formatNumberDecimals(operation?.nominalValue) || '[VALOR_NOMINAL]',
      },
      {
        text: 'RANGO_PARTICIPACIONES_DESDE',
        title: 'RANGO_PARTICIPACIONES_DESDE',
        value: 'RANGO_PARTICIPACIONES_DESDE',
        url:
          getSharesRangeFromDraft(operation)?.sharesFrom ||
          '[RANGO_PARTICIPACIONES_DESDE]',
      },
      {
        text: 'RANGO_PARTICIPACIONES_HASTA',
        title: 'RANGO_PARTICIPACIONES_HASTA',
        value: 'RANGO_PARTICIPACIONES_HASTA',
        url:
          getSharesRangeFromDraft(operation)?.sharesTo ||
          '[RANGO_PARTICIPACIONES_HASTA]',
      },
      {
        text: 'INVERSORES_PARTICIPACION_AMPLIACION',
        title: 'INVERSORES_PARTICIPACION_AMPLIACION',
        value: 'INVERSORES_PARTICIPACION_AMPLIACION',
        url: getInvestorsDetailsCapitalIncrease(operation),
      },
      {
        text: 'IMPORTE_TOTAL_CAPITAL_SOCIAL',
        title: 'IMPORTE_TOTAL_CAPITAL_SOCIAL',
        value: 'IMPORTE_TOTAL_CAPITAL_SOCIAL',
        url:
          formatNumberDecimals(operation?.totalCapital) ||
          '[IMPORTE_TOTAL_CAPITAL_SOCIAL]',
      },
    ],
  };

  const holdingClassesTags = holdingClasses && {
    title: 'Clases de participaciones',
    menu: [
      {
        text: 'LISTADO_CLASES_NOMBRE_Y_NUMERACION',
        title: 'LISTADO_CLASES_NOMBRE_Y_NUMERACION',
        value: 'LISTADO_CLASES_NOMBRE_Y_NUMERACION',
        url: getHoldingClassesList(holdingClasses, society),
      },
    ],
  };

  const adminMembersTags = society && {
    title: 'Órgano de Administración',
    menu: [
      {
        text: 'ADMINISTRADOR_MANCOMUNADO',
        title: 'ADMINISTRADOR_MANCOMUNADO',
        value: 'ADMINISTRADOR_MANCOMUNADO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.POOLED_ADMIN
        ),
      },
      {
        text: 'ADMINISTRADOR_SOLIDARIO',
        title: 'ADMINISTRADOR_SOLIDARIO',
        value: 'ADMINISTRADOR_SOLIDARIO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.SOLIDARY_ADMIN
        ),
      },
      {
        text: 'ADMINISTRADOR_UNICO',
        title: 'ADMINISTRADOR_UNICO',
        value: 'ADMINISTRADOR_UNICO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.UNIQUE_ADMIN
        ),
      },
      {
        text: 'AUDITOR',
        title: 'AUDITOR',
        value: 'AUDITOR',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.AUDITOR
        ),
      },
      {
        text: 'CONSEJERO',
        title: 'CONSEJERO',
        value: 'CONSEJERO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.COUNSELOR
        ),
      },
      {
        text: 'CONSEJERO_DELEGADO',
        title: 'CONSEJERO_DELEGADO',
        value: 'CONSEJERO_DELEGADO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.DELEGATE_COUNSELOR
        ),
      },
      {
        text: 'CONSEJERO_DELEGADO_MANCOMUNADO',
        title: 'CONSEJERO_DELEGADO_MANCOMUNADO',
        value: 'CONSEJERO_DELEGADO_MANCOMUNADO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.JOINT_DELEGATE_COUNSELOR
        ),
      },
      {
        text: 'CONSEJERO_INDEPENDIENTE',
        title: 'CONSEJERO_INDEPENDIENTE',
        value: 'CONSEJERO_INDEPENDIENTE',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.INDEPENDENT_COUNSELOR
        ),
      },
      {
        text: 'CONSEJERO_NO_EJECUTIVO',
        title: 'CONSEJERO_NO_EJECUTIVO',
        value: 'CONSEJERO_NO_EJECUTIVO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.NON_EXECUTIVE_COUNSELOR
        ),
      },
      {
        value: 'PRESIDENTE_DEL_CONSEJO',
        title: 'PRESIDENTE_DEL_CONSEJO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.COUNCIL_PRESIDENT
        ),
      },
      {
        text: 'SECRETARIO_CONSEJO',
        title: 'SECRETARIO_CONSEJO',
        value: 'SECRETARIO_CONSEJO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.COUNCIL_SECRETARY
        ),
      },
      {
        value: 'SECRETARIO_NO_CONSEJERO',
        title: 'SECRETARIO_NO_CONSEJERO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.SECRETARY_NO_DIRECTOR
        ),
      },
      {
        text: 'TESORERO',
        title: 'TESORERO',
        value: 'TESORERO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.TREASURER
        ),
      },
      {
        text: 'VICEPRESIDENTE_CONSEJO',
        title: 'VICEPRESIDENTE_CONSEJO',
        value: 'VICEPRESIDENTE_CONSEJO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.COUNCIL_VICE_PRESIDENT
        ),
      },
      {
        text: 'VICESECRETARIO_NO_CONSEJERO',
        title: 'VICESECRETARIO_NO_CONSEJERO',
        value: 'VICESECRETARIO_NO_CONSEJERO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.NON_DIRECTOR_DEPUTY_SECRETARY
        ),
      },
      {
        text: 'VOCAL_NO_CONSEJERO',
        title: 'VOCAL_NO_CONSEJERO',
        value: 'VOCAL_NO_CONSEJERO',
        url: getDirectorSocialDenomination(
          society,
          currDirectorTypes.var.NON_COUNSELOR_VOWEL
        ),
      },
    ],
  };

  const defaultTags = {
    title: 'Tags por defecto',
    menu: [
      {
        text: 'FECHA_ACTUAL',
        title: 'FECHA_ACTUAL',
        value: 'FECHA_ACTUAL',
        url: dayMonthYear(new Date()),
      },
    ],
  };

  const tags = [
    societyTags,
    planTags,
    partnerTags,
    beneficiaryTags,
    boardTags,
    signaturesTags,
    tenderOfferTags,
    tenderOfferSharesTags,
    operationTags,
    holdingClassesTags,
    adminMembersTags,
  ].filter((tag) => tag);

  // Add default tags.
  tags.push(defaultTags);

  return tags;
}

export default tags;
