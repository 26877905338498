/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-extraneous-dependencies */
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { MixpanelProvider } from 'react-mixpanel-browser';

import UnderMaintenance from 'modules/_shared/pages/_UnderMaintenance';
import { ThemeProvider } from 'modules/_shared/contexts/ThemeContext';
import Auth0ProviderWithDynamicConfig from 'Auth0ProviderWithDynamicConfig';
import { store, persistor } from 'redux/store';
import { initSentry } from 'setupSentry';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { AxiosAuthProvider } from 'contexts/AxiosAuthProvider';
import App from 'App';

import './i18n/config';

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID, {
    shouldDebugLog: false,
  });
  setupLogRocketReact(LogRocket);
}

initSentry();

const isUnderMaintenance = false;

ReactDOM.render(
  isUnderMaintenance ? (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider>
          <UnderMaintenance />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Auth0ProviderWithDynamicConfig>
        <Provider store={store}>
          <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
            <MixpanelProvider>
              <ThemeProvider>
                <AxiosAuthProvider>
                  <App />
                </AxiosAuthProvider>
              </ThemeProvider>
            </MixpanelProvider>
          </PersistGate>
        </Provider>
      </Auth0ProviderWithDynamicConfig>
    </BrowserRouter>
  ),
  document.getElementById('root')
);
