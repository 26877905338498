import i18n from 'i18n/config';
import parse from 'html-react-parser';
import { format } from 'date-fns';
import { TranslateFn } from 'hooks/useTranslate';

import { Council, Society } from 'types';
import { getFileDate, getFileSize } from 'utils/files';
import { DATE_FORMAT } from 'constants/defaultConstants';
import { formatDateToTime } from 'constants/formats';
import { getSocietyEmailConfiguration } from 'utils/emails';
import { getCouncilAnnouncementCommunication } from 'modules/administratives/modals/CouncilAnnouncementModal/utils/getCouncilAnnouncementCommunication';
import {
  ContentTypes,
  DocumentsGroupItem,
} from '../components/CouncilMembersDocumentsCard';
import { getCouncilTypeLabel } from '../../CouncilDetails/utils/getCouncilTypeLabel';
import { getCouncilAttendanceTypeLabel } from '../../CouncilDetails/utils/getCouncilAttendanceTypeLabel';

export const generateDocumentGroups = (
  council: Council,
  documents: any,
  society: Society,
  t: TranslateFn
) => {
  const {
    announcementCommunication,
    announcementDocument,
    actDocument,
    actCertificateDocument,
    actDocumentSignature,
    actCertificateDocumentSignature,
  } = documents || {};

  const societyTheme = getSocietyEmailConfiguration(society, {
    addSuffix: false,
  })?.theme;

  const hasWhiteBranding = society?.configuration?.hasWhiteBranding;

  const announcementItems: DocumentsGroupItem[] = [
    {
      name: t('Communication'),
      size: getFileSize(announcementCommunication),
      date: getFileDate(announcementCommunication, 'MMM dd, yyyy'),
      description: announcementCommunication ? 'Email' : '-',
      content: {
        name: announcementCommunication?.subject || '-',
        editorModel: parse(
          getCouncilAnnouncementCommunication(
            societyTheme,
            hasWhiteBranding,
            {
              time: council?.date ? formatDateToTime(council?.date) : '-',
              councilName: council?.name || '',
              date: council?.date
                ? format(new Date(council?.date), DATE_FORMAT)
                : '-',
              societyName: society?.name || '-',
              announcement: council?.announcementText || '',
              place: council?.place || '-',
              onlineAccess: council?.onlineAccess || '-',
              attendanceType: getCouncilAttendanceTypeLabel(council) || '-',
              councilType: getCouncilTypeLabel(council) || '-',
            },
            i18n?.language || 'es'
          )
        ),
      },
      contentType: ContentTypes.EMAIL,
    },
    {
      name: t('Announcement'),
      size: getFileSize(announcementDocument),
      date: getFileDate(announcementDocument, 'MMM dd, yyyy'),
      description: announcementDocument?.name,
      content: announcementDocument,
      contentType: ContentTypes.PDF,
    },
  ];

  const actItems = [
    {
      name: t('Act'),
      size: getFileSize(actDocument),
      date: getFileDate(actDocument, 'MMM dd, yyyy'),
      description: actDocument?.name || '-',
      content: {
        name: actDocument?.name || '-',
        editorModel: parse(actDocument?.editorModel || ''),
      },
      contentType: ContentTypes.TEMPLATE,
    },
    {
      name: t('Certificate'),
      size: getFileSize(actCertificateDocument),
      date: getFileDate(actCertificateDocument, 'MMM dd, yyyy'),
      description: actCertificateDocument?.name || '-',
      content: {
        name: actCertificateDocument?.name || '-',
        editorModel: parse(actCertificateDocument?.editorModel || ''),
      },
      contentType: ContentTypes.TEMPLATE,
    },
  ];

  const signItems = [
    {
      name: t('ActSignature'),
      size: getFileSize(actDocumentSignature),
      date: getFileDate(actDocumentSignature, 'MMM dd, yyyy'),
      description: actDocumentSignature?.name || '-',
      content: actDocumentSignature,
      contentType: ContentTypes.PDF,
    },
    {
      name: t('CertificateSignature'),
      size: getFileSize(actCertificateDocumentSignature),
      date: getFileDate(actCertificateDocumentSignature, 'MMM dd, yyyy'),
      description: actCertificateDocumentSignature?.name || '-',
      content: actCertificateDocumentSignature,
      contentType: ContentTypes.PDF,
    },
  ];

  return {
    announcementItems,
    actItems,
    signItems,
  };
};
