import { FC } from 'react';

import { SocietyDirector } from 'types';

import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';
import AdminMembersTableHeader from './AdminMembersTableHeader';
import AdminMembersTableBody from './AdminMembersTableBody';

type Props = {
  societyId: string;
  adminMembers: SocietyDirector[];
};

const AdminMembersTable: FC<Props> = ({ societyId, adminMembers }) => {
  const { isReadOnly } = useGetSocietySectionPermissions().data;

  return (
    <div className="card card-bordered card-preview">
      <div className="table-responsive">
        <table className="nk-tb-list nk-tb-ulist">
          <AdminMembersTableHeader isReadOnly={isReadOnly} />

          <AdminMembersTableBody
            societyId={societyId}
            adminMembers={adminMembers}
            isReadOnly={isReadOnly}
          />
        </table>
      </div>
    </div>
  );
};

export default AdminMembersTable;
