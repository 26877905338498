/* eslint-disable no-underscore-dangle */
import {
  ANADIR_SOCIO,
  NUMERATE_FROM_START,
  SELECCIONAR_SOCIO,
  SELECT_OPTION,
} from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import { cleanValue, numberFormat } from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import CommentsCharCounter from 'modules/_shared/components/CommentsCharCounter';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { capitalIncreaseAction } from 'modules/_shared/redux/modalsActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import UploadDocument from 'modules/documents/components/UploadDocument';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { store } from 'redux/store';
import cleanText from 'utils/cleanText';
import trackEvent from 'utils/trackEvent';
import AddPartnerEmbeddedModal from '../AddPartnerEmbedded';
import '../Modals.scss';

function CapitalIncreaseModal() {
  const { t } = useTranslate();
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();

  const { user, actualSociety, holdingClasses } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
    holdingClasses: state.holdingClasses,
  }));

  const [step, setStep] = useState(0);
  const [missingParticipations, setMissingParticipations] = useState(0);
  const [movements, setMovements] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [actualFrom, setActualFrom] = useState();
  const [numerationRefs, setNumerationRefs] = useState();
  const [file, setFile] = useState();
  const [comments, setComments] = useState('');
  const [notaryName, setNotaryName] = useState('');
  const [notaryCity, setNotaryCity] = useState('');
  const [protocolNr, setProtocolNr] = useState('');
  const [sharePremium, setSharePremium] = useState('');
  const [isFixedPremium, setIsFixedPremium] = useState(false);
  const [customizeSharePremium, setCustomizeSharePremium] = useState(false);
  const [holdingClass, setHoldingClass] = useState(SELECT_OPTION);
  const [holdingClassSelectedStartFrom0, setHoldingClassSelectedStartFrom0] =
    useState(false);
  const [totalParticipationsToAdd, setTotalParticipationsToAdd] = useState('');

  const [selectedPartner, setSelectedPartner] = useState(SELECCIONAR_SOCIO);
  const [partnerParticipations, setPartnerParticipations] = useState('');

  const [date, setDate] = useState('');
  const [lastOperationDate, setLastOperationDate] = useState();

  const [operationDate, setOperationDate] = useState();
  const [operationLimit, setOperationLimit] = useState();

  const [createPartnerInsite, setCreatePartnerInsite] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [partnerOptions, setPartnerOptions] = useState([]);
  const [selectedPartnerOption, setSelectedPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: t('SelectPartner'),
  });

  const [selectedClassOption, setSelectedClassOption] = useState({
    value: SELECT_OPTION,
    label: t('Select'),
  });

  const animatedComponents = makeAnimated();

  const handlePartnerOptionChange = (selectedOption) => {
    setSelectedPartner(selectedOption.value);
    setSelectedPartnerOption(selectedOption);
  };

  const handleClassOptionChange = (selectedOption) => {
    setHoldingClass(selectedOption.value);
    setSelectedClassOption(selectedOption);
  };
  const handleDate = (selectedDate) => {
    const objDate = new Date(selectedDate);
    if (objDate.getFullYear() < 1970) {
      if (operationDate) setOperationDate();
    } else if (objDate > new Date(lastOperationDate)) {
      setErrorMessage('');
      setDate(objDate);
      setOperationDate(format(objDate, 'yyyy-MM-dd'));
    } else {
      const nextDate = new Date(lastOperationDate);
      nextDate.setMinutes(nextDate.getMinutes() + 30);
      setDate(nextDate);
      setOperationDate(format(nextDate, 'yyyy-MM-dd'));
    }
  };

  const handleChangeFile = (file) => {
    setFile(file);
  };

  useEffect(() => {
    if (actualSociety) {
      const excludedOps = [
        operationTypes.ADD_PARTNERS,
        operationTypes.ADD_PARTNER,
        operationTypes.ADD_BENEFICIARY,
        operationTypes.STOCK_OPTION,
        operationTypes.PHANTOM_SHARE,
      ];
      const filteredOperations = actualSociety.operations.filter(
        (operation) => !excludedOps.includes(operation.operationType)
      );
      const lastOperation = filteredOperations.reduce((acc, op) =>
        new Date(acc.date) > new Date(op.date) ? acc : op
      );
      // Get last operation date from UTC to local timezone with new Date()
      const lastDate = format(
        new Date(lastOperation.date),
        "yyyy-MM-dd'T'HH:mm"
      );
      const dateLimit = format(new Date(lastOperation.date), 'yyyy-MM-dd');
      setOperationLimit(dateLimit);
      setLastOperationDate(lastDate || '1990-01-01T00:00');

      const options = actualSociety?.partners.map((partner) => ({
        value: partner?.['_id'],
        label: `${partner?.cif} | ${partner?.name}`,
      }));

      options.push({
        value: 'disabled',
        label: '─────────────────',
        isDisabled: true,
      });

      options.push({
        value: ANADIR_SOCIO,
        label: ANADIR_SOCIO,
      });

      setPartnerOptions(options);
    }
  }, [actualSociety]);

  function nextPartner() {
    if (selectedPartner === SELECCIONAR_SOCIO)
      dispatch(addAlert(alertBodyTypes['SELECT_PARTNER']));
    else {
      const premium = isFixedPremium
        ? +sharePremium / +partnerParticipations
        : +sharePremium;
      const newMovement = {
        partner: selectedPartner,
        shareFrom: actualFrom[numerationRefs[holdingClass]],
        shareTo:
          actualFrom[numerationRefs[holdingClass]] + partnerParticipations - 1,
        shareClass: holdingClass,
        sharePremium: premium,
        operation: 'THIS',
      };
      const newTransaction = {
        partner: selectedPartner,
        shareFrom: actualFrom[numerationRefs[holdingClass]],
        shareTo:
          actualFrom[numerationRefs[holdingClass]] + partnerParticipations - 1,
        shareClass: holdingClass,
        sharePremium: premium,
        transactionType: transactionTypes.CAPITAL_INCREASE,
      };

      setMovements([...movements, newMovement]);
      setTransactions([...transactions, newTransaction]);
      const currentFrom = actualFrom;
      currentFrom[numerationRefs[holdingClass]] += partnerParticipations;
      setActualFrom(currentFrom);
      setPartnerParticipations('');
      setSelectedPartner(SELECCIONAR_SOCIO);
      setSelectedPartnerOption({
        value: SELECCIONAR_SOCIO,
        label: SELECCIONAR_SOCIO,
      });
      setMissingParticipations(missingParticipations - partnerParticipations);
    }
  }

  async function saveIncrease() {
    if (selectedPartner === SELECCIONAR_SOCIO)
      dispatch(addAlert(alertBodyTypes['SELECT_PARTNER']));
    else {
      const premium = isFixedPremium
        ? +sharePremium / +partnerParticipations
        : +sharePremium;
      const finalMovements = [
        ...movements,
        {
          partner: selectedPartner,
          shareFrom: actualFrom[numerationRefs[holdingClass]],
          shareTo:
            actualFrom[numerationRefs[holdingClass]] +
            partnerParticipations -
            1,
          shareClass: holdingClass,
          sharePremium: premium,
          operation: 'THIS',
        },
      ];
      const finalTransactions = [
        ...transactions,
        {
          partner: selectedPartner,
          shareFrom: actualFrom[numerationRefs[holdingClass]],
          shareTo:
            actualFrom[numerationRefs[holdingClass]] +
            partnerParticipations -
            1,
          shareClass: holdingClass,
          sharePremium: premium,
          transactionType: transactionTypes.CAPITAL_INCREASE,
        },
      ];
      let uploadedDocument = null;
      if (file) {
        const newDocument = {
          file,
          date,
          size: file.size,
          name: operationTypes.CAPITAL_INCREASE_FILENAME,
          author: user['_id'],
          society: actualSociety['_id'],
          fileType: fileTypes[file.type],
          category: documentTypes.SCRIPTURES,
          description: t('DocumentLinkedToOperation'),
        };
        uploadedDocument = await store.dispatch(addDocument(newDocument));
      }

      dispatch(
        capitalIncreaseAction({
          movements: finalMovements,
          transactions: finalTransactions,
          date,
          nominalValue: actualSociety.nominalValue,
          society: actualSociety['_id'],
          user: user['_id'],
          documents: uploadedDocument?.['_id'] ? [uploadedDocument['_id']] : [],
          comments: cleanText(comments),
          notarialRegistration: {
            notaryName,
            notaryCity,
            protocolNr,
          },
        })
      );
      dispatch(setModal(null));

      trackEvent(mixpanel, eventTypes.CAPITAL_INCREASE, {
        userId: user?._id,
        userName: user?.name,
        userEmail: user?.email,
        societyId: actualSociety?._id,
        societyName: actualSociety?.name,
        operation: eventTypes.CAPITAL_INCREASE,
      });
    }
  }

  function nextStep(event) {
    event.preventDefault();

    if (!step) {
      setTotalParticipationsToAdd(totalParticipationsToAdd);
      setSharePremium(+sharePremium);
      setMissingParticipations(totalParticipationsToAdd);
      setStep(1);
    } else if (missingParticipations - partnerParticipations) nextPartner();
    else saveIncrease();
  }

  useEffect(() => {
    if (selectedPartner === ANADIR_SOCIO) {
      setCreatePartnerInsite(true);
    } else {
      setCreatePartnerInsite(false);
    }
  }, [selectedPartner]);

  useEffect(() => {
    if (!customizeSharePremium) {
      setSharePremium('');
    }
  }, [customizeSharePremium]);

  useEffect(() => {
    if (holdingClasses?.length) {
      const currentClass = holdingClasses.find(
        (curr) => curr['_id'] === holdingClass
      );
      const numeration = currentClass?.numerationOption
        ? holdingClasses.find(
            (curr) => curr['_id'] === currentClass.numerationOption
          )?.name
        : NUMERATE_FROM_START;
      setHoldingClassSelectedStartFrom0(numeration === NUMERATE_FROM_START);
    }
  }, [holdingClass]);

  useEffect(() => {
    if (holdingClasses?.length) {
      setHoldingClass(holdingClasses[0]?.['_id']);
      setSelectedClassOption({
        value: holdingClasses[0]?.['_id'],
        label: holdingClasses[0]?.name,
      });
      const initial = holdingClasses?.reduce(
        (acc, currentClass) => {
          acc.from[currentClass['_id']] = 1;
          acc.refs[currentClass['_id']] =
            currentClass.numerationOption || currentClass['_id'];
          return acc;
        },
        {
          from: {},
          refs: {},
        }
      );
      const refs = Object.entries(initial.refs).reduce((acc, [id, ref]) => {
        acc[id] = acc[initial.refs[ref]] || initial.refs[ref];
        return acc;
      }, {});

      const from = actualSociety?.shares?.reduce((acc, share) => {
        const classId = share.shareClass?.['_id'];
        if (share?.isActive && acc[refs[classId]] <= share.to)
          acc[refs[classId]] = share.to + 1;
        return acc;
      }, initial.from);

      setNumerationRefs(refs);
      setActualFrom(from);
    }
  }, [holdingClasses]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('CapitalExpansion')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={nextStep}>
          {!step ? (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('ExpansionDate')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-right">
                    <em className="icon ni ni-calendar-alt" />
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    value={operationDate}
                    onChange={(event) => handleDate(event.target.value)}
                    required
                    max="2100-01-01"
                    min={operationLimit}
                  />
                </div>
                {errorMessage ? (
                  <span className="sub-text mt-1 text-danger">
                    {errorMessage}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('NºNewParticipations')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('Nr')}</span>
                  </div>
                  <NumberFormat
                    id="outlined-normal"
                    className="form-control form-control-outlined"
                    placeholder={t('NumberOfNewParticipations')}
                    value={totalParticipationsToAdd}
                    onChange={(e) =>
                      setTotalParticipationsToAdd(
                        cleanValue(e.target.value) || ''
                      )
                    }
                    isAllowed={(inputObj) => {
                      const { floatValue, formattedValue } = inputObj;
                      if (formattedValue === '') return true;
                      if (floatValue >= 1 && Number.isInteger(floatValue))
                        return true;
                      return false;
                    }}
                    required
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </div>
              </div>

              <UploadDocument
                label="AddWritingOptional"
                inputLabel={file?.name || t('DragDocumentToUpload')}
                file={file}
                setFile={handleChangeFile}
              />

              <div className="form-group">
                <label className="form-label" htmlFor="operation-comments">
                  {t('CommentsLabel')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-editor-custom">
                    <textarea
                      id="operation-comments"
                      maxLength="500"
                      className="form-control form-control-lg no-resize"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-note d-flex justify-content-between">
                  <>{t('CommentsVisibleOnlyAdmins')}</>
                  <CommentsCharCounter comments={comments} />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">{t('NotaryName')}</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('Mr. John Smith')}
                        value={notaryName}
                        onChange={(e) => setNotaryName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">
                      {t('NotaryProtocolNumber')}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('P-8234290A')}
                        value={protocolNr}
                        onChange={(e) => setProtocolNr(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">{t('NotaryCity')}</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('Barcelona')}
                        value={notaryCity}
                        onChange={(e) => setNotaryCity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-lg btn-primary">
                {t('Following')}
              </button>
            </>
          ) : (
            <>
              <label className="form-label">
                <span className="bg-warning p-2">
                  {t('TotalNewParticipationsPen')}:
                </span>
                <span className="badge-primary rounded px-2 py-1 ml-2">
                  <NumberFormat
                    displayType="text"
                    value={missingParticipations}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </span>
              </label>
              <hr />

              {createPartnerInsite && date ? (
                <AddPartnerEmbeddedModal
                  setSelectedPartner={setSelectedPartner}
                  setSelectedPartnerOption={setSelectedPartnerOption}
                  setCreatePartnerInsite={setCreatePartnerInsite}
                  date={date}
                />
              ) : (
                <div className="form-group">
                  <label className="form-label" htmlFor="default-01">
                    {t('PartnerName')}
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      closeMenuOnSelect
                      className="react-select"
                      value={selectedPartnerOption}
                      options={partnerOptions}
                      components={animatedComponents}
                      onChange={handlePartnerOptionChange}
                    />
                  </div>
                  {createPartnerInsite && !date && (
                    <span className="sub-text mt-1 text-danger">
                      {t('SelectATransmissionDate')}
                    </span>
                  )}
                  <span className="sub-text">
                    {t('FollowTheOrderOfAssignmen')}
                  </span>
                </div>
              )}

              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('NºNewParticipations')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('Nr')}</span>
                  </div>
                  <NumberFormat
                    id="outlined-normal"
                    className="form-control form-control-outlined"
                    placeholder={t('NumberOfNewParticipations')}
                    value={partnerParticipations}
                    onChange={(e) =>
                      setPartnerParticipations(cleanValue(e.target.value) || '')
                    }
                    isAllowed={(inputObj) => {
                      const { floatValue, formattedValue } = inputObj;
                      if (formattedValue === '') return true;
                      if (
                        Number.isInteger(floatValue) &&
                        floatValue >= 1 &&
                        floatValue <= missingParticipations
                      )
                        return true;
                      return false;
                    }}
                    required
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </div>
              </div>

              <div
                className="form-group"
                style={{
                  zIndex: 0,
                }}
              >
                <div className="custom-control custom-control-xs custom-checkbox mt-1">
                  <input
                    type="checkbox"
                    id="checkbox-toggle"
                    className="custom-control-input"
                    value={customizeSharePremium}
                    onChange={(event) =>
                      setCustomizeSharePremium(event.target.checked)
                    }
                  />
                  <label
                    className="custom-control-label mb-2"
                    htmlFor="checkbox-toggle"
                  >
                    {t('EmissionPremium')}
                  </label>
                </div>
                {customizeSharePremium && (
                  <>
                    <div className="form-control-wrap">
                      <div className="form-text-hint">
                        <span className="overline-title">Eur</span>
                      </div>
                      <NumberFormat
                        id="outlined-normal"
                        className="form-control form-control-outlined"
                        placeholder={
                          isFixedPremium
                            ? t('FixedPremium')
                            : t('PremiumAmountForParticipati')
                        }
                        value={sharePremium}
                        onChange={(e) =>
                          setSharePremium(cleanValue(e.target.value) || '')
                        }
                        isAllowed={(inputObj) => {
                          const { floatValue, formattedValue } = inputObj;
                          if (formattedValue === '') return true;
                          if (floatValue >= 0) return true;
                          return false;
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...numberFormat}
                      />
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <div className="custom-control-sm custom-switch float-right d-flex align-content-center">
                          <span
                            style={{
                              marginRight: '45px',
                              fontSize: '12px',
                              lineHeight: '1.125rem',
                              paddingTop: '0',
                            }}
                          >
                            {t('FixedPremium')}
                          </span>
                          <input
                            type="checkbox"
                            id="price-switch"
                            className="custom-control-input float-right"
                            checked={!isFixedPremium}
                            onChange={(event) =>
                              setIsFixedPremium(!event.target.checked)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="price-switch"
                          >
                            {t('SharePremium')}
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('ClassOfParticipations')}
                </label>
                <div className="form-control-wrap">
                  <div className="">
                    <Select
                      closeMenuOnSelect
                      className="react-select"
                      value={selectedClassOption}
                      options={holdingClasses?.map((holdingClass) => ({
                        value: holdingClass['_id'],
                        label: `${holdingClass.name}`,
                      }))}
                      components={animatedComponents}
                      onChange={handleClassOptionChange}
                      placeholder={SELECT_OPTION}
                    />
                  </div>
                  {holdingClassSelectedStartFrom0 && (
                    <span className="sub-text text-primary">
                      {t('ThisClassListsFromNo1')}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('OfTheNumber')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('Numeration')}</span>
                  </div>
                  <NumberFormat
                    id="outlined-normal"
                    className="form-control form-control-outlined"
                    value={actualFrom[numerationRefs[holdingClass]]}
                    readOnly
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('ToTheNumber')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('Numeration')}</span>
                  </div>
                  <NumberFormat
                    id="outlined-normal"
                    className="form-control form-control-outlined"
                    value={
                      actualFrom[numerationRefs[holdingClass]] +
                      partnerParticipations -
                      (partnerParticipations ? 1 : 0)
                    }
                    readOnly
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={
                  !partnerParticipations ||
                  selectedPartner === ANADIR_SOCIO ||
                  selectedPartner === SELECCIONAR_SOCIO ||
                  holdingClass === SELECT_OPTION
                }
              >
                {missingParticipations - partnerParticipations
                  ? t('Following')
                  : t('Save')}
              </button>
            </>
          )}
        </form>
      </Modal.Body>
    </>
  );
}

export default CapitalIncreaseModal;
