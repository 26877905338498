/* eslint-disable no-console */
import { axiosV1 } from 'interceptors/axios';
import { store } from 'redux/store';
import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import { decryptResponse } from './token';

async function downloadFile({ documentId }) {
  try {
    const { data } = await axiosV1.get(`/download/${documentId}`);
    const decodedData = await decryptResponse(data);
    window.open(decodedData?.url, '_blank');
  } catch (error) {
    store.dispatch(addAlert(alertBodyTypes['ERROR_OPENING_DOCUMENT']));
  }
}

export default downloadFile;
