import { MAX_FILESIZE_ALLOWED } from 'constants/defaultConstants';
import { useTranslate } from 'hooks/useTranslate';
import UploadDocument from 'modules/documents/components/UploadDocument';

type Props = {
  register: string;
  setRegister: (value: string) => void; // eslint-disable-line no-unused-vars
  constitutionFile: File;
  setConstitutionFile: (value: File) => void; // eslint-disable-line no-unused-vars
  constitutionDocumentName: string;
  section: string;
  setSection: (value: string) => void; // eslint-disable-line no-unused-vars
  appointmentFile: File;
  setAppointmentFile: (value: File) => void; // eslint-disable-line no-unused-vars
  appointmentDocumentName: string;
  sheet: string;
  setSheet: (value: string) => void; // eslint-disable-line no-unused-vars
  ownershipFile: File;
  setOwnershipFile: (value: File) => void; // eslint-disable-line no-unused-vars
  ownershipDocumentName: string;
  page: boolean;
};

const EditLegalPersonScripturesForm = ({
  register,
  setRegister,
  constitutionFile,
  setConstitutionFile,
  constitutionDocumentName,
  section,
  setSection,
  appointmentFile,
  setAppointmentFile,
  appointmentDocumentName,
  sheet,
  setSheet,
  ownershipFile,
  setOwnershipFile,
  ownershipDocumentName,
  page,
}: Props) => {
  const { t } = useTranslate();

  const handleChangeConstitutionFile = (file: File) => {
    setConstitutionFile(file);
  };

  const handleChangeAppointmentFile = (file: File) => {
    setAppointmentFile(file);
  };

  const handleChangeOwnershipFile = (file: File) => {
    setOwnershipFile(file);
  };

  return (
    <div className={`tab-pane ${page && 'active'}`}>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="commercial-register">
              {t('CommercialRegister')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="commercial-register"
              placeholder={t('EnterCommercialRegister')}
              value={register}
              onChange={(event) => setRegister(event.target.value)}
            />
          </div>
        </div>
        <div className="form-group col-6">
          <UploadDocument
            label="DeedOfConstitution"
            inputLabel={constitutionDocumentName || t('SelectDocument')}
            file={constitutionFile}
            setFile={handleChangeConstitutionFile}
          />

          {constitutionFile &&
            constitutionFile.size > MAX_FILESIZE_ALLOWED.value && (
              <div className="alert alert-icon alert-danger mt-2" role="alert">
                <em className="icon ni ni-alert-circle" />
                <strong>Archivo no válido</strong>. Excede el tamaño máximo
                permitido.
              </div>
            )}
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="section">
              {t('PartnerSection')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="section"
              placeholder={t('EnterSection')}
              value={section}
              onChange={(event) => setSection(event.target.value)}
            />
          </div>
        </div>
        <div className="form-group col-6">
          <UploadDocument
            label="DeedAppointmentRepresentative"
            inputLabel={appointmentDocumentName || t('SelectDocument')}
            file={appointmentFile}
            setFile={handleChangeAppointmentFile}
          />

          {appointmentFile &&
            appointmentFile.size > MAX_FILESIZE_ALLOWED.value && (
              <div className="alert alert-icon alert-danger mt-2" role="alert">
                <em className="icon ni ni-alert-circle" />
                <strong>Archivo no válido</strong>. Excede el tamaño máximo
                permitido.
              </div>
            )}
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="sheet">
              {t('Sheet')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="sheet"
              placeholder={t('EnterSheet')}
              value={sheet}
              onChange={(event) => setSheet(event.target.value)}
            />
          </div>
        </div>
        <div className="form-group col-6">
          <UploadDocument
            label="DeedOfRealOwnership"
            inputLabel={ownershipDocumentName || t('SelectDocument')}
            file={ownershipFile}
            setFile={handleChangeOwnershipFile}
          />

          {ownershipFile && ownershipFile.size > MAX_FILESIZE_ALLOWED.value && (
            <div className="alert alert-icon alert-danger mt-2" role="alert">
              <em className="icon ni ni-alert-circle" />
              <strong>Archivo no válido</strong>. Excede el tamaño máximo
              permitido.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditLegalPersonScripturesForm;
