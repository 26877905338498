export default [
  {
    value: 'ORDINARY',
    label: 'CouncilOrdinaryLabel',
  },
  {
    value: 'EXTRAORDINARY',
    label: 'CouncilExtraordinaryLabel',
  },
  {
    value: 'ORDINARY_EXTRAORDINARY',
    label: 'CouncilOrdinaryAndExtraordinaryLabel',
  },
  {
    value: 'UNIVERSAL',
    label: 'CouncilUniversalLabel',
  },
];
