import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  handleCloseModal: any;
};

const AddRealtyModalHeader: FC<Props> = ({ handleCloseModal }: Props) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Header>
        <div className="row">
          <h5 className="title col-12">{t('AddRealty')}</h5>
        </div>
        <a className="close cursor-pointer" onClick={handleCloseModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
    </>
  );
};

export default AddRealtyModalHeader;
