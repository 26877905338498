import { Society, SocietyDirector, SocietyDirectorType } from 'types';
import { TranslateFn } from 'hooks/useTranslate';
import i18n from 'i18n/config';

import { getAdminMemberStatus } from 'modules/administratives/pages/AdminMembers/utils/getAdminMemberStatus';
import adminMemberStatus from 'modules/administratives/pages/AdminMembers/constants/adminMemberStatus';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import { getDirectorImage } from 'modules/society/utils/getDirectorImage';
import { WidgetLegalTableData } from '../components/WidgetLegalTableExtended';
import { createTermFromDates } from './createTermFromDates';

const isActiveDirector = (director: SocietyDirector) => {
  const activeStatus = adminMemberStatus[0];
  const status = getAdminMemberStatus(director);

  return status === activeStatus;
};

export const mapDirectorsToLegalDashboard = (
  directors: SocietyDirector[],
  society: Society,
  translate: TranslateFn
): WidgetLegalTableData[] => {
  const directorTypesList: Record<SocietyDirectorType, string> =
    directorTypes(i18n).name;

  return directors
    .filter((director) => isActiveDirector(director))
    .map((director) => ({
      name: director.socialDenomination,
      image: getDirectorImage(director, society),
      value1:
        directorTypesList?.[director.directorType as SocietyDirectorType] ||
        '-',
      value2: translate(director?.condition) || '-',
      value3: createTermFromDates(
        director.initDate,
        director.endDate,
        translate
      ),
      link: `/detalle-miembro/${society._id}/${director._id}`,
    }));
};
