/* eslint-disable no-plusplus */
import { useEffect, useState } from 'react';

type Props = {
  dates: string[];
};

const getMinDate = (dates: string[]) => {
  for (let i = 0; i < dates.length; i++) {
    if (dates[i] !== '') {
      return dates[i];
    }
  }
};

const getMaxDate = (dates: string[]) => {
  for (let i = dates.length - 1; i >= 0; i--) {
    if (dates[i] !== '') {
      return dates[i];
    }
  }
};

export const useGetDates = ({ dates }: Props) => {
  const [minDate, setMinDate] = useState<string>('');
  const [currentDate, setCurrent] = useState<string>('');

  useEffect(() => {
    const datesArray: string[] = Object.values(dates);

    const minDateValue = getMinDate(datesArray);
    const maxDateValue = getMaxDate(datesArray);

    if (minDateValue) {
      const minDateISOString = new Date(minDateValue)
        .toISOString()
        .split('T')[0];
      setMinDate(minDateISOString);
    }

    if (maxDateValue) {
      const maxDateISOString = new Date(maxDateValue)
        .toISOString()
        .split('T')[0];
      setCurrent(maxDateISOString);
    }
  }, [dates]);

  return {
    data: { minDate, date: currentDate },
    actions: { setDate: setCurrent },
  };
};
