import { FC } from 'react';

/* eslint-disable react/no-array-index-key */
type WidgetChartLineLoaderProps = {
  columns: 3 | 5;
};

const WidgetChartLineLoader: FC<WidgetChartLineLoaderProps> = ({ columns }) => {
  switch (columns) {
    case 5: {
      return (
        <div className="col-12 col-xl-7 mb-4">
          <div className="card card-bordered h-100">
            <div className="card-inner">
              <p className="placeholder-glow ">
                <span className="placeholder col-5    h-15px mb-2" />
                <span className="placeholder offset-4 h-15px mb-2" />
                <span className="placeholder col-3    h-15px mb-2" />

                <span className="placeholder col-7    h-15px mb-4" />
                <span className="placeholder offset-3 h-15px mb-4" />
                <span className="placeholder col-2    h-15px mb-4" />

                <span className="placeholder col-2    h-15px mb-2" />
                <span className="placeholder offset-1 h-15px mb-2" />
                <span className="placeholder col-3    h-15px mb-2" />
                <span className="placeholder offset-1 h-15px mb-2" />
                <span className="placeholder col-2    h-15px mb-2" />
                <span className="placeholder offset-1 h-15px mb-2" />
                <span className="placeholder col-2    h-15px mb-2" />

                <span className="placeholder col-2    h-20px mb-2" />
                <span className="placeholder offset-1 h-20px mb-2" />
                <span className="placeholder col-3    h-20px mb-2" />
                <span className="placeholder offset-1 h-20px mb-2" />
                <span className="placeholder col-2    h-20px mb-2" />
                <span className="placeholder offset-1 h-20px mb-2" />
                <span className="placeholder col-2    h-20px mb-2" />

                <span className="placeholder col-1    h-10px mb-4" />
                <span className="placeholder offset-2 h-10px mb-4" />
                <span className="placeholder col-1    h-10px mb-4" />
                <span className="placeholder offset-3 h-10px mb-4" />
                <span className="placeholder col-1    h-10px mb-4" />
                <span className="placeholder offset-2 h-10px mb-4" />
                <span className="placeholder col-1    h-10px mb-4" />

                <br />

                <span className="placeholder col-12   h-175px mb-3" />

                <span className="placeholder offset-1 h-40px mb-2" />
                <span className="placeholder col-11   h-40px mb-2" />
              </p>
            </div>
          </div>
        </div>
      );
    }

    case 3: {
      return (
        <div className="col-12 col-xl-5 mb-4">
          <div className="card card-bordered h-100">
            <div className="card-inner">
              <p className="placeholder-glow ">
                <span className="placeholder col-5    h-15px mb-2" />
                <span className="placeholder offset-7 h-15px mb-2" />

                <span className="placeholder col-7    h-15px mb-4" />
                <span className="placeholder offset-5 h-15px mb-4" />

                <span className="placeholder col-3    h-15px mb-2" />
                <span className="placeholder offset-1 h-15px mb-2" />
                <span className="placeholder col-4    h-15px mb-2" />
                <span className="placeholder offset-1 h-15px mb-2" />
                <span className="placeholder col-3    h-15px mb-2" />

                <span className="placeholder col-3    h-25px mb-5" />
                <span className="placeholder offset-1 h-25px mb-5" />
                <span className="placeholder col-4    h-25px mb-5" />
                <span className="placeholder offset-1 h-25px mb-5" />
                <span className="placeholder col-3    h-25px mb-5" />

                <br />

                <span className="placeholder col-12   h-175px mb-3" />

                <span className="placeholder offset-1 h-40px" />
                <span className="placeholder col-11   h-40px" />
              </p>
            </div>
          </div>
        </div>
      );
    }

    default:
      return null;
  }
};

export default WidgetChartLineLoader;

// VARIANT 2
// <div className="card-inner">
//   <p className="placeholder-glow ">
//     <span className="placeholder col-6    h-40px mb-4" />
//     <span className="placeholder offset-2 h-40px mb-4" />
//     <span className="placeholder col-4    h-40px mb-4" />

//     <span className="placeholder col-2    h-50px mb-2" />
//     <span className="placeholder offset-1 h-50px mb-2" />
//     <span className="placeholder col-3    h-50px mb-2" />
//     <span className="placeholder offset-1 h-50px mb-2" />
//     <span className="placeholder col-2    h-50px mb-2" />
//     <span className="placeholder offset-1 h-50px mb-2" />
//     <span className="placeholder col-2    h-50px mb-2" />

//     <span className="placeholder col-1    h-10px mb-4" />
//     <span className="placeholder offset-2 h-10px mb-4" />
//     <span className="placeholder col-1    h-10px mb-4" />
//     <span className="placeholder offset-3 h-10px mb-4" />
//     <span className="placeholder col-1    h-10px mb-4" />
//     <span className="placeholder offset-2 h-10px mb-4" />
//     <span className="placeholder col-1    h-10px mb-4" />

//     <br />

//     <span className="placeholder col-12    h-150px mb-3" />

//     <span className="placeholder col-12   h-25px mb-3" />
//   </p>
// </div>
