import i18n from 'i18n/config';
import { useEffect, useState } from 'react';

import editorModuleSections from '../constants/editorModuleSections';
import actDropdownOptions from '../constants/actDropdownOptions';
import certificationDropdownOptions from '../constants/certificationDropdownOptions';

const getModuleHeaderLabel = (currentSection: string) => {
  const section = editorModuleSections.find(
    (moduleSection) => moduleSection.value === currentSection
  );

  return section?.label || '';
};

const getModuleOptions = (currentSection: string) => {
  switch (currentSection) {
    case editorModuleSections[0].value:
      return actDropdownOptions;
    case editorModuleSections[1].value:
      return certificationDropdownOptions;
    default:
      return [];
  }
};

type Props = {
  currentSection: string;
};

export default function useGetEditorModuleSectionValues({
  currentSection,
}: Props) {
  const [moduleHeaderLabel, setModuleHeaderLabel] = useState<string>('');
  const [moduleOptions, setModuleOptions] = useState<any[]>([]);

  useEffect(() => {
    const label = getModuleHeaderLabel(currentSection);
    setModuleHeaderLabel(label);

    const options = getModuleOptions(currentSection);
    setModuleOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection, i18n.language]);

  return { data: { moduleHeaderLabel, moduleOptions } };
}
