import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

const AttendanceTableHeader: FC = () => {
  const { t } = useTranslate();

  const isReadOnly = false;

  return (
    <thead>
      <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
        <th className="nk-tb-col">
          <span>{t('Name')}</span>
        </th>

        <th className="nk-tb-col">
          <span>{t('Position')}</span>
        </th>

        <th className="nk-tb-col">
          <span>{t('Attendance')}</span>
        </th>

        {!isReadOnly && (
          <th className="nk-tb-col">
            <span>{t('MarkAsAssistant')}</span>
          </th>
        )}
      </tr>
    </thead>
  );
};

export default AttendanceTableHeader;
