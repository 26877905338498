import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { State } from 'redux/initialState';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { getCommittees } from 'modules/administratives/redux/committeeActions';

import { useGetActualSociety } from 'hooks/useGetActualSociety';
import { useGetMenu } from '../../hooks/useGetMenu';
import CommitteesPageHeader from './components/CommitteesPageHeader';
import CommitteesPageBody from './components/CommitteesPageBody';

function Committees() {
  const { societyId } = useParams();
  const dispatch = useDispatch();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const committees = useSelector((state: State) => state.committees);

  useGetMenu();
  useGetActualSociety(societyId);

  useEffect(() => {
    if (societyId) {
      dispatch(getCommittees(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  return actualSociety ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <CommitteesPageHeader
          societyId={societyId}
          societyName={actualSociety?.name}
          committeesCount={committees?.length || 0}
        />

        <CommitteesPageBody committees={committees} />
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
}

export default Committees;
