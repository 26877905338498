export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  const diffInMillis: number = Math.abs(
    startDate.getTime() - endDate.getTime()
  );

  const oneDay = 24 * 60 * 60 * 1000;
  const diffInDays = Math.ceil(diffInMillis / oneDay);

  return diffInDays;
};
