import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import {
  CommitteeMember,
  SocietyAuditor,
  SocietyDirector,
  SocietyRepresentative,
} from 'types';

type GlobalSearchSummaryBarProps = {
  summary: {
    directors: SocietyDirector[];
    representatives: SocietyRepresentative[];
    auditors: SocietyAuditor[];
    committeeMembers: CommitteeMember[];
  };
};

const GlobalSearchSummaryBar: FC<GlobalSearchSummaryBarProps> = ({
  summary,
}) => {
  const { t } = useTranslate();

  const summaries = [
    {
      title: t('AdminOrgan'),
      key: 'directors' as const,
    },
    {
      title: t('Representatives'),
      key: 'representatives' as const,
    },
    {
      title: t('Auditors'),
      key: 'auditors' as const,
    },
    {
      title: t('CommitteeMembers'),
      key: 'committeeMembers' as const,
    },
  ];

  return (
    <div className="d-flex flex-row flex-wrap justify-content-between bg-lighter px-4">
      {summaries.map((item) => (
        <div
          key={item.key}
          className="flex-item d-flex flex-row align-items-center my-3"
        >
          <h6 className="mb-0">{item.title}</h6>
          <span className="bg-primary text-white rounded px-2 ml-2">
            {summary[item.key].length || 0}
          </span>
        </div>
      ))}
    </div>
  );
};

export default GlobalSearchSummaryBar;
