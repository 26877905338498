import { FC } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { dateFormat } from 'constants/formats';
import { useTranslate } from 'hooks/useTranslate';
import { CommitteeMember } from 'types';

import CellData from 'modules/_shared/components/Tables/CellData';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';
import useGetSelectedValues from '../hooks/useGetSelectedValues';

type Props = {
  index: number;
  member: CommitteeMember;
};

const CommitteeMembersTableRow: FC<Props> = ({ index, member }) => {
  const { t } = useTranslate();
  const societyId = useSelector(
    (state: State) => state.society?.actualSociety?._id
  );

  const { isReadOnly } = useGetSocietySectionPermissions().data;
  const { statusLabel, statusBadge, conditionLabel, memberTypeLabel } =
    useGetSelectedValues({ member }).data;

  const memberNameCell = CellData.CommiteeMember({
    name: member?.name,
    image: member?.image,
    email: member?.email,
  });

  const memberDetailsURL = `/detalle-miembro-comite/${societyId}/${member.committee}/${member._id}`;

  return (
    <>
      <td className="nk-tb-col" key={`member-name-${index}`}>
        {isReadOnly ? (
          <span className="text-primary font-weight-bold cursor-pointer">
            {memberNameCell}
          </span>
        ) : (
          <Link
            className="text-primary font-weight-bold cursor-pointer"
            to={memberDetailsURL}
          >
            {memberNameCell}
          </Link>
        )}
      </td>
      <td className="nk-tb-col tb-col-xl" key={`member-cif-${index}`}>
        <span>{member?.cif || '-'}</span>
      </td>

      <td className="nk-tb-col tb-col-hide" key={`member-nationality-${index}`}>
        <span>{member?.nationality || '-'}</span>
      </td>
      <td className="nk-tb-col tb-col-xl" key={`member-initdate-${index}`}>
        <span>
          {member?.startDate
            ? format(new Date(member.startDate), dateFormat)
            : '-'}
        </span>
      </td>
      <td className="nk-tb-col tb-col-xl" key={`member-enddate-${index}`}>
        <span>
          {member?.endDate
            ? format(new Date(member.endDate), dateFormat)
            : t('Indefinite')}
        </span>
      </td>

      <td className="nk-tb-col tb-col-hide" key={`member-birthdate-${index}`}>
        <span>
          {member?.birthdate
            ? format(new Date(member.birthdate), dateFormat)
            : '-'}
        </span>
      </td>
      <td className="nk-tb-col">
        <span className={`badge badge-dot badge-${statusBadge}`}>
          {t(statusLabel)}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{t(conditionLabel)}</span>
      </td>
      <td className="nk-tb-col">
        <span>{t(memberTypeLabel)}</span>
      </td>
    </>
  );
};

export default CommitteeMembersTableRow;
