import { FC } from 'react';
import { Council } from 'types';

import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import CouncilsRecentsSectionTable from './CouncilsRecentsSectionTable';

type CouncilsRecentsSectionProps = {
  councils: Council[];
};

const CouncilsRecentsSection: FC<CouncilsRecentsSectionProps> = ({
  councils,
}) => (
  <>
    <PageListHeader subTitle="Consejos recientes" />

    <div className="nk-block council-recents-section">
      <div className="card bg-lighter rounded-xl">
        <CouncilsRecentsSectionTable councils={councils} />
      </div>
    </div>
  </>
);

export default CouncilsRecentsSection;
