import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { store } from 'redux/store';
import { getRealty } from 'modules/administratives/redux/realtyActions';

export const useRealtyDetails = () => {
  const { realtyId } = useParams();

  const [data, setData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    if (!realtyId) return;

    setIsLoading(true);
    setIsError(false);

    try {
      const realty = await store.dispatch(getRealty(realtyId));

      setData(realty);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [realtyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
    isError,
  };
};
