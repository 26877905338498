/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
/* eslint-disable no-console */
/* eslint-disable react/jsx-curly-newline */
import React, { FC, useState } from 'react'; // eslint-disable-line
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';

import { updateSocietyTheme } from 'modules/society/redux/societyActions';
import { State } from 'redux/initialState';

import { updatePrimaryColor } from 'utils/theme';
import { allowedFileImage } from 'utils/allowedFileImage';
import errorSwal from 'utils/errorSwal';

import {
  DEFAULT_EMAIL_FRAME_COLOR,
  DEFAULT_EMAIL_FRAME_LOGO,
  DEFAULT_HEADER_COLOR,
  DEFAULT_HEADER_LOGO,
  DEFAULT_HEADER_TEXT_COLOR,
  DEFAULT_HEADER_TEXT_HOVER_COLOR,
} from 'constants/defaultConstants';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

import 'assets/styles/CustomForms.scss';
import 'assets/styles/Styles.scss';

import './SocietyConfiguration.scss';
import SocietyConfigurationColorRow from './components/SocietyConfigurationColorRow';
import SocietyConfigurationHeader from './components/SocietyConfigurationHeader';
import SocietyConfigurationHeaderButtons from './components/SocietyConfigurationHeaderButtons';
import SocietyConfigurationLogoRow from './components/SocietyConfigurationLogoRow';
import { twitterColors } from './constants/themeColors';

type SocietyConfigurationProps = {
  isAdmin: boolean;
};

const SocietyConfiguration: FC<SocietyConfigurationProps> = ({ isAdmin }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { isReadOnly } = useGetSocietySectionPermissions().data;

  const society = useSelector((state: State) => state.society?.actualSociety);
  const hasWhiteBranding = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.hasWhiteBranding
  );
  const theme = useSelector(
    (state: State) => state.society?.actualSociety?.customization?.theme
  );

  const [logoFile, setLogoFile] = useState<File>();
  const [emailFrameLogoFile, setEmailFrameLogoFile] = useState<File>();

  const [logo, setLogo] = useState<string>(
    society?.customization?.theme?.logo || DEFAULT_HEADER_LOGO
  );
  const [emailFrameLogo, setEmailFrameLogo] = useState<string>(
    society?.customization?.theme?.emailFrameLogo || DEFAULT_EMAIL_FRAME_LOGO
  );
  const [headerColor, setHeaderColor] = useState(
    society?.customization?.theme?.headerColor || DEFAULT_HEADER_COLOR
  );
  const [headerTextColor, setHeaderTextColor] = useState(
    society?.customization?.theme?.headerTextColor || DEFAULT_HEADER_TEXT_COLOR
  );
  const [headerTextHoverColor, setHeaderTextHoverColor] = useState(
    society?.customization?.theme?.headerTextHoverColor ||
      DEFAULT_HEADER_TEXT_HOVER_COLOR
  );

  const [emailFrameColor, setEmailFrameColor] = useState(
    society?.customization?.theme?.emailFrameColor || DEFAULT_EMAIL_FRAME_COLOR
  );

  const [isLoading, setIsLoading] = useState(false);

  const isHeaderColorDisabled = (color: string): boolean =>
    color === headerTextColor || color === headerTextHoverColor;

  const isHeaderTextColorDisabled = (color: string): boolean =>
    color === headerColor;

  const isHeaderTextHoverColorDisabled = (color: string): boolean =>
    color === headerColor;

  const isEmailFrameColorDisabled = (): boolean => false;

  const handleChangeHeaderColor = (
    e: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isReadOnly) return;

    const color = typeof e === 'string' ? e : e.target.value;
    setHeaderColor(color);
  };

  const handleChangeHeaderTextColor = (
    e: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isReadOnly) return;

    const color = typeof e === 'string' ? e : e.target.value;
    setHeaderTextColor(color);
  };

  const handleChangeHeaderTextHoverColor = (
    e: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isReadOnly) return;

    const color = typeof e === 'string' ? e : e.target.value;
    setHeaderTextHoverColor(color);
  };

  const handleChangeEmailFrameColor = (
    e: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isReadOnly) return;

    const color = typeof e === 'string' ? e : e.target.value;
    setEmailFrameColor(color);
  };

  const handleApplyChanges = async () => {
    if (isReadOnly) return;

    setIsLoading(true);
    await dispatch(
      updateSocietyTheme(society._id, {
        ...theme,
        headerColor,
        headerTextColor,
        headerTextHoverColor,
        emailFrameColor,
        logo: logoFile || logo,
        emailFrameLogo: emailFrameLogoFile || emailFrameLogo,
      })
    );
    updatePrimaryColor(headerTextHoverColor);
    setIsLoading(false);
  };

  const handleResetValues = () => {
    if (isReadOnly) return;

    setLogo(DEFAULT_HEADER_LOGO);
    setHeaderColor(DEFAULT_HEADER_COLOR);
    setHeaderTextColor(DEFAULT_HEADER_TEXT_COLOR);
    setHeaderTextHoverColor(DEFAULT_HEADER_TEXT_HOVER_COLOR);
    setEmailFrameColor(DEFAULT_EMAIL_FRAME_COLOR);
    setEmailFrameLogo(DEFAULT_EMAIL_FRAME_LOGO);
  };

  const handleChangeHeaderLogo = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isReadOnly) return;

    const file = e.target?.files?.length ? e.target.files[0] : undefined;

    if (!file) return;

    const isAllowedImage = await allowedFileImage(file);

    if (!isAllowedImage) {
      errorSwal(t('InvalidFile'));
      return;
    }

    setLogoFile(file);
    setLogo(URL.createObjectURL(file));
  };

  const handleChangeEmailLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) return;

    const file = e.target?.files?.length ? e.target.files[0] : undefined;

    if (!file) return;

    setEmailFrameLogoFile(file);
    setEmailFrameLogo(URL.createObjectURL(file));
  };

  return (
    <div className="nk-block custom-forms-wrapper">
      <div className="nk-block-head">
        <SocietyConfigurationHeader />
      </div>

      <div className="nk-block">
        {isAdmin && (
          <div className="nk-block-head">
            <SocietyConfigurationHeaderButtons
              onApplyChanges={handleApplyChanges}
              onResetValues={handleResetValues}
            />
          </div>
        )}

        <div className="card card-bordered  card-preview">
          <div className="nk-data data-list">
            <SocietyConfigurationColorRow
              id="header-demo"
              label={t('Fondo cabecera')}
              colors={twitterColors}
              currentColor={headerColor}
              onColorChange={handleChangeHeaderColor}
              isColorDisabled={isHeaderColorDisabled}
            />

            <SocietyConfigurationColorRow
              id="header-text"
              label={t('Texto cabecera')}
              colors={twitterColors}
              currentColor={headerTextColor}
              onColorChange={handleChangeHeaderTextColor}
              isColorDisabled={isHeaderTextColorDisabled}
            />

            <SocietyConfigurationColorRow
              id="header-text-hover"
              label={t('Texto enlaces')}
              colors={twitterColors}
              currentColor={headerTextHoverColor}
              onColorChange={handleChangeHeaderTextHoverColor}
              isColorDisabled={isHeaderTextHoverColorDisabled}
            />

            <SocietyConfigurationLogoRow
              id="society-logo"
              label={t('Logo cabecera')}
              labelSize="170 x 25px"
              currentLogo={logo}
              backgroundColor={headerColor}
              onLogoChange={handleChangeHeaderLogo}
            />

            {hasWhiteBranding && (
              <>
                <SocietyConfigurationColorRow
                  id="email-frame-color"
                  label={t('Color marco emails')}
                  colors={twitterColors}
                  currentColor={emailFrameColor}
                  onColorChange={handleChangeEmailFrameColor}
                  isColorDisabled={isEmailFrameColorDisabled}
                />

                <SocietyConfigurationLogoRow
                  id="email-frame-logo"
                  label={t('Logo marco emails')}
                  labelSize="265 x 40px"
                  currentLogo={emailFrameLogo}
                  backgroundColor={emailFrameColor}
                  onLogoChange={handleChangeEmailLogo}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {isLoading && <CustomLoading />}
    </div>
  );
};

export default SocietyConfiguration;
