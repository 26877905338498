import { axiosV1 } from 'interceptors/axios';
import { decryptResponse } from 'utils/token';

import { dispatchError } from 'utils/dispatchError';

type SignRedirectionProps = {
  envelopeId: string;
  signerId: string;
};

const getSignRedirection =
  ({ envelopeId, signerId }: SignRedirectionProps): any =>
  async (dispatch: any) => {
    try {
      const query = {
        params: { envelopeId, signerId },
      };

      const { data } = await axiosV1.get(`/signRedirection`, query);

      const decodedData = await decryptResponse(data);
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export default getSignRedirection;
