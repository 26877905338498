import { useEffect, useState } from 'react';
import { SocietyRepresentative } from 'types';

import { getSocietyRepresentativeTypeLabel } from '../utils/getSocietyRepresentativeTypeLabel';
import { getSocietyRepresentativeStatusData } from '../utils/getSocietyRepresentativeStatusData';

type Props = {
  representative: SocietyRepresentative;
};

export default function useGetSelectedValues({ representative }: Props) {
  const [representativeStatusLabel, setRepresentativeStatusLabel] =
    useState<string>('');
  const [representativeStatusBadge, setRepresentativeStatusBadge] =
    useState<string>('');
  const [representativeTypeLabel, setRepresentativeTypeLabel] =
    useState<string>('');

  useEffect(() => {
    const { statusLabel, statusBadge } =
      getSocietyRepresentativeStatusData(representative);
    setRepresentativeStatusLabel(statusLabel);
    setRepresentativeStatusBadge(statusBadge);

    const representativeTypeLabel =
      getSocietyRepresentativeTypeLabel(representative);
    setRepresentativeTypeLabel(representativeTypeLabel);
  }, [representative]);

  return {
    data: {
      representativeStatusLabel,
      representativeStatusBadge,
      representativeTypeLabel,
    },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
