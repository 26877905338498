import { FC } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';

import { Council } from 'types';
import { dateFormat, timeFormat } from 'constants/formats';

import { getCouncilBadge } from 'modules/administratives/utils/getCouncilBadge';
import { getCouncilDetailsUrl } from 'modules/administratives/utils/getCouncilDetailsUrl';
import { getCouncilTypeLabel } from '../../CouncilDetails/utils/getCouncilTypeLabel';
import { getCouncilAttendanceTypeLabel } from '../../CouncilDetails/utils/getCouncilAttendanceTypeLabel';

type CouncilsCardDetailsProps = {
  council: Council;
};

const CouncilsCardDetails: FC<CouncilsCardDetailsProps> = ({ council }) => {
  const { t } = useTranslate();

  const councilDetails = [
    { label: t('Date'), value: format(new Date(council.date), dateFormat) },
    { label: t('Time'), value: format(new Date(council.date), timeFormat) },
    { label: t('Place'), value: council.place },
    {
      label: t('AttendanceForm'),
      value: t(getCouncilAttendanceTypeLabel(council)),
    },
    { label: t('CouncilType'), value: t(getCouncilTypeLabel(council)) },
  ];

  return (
    <div className="col-8 ">
      <div className="sp-plan-info card-inner">
        <div className="sp-plan-name">
          <h6 className="title lh-30px">
            <Link
              to={getCouncilDetailsUrl(council)}
              className="d-flex justify-content-between align-center"
            >
              {council.name}&nbsp;
              {getCouncilBadge(council)}
            </Link>
          </h6>
          <p>
            {t('Details')}: <span className="text-base">{council.details}</span>
          </p>
        </div>
      </div>

      <div className="sp-plan-desc card-inner">
        <ul className="sp-plan-list row gx-1">
          {councilDetails.map((detail) => (
            <li key={detail.label} className="col-6 col-lg-4">
              <span className="text-soft d-block">{detail.label}:</span>
              <span className="text-dark">{detail.value}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CouncilsCardDetails;
