import { FC } from 'react';
import { Council } from 'types';
import { useTranslate } from 'hooks/useTranslate';
import CouncilsRecentsSectionRow from './CouncilsRecentsSectionRow';

type CouncilsRecentsSectionTableProps = {
  councils: Council[];
};

const CouncilsRecentsSectionTable: FC<CouncilsRecentsSectionTableProps> = ({
  councils,
}) => {
  const { t } = useTranslate();

  return (
    <table className="table">
      <thead>
        <tr className="nk-tb-item nk-tb-head">
          <th className="nk-tb-col nk-tb-col-check d-none">
            <div className="custom-control custom-control-sm custom-checkbox notext">
              <input
                type="checkbox"
                className="custom-control-input"
                id="did-all"
              />
              <label className="custom-control-label" htmlFor="did-all" />
            </div>
          </th>
          <th className="nk-tb-col" />
          <th className="nk-tb-col">
            <h6 className="overline-title mb-05">{t('Name')}</h6>
          </th>
          <th className="nk-tb-col tb-col-sm">
            <h6 className="overline-title mb-05">{t('Status')}</h6>
          </th>
          <th className="nk-tb-col tb-col-md">
            <h6 className="overline-title mb-05">{t('CouncilDate')}</h6>
          </th>
          <th className="nk-tb-col d-none" />
        </tr>
      </thead>

      <tbody>
        {councils.map((council, index) => (
          <CouncilsRecentsSectionRow
            key={council._id}
            council={council}
            index={index}
          />
        ))}
      </tbody>
    </table>
  );
};

export default CouncilsRecentsSectionTable;
