import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import html2pdf from 'html3pdf';
import { format } from 'date-fns';
import ReactHtmlParser from 'react-html-parser';

import { store } from 'redux/store';
import { useTranslate } from 'hooks/useTranslate';
import { getActualSociety } from 'utils/filters';

import fileTypes from 'constants/fileTypes';
import boardTypes from 'constants/boardTypes';
import documentTypes from 'constants/documentTypes';
import delegationTemplates from 'constants/delegationTemplates';
import { formatDateToTime } from 'constants/formats';

import SignatureBox from 'modules/_shared/components/SignatureBox';
import {
  addDocument,
  getDocument,
  getDocuments,
} from 'modules/documents/redux/documentActions';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import sendEmail from 'modules/communications/redux/mailActions';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { setPreview } from 'modules/_shared/redux/previewActions';
import DocumentsGenerator from 'modules/documents/components/Creators/DocumentsGenerator';
import DelegationMessageBox from 'modules/boards/components/DelegationMessageBox';
import { getBoards, updateBoard } from 'modules/boards/redux/boardActions';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import { getPartnerRepresentants } from 'modules/partners/redux/partnerRepresentantsActions';

import CircleDrawImage from 'assets/images/circle-draw.png';
import { createDelegationEmail } from 'modules/boards/utils/createDelegationEmail';

const DelegationSignature = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { societyId, boardId, partnerId } = useParams();

  const user = useSelector((state) => state.user);
  const boards = useSelector((state) => state.boards);
  const preview = useSelector((state) => state.preview);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const partnerRepresentants = useSelector(
    (state) => state.partnerRepresentants
  );

  const hasWhiteBranding = useSelector(
    (state) => state.society?.actualSociety?.configuration?.hasWhiteBranding
  );

  const [signature, setSignature] = useState(null);
  const [showSignatureBox, setShowSignatureBox] = useState(true);
  const [delegationTemplate, setDelegationTemplate] = useState();
  const [currentBoard, setCurrentBoard] = useState();
  const [currentDelegation, setCurrentDelegation] = useState();

  const [hasFinishedSignature, setHasFinishedSignature] = useState(false);

  const saveSignedDocument = async () => {
    try {
      const partnerName = currentDelegation.partner?.name;

      const options = {
        filename: `${t('VoteDelegation')}_${partnerName}.pdf`,
        margin: [10, 10, 10, 10], // top, right, bottom, left
        html2canvas: { scale: 2 },
      };

      const documentUpdated = updateDocumentWithValues(
        delegationTemplate?.editorModel,
        tags({
          society: actualSociety,
          board: {
            ...currentBoard,
            delegation: currentDelegation,
          },
          signatures: { partnerSignature: signature || null },
        })
      );

      const fileBlob = await html2pdf()
        .from(documentUpdated?.html)
        .set(options)
        .output('blob');

      const delegationFile = new File(
        [fileBlob],
        `${t('VoteDelegation')}_${partnerName}.pdf`,
        {
          type: 'application/pdf',
        }
      );

      const newDelegationDocument = {
        name: `${t('VoteDelegation')}_${partnerName}.pdf`,
        file: delegationFile,
        fileType: fileTypes.PDF,
        size: delegationFile.size,
        date: new Date(),
        lastAccess: new Date(),
        category: documentTypes.DOCUMENTS,
        author: user['_id'],
        society: actualSociety['_id'],
        isGenerated: true,
        description: `${t('GeneratedByTheTemplate')} ${
          delegationTemplate?.name
        }`,
      };

      const uploadedDocument = await store.dispatch(
        addDocument(newDelegationDocument)
      );

      if (uploadedDocument) {
        const partnerParticipant = currentBoard.participants.find(
          (participant) => participant.member === partnerId
        );

        const docs = partnerParticipant?.delegationDocuments || [];

        await store.dispatch(
          updateBoard(
            boardId,
            {
              participantData: {
                ...partnerParticipant,
                delegationDocuments: [...docs, uploadedDocument['_id']],
                hasDelegated: true,
              },
              delegationDocs: { add: uploadedDocument['_id'] },
            },
            false
          )
        );

        let selectedRepresentant;

        const currentRepresentantId =
          currentDelegation.participant?.representative;

        if (currentDelegation.participant?.hasPartnerRepresentant) {
          selectedRepresentant = partnerRepresentants?.find(
            (representant) => representant['_id'] === currentRepresentantId
          );

          // TODO: Update partnerRepresentant with the new document
        } else {
          selectedRepresentant = currentBoard.participants.find(
            (participant) => participant.member === currentRepresentantId
          );

          const docsRepresentative =
            selectedRepresentant?.delegationDocuments || [];

          await store.dispatch(
            updateBoard(
              boardId,
              {
                participantData: {
                  ...selectedRepresentant,
                  delegationDocuments: [
                    ...docsRepresentative,
                    uploadedDocument['_id'],
                  ],
                },
              },
              false
            )
          );
        }

        dispatch(getDocuments({ society: actualSociety['_id'] }));

        if (selectedRepresentant && currentDelegation.partner) {
          const emailData = createDelegationEmail({
            actualSociety,
            currentBoard,
            currentDelegation,
            delegationFile,
            selectedRepresentant,
            user,
          });
          dispatch(sendEmail(emailData, false));
        }
      }
    } catch {
      dispatch(addAlert(alertBodyTypes.ERROR_GENERATING_DOCUMENT));
    }
  };

  const generateDelegationTemplatePreview = async () => {
    try {
      const documentUpdated = updateDocumentWithValues(
        delegationTemplate?.editorModel,
        tags({
          society: actualSociety,
          board: {
            ...currentBoard,
            delegation: currentDelegation,
          },
          signatures: { partnerSignature: signature || null },
        })
      );
      const dataToDocument = (
        <DocumentsGenerator
          invitationModel={documentUpdated?.html}
          hasConsolidationCalendar={false}
          consolidationCalendar={{}}
        />
      );

      await store.dispatch(
        setPreview({
          name: delegationTemplate.name,
          bdDocument: delegationTemplate,
          document: dataToDocument,
          documentModel: documentUpdated?.html,
          template: delegationTemplate?.['_id'],
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getDelegationTemplate = async () => {
    try {
      const globalDelegationTemplateId =
        delegationTemplates[process.env.NODE_ENV];
      const globalDelegationTemplate = await store.dispatch(
        getDocument(globalDelegationTemplateId)
      );
      return globalDelegationTemplate;
    } catch {
      throw Error('Error getting delegation template');
    }
  };

  useEffect(() => {
    if (societyId) {
      dispatch(getBoards(societyId));
      dispatch(getPartnerRepresentants({ societyId }));
      getDelegationTemplate()
        .then((res) => setDelegationTemplate(res))
        .catch((e) => console.error(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocuments({ society: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (boards.length && actualSociety && partnerId) {
      const actualBoard = boards.find((board) => board['_id'] === boardId);

      const partner = actualSociety?.partners?.find(
        (p) => p['_id'] === partnerId
      );

      const partnerAsParticipant = actualBoard?.participants?.find(
        (p) => p.member === partnerId
      );

      const delegationData = {};

      if (partnerAsParticipant.representative) {
        const partnerRepresentative =
          partnerAsParticipant?.hasPartnerRepresentant
            ? partnerRepresentants.find(
                (p) => p['_id'] === partnerAsParticipant.representative
              )
            : actualSociety?.partners?.find(
                (p) => p['_id'] === partnerAsParticipant.representative
              );

        delegationData.representative = partnerRepresentative || null;
      }

      delegationData.partner = partner;
      delegationData.participant = partnerAsParticipant;
      delegationData.delegationVote =
        partnerAsParticipant.delegationVote || null;

      setCurrentBoard(actualBoard);
      setCurrentDelegation(delegationData);
    }
  }, [boards, boardId, actualSociety, partnerRepresentants, partnerId]);

  useEffect(() => {
    dispatch(
      setMenu({
        type: null,
        societyId: actualSociety?.['_id'] || null,
        societyName: actualSociety?.name,
      })
    );
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (actualSociety && delegationTemplate) {
      generateDelegationTemplatePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delegationTemplate, currentBoard]);

  useEffect(() => {
    if (signature) {
      saveSignedDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  return !hasFinishedSignature ? (
    <div className="delegation-signature-wrapper page-full-height custom-page-background nk-content-body justify-content-start justify-content-md-center pt-0">
      <div className="nk-content-wrap row">
        <div className="col-md-6 px-2 py-5 py-md-3">
          {showSignatureBox ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <SignatureBox
                title={t('SignDelegationDocument')}
                isDelegation
                signature={signature}
                setSignature={setSignature}
                setHasFinishedSignature={setHasFinishedSignature}
              />
            </div>
          ) : (
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h5 className="title nk-block-title">Delegación voto</h5>
                <div className="nk-block-des">
                  <p>Socio: {currentDelegation?.partner?.name || ''}</p>
                </div>
              </div>
              <div className="nk-fmg-actions">
                <ul className="nk-block-tools g-3">
                  <li>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="dropdown"
                      onClick={() => setShowSignatureBox(true)}
                    >
                      <em className="icon ni ni-pen" />
                      <span>{t('Sign')}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className="col-md-6 px-2 py-5 py-md-3">
          {preview?.documentModel ? (
            <>
              <h5 className="title nk-block-title">{t('DocumentPreview')}</h5>
              <div
                className="card card-bordered w-100 mw-700px shadow"
                id="preview--box"
              >
                <div className="card-inner px-3 py-3 px-md-5 py-md-5">
                  {ReactHtmlParser(preview?.documentModel)}
                </div>
                <img
                  src={CircleDrawImage}
                  alt="circle-draw"
                  width={150}
                  className="signature-circle-draw"
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  ) : (
    <DelegationMessageBox
      icon="SUCCESS"
      title={t('DelegationSuccessfully')}
      description={t(
        hasWhiteBranding
          ? 'DelegationSuccessfullyDescriptionWhiteBranding'
          : 'DelegationSuccessfullyDescription',
        { societyName: actualSociety?.name }
      )}
      link={`/detalle-socio/${societyId}/${partnerId}`}
      linkText={t('SeeMyPartnerDetails')}
    />
  );
};

export default DelegationSignature;
