import { FC } from 'react';

type DetailsListItem = {
  label: string;
  value: string | number | undefined;
};

type DetailsListSectionProps = {
  title?: string;
  data?: DetailsListItem[];
};

const DetailsListSection: FC<DetailsListSectionProps> = ({ title, data }) => (
  <section className="card-inner">
    {title && <h6 className="overline-title-alt mb-2">{title}</h6>}

    {data && data.length > 0 && (
      <>
        {data?.map((item) => (
          <div className="profile-ud wider" key={item.label}>
            <span className="profile-ud-label w-200px">{item.label}</span>

            <span className="profile-ud-value text-left ws-pre-wrap">
              {item.value || ''}
            </span>
          </div>
        ))}
      </>
    )}
  </section>
);

export default DetailsListSection;
