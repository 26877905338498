import { store } from 'redux/store';
import {
  addDocument,
  getDocument,
} from 'modules/documents/redux/documentActions';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import { Council, Society } from 'types';
import fileTypes from 'constants/fileTypes';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';

type Props = {
  templateId: string | undefined;
  society: Society;
  council: Council;
  userId: string;
};

export const generateDocumentFromTemplate = async ({
  templateId,
  society,
  council,
  userId,
}: Props) => {
  if (templateId === undefined) {
    return;
  }
  const template = await store.dispatch(getDocument(templateId));

  const documentName = template?.name || 'Nuevo documento generado';

  const htmlContent = template?.editorModel || '';

  const htmlWithValues = updateDocumentWithValues(
    htmlContent,
    tags({
      society,
      council,
    })
  );

  const editorModel = htmlWithValues?.html;

  const newDocument = {
    name: documentName,
    date: new Date(),
    lastAccess: new Date(),
    category: 'TEMPLATES',
    subcategory: 'DOCUMENTS',
    author: userId,
    society: society['_id'],
    isGenerated: false,
    isFavorite: false,
    editorModel,
    fileType: fileTypes.TEMPLATE,
    description: '',
  };

  const resultDocument = await store.dispatch(addDocument(newDocument));

  return resultDocument;
};
