/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';
import { Council } from 'types';

export default function reducer(councils = initialState.councils, action: any) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.councils;

    case actionTypes.CREATE_COUNCIL:
      if (action.council) {
        return [...councils, action.council];
      }
      return [...councils];

    case actionTypes.GET_COUNCILS: {
      return action.councils.sort(
        (a: Council, b: Council) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    }

    case actionTypes.UPDATE_COUNCIL:
      const currentId = action.council['_id'];

      const updatedCouncils = councils?.map((council) => {
        if (council['_id'] === currentId) {
          return action.council;
        }
        return council;
      });
      return [...updatedCouncils];

    case actionTypes.DELETE_COUNCIL:
      return [
        ...councils.filter(
          (currentCouncil) => currentCouncil['_id'] !== action.council['_id']
        ),
      ];

    default:
      return councils;
  }
}
