import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { Council, CouncilAnnouncementData, Society } from 'types';

import { DATE_FORMAT } from 'constants/defaultConstants';
import { formatDateToTime } from 'constants/formats';
import { getCouncilAttendanceTypeLabel } from 'modules/administratives/pages/CouncilDetails/utils/getCouncilAttendanceTypeLabel';
import { getCouncilTypeLabel } from 'modules/administratives/pages/CouncilDetails/utils/getCouncilTypeLabel';

const AnnouncementInitialState = {
  time: '',
  councilName: '',
  date: '',
  societyName: '',
  announcement: '',
  place: '',
  onlineAccess: '',
  attendanceType: '',
  councilType: '',
};

export default function useGetCouncilAnnouncementData(
  council: Council,
  society: Society
) {
  const [announcementData, setAnnouncementData] =
    useState<CouncilAnnouncementData>(AnnouncementInitialState);

  useEffect(() => {
    const data = {
      time: council.date ? formatDateToTime(council.date) : '-',
      councilName: council?.name || '',
      date: council.date ? format(new Date(council.date), DATE_FORMAT) : '-',
      societyName: society?.name || '-',
      announcement: council?.announcementText || '',
      place: council?.place || '-',
      onlineAccess: council?.onlineAccess || '-',
      attendanceType: getCouncilAttendanceTypeLabel(council) || '-',
      councilType: getCouncilTypeLabel(council) || '-',
    };

    setAnnouncementData(data);
  }, [council, society]);

  return { announcementData };
}
