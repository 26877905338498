import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { setModal } from 'modules/_shared/redux/modalActions';
import GlobalSearch from 'modules/_shared/components/Modals/GlobalSearch';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import AddSocietyRepresentative from 'modules/_shared/components/Modals/AddSocietyRepresentative';

import sizes from 'constants/sizes';
import { useGetActualSociety } from 'hooks/useGetActualSociety';
import { useGetMenu } from '../../hooks/useGetMenu';
import useGetSocietyRepresentatives from './hooks/useGetSocietyRepresentatives';
import SocietyRepresentativesPageHeader from './components/SocietyRepresentativesPageHeader';
import SocietyRepresentativesPageBody from './components/SocietyRepresentativesPageBody';

function SocietyRepresentatives() {
  const { societyId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const [currentRepresentativesView, setCurrentRepresentativesView] =
    useState<boolean>(true);

  const { societyRepresentatives, activeSocietyRepresentativesCount } =
    useGetSocietyRepresentatives({
      society: actualSociety,
      currentRepresentativesView,
    }).data;

  useGetMenu();
  useGetActualSociety(societyId);

  const handleChangeMembersView = () => {
    setCurrentRepresentativesView((prev) => !prev);
  };

  const handleOpenGlobalSearch = () => {
    dispatch(setModal(<GlobalSearch size={sizes.XL} />));
  };

  const handleAddRepresentative = () => {
    if (societyId) {
      dispatch(
        setModal(
          <AddSocietyRepresentative
            societyId={societyId}
            action="ADD"
            representativeData={null}
            representativeId={null}
            userId={user['_id']}
          />
        )
      );
    }
  };

  return actualSociety ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <SocietyRepresentativesPageHeader
          societyName={actualSociety?.name}
          activeSocietyRepresentativesCount={activeSocietyRepresentativesCount}
          currentSocietyRepresentativesView={currentRepresentativesView}
          handleAddRepresentative={handleAddRepresentative}
          handleChangeRepresentativesView={handleChangeMembersView}
          handleOpenGlobalSearch={handleOpenGlobalSearch}
        />

        <SocietyRepresentativesPageBody
          societyId={actualSociety['_id']}
          societyRepresentatives={societyRepresentatives}
        />
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
}

export default SocietyRepresentatives;
