import { CouncilAnnouncementData } from 'types';

function getStyle(theme: any) {
  return ` <style>
  @import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

      @media (max-width: 768px) {
          .main {
              padding: 0 !important;
          }
          .email-body {
              padding: 1.75rem 20px !important;
              width: 85% !important;
          }
      }

      .main {
          padding: 0;
    font-family: "Archivo", sans-serif !important;
    }
      .custom-background {
          background: ${theme?.frameColor};
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: ${theme?.headingsColor};
          width: 100%;
          text-align: center;
      }

      .custom-logo {
          height: 40px;
          margin-top: 2.75rem;
      }

      .branding {
          font-size: 13px;
          color: ${theme?.headingsColor};
          padding-top: 6px;
          margin-top: 0;
          padding-bottom: 24px;
      }

      .email-body {
          width: 96%;
          max-width: 620px;
          text-align: left;
          margin: 0 auto;
          padding: 1.75rem 2.75rem;
          background: #ffffff;
          color: ${theme?.bodyTextColor};
      }

      .email-body h2 {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
          line-height: 1.4;
          margin: 10px 0;
          padding-bottom: 15px;
          text-align: left;
      }

      .email-body p,
      .email-body h3 {
          text-align: left;
      }

      .email-body h3 {
          margin-bottom: 0.5rem;
          font-weight: 700;
          line-height: 1.1;
      }

      .email-body table {
          width: 100%;
      }

      .email-body td {
          text-align: left;
          padding-bottom: 10px;
      }

      h1, h2, h3, h4, h5, h6 {
          color: ${theme?.headingsColor}
      }

      .button {
          background: ${theme?.buttonColor};
          border-radius: ${theme?.buttonRounded};
          border: none;
          color: ${theme?.buttonTextColor} !important;
          display: block;
          font-size: 13px;
          font-weight: 400;
          line-height: 38px;
          text-align: center;
          text-decoration: none;
          text-transform: none;
          padding: 0 30px;
          width: fit-content;
          margin: 0 auto;
      }

      .button-small {
          padding: 0 15px;
      }

      .footer {
          padding-bottom: 2.75rem;
          padding-top: 2.75rem;
          margin: 0;
      }

      .footer a {
          color: ${theme?.linkColor};
          text-decoration: none;
      }
  </style>`;
}

function getCouncilAnnouncementCommunicationEs(
  theme: any,
  hasWhiteBranding: boolean,
  announcementData: CouncilAnnouncementData
) {
  const style = getStyle(theme);
  const headerBranding = hasWhiteBranding ? ' ' : `Gestión de Socios`;
  const footerBranding = hasWhiteBranding
    ? ''
    : `Correo enviado por <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a><br/>Puede contactar en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>`;

  const buttonText = 'Ir al Consejo';

  return `
  ${style}
  <body>
  <div class="main">
      <div class="custom-background">
    <a href="https://www.sttok.com">
          <img src="${
            theme?.logo
          }" alt="Logo" style="height: 40px; margin-top: 2.75rem"/>

      </a>
        <p class="branding">
      ${headerBranding}
        </p>
      <div class="email-body">
        <h2>
            Board ${announcementData?.societyName || ''}
        </h2>
        <p>${announcementData?.announcement || ''}</p>
        <h2>
          ${announcementData?.councilName || ''}
         </h2>
         <table>
             <tr>
                <td>
                  <span>Fecha: ${announcementData?.date || '-'}</span>
                </td>
                <td>
                  <span>Hora: ${announcementData?.time || '-'}</span>
                </td>
             </tr>
             <tr>
                <td>
                  <span>Videollamada: ${
                    announcementData?.onlineAccess || '-'
                  }</span>
                </td>
             </tr>
          </table>
          <p>Lugar de celebración: ${announcementData?.place || '-'}</p>
          <br/>
          <h2>
            Acceso al Consejo
         </h2>
         <p>Puedes acceder a los detalles y documentación del Consejo a través del siguiente enlace:</p>
         <a class="button button-small">
             ${buttonText}
          </a>
          <br/>
          <p class="pt-2">
              El proceso está gestionado a través de la plataforma Sttok desde la cual podrás acceder a la convocatoria y poder revisar la documentación.
          </p>
        </div>
      <p class="footer">
        ${footerBranding}
      </p>
    </div>
  </div>
</body>`;
}

// TODO: Translate this comm content
function getCouncilAnnouncementCommunicationEn(
  theme: any,
  hasWhiteBranding: boolean,
  announcementData: CouncilAnnouncementData
) {
  const style = getStyle(theme);
  const headerBranding = hasWhiteBranding ? ' ' : `Shareholder Management`;
  const footerBranding = hasWhiteBranding
    ? ''
    : `Mail sent by <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a><br/>You can contact us at <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>`;

  const buttonText = 'Access to the Board';

  return `
  ${style}
  <body>
  <div class="main">
      <div class="custom-background">
    <a href="https://www.sttok.com">
          <img src="${
            theme?.logo
          }" alt="Logo" style="height: 40px; margin-top: 2.75rem"/>

      </a>
        <p class="branding">
      ${headerBranding}
        </p>
      <div class="email-body">
        <h2>
            Board Announcement by ${announcementData?.societyName || ''}
        </h2>
        <p>${announcementData?.announcement || ''}</p>
        <h2>
          ${announcementData?.councilName || ''}
         </h2>
         <table>
             <tr>
                <td>
                  <span>Date: ${announcementData?.date || '-'}</span>
                </td>
                <td>
                  <span>Time: ${announcementData?.time || '-'}</span>
                </td>
             </tr>
             <tr>
                <td>
                  <span>Video call: ${
                    announcementData?.onlineAccess || '-'
                  }</span>
                </td>
             </tr>
          </table>
          <p>Place: ${announcementData?.place || '-'}</p>
          <br/>
          <h2>
            Access to the Board
         </h2>
         <p>You can access the Board's details and documentation through the following link:</p>
         <a class="button button-small">
             ${buttonText}
          </a>
          <br/>
          <p class="pt-2">
              The process is managed through the Sttok platform from which you can access the call and review the documentation..
          </p>
        </div>
      <p class="footer">
        ${footerBranding}
      </p>
    </div>
  </div>
</body>`;
}
function getCouncilAnnouncementCommunicationFr(
  theme: any,
  hasWhiteBranding: boolean,
  announcementData: CouncilAnnouncementData
) {
  const style = getStyle(theme);
  const headerBranding = hasWhiteBranding ? ' ' : `Gestion des partenaires`;
  const footerBranding = hasWhiteBranding
    ? ''
    : `Courriel envoyé par <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a><br/>Vous pouvez nous contacter à <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>`;

  const buttonText = 'Accéder au Conseil';

  return `
  ${style}
  <body>
  <div class="main">
      <div class="custom-background">
    <a href="https://www.sttok.com">
          <img src="${
            theme?.logo
          }" alt="Logo" style="height: 40px; margin-top: 2.75rem"/>

      </a>
        <p class="branding">
      ${headerBranding}
        </p>
      <div class="email-body">
        <h2>
        Convocation du Conseil d'administration ${
          announcementData?.societyName || ''
        }
        </h2>
        <p>${announcementData?.announcement || ''}</p>
        <h2>
          ${announcementData?.councilName || ''}
         </h2>
         <table>
             <tr>
                <td>
                  <span>Date: ${announcementData?.date || '-'}</span>
                </td>
                <td>
                  <span>L'heure: ${announcementData?.time || '-'}</span>
                </td>
             </tr>
             <tr>
                <td>
                  <span>Appel vidéo: ${
                    announcementData?.onlineAccess || '-'
                  }</span>
                </td>
             </tr>
          </table>
          <p>Lieu: ${announcementData?.place || '-'}</p>
          <br/>
          <h2>
           Accès à l'Assemblée et vote
         </h2>
         <p>Vous pouvez accéder aux détails et à la documentation de la réunion:</p>
         <a class="button button-small">
             ${buttonText}
          </a>
          <br/>
          <p class="pt-2">
          Le processus est géré par la plateforme Sttok, à partir de laquelle vous pouvez accéder à l'appel et consulter la documentation.
          </p>
        </div>
      <p class="footer">

        ${footerBranding}
      </p>
    </div>
  </div>
</body>`;
}

export function getCouncilAnnouncementCommunication(
  theme: any,
  hasWhiteBranding: boolean,
  announcementData: CouncilAnnouncementData,
  language: string
) {
  switch (language) {
    case 'es':
      return getCouncilAnnouncementCommunicationEs(
        theme,
        hasWhiteBranding,
        announcementData
      );
    case 'en':
      return getCouncilAnnouncementCommunicationEn(
        theme,
        hasWhiteBranding,
        announcementData
      );
    case 'fr':
      return getCouncilAnnouncementCommunicationFr(
        theme,
        hasWhiteBranding,
        announcementData
      );
    default:
      return '';
  }
}
