/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { RealtyLicense } from 'types';
import LicensesTableRow from './LisencesTableRow';

type Props = {
  licenses: RealtyLicense[];
  // eslint-disable-next-line no-unused-vars
  handleRemoveLicense: (index: number) => void;
};

const LicensesTableBody: FC<Props> = ({ licenses, handleRemoveLicense }) => (
  <>
    <tbody>
      {licenses?.map((license: RealtyLicense, index: number) => (
        <tr className="nk-tb-item" key={`license-row-${index}`}>
          <LicensesTableRow
            index={index}
            license={license}
            handleRemoveLicense={handleRemoveLicense}
          />
        </tr>
      ))}
    </tbody>
  </>
);

export default LicensesTableBody;
