import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  announcementCommunication: any;
  handleSendTest: () => void;
};

const AnnouncementTemplateViewerBody: FC<Props> = ({
  announcementCommunication,
  handleSendTest,
}: Props) => {
  const { t } = useTranslate();
  return (
    <>
      <Modal.Body>
        <button
          type="button"
          className="btn btn-lg btn-primary mb-4"
          onClick={handleSendTest}
        >
          {t('SendTestEmail')}
        </button>

        <div className="nk-reply-entry entry">
          <p
            style={{
              whiteSpace:
                typeof announcementCommunication === 'string'
                  ? 'pre-line'
                  : 'normal',
            }}
          >
            {announcementCommunication || ''}
          </p>
        </div>
      </Modal.Body>
    </>
  );
};

export default AnnouncementTemplateViewerBody;
