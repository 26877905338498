import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useDownloadPartnerBookModal } from '../context/DownloadPartnerBookModalContext';

const DownloadPartnerBookModalFooter: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useDownloadPartnerBookModal();

  return (
    <>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-primary "
          onClick={actions.handleDownloadBook}
          disabled={!data.date}
        >
          {t('Download')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default DownloadPartnerBookModalFooter;
