import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';

const ReferencesView: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useAddRealtyModal();

  return (
    <div className="row gy-4">
      <div className="col-md-12 d-flex align-items-center">
        <h5 className="mb-0">{t('References')}</h5>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-cadastral">
            {t('CadastralReference')}
          </label>

          <input
            id="realty-cadastral"
            type="text"
            className="form-control form-control-lg"
            value={data.cadastralReference}
            onChange={actions.handleChangeCadastralReference}
            placeholder={t('IntroduceCadastralReference')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-registry">
            {t('RegistryReference')}
          </label>

          <input
            id="realty-registry"
            type="text"
            className="form-control form-control-lg"
            value={data.registryReference}
            onChange={actions.handleChangeRegistryReference}
            placeholder={t('IntroduceRegistryReference')}
          />
        </div>
      </div>
    </div>
  );
};

export default ReferencesView;
