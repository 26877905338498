import { FC } from 'react';

import { RealtyPlot } from 'types';

import PlotsTableHeader from './PlotsTableHeader';
import PlotsTableBody from './PlotsTableBody';

type Props = {
  plots: RealtyPlot[];
  // eslint-disable-next-line no-unused-vars
  handleRemovePlot: (index: number) => void;
};

const PlotsTable: FC<Props> = ({ plots, handleRemovePlot }) => (
  <>
    <table className="table my-2">
      <PlotsTableHeader />
      <PlotsTableBody plots={plots} handleRemovePlot={handleRemovePlot} />
    </table>
  </>
);

export default PlotsTable;
