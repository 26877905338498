import alertTypes from 'constants/alertTypes';
import i18n from 'i18n/config';

export default {
  500: {
    type: alertTypes.WARNING,
    title: `<h4 class="nk-modal-title">${i18n.t('ALERT_ERROR_500_TITLE')}</h4>`,
    html: `<div class="caption-text">${i18n.t(
      'ALERT_ERROR_500_MESSAGE'
    )}</div>`,
  },
  401: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en el acceso</h4>',
    html: '<div class="caption-text">Usuario o contraseña desconocidos</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Pruebe añadir una combinación diferente</p>
    </div></div>`,
  },
  ARE_YOU_SURE: {
    type: alertTypes.QUESTION,
    title: '<h4 class="nk-modal-title">¿Estás seguro?</h4>',
    html: '<div class="caption-text">Esta acción no se puede deshacer</div>',
  },
  DUPLICATED_EMAIL: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Email duplicado</h4>',
    html: '<div class="caption-text">Ya existe un socio con este correo.</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Pruebe añadir una dirección de correo diferente</p>
    </div></div>`,
  },
  DUPLICATED_EMAIL_ADMIN: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Email duplicado</h4>',
    html: '<div class="caption-text">Ya existe un administrador con este correo.</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Pruebe añadir una dirección de correo diferente</p>
    </div></div>`,
  },
  DUPLICATED_CIF: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">CIF duplicado</h4>',
    html: '<div class="caption-text">Ya existe un socio con este CIF</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>No se dará de alta</p>
    </div></div>`,
  },
  ERROR_INVALID_NAME: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Nombre no válido</h4>',
    html: '<div class="caption-text">Debe introducir un nombre correcto</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Si persiste, consulte al administrador</p>
    </div></div>`,
  },
  ERROR_INVALID_CIF: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">CIF no válido</h4>',
    html: '<div class="caption-text">Debe introducir un CIF correcto</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Si persiste, consulte al administrador</p>
    </div></div>`,
  },
  INVALID_CATEGORIES: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Categorías no válidas</h4>',
    html: '<div class="caption-text">La plantilla contiene categorías laborales no definidas en el plan</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>No se dará de alta</p>
    </div></div>`,
  },
  ERROR_TRANSACTION: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en la transacción</h4>',
    html: '<div class="caption-text">Hubo un problema al crear esta transacción</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_NO_HOLDING_CLASSES: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en la operación</h4>',
    html: '<div class="caption-text">No se han podido cargar las clases de participación</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  INFORMATION_NO_COMPLETE: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">No has introducido todos los datos</h4>',
    html: '<div class="caption-text">Escribe todos los datos requeridos</div>',
  },
  SAME_BUY_AND_SELL_USER: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">El comprador y el vendedor no pueden ser el mismo usuario</h4>',
    html: '<div class="caption-text">Modifica uno de los dos</div>',
  },
  SELECT_USER: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">Selecciona un usuario en el menú desplegable</h4>',
    html: '<div class="caption-text">Selecciona al usuario y vuelve a intentarlo</div>',
  },
  SELECT_PARTNER: {
    type: alertTypes.WARNING,
    title:
      '<h4 class="nk-modal-title">Selecciona un socio en el menú desplegable</h4>',
    html: '<div class="caption-text">Selecciona al socio y vuelve a intentarlo</div>',
  },
  INVALID_RANGES: {
    type: alertTypes.WARNING,
    title:
      '<h4 class="nk-modal-title">El número de participaciones asignadas excede la cantidad disponible</h4>',
    html: '<div class="caption-text">Seleccione una distribución válida y vuelve a intentarlo</div>',
  },
  INVALID_NUMERATIONS: {
    type: alertTypes.WARNING,
    title:
      '<h4 class="nk-modal-title">Las numeraciones asignadas son inválidas</h4>',
    html: '<div class="caption-text">Compruebe que no existan rangos solapados y vuelva a intentarlo</div>',
  },
  ERROR_UPDATING_USER: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al actualizar el usuario</h4>',
    html: '<div class="caption-text">Hubo un problema al guardar los datos de este usuario</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_ADDING_BENEFICIARY: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al crear el beneficiario</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_UPDATING_BENEFICIARY: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">Error al actualizar el beneficiario</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_ADDING_INVESTORS: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">Error al guardar la Nota Convertible</h4>',
    html: '<div class="caption-text">Hubo un problema al guardar los datos de los inversores</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_ADDING_BOARD: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al guardar la Junta</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Consulte al administrador para más detalles</p>
    </div></div>`,
  },
  ERROR_DELETING_BOARD: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al eliminar la Junta</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Consulte al administrador para más detalles</p>
    </div></div>`,
  },
  ERROR_EMAIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al enviar el correo</h4>',
    html: '<div class="caption-text">Hubo un problema al enviar los datos a este usuario</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_ADDING_COUNCIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al añadir el Consejo</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Consulte al administrador para más detalles</p>
    </div></div>`,
  },
  ERROR_UPDATING_COUNCIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al actualizar el Consejo</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Consulte al administrador para más detalles</p>
    </div></div>`,
  },
  ERROR_DELETING_COUNCIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al eliminar el Consejo</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Consulte al administrador para más detalles</p>
    </div></div>`,
  },
  ERROR_CONTACT_EMAIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al enviar el correo</h4>',
    html: '<div class="caption-text">Hubo un problema al enviar esta solicitud</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_UPLOADING_IMAGE: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al guardar la imagen</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_CREATING_DOCUMENT: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al añadir el Documento</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_OPENING_DOCUMENT: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al abrir el Documento</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_UPDATING_DOCUMENT: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al actualizar el Documento</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_DOWNLOADING_DOCUMENT: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al descargar el Documento</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_DUPLICATING_DOCUMENT: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al duplicar el Documento</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_GENERATING_DOCUMENT: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al generar el Documento</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_GETTING_DRAFTS: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al recuperar los borradores</h4>',
  },
  BOARD_NOT_CLOSED: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Junta no cerrada</h4>',
    html: '<div class="caption-text">No se puede enviar a firmar la lista de asistentes hasta que la Junta esté cerrada</div>',
  },
  BOARD_WITHOUT_PRESIDENT: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Junta sin Presidente</h4>',
    html: '<div class="caption-text">No se puede enviar a firmar el Acta de la Junta hasta que se asigne un Presidente</div>',
  },
  BOARD_WITHOUT_SECRETARY: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Junta sin Secretario</h4>',
    html: '<div class="caption-text">No se puede enviar a firmar el Acta de la Junta hasta que se asigne un Secretario</div>',
  },
  BOARD_ASSISTANTS_UPDATED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Asistentes a la Junta actualizados</h4>',
  },
  BOARD_ASSISTANTS_SIGNATURE_REQUESTED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Lista de asistentes enviada correctamente</h4>',
  },
  BOARD_UPDATE_ATTENDANCE_ERROR: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">En estos momentos no se pudo recuperar la información actualizada de la asistencia</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Por favor inténtelo más tarde</p>
    </div></div>`,
  },
  BOARD_ASSISTANTS_SIGNATURE_ERROR: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">Hubo un error al enviar la lista de asistentes</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Por favor inténtelo más tarde</p>
    </div></div>`,
  },
  PARTICIPANT_UPDATED_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Participante actualizado correctamente</h4>',
  },
  BOARD_UPDATED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Datos de la Junta actualizados</h4>',
  },
  COUNCIL_UPDATED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Datos del Consejo actualizados</h4>',
  },
  USER_UPDATED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Usuario actualizado</h4>',
  },
  SOCIETY_UPDATED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Sociedad actualizada</h4>',
  },
  SOCIETY_DELETED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Sociedad eliminada</h4>',
  },
  SOCIETY_ADDED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Sociedad añadida correctamente</h4>',
  },
  DOCUMENT_ADDED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Documento añadido correctamente</h4>',
  },
  DOCUMENT_DUPLICATED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Documento duplicado correctamente</h4>',
  },
  DOCUMENT_SHARED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Documento compartido correctamente</h4>',
  },
  DOCUMENT_UPDATED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Documento actualizado correctamente</h4>',
  },
  DOCUMENT_GENERATED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Documento generado correctamente</h4>',
  },
  CONTACT_EMAIL_SENT: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Solicitud enviada correctamente</h4>',
  },
  BENEFICIARY_UPDATED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Beneficiario actualizado correctamente</h4>',
  },
  BENEFICIARY_SHEET_ADDED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">¡Beneficiarios añadidos correctamente!</h4>',
    html: '<div class="caption-text">Recuerda que debes asignarle un plan a cada uno... y completar sus datos.</div>',
  },
  ERROR_BENEFICIARY_SHEET_NOT_FOUND: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en el archivo</h4>',
    html: '<div class="caption-text">No se encuentra la pestaña con nombre <b>Beneficiarios</b></div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_BENEFICIARY_SHEET_COLUMNS: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en las columnas</h4>',
    html: '<div class="caption-text">Las columnas no se corresponden con el siguiente modelo:<ul style="list-style-type: disc; text-align:left; margin-left: 2em; margin-top: 1em;"><li>Nombre y Apellidos</li><li>NIF</li><li>Email</li><li>Dirección</li><li>Nacionalidad</li><li>Unidades</li><li>Fecha de adhesion</li></ul></div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  HOLDING_CLASS_ADDED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Clase de Participaciones añadida correctamente</h4>',
  },
  DOWNLOAD_CERTIFICATE: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">¡Certificado listo!</h4>',
    html: '<div class="caption-text">Puede proceder a descargarlo a través del siguiente enlace:</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  INVITATION_INCOMPLETE: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Documento incompleto</h4>',
    html: '<div class="caption-text">Debe guardar los cambios primero</div>',
  },
  INVITATION_NO_EXTRA_SIGNER_DATA: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Faltan datos</h4>',
    html: '<div class="caption-text">Al utilizar la etiqueta <b>@FIRMA_SOCIEDAD</b> es obligatorio introducir los datos del Firmante Sociedad</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Complete estos campos y vuelva a guardar</p>
    </div></div>`,
  },
  ERROR_SENDING_INVITATION_LETTER: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">Error al enviar la carta de invitación</h4>',
    html: '<div class="caption-text">Hubo un problema al enviar esta comunicación</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_COMMUNICATIONS_EMAIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al enviar el correo</h4>',
    html: '<div class="caption-text">Hubo un problema al enviar esta comunicación</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  COMMUNICATIONS_EMAIL_SENT: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Comunicación enviada correctamente</h4>',
  },
  ERROR_COMMUNICATIONS_EMAIL_DUPLICATED: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en la lista de emails</h4>',
    html: '<div class="caption-text">Sólo se aceptan emails que pertenezcan a la sociedad</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Reemplace o elimine estos emails no permitidos</p>
  </div></div>`,
  },
  ERROR_SEND_EMAIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en el envío</h4>',
    html: '<div class="caption-text">Hubo un problema al enviar este email</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  SEND_EMAIL_SENT: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Email enviado correctamente</h4>',
  },
  TEST_EMAIL_SENT: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Email de prueba enviado correctamente</h4>',
  },
  ERROR_SENDING_TEST_EMAIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al enviar el email de prueba</h4>',
    footer: `<div class="bg-lighter">Consulte al administrador para más detalles<div class="text-center w-100">`,
  },
  TENDER_OFFER_SENT_es: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Oferta enviada</h4>',
    html: `<div class="caption-text">La oferta de compra se ha comunicado correctamente los elegibles</div><br />
    <div class="caption-text text-soft"><small>Pueder hacer el seguimiento de la oferta en la ficha Detalles de la Oferta</small></div>`,
  },
  TENDER_OFFER_ACCEPTED_es: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Oferta aceptada</h4>',
    html: '<div class="caption-text">Gracias por aceptarla. Recibirás un documento vía email que deberás firmar para cerrar esta operación.</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Si no recibes el email, contacta al Administrador</p>
    </div></div>`,
  },
  TENDER_OFFER_REJECTED_es: {
    type: alertTypes.INFO,
    title: '<h4 class="nk-modal-title">Oferta rechazada</h4>',
    html: '<div class="caption-text">No habrá ninguna modificación en tus Participaciones / Unidades.</div>',
  },
  TENDER_OFFER_SENT_en: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Offer sent</h4>',
    html: '<div class="caption-text">The buy offer has been successfully communicated to eligible holders.</div><br /> <div class="caption-text text-soft"><small>You can track the offer on the Offer Details tab</small></div>',
  },
  TENDER_OFFER_ACCEPTED_en: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Offer accepted</h4>',
    html: '<div class="caption-text">Thank you for accepting. You will receive a document by email which you will need to sign to close this transaction.</div>',
    footer:
      '<div class="bg-lighter"><div class="text-center w-100"> <p>If you do not receive the email, contact the administrator</p> </div></div>',
  },
  TENDER_OFFER_REJECTED_en: {
    type: alertTypes.INFO,
    title: '<h4 class="nk-modal-title">Offer rejected</h4>',
    html: '<div class="caption-text">There will be no modifications in your Shares / Units.</div>',
  },
  SIGNATURE_SENT: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Documento enviado</h4>',
    html: '<div class="caption-text">El socio/beneficiario recibirá un mail solicitando que firme este documento.</div><br /> <div class="caption-text text-soft"><small>Recibirás una notificación cuando lo haya terminado</small></div>',
  },
  SIGNATURE_SAVED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Firma guardada</h4>',
  },
  UPDATING_CUSTOM_DASHBOARD_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">¡Contenido actualizado!</h4>',
  },
  UPDATING_CUSTOM_DASHBOARD_ERROR: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al actualizar el contenido</h4>',
    html: '<div class="caption-text">En estos momentos no pudimos actualizar el Dashboard</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  ERROR_INVITATION_LETTER_INCOMPATIBLE: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en la carta de invitación</h4>',
    html: '<div class="caption-text">La carta de invitación de este plan no es compatible con la plataforma</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  EMPLOYEES_IMPORTED_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Empleados importados correctamente</h4>',
    html: '<div class="caption-text"></div>',
  },
  ERROR_IMPORTING_EMPLOYEES: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al cargar los empleados</h4>',
    html: '<div class="caption-text"></div>',
  },
  DRAFT_INCREASE_GENERATED: {
    type: alertTypes.SUCCESS,
    title: `<h4 class="nk-modal-title">${i18n.t(
      'DraftIncreaseSuccessfullyCreated'
    )}</h4>`,
  },
  SHARE_UPDATED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Participación actualizada correctamente</h4>',
  },
  ERROR_SHARE_UPDATED: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">No se pudo actualizar la Participación</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  SUCCESS_BUY_SHARES_TRANSACTION: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Compra de Participaciones confirmada</h4>',
    html: '<div class="caption-text">Recibirás un documento via email que tendrás que firmar para cerrar esta transacción</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Si no recibes el email, contacta al administrador</p></div></div>`,
  },
  ERROR_BUY_SHARES_TRANSACTION: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">No se pudo confirmar la compra de Participaciones</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  SUCCESS_SELL_SHARES_TRANSACTION: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Oferta aceptada</h4>',
    html: '<div class="caption-text">Gracias por confirmar. Recibirás un documento via email que tendrás que firmar para cerrar esta transacción</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Si no recibes el email, contacta al administrador</p></div></div>`,
  },
  ERROR_SELL_SHARES_TRANSACTION: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">No se pudo confirmar la venta de Participaciones</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  ROLLBACK_DENIED: {
    type: alertTypes.WARNING,
    title:
      '<h4 class="nk-modal-title">No se puede deshacer esta operación por falta de consistencia en los datos</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al equipo de soporte para recibir asistencia</p></div></div>`,
  },
  PARTNER_CATEGORY_CREATED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Categoría de socio creada correctamente</h4>',
  },
  PARTNER_CATEGORY_UPDATED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Categoría de socio actualizada correctamente</h4>',
  },
  PARTNER_CATEGORY_DELETED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Categoría de socio eliminada correctamente</h4>',
  },
  PARTNER_REPRESENTANT_CREATED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Representante de socio creado correctamente</h4>',
  },
  PARTNER_REPRESENTANT_UPDATED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Representante de socio actualizado correctamente</h4>',
  },
  PARTNER_REPRESENTANT_DELETED: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Representante de socio eliminado correctamente</h4>',
  },
  ERROR_PARTNER_REPRESENTANT_CREATED: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">Error al crear el Representante de socio</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  ERROR_PARTNER_REPRESENTANT_DELETED: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">Error al eliminar el Representante de socio</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  ADMIN_DELETED_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Administrador eliminado correctamente</h4>',
  },
  ADMIN_UPDATED_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Administrador actualizado correctamente</h4>',
  },
  ADMIN_ADDED_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title:
      '<h4 class="nk-modal-title">Administrador añadido correctamente</h4>',
  },
  ERROR_ADDING_ADMIN: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al añadir el Administrador</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  ERROR_UPDATING_ADMIN: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">Error al actualizar el Administrador</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  ERROR_DELETING_ADMIN: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al eliminar el Administrador</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  GUEST_DELETED_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Invitado eliminado correctamente</h4>',
  },
  GUEST_UPDATED_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Invitado actualizado correctamente</h4>',
  },
  GUEST_ADDED_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Invitado añadido correctamente</h4>',
  },
  ERROR_ADDING_GUEST: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al añadir el Invitado</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  ERROR_UPDATING_GUEST: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al actualizar el Invitado</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  ERROR_DELETING_GUEST: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al eliminar el Invitado</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Por favor notifica al Equipo de Soporte</p></div></div>`,
  },
  ERROR_EXTRACTING_DOC_INFO_WITH_AI: {
    type: alertTypes.WARNING,
    title: '<h4 class="nk-modal-title">Ups! Algo ha ido mal</h4>',
    html: '<div class="caption-text">Lo sentimos, ha habido un error al intentar extraer los datos del documento. Por favor, inténtalo más tarde</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100"><p>Contacta con nosotros si el problema persiste</p></div></div>`,
  },
  COUNCIL_CREATED_SUCCESSFULLY: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Consejo creado correctamente</h4>',
  },
};
