import { SocietyRepresentative } from 'types';
import { getSocietyRepresentativeStatus } from './getSocietyRepresentativeStatus';

export const getSocietyRepresentativeStatusData = (
  representative: SocietyRepresentative
) => {
  const status = getSocietyRepresentativeStatus(representative);

  const statusLabel = status ? status.label : '-';
  const statusBadge = status ? status.badge : '-';

  return { statusLabel, statusBadge };
};
