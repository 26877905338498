import adminMemberTypes from '../constants/adminMemberTypes';

export const getAdminMemberTypeLabel = (
  memberTypeValue: string | undefined
) => {
  const memberType = adminMemberTypes.find(
    (type) => type.value === memberTypeValue
  );

  const memberTypeLabel = memberType ? memberType.label : '-';

  return memberTypeLabel;
};
