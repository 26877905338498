/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useState } from 'react';

import { useTranslate } from 'hooks/useTranslate';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { getDirectorImage } from 'modules/society/utils/getDirectorImage';
import DetailsProfileSection from 'modules/_shared/components/Sections/DetailsProfileSection';
import DetailsTableSection from 'modules/_shared/components/Sections/DetailsTableSection';
import { getDirectorDetailsList } from 'modules/society/pages/DirectorDetail/utils/getDirectorDetailsList';

function DirectorSociety({ partner, society, director }) {
  const { t, i18n } = useTranslate();

  const [currDirectorTypes, setCurrDirectorTypes] = useState(
    directorTypes(i18n)
  );

  const [fullName] = useState(director?.socialDenomination || '');
  const [directorImage, setDirectorImage] = useState('');

  useEffect(() => {
    setCurrDirectorTypes(directorTypes(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const currentDirectorImage = getDirectorImage(director, society);
    if (currentDirectorImage !== BLANK_PROFILE_PICTURE) {
      setDirectorImage(currentDirectorImage);
    } else {
      setDirectorImage(null);
    }
  }, [director, society]);

  const details = getDirectorDetailsList(director, t);

  return (
    <>
      <DetailsProfileSection
        name={fullName}
        image={directorImage || undefined}
        position={
          director.directorType
            ? currDirectorTypes.name[director.directorType]
            : ''
        }
        description={t('HeIsAMemberOfBoardAdmin', {
          societyName: society.name,
        })}
      />

      <div className="nk-divider divider my-0" />

      <DetailsTableSection title={t('Details')} data={details} />
    </>
  );
}

export default DirectorSociety;
