import { SocietyAuditor } from 'types';
import { getSocietyAuditorStatusData } from 'modules/administratives/pages/SocietyAuditors/utils/getSocietyAuditorStatusData';
import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { WidgetLegalTableData } from '../components/WidgetLegalTableExtended';
import { createTermFromDates } from './createTermFromDates';

export const mapAuditorsToLegalDashboard = (
  auditors: SocietyAuditor[],
  translate: any
): WidgetLegalTableData[] => {
  const data: WidgetLegalTableData[] = [];

  auditors.forEach((auditor) => {
    const { statusLabel } = getSocietyAuditorStatusData(auditor);

    data.push({
      name: auditor.socialDenomination,
      image: auditor?.image || BLANK_PROFILE_PICTURE,
      value1: translate(statusLabel),
      value2: createTermFromDates(
        auditor?.initDate,
        auditor?.endDate,
        translate
      ),
      link: '',
    });
  });
  return data;
};
