import { store } from 'redux/store';
import { getDocuments } from 'modules/documents/redux/documentActions';

export const getSocietyDocuments = async (societyId: string) => {
  if (societyId) {
    const societyDocuments = await store.dispatch(
      getDocuments({ society: societyId })
    );
    return societyDocuments;
  }
  return [];
};
