import { axiosV1 } from 'interceptors/axios';
import types from 'redux/actions/actionTypes';

import { dispatchError } from 'utils/dispatchError';
import { decryptResponse } from 'utils/token';

export function setPartnerBook(partnerBook) {
  return { type: types.SET_PARTNER_BOOK, partnerBook };
}

export const getPartnerBook = (societyId) => async (dispatch) => {
  try {
    const { data } = await axiosV1.get(`/book/${societyId}`);

    const decodedData = await decryptResponse(data);

    return dispatch(setPartnerBook(decodedData));
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};
