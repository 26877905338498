import { useEffect, useState } from 'react';

import { Society, SocietyRepresentative } from 'types';
import societyRepresentativeStatus from '../constants/societyRepresentativeStatus';
import { getSocietyRepresentativeStatus } from '../utils/getSocietyRepresentativeStatus';

type Props = {
  society: Society;
  currentRepresentativesView: boolean;
};

const getActiveSocietyRepresentativesCount = (society: Society) => {
  let count = 0;
  society?.representatives?.forEach((representative) => {
    const status = getSocietyRepresentativeStatus(representative);
    if (status?.value === societyRepresentativeStatus[0].value) count += 1;
  });
  return count;
};

const getSocietyRepresentatives = (
  society: Society,
  currentRepresentativesView: boolean
) => {
  const filteredRepresentatives: SocietyRepresentative[] = [];

  society?.representatives?.forEach((representative) => {
    const activeStatus = societyRepresentativeStatus[0].value;
    const status = getSocietyRepresentativeStatus(representative);

    if (currentRepresentativesView && status?.value === activeStatus) {
      filteredRepresentatives.push(representative);
    } else if (!currentRepresentativesView) {
      filteredRepresentatives.push(representative);
    }
  });
  return filteredRepresentatives;
};

export default function useGetSocietyRepresentatives({
  society,
  currentRepresentativesView,
}: Props) {
  const [societyRepresentatives, setSocietyRepresentatives] = useState<
    SocietyRepresentative[]
  >([]);
  const [
    activeSocietyRepresentativesCount,
    setActiveSocietyRepresentativesCount,
  ] = useState<number>(0);

  useEffect(() => {
    if (society) {
      const activeSocietyRepresentativesCount =
        getActiveSocietyRepresentativesCount(society);
      setActiveSocietyRepresentativesCount(activeSocietyRepresentativesCount);

      const societyRepresentatives = getSocietyRepresentatives(
        society,
        currentRepresentativesView
      );
      setSocietyRepresentatives(societyRepresentatives);
    }
  }, [society, currentRepresentativesView]);

  return {
    data: { societyRepresentatives, activeSocietyRepresentativesCount },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
