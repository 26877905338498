/* eslint-disable react/react-in-jsx-scope */
import { i18n } from 'i18next';
import { store } from 'redux/store';

import { Council } from 'types';
import actionTypes from 'constants/actionTypes';

import { setModal } from 'modules/_shared/redux/modalActions';
import AddCouncil from 'modules/_shared/components/Modals/AddCouncil';

type CurrentCouncil = {
  currentCouncil: Council;
};

type IsEditButtonEnabled = {
  isEditButtonEnabled: () => boolean;
};

type IsSendButtonEnabled = {
  isSendButtonEnabled: () => boolean;
  currentCouncil: Council;
};

export const councilActions = (i18n: i18n) => [
  {
    icon: 'ni-edit',
    text: i18n.t('Edit'),
    action: ({ currentCouncil }: CurrentCouncil) => {
      store.dispatch(
        setModal(
          <AddCouncil
            action={actionTypes.EDIT as keyof typeof actionTypes}
            council={currentCouncil}
          />
        )
      );
    },
    dropdown: true,
    dontShowCurrent: ({ isEditButtonEnabled }: IsEditButtonEnabled) =>
      !isEditButtonEnabled(),
  },
  {
    icon: 'ni-send',
    text: i18n.t('SendAnnouncement'),
    action: ({ handleSendAnnouncement }: any) => {
      handleSendAnnouncement();
    },
    dropdown: true,
    dontShowCurrent: ({
      isSendButtonEnabled,
      currentCouncil,
    }: IsSendButtonEnabled) =>
      !isSendButtonEnabled() || currentCouncil?.announcementCommunication,
  },
  {
    icon: 'ni-send',
    text: i18n.t('SendReminder'),
    action: ({ handleSendReminder }: any) => {
      handleSendReminder();
    },
    dropdown: true,
    dontShowCurrent: () => true,
    // dontShowCurrent: ({
    //   isSendButtonEnabled,
    //   currentCouncil,
    // }: IsSendButtonEnabled) =>
    //   !isSendButtonEnabled() || !currentCouncil?.announcementCommunication,
  },
];
