import { ReactNode } from 'react';

type Props = {
  title?: string;
  titleClassName?: string;
  subTitle?: string;
  subTitleSuffix?: string;
  subTitleClassName?: string;
  description?: string | ReactNode;
  actions?: ReactNode[];
};

const getActionKey = (index: number) => `page-list-header-action-${index}`;

const PageListHeader = (props: Props) => {
  const {
    title,
    titleClassName,
    subTitle,
    subTitleSuffix,
    subTitleClassName,
    description,
    actions,
  } = props;

  const isDescriptionString = typeof description === 'string';

  return (
    <>
      {title && (
        <div className="nk-block-head-content page-header-title">
          <h3 className={`nk-block-title page-title ${titleClassName ?? ''}`}>
            {title}
          </h3>
          <p />
        </div>
      )}

      <div className="nk-block-head page-header-subtitle">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            {subTitle && (
              <h3
                className={`nk-block-title page-title ${
                  subTitleClassName ?? ''
                }`}
              >
                {subTitle}
                {subTitleSuffix && (
                  <>
                    <strong className="text-primary small">
                      <span className="px-1">|</span>
                      {subTitleSuffix}
                    </strong>
                  </>
                )}
              </h3>
            )}

            {description && (
              <>
                {isDescriptionString ? (
                  <div className="nk-block-des text-soft">
                    <p>{description}</p>
                  </div>
                ) : (
                  <>{description}</>
                )}
              </>
            )}
          </div>

          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <div className="toggle-expand-content" data-content="pageMenu">
                <ul className="nk-block-tools g-3">
                  {actions &&
                    actions?.length > 0 &&
                    actions?.map((action, index) => (
                      <li key={getActionKey(index)}>{action}</li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageListHeader;
