import { Society, SocietyDirector } from 'types';
import { getAdminMemberStatus } from './getAdminMemberStatus';
import adminMemberStatus from '../constants/adminMemberStatus';

type Props = {
  society: Society;
};

const getActiveAdminMembersCount = (society: Society) => {
  let count = 0;
  society?.directors?.forEach((director) => {
    const status = getAdminMemberStatus(director);
    if (status?.value === adminMemberStatus[0].value) count += 1;
  });
  return count;
};

const getAdminMembers = (society: Society) => society?.directors || [];

export const getAdminMembersData = ({ society }: Props) => {
  let membersCount = 0;
  let members: SocietyDirector[] = [];

  if (society) {
    membersCount = getActiveAdminMembersCount(society);
    members = getAdminMembers(society);
  }

  return { members, membersCount };
};
