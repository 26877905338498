import { FC, memo } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import operationTypes from 'constants/operationTypes';

type TransactionDetailsOperationTableHeaderProps = {
  operationType: string;
};

const TransactionDetailsOperationTableHeader: FC<
  TransactionDetailsOperationTableHeaderProps
> = ({ operationType }) => {
  const { t } = useTranslate();

  const renderCondition =
    operationType === operationTypes.SELL_PARTICIPATION ||
    operationType === operationTypes.HERITAGE ||
    operationType === operationTypes.DONATION;

  return (
    <>
      <thead>
        <tr>
          <th className="text-left">{t('Partner')}</th>
          <th className="text-left">{t('Description')}</th>
          <th className="text-center">{t('NrPart.')}</th>
          <th className="text-center">{t('NominalValue')}</th>
          {renderCondition ? (
            <th className="text-center">{t('PricePart.')}</th>
          ) : (
            <th className="text-center">{t('Premium')}</th>
          )}
          {operationType === operationTypes.CAPITAL_INCREASE && (
            <>
              <th className="text-center">{t('TotalVNominal')}</th>
              <th className="text-center">{t('TotalPremium')}</th>
            </>
          )}
          {operationType === operationTypes.DIVIDENDS_DISTRIBUTION ? (
            <th className="text-center">{t('DividendsTotal')}</th>
          ) : (
            <th className="text-center">{t('TotalInvestment')}</th>
          )}
          <th className="text-center">{t('%')}</th>
        </tr>
      </thead>
    </>
  );
};

export default memo(TransactionDetailsOperationTableHeader);
