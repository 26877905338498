/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Cookies from 'js-cookie';

const Auth0ProviderWithDynamicConfig = ({ children }) => {
  const location = useLocation();

  // Define base security configurations
  const baseSecurityConfig = {
    prompt: 'login',
    acrValues: 'http://schemas.openid.net/pape/policies/2007/06/multi-factor', // renamed from acr_values
    responseType: 'code', // renamed from response_type
    scope: 'openid profile email offline_access', // renamed from scope
    disableSignup: true, // renamed from disable_signup
    certificateType: 'hard', // renamed from certificate_type
    maxAge: 7200, // renamed from max_age
    connectionScope: 'certificates', // renamed from connection_scope
  };

  // Define tenant-specific configurations
  const getTenantConfig = (tenant) => {
    const configs = {
      pwc: {
        domain: process.env.REACT_APP_AUTH0_DOMAIN_PWC,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID_PWC,
        authorizationParams: {
          ...baseSecurityConfig,
          // connection: 'active-directory-certificate',
          // organization: process.env.REACT_APP_AUTH0_ORG_ID_PWC,
          prompt: 'login',
          certificateType: 'hard',
          certificateProvider: 'pwc-idam',
          loginHint: 'cert_required', // renamed from login_hint
          redirect_uri: window.location.origin, // moved inside authorizationParams
          audience: process.env.REACT_APP_AUTH0_AUDIENCE_PWC,
        },
      },
      default: {
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
        authorizationParams: {
          ...baseSecurityConfig,
          // connection: 'cert-auth',
          redirect_uri: window.location.origin, // moved inside authorizationParams
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        },
      },
    };

    return configs[tenant] || configs.default;
  };

  const [authConfig, setAuthConfig] = useState(() => {
    const savedTenant = Cookies.get('authTenant');
    return getTenantConfig(savedTenant);
  });

  useEffect(() => {
    const { hash } = location;

    if (hash.includes('/pwc-login')) {
      console.log('PWC Space detected', window.location.origin); // eslint-disable-line

      Cookies.set('authTenant', 'pwc', {
        secure: true,
        sameSite: 'strict',
        httpOnly: true,
      });

      setAuthConfig(getTenantConfig('pwc'));
    } else if (hash.includes('/login')) {
      console.log('STTOK Space detected'); // eslint-disable-line

      Cookies.set('authTenant', 'default', {
        secure: true,
        sameSite: 'strict',
        httpOnly: true,
      });

      setAuthConfig(getTenantConfig('default'));
    }
  }, [location.hash]); // eslint-disable-line

  const onRedirectCallback = (appState) => {
    const managedErrors = [
      'unauthorized',
      'access_denied',
      'invalid_certificate',
    ];

    if (appState && appState.error) {
      if (managedErrors.includes(appState.error)) {
        const tenant = Cookies.get('authTenant');
        const config = getTenantConfig(tenant);

        window.location.href =
          `https://${config.domain}/authorize?` +
          `response_type=code&` +
          `client_id=${config.clientId}&` +
          `redirect_uri=${encodeURIComponent(
            config.authorizationParams.redirect_uri
          )}&` +
          `connection=${config.authorizationParams.connection}&` +
          `certificate_type=hard&` +
          `prompt=login`;
      }
    }
  };

  return (
    <Auth0Provider
      key={authConfig.domain}
      {...authConfig}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
      useRefreshTokensFallback={false}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithDynamicConfig;
