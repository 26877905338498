import types from 'redux/actions/actionTypes';

import { axiosV2 } from 'interceptors/axios';
import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { CommitteeMember as OriginalCommitteeMember } from 'types';

type CommitteeMember = Omit<OriginalCommitteeMember, '_id'>;

export const createCommitteeMember =
  (committeeMemberData: CommitteeMember) => async (dispatch: any) => {
    try {
      const body = { ...committeeMemberData };
      const { data } = await axiosV2.post(`/committeeMembers`, body);
      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.CREATE_COMMITTEE,
        committee: decodedData,
      });
      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };
