import { FC } from 'react';
import downloadFile from 'utils/downloadFile';

type Props = {
  documentId: string;
};

const DownloadDocument: FC<Props> = ({ documentId }) => {
  const handleDownloadDocument = () => {
    downloadFile({ documentId });
  };

  return (
    <span className="btn btn-icon btn-trigger" onClick={handleDownloadDocument}>
      <em className="icon ni ni-file-download" />
    </span>
  );
};

export default DownloadDocument;
