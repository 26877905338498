import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  meetingName: string;
  handleCloseModal: () => void;
};

const AnnouncementTemplateViewerHeader: FC<Props> = ({
  meetingName,
  handleCloseModal,
}: Props) => {
  const { t } = useTranslate();

  return (
    <Modal.Header>
      <h5 className="modal-title" style={{ color: '#364a63' }}>
        {t('CommunicationTemplate')} &nbsp;
        <span className="text-muted ff-alt fs-14px text-right">
          ({meetingName})
        </span>
      </h5>
      <a className="close cursor-pointer" onClick={handleCloseModal}>
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default AnnouncementTemplateViewerHeader;
