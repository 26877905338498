export function kFormatter(num: number, decimals: number = 2) {
  const absNum = Math.abs(num);
  const sign = Math.sign(num);

  if (absNum < 1000) {
    return sign * absNum;
  }
  if (absNum < 1000000) {
    return `${((sign * absNum) / 1000).toFixed(decimals)}K`;
  }

  return `${((sign * absNum) / 1000000).toFixed(decimals)}M`;
}
