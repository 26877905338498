import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { format } from 'date-fns';

import InputError from 'modules/_shared/components/InputError';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import { dateFormatInverted } from 'constants/formats';

import { Beneficiary } from 'types';
import isValidEmail from 'utils/isValidEmail';
import errorSwal from 'utils/errorSwal';
import { allowedFileImage } from 'utils/allowedFileImage';
import ImageInput from 'modules/documents/components/ImageInput';
import emailCategories from './constants/emailCategories';

type PersonalDataViewProps = {
  beneficiary: Beneficiary;
  setUpdatedBeneficiary: any;
};

const PersonalDataView: FC<PersonalDataViewProps> = ({
  beneficiary,
  setUpdatedBeneficiary,
}) => {
  const { t } = useTranslate();

  const user: any = useSelector((state: any) => state.user);
  const isAdmin: boolean = useSelector(
    (state: any) => state.society?.role?.isAdmin
  );

  const [name, setName] = useState<string>(beneficiary?.name || '');
  const [cif, setCif] = useState<string>(beneficiary?.cif || '');
  const [email, setEmail] = useState<string>(beneficiary?.email || '');
  const [emails, setEmails] = useState<any[]>(beneficiary?.emails || []);
  const [birthdate, setBirthdate] = useState<string>(
    beneficiary?.birthdate
      ? format(new Date(beneficiary?.birthdate), dateFormatInverted)
      : ''
  );
  const [jobTitle, setJobTitle] = useState<string>(beneficiary?.jobTitle || '');
  const [employeeNumber, setEmployeeNumber] = useState<string>(
    beneficiary?.employeeNumber || ''
  );
  const [nationality, setNationality] = useState<string>(
    beneficiary?.nationality || ''
  );
  const [phone, setPhone] = useState<string>(beneficiary?.phone || '');
  const [image, setImage] = useState<string>(beneficiary?.image || '');

  const validEmails = () => {
    const beneficiaryEmails = beneficiary?.emails?.map(
      (email: any) => email?.text
    );
    const emails = [beneficiary.email, ...(beneficiaryEmails || [])];

    return emails.every((email) => email !== '' && isValidEmail(email));
  };

  const handleChangeSelectedImage = async (event: any) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      const isAllowedImage = await allowedFileImage(file);

      if (!isAllowedImage) {
        errorSwal(t('InvalidFile'));
        return;
      }

      const reader = new FileReader();
      reader.onloadend = (e) => {
        const result = e.target?.result || '';
        setImage(result as string);
        setUpdatedBeneficiary({ ...beneficiary, image: result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeName = (event: any) => {
    const beneficiaryName = event.target.value;
    setName(beneficiaryName);
    setUpdatedBeneficiary({ ...beneficiary, name: beneficiaryName });
  };

  const handleChangeCif = (event: any) => {
    const beneficiaryCif = event.target.value;
    setCif(beneficiaryCif);
    setUpdatedBeneficiary({ ...beneficiary, cif: beneficiaryCif });
  };

  const handleChangeEmail = (event: any) => {
    const beneficiaryEmail = event.target.value;
    setEmail(beneficiaryEmail);
    setUpdatedBeneficiary({ ...beneficiary, email: beneficiaryEmail });
  };

  const handleChangeBirthdate = (event: any) => {
    const beneficiaryBirthdate = event.target.value;
    setBirthdate(beneficiaryBirthdate);
    setUpdatedBeneficiary({ ...beneficiary, birthdate: beneficiaryBirthdate });
  };

  const handleChangeJobTitle = (event: any) => {
    const beneficiaryJobTitle = event.target.value;
    setJobTitle(beneficiaryJobTitle);
    setUpdatedBeneficiary({ ...beneficiary, jobTitle: beneficiaryJobTitle });
  };

  const handleChangeEmployeeNumber = (event: any) => {
    const beneficiaryEmployeeNumber = event.target.value;
    setEmployeeNumber(beneficiaryEmployeeNumber);
    setUpdatedBeneficiary({
      ...beneficiary,
      employeeNumber: beneficiaryEmployeeNumber,
    });
  };

  const handleChangeNationality = (event: any) => {
    const beneficiaryNationality = event.target.value;
    setNationality(beneficiaryNationality);
    setUpdatedBeneficiary({
      ...beneficiary,
      nationality: beneficiaryNationality,
    });
  };

  const handleChangePhone = (event: any) => {
    const beneficiaryPhone = event.target.value;
    setPhone(beneficiaryPhone);
    setUpdatedBeneficiary({ ...beneficiary, phone: beneficiaryPhone });
  };

  const handleAddEmail = () => {
    const newEmail = { text: '', category: emailCategories.PRIMARY };

    setEmails([newEmail]);
    setUpdatedBeneficiary({ ...beneficiary, emails: [newEmail] });
  };

  const handleRemoveEmail = () => {
    setEmails([]);
    setUpdatedBeneficiary({ ...beneficiary, emails: [] });
  };

  const handleChangeOtherEmail = (event: any) => {
    const beneficiaryOtherEmail = {
      text: event.target.value,
      category: emailCategories.PRIMARY,
    };
    setEmails([beneficiaryOtherEmail]);
    setUpdatedBeneficiary({ ...beneficiary, emails: [beneficiaryOtherEmail] });
  };

  return (
    <>
      <div className="tab-pane active">
        <div className="row gy-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="name">
                {t('Name')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="name"
                value={name}
                onChange={handleChangeName}
                placeholder={t('IntroduceFullName')}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="cif">
                {t('FiscalIdentification')}
              </label>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <em className="icon ni ni-cc-alt2" />
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="cif"
                  value={cif}
                  onChange={handleChangeCif}
                  placeholder={t('EnterTheCifOrNif')}
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="email">
                {t('Email')}
              </label>
              <input
                type="email"
                className="form-control form-control-lg"
                id="email"
                value={email}
                onChange={handleChangeEmail}
                disabled={!isAdmin && user['_id'] !== beneficiary?.user}
                placeholder={t('IntroduceNewEmail')}
                pattern={EMAIL_PATTERN}
              />
            </div>

            {emails.length > 0 ? (
              <div className="form-control-wrap mb-3">
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    value={emails[0].text}
                    onChange={handleChangeOtherEmail}
                    placeholder={t('IntroduceNewEmail')}
                    pattern={EMAIL_PATTERN}
                  />

                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-primary btn-dim px-1"
                      type="button"
                      onClick={handleRemoveEmail}
                    >
                      <em className="icon ni ni-minus" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-lg btn-dim btn-outline-primary w-100"
                disabled={emails.length > 0}
                onClick={handleAddEmail}
              >
                <em className="icon ni ni-plus" />
                <span>{t('AddAnotherEmail')}</span>
              </button>
            )}
            {!validEmails() && <InputError errorMessage={t('InvalidEmail')} />}
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="birth-day">
                {t('DateOfBirth')}
              </label>
              <input
                type="date"
                className="form-control form-control-lg date-picker"
                id="birth-day"
                value={birthdate}
                onChange={handleChangeBirthdate}
                placeholder={t('IntroduceBirthDate')}
                max="2100-01-01"
                min="1000-01-01"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="job-title">
                {t('JobTitle')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                  id="job-title"
                  value={jobTitle}
                  onChange={handleChangeJobTitle}
                  placeholder={t('IntroduceJobTitle')}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="employee-number">
                {t('EmployeeNumber')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                  id="employee-number"
                  value={employeeNumber}
                  onChange={handleChangeEmployeeNumber}
                  placeholder={t('IntroduceEmployeeNumber')}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="nationality">
                {t('Nationality')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                id="nationality"
                value={nationality}
                onChange={handleChangeNationality}
                placeholder={t('IntroduceNationality')}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="phone-no">
                {t('Telephone')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                id="phone-no"
                value={phone}
                onChange={handleChangePhone}
                placeholder={t('IntroducePhoneNumber')}
              />
            </div>
          </div>
          <ImageInput
            value={image}
            onChange={handleChangeSelectedImage}
            name={name}
          />
        </div>
      </div>
    </>
  );
};

export default PersonalDataView;
