import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import validateRoute from 'utils/validateRoute';
import { getThemeContentPadding } from 'utils/theme';

import Footer from 'modules/_shared/components/Footer';
import Header from 'modules/_shared/components/Header';
import SideNav from 'modules/_shared/components/SideNav';
import Page403 from 'modules/_shared/pages/403';
import Notification from 'modules/_shared/components/Notification';
import CustomLoading from 'modules/_shared/components/CustomLoading';

const validateFullWidth = (fullWidth, isFullScreen) =>
  fullWidth || isFullScreen ? 'app-full-width' : '';

const isNotAuthenticated = (isLoading, token, user) =>
  !isLoading && !token && !user;

const getAppPadding = (theme) => {
  getThemeContentPadding(theme);
};

const GlobalNotification = ({ notification }) =>
  notification?.visible ? (
    <Notification
      id={notification.id}
      title={notification.title}
      message={notification.message}
      type={notification.type}
      link={notification.link}
      linkText={notification.linkText}
      icon={notification.icon}
      fill={notification.fill}
      dismissible={notification.dismissible}
      action={notification.action}
      actionText={notification.actionText}
      actionData={notification.actionData}
    />
  ) : null;

export const LegalRoute = ({
  id,
  path,
  component,
  user,
  isLoading,
  isFullScreen,
  isNewUI,
}) => {
  const location = useLocation();
  const history = useHistory();

  const notification = useSelector((state) => state.notification);
  const fullWidth = useSelector((state) => state.fullWidth);
  const theme = useSelector(
    (state) => state.society?.actualSociety?.customization?.theme
  );

  const isFullWidth = validateFullWidth(fullWidth, isFullScreen);

  useEffect(() => {
    if (isNotAuthenticated(isLoading, localStorage.token, user)) {
      history.push('/login');
    }
  });

  return user ? (
    <div className="app-content nk-wrap">
      <Header isFullScreen={isFullScreen} />
      <GlobalNotification notification={notification} />

      <div
        className={`nk-content ${isNewUI ? 'bg-lighter' : ''}`}
        style={getAppPadding(theme)}
      >
        <div className={`container wide-xl d-flex ${isFullWidth}`}>
          <SideNav className="container__nav" />

          <div className="container__main">
            {validateRoute(user, id, location.pathname) ? (
              <Route
                path={path}
                component={withAuthenticationRequired(component)}
              />
            ) : (
              <Route component={Page403} />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  ) : (
    <CustomLoading />
  );
};
