import { FC } from 'react';

import { Realty } from 'types';
import RealtiesTableHeader from './RealtiesTableHeader';
import RealtiesTableBody from './RealtiesTableBody';

type Props = {
  realties: Realty[];
};

const RealtiesTable: FC<Props> = ({ realties }) => (
  <div className="card card-bordered card-preview">
    <div className="table-responsive">
      <table className="nk-tb-list nk-tb-ulist">
        <RealtiesTableHeader />

        <RealtiesTableBody realties={realties} />
      </table>
    </div>
  </div>
);

export default RealtiesTable;
