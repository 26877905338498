import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { useTranslate } from 'hooks/useTranslate';

import Button from 'modules/_shared/components/Buttons';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';

type Props = {
  societyName: string;
  adminMembersCount: number;
  currentMembersView: boolean;
  handleAddMember: () => void;
  handleChangeMembersView: () => void;
  handleOpenGlobalSearch: () => void;
};

const AdminMembersPageHeader: FC<Props> = ({
  societyName,
  adminMembersCount,
  currentMembersView,
  handleAddMember,
  handleChangeMembersView,
  handleOpenGlobalSearch,
}) => {
  const { t } = useTranslate();

  const currentMembersViewIcon = currentMembersView
    ? 'icon ni ni-eye'
    : 'icon ni ni-eye-off';

  const currentMembersViewTooltipText = currentMembersView
    ? t('SeeNotCurrent')
    : t('HideNotCurrent');

  return (
    <>
      <PageListHeader
        title={t('AdminOrgan')}
        subTitle={societyName}
        description={`${societyName || ''} ${t('HasAdminMembers', {
          count: adminMembersCount,
        })}`}
        actions={[
          <Button
            id="open-global-search-button"
            icon="ni ni-search"
            variant="light"
            onClick={handleOpenGlobalSearch}
          />,
          <Button
            id="toggle-members-view-button"
            icon={currentMembersViewIcon}
            variant="light"
            onClick={handleChangeMembersView}
          />,
          <Button icon="ni ni-plus" variant="primary" onClick={handleAddMember}>
            {t('AddMember')}
          </Button>,
        ]}
      />
      <Tooltip
        anchorSelect="#toggle-members-view-button"
        content={currentMembersViewTooltipText}
      />

      <Tooltip
        anchorSelect="#open-global-search-button"
        content={t('GlobalSearch')}
      />
    </>
  );
};

export default AdminMembersPageHeader;
