import operationTypes from 'constants/operationTypes';
import { Operation, Society } from 'types';

export const getFilteredOperations = (
  society: Society,
  includedOps?: string[]
): Operation[] => {
  if (!society.operations) {
    return [];
  }

  const excludedOps = [
    operationTypes.ADD_PARTNER,
    operationTypes.ADD_PARTNERS,
    operationTypes.ADD_BENEFICIARY,
    operationTypes.STOCK_OPTION,
    operationTypes.PHANTOM_SHARE,
  ];

  const filteredTransactions = society.operations
    ?.filter((operation) =>
      includedOps
        ? includedOps.includes(operation.operationType)
        : !excludedOps.includes(operation.operationType)
    )
    .sort(
      (operationA, operationB) =>
        new Date(operationB.date).getTime() -
        new Date(operationA.date).getTime()
    );

  return filteredTransactions;
};
