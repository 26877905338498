/* eslint-disable react/no-array-index-key */

import { Tooltip } from 'react-tooltip';

type Header = {
  text: string;
  style1: string;
  style2: string;
};

type ClassesTableFirstColumnProps = {
  currentPartners: any;
  totalsRowHeaders: any;
  aditionalRowHeaders: any;
};

type CurrentPartner = Record<string, any>;

const ClassesTableFirstColumn = ({
  currentPartners,
  totalsRowHeaders,
  aditionalRowHeaders,
}: ClassesTableFirstColumnProps) => (
  <>
    {currentPartners
      ?.filter((partner: CurrentPartner) => partner?.sharesCount?.actual > 0)
      .sort(
        (a: CurrentPartner, b: CurrentPartner) =>
          (a?.partnerNumber || 0) - (b?.partnerNumber || 0)
      )
      ?.map((partner: CurrentPartner, index: number) => (
        <tr key={partner?.['_id']}>
          <td
            key={partner?.['_id']}
            id={`${partner?.['_id']}s`}
            className={
              !(index % 2 === 0)
                ? 'text-left w-200px d-block text-ellipsis bg-light '
                : 'text-left w-200px d-block text-ellipsis'
            }
            style={
              index === currentPartners.length - 1
                ? { borderBottom: '1px solid #999' }
                : {}
            }
          >
            {partner.name}
            <Tooltip anchorId={`${partner?.['_id']}s`} place="bottom">
              {partner?.name}
            </Tooltip>
          </td>
        </tr>
      ))}

    {aditionalRowHeaders?.map((header: Header, index: number) => (
      <tr key={`${header.text}-${index}`}>
        <td
          key={header.text}
          className={
            !((currentPartners.length + index) % 2 === 0)
              ? header.style1
              : header.style2
          }
          style={
            index === aditionalRowHeaders.length - 1
              ? { borderBottom: '1px solid #999' }
              : {}
          }
        >
          {header.text}
        </td>
      </tr>
    ))}

    {totalsRowHeaders?.map((header: Header, index: number) => (
      <tr key={header.text}>
        <td
          key={header.text}
          className={
            !((currentPartners.length + index + 9) % 2 === 0)
              ? header.style1
              : header.style2
          }
        >
          {header.text}
        </td>
      </tr>
    ))}
  </>
);

export default ClassesTableFirstColumn;
