import { useCallback, useEffect, useState } from 'react';
import { store } from 'redux/store';
import { getRealties } from 'modules/administratives/redux/realtyActions';
import { State } from 'redux/initialState';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useRealties = () => {
  const { societyId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchRealties = useCallback(async () => {
    // const notShouldFetch = !societyId || realties?.length > 0;
    if (!societyId) return;
    setIsLoading(true);
    setIsError(false);
    try {
      await store.dispatch(getRealties(societyId));
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [societyId]);

  useEffect(() => {
    fetchRealties();
  }, [fetchRealties]);

  return { isLoading, isError };
};
