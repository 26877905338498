import adminMemberStatus from 'modules/administratives/pages/AdminMembers/constants/adminMemberStatus';
import { getAdminMemberStatus } from 'modules/administratives/pages/AdminMembers/utils/getAdminMemberStatus';
import { isActiveDirector } from 'modules/administratives/utils/isActiveDirector';
import {
  CouncilNewParticipant,
  CouncilParticipant,
  Society,
  SocietyDirector,
} from 'types';

export const isParticipantAllowedForAnnouncement = (
  participant: CouncilParticipant | CouncilNewParticipant,
  society: Society
): boolean => {
  const currentParticipant = society.directors?.find(
    (director) => director._id === participant.member
  ) as SocietyDirector;

  return (
    getAdminMemberStatus(currentParticipant)?.value ===
    adminMemberStatus[0].value
  );
};
