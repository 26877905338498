import userTypes from 'constants/userTypes';
import {
  CouncilNewParticipant,
  CouncilParticipantUserType,
  SocietyDirector,
} from 'types';
import { getAdminMemberStatus } from '../pages/AdminMembers/utils/getAdminMemberStatus';
import adminMemberStatus from '../pages/AdminMembers/constants/adminMemberStatus';

export const parseDirectorsToParticipants = (
  directors: SocietyDirector[]
): CouncilNewParticipant[] => {
  const participants =
    directors
      ?.filter(
        (director) =>
          getAdminMemberStatus(director)?.value === adminMemberStatus[0].value
      )
      ?.map((director) => ({
        member: director._id || '',
        name: director.socialDenomination,
        email: director.email,
        userType: userTypes.DIRECTOR as CouncilParticipantUserType,
        assists: false,
        isAbsent: true,
        isRepresented: false,
        representative: null,
        participantPosition: director?.directorType,
      })) || [];

  return participants;
};
