import { useEffect, useState } from 'react';
import { store } from 'redux/store';

import { Document } from 'types';
import { getDocuments } from 'modules/documents/redux/documentActions';

type Props = {
  societyId: string;
  memberId?: string;
};

export default function useGetAdminMemberDocuments({
  societyId,
  memberId,
}: Props) {
  const [memberDocuments, setMemberDocuments] = useState<Document[]>([]);

  useEffect(() => {
    if (societyId && memberId) {
      const getDocumentsData = async () => {
        try {
          store.dispatch(
            getDocuments(
              { director: memberId, society: societyId },
              setMemberDocuments
            )
          );
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      };

      getDocumentsData();
    }
  }, [societyId, memberId]);

  return {
    data: { memberDocuments },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
