import { useMemo } from 'react';

type Roles = {
  isAdmin?: boolean;
  isPartner?: boolean;
  isBeneficiary?: boolean;
  isInvestor?: boolean;
  isRepresentant?: boolean;
  isGuest?: boolean;
  isHolder?: boolean;
  isDemo?: boolean;
  isDirector?: boolean;
};

const useRolesValidation = (roles: Roles) => {
  const isAdmin = roles?.isAdmin || false;
  const isPartner = roles?.isPartner || false;
  const isBeneficiary = roles?.isBeneficiary || false;
  const isInvestor = roles?.isInvestor || false;
  const isRepresentant = roles?.isRepresentant || false;
  const isGuest = roles?.isGuest || false;
  const isHolder = roles?.isHolder || false;
  const isDemo = roles?.isDemo || false;
  const isDirector = roles?.isDirector || false;

  const isAdminOnly = useMemo(
    () =>
      isAdmin &&
      !isPartner &&
      !isBeneficiary &&
      !isInvestor &&
      !isRepresentant &&
      !isGuest &&
      !isHolder &&
      !isDirector,
    [
      isAdmin,
      isPartner,
      isBeneficiary,
      isInvestor,
      isRepresentant,
      isGuest,
      isHolder,
      isDirector,
    ]
  );

  const isPartnerOnly = useMemo(
    () =>
      !isAdmin &&
      isPartner &&
      !isBeneficiary &&
      !isInvestor &&
      !isRepresentant &&
      !isGuest &&
      !isHolder &&
      !isDirector,
    [
      isAdmin,
      isPartner,
      isBeneficiary,
      isInvestor,
      isRepresentant,
      isGuest,
      isHolder,
      isDirector,
    ]
  );

  const isBeneficiaryOnly = useMemo(
    () =>
      !isAdmin &&
      !isPartner &&
      isBeneficiary &&
      !isInvestor &&
      !isRepresentant &&
      !isGuest &&
      !isHolder &&
      !isDirector,
    [
      isAdmin,
      isPartner,
      isBeneficiary,
      isInvestor,
      isRepresentant,
      isGuest,
      isHolder,
      isDirector,
    ]
  );

  const isInvestorOnly = useMemo(
    () =>
      !isAdmin &&
      !isPartner &&
      !isBeneficiary &&
      isInvestor &&
      !isRepresentant &&
      !isGuest &&
      !isHolder &&
      !isDirector,
    [
      isAdmin,
      isPartner,
      isBeneficiary,
      isInvestor,
      isRepresentant,
      isGuest,
      isHolder,
      isDirector,
    ]
  );

  const isRepresentantOnly = useMemo(
    () =>
      !isAdmin &&
      !isPartner &&
      !isBeneficiary &&
      !isInvestor &&
      isRepresentant &&
      !isGuest &&
      !isHolder &&
      !isDirector,
    [
      isAdmin,
      isPartner,
      isBeneficiary,
      isInvestor,
      isRepresentant,
      isGuest,
      isHolder,
      isDirector,
    ]
  );

  const isGuestOnly = useMemo(
    () =>
      !isAdmin &&
      !isPartner &&
      !isBeneficiary &&
      !isInvestor &&
      !isRepresentant &&
      isGuest &&
      !isHolder &&
      !isDirector,
    [
      isAdmin,
      isPartner,
      isBeneficiary,
      isInvestor,
      isRepresentant,
      isGuest,
      isHolder,
      isDirector,
    ]
  );

  const isHolderOnly = useMemo(
    () =>
      !isAdmin &&
      !isPartner &&
      !isBeneficiary &&
      !isInvestor &&
      !isRepresentant &&
      !isGuest &&
      isHolder &&
      !isDirector,
    [
      isAdmin,
      isPartner,
      isBeneficiary,
      isInvestor,
      isRepresentant,
      isGuest,
      isHolder,
      isDirector,
    ]
  );

  const isDirectorOnly = useMemo(
    () =>
      !isAdmin &&
      !isPartner &&
      !isBeneficiary &&
      !isInvestor &&
      !isRepresentant &&
      !isGuest &&
      !isHolder &&
      isDirector,
    [
      isAdmin,
      isPartner,
      isBeneficiary,
      isInvestor,
      isRepresentant,
      isGuest,
      isHolder,
      isDirector,
    ]
  );

  const isDemoOnly = useMemo(
    () =>
      !isAdmin &&
      !isPartner &&
      !isBeneficiary &&
      !isInvestor &&
      !isRepresentant &&
      !isGuest &&
      !isHolder &&
      !isDirector &&
      isDemo,
    [
      isAdmin,
      isPartner,
      isBeneficiary,
      isInvestor,
      isRepresentant,
      isGuest,
      isHolder,
      isDirector,
      isDemo,
    ]
  );

  return {
    isAdmin,
    isPartner,
    isBeneficiary,
    isInvestor,
    isRepresentant,
    isGuest,
    isHolder,
    isDemo,
    isDirector,
    isAdminOnly,
    isPartnerOnly,
    isBeneficiaryOnly,
    isInvestorOnly,
    isRepresentantOnly,
    isGuestOnly,
    isHolderOnly,
    isDemoOnly,
    isDirectorOnly,
  };
};

export default useRolesValidation;
