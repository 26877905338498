import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { EnvelopeSigner } from 'types';

import SignerStatusRow from './SignerStatusRow';
import NoItemsAvailable from '../../Modals/NoItemsAvailable';

type Props = {
  signers: EnvelopeSigner[];
};

const SignersStatusTableBody: FC<Props> = ({ signers }) => {
  const { t } = useTranslate();

  const hasData = signers?.length > 0;

  return (
    <tbody>
      {hasData ? (
        signers.map((signer) => <SignerStatusRow signer={signer} />)
      ) : (
        <NoItemsAvailable mainMessage={t('NoSignersMessage')} />
      )}
    </tbody>
  );
};

export default SignersStatusTableBody;
