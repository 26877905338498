import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { getActualSociety } from 'utils/filters';
import isSuperAdmin from 'utils/isSuperAdmin';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import SocietyConfiguration from 'modules/society/components/SocietyConfiguration';
import SocietyIntegrations from 'modules/society/components/SocietyIntegrations';
import SocietyProfileData from 'modules/society/components/SocietyProfileData';
import SocietySubscription from 'modules/society/components/SocietySubscription';
import SocietyValues from 'modules/society/components/SocietyValues';
import SocietyAnnualValue from 'modules/society/pages/SocietyAnnualValue';
import SocietyUsers from 'modules/society/components/SocietyUsers';
import { useGetActualSociety } from 'hooks/useGetActualSociety';

export const pageTypes = {
  datos: 0,
  valor: 1,
  'valor-participaciones': 2,
  administradores: 3,
  integraciones: 4,
  suscripcion: 5,
  configuracion: 6,
};

function SocietyProfile() {
  const { t } = useTranslate();
  const { societyId, pageType } = useParams();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState();

  const [isModalOpened, setIsModelOpened] = useState(false);

  useGetActualSociety(societyId);

  useEffect(() => {
    if (actualSociety) {
      const page = pageTypes[pageType];
      setPage(page || pageTypes.datos);
      setPages([
        <SocietyProfileData
          isOpen={page === undefined && !isModalOpened}
          key="SocietyProfileData"
        />,
        <SocietyValues
          key="SocietyValues"
          society={actualSociety}
          isAdmin
          setPage={setPage}
        />,
        <SocietyAnnualValue key="SocietyAnnualValue" />,
        <SocietyUsers key="SocietyUsers" />,
        <SocietyIntegrations />,
        <SocietySubscription key="SocietySubscription" />,
        <SocietyConfiguration key="SocietyConfiguration" isAdmin />,
      ]);
      setIsModelOpened(true);
    }
  }, [actualSociety, isModalOpened, pageType, user]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyName: actualSociety?.name,
          societyId: actualSociety?.['_id'],
        })
      );
    }
  }, [dispatch, actualSociety]);

  return actualSociety ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <div className="nk-block-head-sub">
              <span>{t('SocietyConfiguration')}</span>
            </div>
            <h2 className="nk-block-title fw-normal">{actualSociety?.name}</h2>
            <div className="nk-block-des">
              <p>{t('FromThisSectionYouControlTheDataOfTheCompany')}</p>
            </div>
          </div>
        </div>
        <ul className="nk-nav nav nav-tabs">
          <li className="nav-item">
            <Link
              to={`/perfil-sociedad/datos/${societyId}`}
              className={`nav-link ${page === pageTypes.datos ? 'active' : ''}`}
            >
              {t('Data')}
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to={`/perfil-sociedad/valor/${societyId}`}
              className={`nav-link ${page === pageTypes.valor ? 'active' : ''}`}
              onClick={() => setPage(pageTypes.valor)}
            >
              {t('ValueSociety')}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/perfil-sociedad/valor-participaciones/${societyId}`}
              className={`nav-link ${
                page === pageTypes['valor-participaciones'] ? 'active' : ''
              }`}
              onClick={() => setPage(pageTypes['valor-participaciones'])}
            >
              {t('ValueShar.')}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/perfil-sociedad/administradores/${societyId}`}
              className={`nav-link ${
                page === pageTypes.administradores ? 'active' : ''
              } ${isDemo ? 'is-disable' : ''}`}
              onClick={() => setPage(pageTypes.administradores)}
            >
              {t('Users')}
            </Link>
          </li>
          {isSuperAdmin(user) && (
            <li className="nav-item">
              <Link
                to={`/perfil-sociedad/integraciones/${societyId}`}
                className={`nav-link ${
                  page === pageTypes.integraciones ? 'active' : ''
                } ${isDemo ? 'is-disable' : ''}`}
                onClick={() => setPage(pageTypes.integraciones)}
              >
                {t('Integrations')}
              </Link>
            </li>
          )}

          {/* <li className="nav-item">
            <Link
              to={`/perfil-sociedad/suscripcion/${societyId}`}
              className={`nav-link ${
                page === pageTypes.suscripcion ? 'active' : ''
              } is-disable`}
              onClick={() => setPage(pageTypes.suscripcion)}
            >
              {t('Suscription')}
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              to={`/perfil-sociedad/configuracion/${societyId}`}
              className={`nav-link ${
                page === pageTypes.configuracion ? 'active' : ''
              }`}
              onClick={() => setPage(pageTypes.configuracion)}
            >
              {t('Customization')}
            </Link>
          </li>
        </ul>
        {pages ? pages[page] : ''}
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
}

export default SocietyProfile;
