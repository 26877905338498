/* eslint-disable react/no-danger */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import html2pdf from 'html3pdf';
import { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { store } from 'redux/store';
import { formatDateToTime } from 'constants/formats';
import { Council, Document, Society } from 'types';
import { getSingleDocument } from 'utils/downloadDocument';

import { setModal } from 'modules/_shared/redux/modalActions';
import { sendCommEmail } from 'modules/communications/redux/communicationActions';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import SendTestEmail from 'modules/_shared/components/Modals/SendTestEmail';
import useGetCouncilAnnouncementData from 'modules/_shared/components/Modals/AnnouncementTemplateViewer/hooks/useGetCouncilAnnouncementData';
import {
  getCouncils,
  updateCouncil,
} from 'modules/administratives/redux/councilActions';

import EditParticipantModal from '../EditParticipantModal';
import ParticipantsTable from './components/ParticipantsTable';
import { updateParticipantsEmails } from './utils';
import { getInvalidEmailsMessage } from './utils/getInvalidEmailsMessage';
import { getTotalParticipantsAllowed } from './utils/getTotalParticipantsAllowed';
import { getParticipantsWithNullEmails } from './utils/getParticipantsWithNullEmails';
import SendCouncilAnnouncementModalHeader from './components/SendCouncilAnnouncementModalHeader';
import { createCouncilAnnouncementEmailData } from './utils/createCouncilAnnouncementEmailData';
import { isParticipantAllowedForAnnouncement } from './utils/isParticipantAllowedForAnnouncement';

type SendCouncilAnnouncementModalProps = {
  currentCouncil: Council;
  actualSociety: Society;
  announcementTemplateDoc: Document;
  hasAlreadyUpdatedParticipants?: boolean;
};

const SendCouncilAnnouncementModal: FC<SendCouncilAnnouncementModalProps> = ({
  currentCouncil,
  actualSociety,
  announcementTemplateDoc,
  hasAlreadyUpdatedParticipants = false,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);

  const { announcementData } = useGetCouncilAnnouncementData(
    currentCouncil,
    actualSociety
  );

  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasUpdatedParticipants, setHasUpdatedParticipants] =
    useState<boolean>(false);
  const [hasInvalidEmails, setHasInvalidEmails] = useState<boolean>(false);

  const handleEditParticipant = (participant: any): void => {
    store.dispatch(
      setModal(
        <EditParticipantModal
          currentCouncil={currentCouncil}
          actualSociety={actualSociety}
          participant={participant}
          announcementTemplateDoc={announcementTemplateDoc}
        />
      )
    );
  };

  const handleSendAnnouncement = async (): Promise<void> => {
    try {
      setIsLoading(true);

      const activeMembers = currentCouncil?.participants?.filter(
        (participant) =>
          isParticipantAllowedForAnnouncement(participant, actualSociety)
      );

      const recipients = activeMembers.map((member) => ({
        email: member.email,
        name: member.name,
      }));

      const mainEmails = activeMembers.map((recipient) => {
        const { member, email, name } = recipient;
        return {
          id: member,
          email: email ? email.trim() : '',
          name,
        };
      });

      const recipientsForSending = [...recipients];
      const recipientsForDynamicData = [...mainEmails];

      let fileBlob;

      if (currentCouncil?.announcementDocument) {
        fileBlob = await getSingleDocument(
          currentCouncil?.announcementDocument
        );
      } else {
        const documentUpdated = updateDocumentWithValues(
          announcementTemplateDoc?.editorModel,
          tags({
            society: actualSociety,
            board: currentCouncil,
            plan: null,
            partner: null,
            beneficiary: null,
            signatures: null,
            tenderOffer: null,
            tenderOfferShares: null,
            operation: null,
            holdingClasses: null,
            annualValue: null,
          })
        );

        const options = {
          filename: `${t('AnnouncementCouncil')}.pdf`,
          margin: [10, 10, 10, 10], // top, right, bottom, left
          pagebreak: { mode: 'avoid-all' },
          html2canvas: { scale: 2 },
          jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
          },
        };
        fileBlob = await html2pdf()
          .from(documentUpdated?.html)
          .set(options)
          .output('blob');
      }
      const announcementFile = new File(
        [fileBlob],
        `${t('AnnouncementCouncil')}.pdf`,
        {
          type: 'application/pdf',
        }
      );

      const emailData = {
        ...createCouncilAnnouncementEmailData({
          announcementData,
          societyId: actualSociety['_id'],
          society: actualSociety,
          userId: user?._id,
          userEmail: user?.email,
        }),
        to: recipientsForSending,
        files: announcementFile ? [announcementFile] : [],
        templateDynamicData: recipientsForDynamicData.map(
          ({ id, name, email }) => ({
            rcpt: email,
            vars: [
              { name: 'name', content: name },
              { name: 'email', content: email },
              {
                name: 'councilUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=voto-consejo/${actualSociety['_id']}/${currentCouncil._id}/${id}`,
              },
            ],
          })
        ),
      };

      const communication = await store.dispatch(sendCommEmail(emailData));
      const councilStatusActive = 'ACTIVE' as const;

      const councilData = {
        _id: currentCouncil._id,
        status: councilStatusActive,
        announcementCommunication: communication._id,
      };

      await store.dispatch(updateCouncil(councilData));

      await store.dispatch(getCouncils(actualSociety['_id']));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error); // eslint-disable-line
    }
  };

  const handleSendTest = () => {
    const emailData = createCouncilAnnouncementEmailData({
      announcementData,
      userId: user?._id,
      userEmail: user?.email,
      society: actualSociety,
      societyId: actualSociety?._id,
    });

    dispatch(setModal(<SendTestEmail emailData={emailData} />));
  };

  const handleCloseUpdatedParticipantsAlert = () => {
    setHasUpdatedParticipants(false);
  };

  const updateEmails = async () => {
    const hasUpdated = await updateParticipantsEmails(
      currentCouncil,
      actualSociety
    );
    setHasUpdatedParticipants(hasUpdated || false);
  };

  useEffect(() => {
    if (currentCouncil) {
      setTime(
        currentCouncil?.date ? formatDateToTime(currentCouncil?.date) : ''
      );
      setDate(
        currentCouncil?.date
          ? format(new Date(currentCouncil?.date), 'dd/MM/yyyy')
          : ''
      );
      setHasInvalidEmails(
        getParticipantsWithNullEmails(currentCouncil, actualSociety).length > 0
      );
    }
  }, [currentCouncil]); // eslint-disable-line

  useEffect(() => {
    if (hasAlreadyUpdatedParticipants) {
      return;
    }
    updateEmails();
    setHasInvalidEmails(
      getParticipantsWithNullEmails(currentCouncil, actualSociety).length > 0
    );
  }, []); // eslint-disable-line

  return (
    <>
      <Modal.Body>
        <div className="modal-body modal-body-lg text-center">
          <div className="nk-modal">
            <SendCouncilAnnouncementModalHeader
              actualSociety={actualSociety}
              currentCouncil={currentCouncil}
              date={date || ''}
              time={time || ''}
            />

            <ParticipantsTable
              currentCouncil={currentCouncil}
              handleEditParticipant={handleEditParticipant}
              isParticipantAllowedForAnnouncement={
                isParticipantAllowedForAnnouncement
              }
              getTotalParticipantsAllowed={getTotalParticipantsAllowed}
            />

            {hasUpdatedParticipants && (
              <div className="alert alert-icon alert-warning alert-dismissible">
                <em className="icon ni ni-alert-circle" />
                {t('ParticipantsEmailsUpdated')}
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="alert"
                  onClick={handleCloseUpdatedParticipantsAlert}
                />
              </div>
            )}

            {hasInvalidEmails && (
              <div className="alert alert-icon alert-warning alert-dismissible">
                <em className="icon ni ni-alert-circle" />
                {getInvalidEmailsMessage(currentCouncil, actualSociety)}
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="alert"
                />
              </div>
            )}

            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSendTest}
                disabled={hasInvalidEmails}
              >
                {t('SendTestEmail')}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSendAnnouncement}
                disabled={hasInvalidEmails}
              >
                {t('SendNow')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default SendCouncilAnnouncementModal;
