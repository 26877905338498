import { FC } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { Council, Society, SocietyDirector } from 'types';
import { useTranslate } from 'hooks/useTranslate';
import {
  BLANK_PROFILE_PICTURE,
  DATE_FORMAT_WITH_TIME_ALT,
} from 'constants/defaultConstants';

import CouncilBadges from 'modules/administratives/components/CouncilBadges';
import councilStatus from 'modules/administratives/constants/councilStatus';
import { getDirectorImage } from 'modules/society/utils/getDirectorImage';

import { getNameInitials } from 'utils/filters';
import { getCouncilStatusData } from '../../CouncilDetails/utils/getCouncilStatusData';
import { getDirectorDetailsUrl } from '../utils/getDirectorDetailsUrl';

type CouncilMembersBasicInfoCardProps = {
  council: Council;
  society: Society;
};

const MemberAvatar = ({
  director,
  society,
}: {
  director: SocietyDirector;
  society: Society;
}) => {
  const directorImage = getDirectorImage(director, society);

  return (
    <div className="user-card">
      <div className="user-avatar sm bg-success">
        {directorImage && directorImage !== BLANK_PROFILE_PICTURE ? (
          <img src={directorImage} alt="President or Secretary" />
        ) : (
          <span>{getNameInitials(director?.socialDenomination)}</span>
        )}
      </div>
      <div className="user-name">
        <span className="fw-normal fs-15px">
          {director?.socialDenomination}
        </span>
      </div>
    </div>
  );
};

const CouncilMembersBasicInfoCard: FC<CouncilMembersBasicInfoCardProps> = ({
  council,
  society,
}) => {
  const { t } = useTranslate();

  const { statusLabel, statusBadge, statusValue } =
    getCouncilStatusData(council);

  const presidentData = society?.directors.find(
    (director) => director._id === council?.president
  ) as SocietyDirector;
  console.log('🚀 cclog ~ presidentData:', presidentData);

  const secretaryData = society?.directors.find(
    (director) => director._id === council?.secretary
  ) as SocietyDirector;
  console.log('🚀 cclog ~ secretaryData:', secretaryData);

  const items = [
    {
      title: t('Name'),
      value: council?.name || '-',
    },
    {
      title: t('CouncilDate'),
      value: council?.date
        ? format(new Date(council.date), DATE_FORMAT_WITH_TIME_ALT)
        : '-',
    },
    {
      title: t('Status'),
      value: CouncilBadges.Status && (
        <CouncilBadges.Status
          t={t}
          style={statusBadge}
          text={statusLabel}
          status={statusValue as keyof typeof councilStatus}
        />
      ),
    },
    {
      title: t('Place'),
      value: council?.place || '-',
    },
    {
      title: t('President'),
      value: (
        <Link to={getDirectorDetailsUrl(presidentData, society)}>
          <MemberAvatar director={presidentData} society={society} />
        </Link>
      ),
    },
    {
      title: t('Secretary'),
      value: (
        <Link to={getDirectorDetailsUrl(secretaryData, society)}>
          <MemberAvatar director={secretaryData} society={society} />
        </Link>
      ),
    },
    {
      title: t('LastModification'),
      value: council?.lastModifiedDate
        ? format(new Date(council.lastModifiedDate), DATE_FORMAT_WITH_TIME_ALT)
        : '-',
    },
  ];

  return (
    <section className="council-member-basic-info-card col mb-5">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title mb-0">{t('BasicData')}</h5>
        </div>
      </div>

      <div className="card rounded-xl">
        <ul className="data-list is-compact">
          {items.map(({ title, value }) => (
            <li className="data-item" key={title}>
              <div className="data-col">
                <div className="data-label">{title}</div>
                <div className="data-value">{value}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default CouncilMembersBasicInfoCard;
