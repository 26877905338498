import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  templateType: string;
  handleCloseModal: () => void;
};

const RequestSignatureModalHeader: FC<Props> = ({
  templateType,
  handleCloseModal,
}: Props) => {
  const { t } = useTranslate();

  return (
    <Modal.Header>
      <h5 className="modal-title">
        <em className="icon icon-circle ni ni-send bg-primary-alt" />
        &nbsp;&nbsp;
        {t('RequestSignature')}&nbsp;
        <span className="text-muted ff-alt fs-14px text-right">
          ({t(templateType)})
        </span>
      </h5>
      <a className="close cursor-pointer" onClick={handleCloseModal}>
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default RequestSignatureModalHeader;
