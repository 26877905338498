import { useEffect, useState } from 'react';
import signatureStatus from 'constants/signatureStatus';
import { EnvelopeSigner } from 'types';

export default function useGetSignatureEnvelopeStatus(
  signedSignersCount: number,
  signers: EnvelopeSigner[]
) {
  const [signatureEnvelopeStatus, setSignatureEnvelopeStatus] =
    useState<string>(signatureStatus.var.pending);

  useEffect(() => {
    if (signedSignersCount === signers.length) {
      if (signedSignersCount === 0) {
        setSignatureEnvelopeStatus('-');
      } else {
        setSignatureEnvelopeStatus(signatureStatus.var.completed);
      }
    } else {
      setSignatureEnvelopeStatus(signatureStatus.var.pending);
    }
  }, [signedSignersCount, signers]);

  return { signatureEnvelopeStatus };
}
