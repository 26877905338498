/* eslint-disable no-undef */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';

const LicensesViewForm: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useAddRealtyModal();

  const [name, setName] = useState<string>('');
  const [date, setDate] = useState<string>('');

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const handleSaveLicense = () => {
    const newLicense = { name, date: new Date(date) };
    const newLicenses = [...data.licenses, newLicense];

    setName('');
    setDate('');

    actions.handleChangeLicenses(newLicenses);
  };

  return (
    <div className="row gy-4">
      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-license-name">
            {t('Name')}
          </label>

          <input
            id="realty-license-name"
            type="text"
            className="form-control form-control-lg"
            value={name}
            onChange={handleChangeName}
            placeholder={t('IntroduceName')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-license-date">
            {t('Date')}
          </label>

          <input
            type="date"
            className="form-control form-control-lg date-picker"
            id="realty-license-date"
            name="realty-license-date"
            placeholder="dd/mm/yyyy"
            value={date}
            onChange={handleChangeDate}
            required
          />
        </div>
      </div>

      <div className="col-12 text-right">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSaveLicense}
          disabled={!name || !date}
        >
          {t('AddLicense')}
        </button>
      </div>
    </div>
  );
};

export default LicensesViewForm;
