/* eslint-disable no-console */
import { store } from 'redux/store';
import { Council, Society } from 'types';
import { getDirectorFromParticipant } from 'modules/administratives/utils/getDirectorFromParticipant';
import { updateCouncil } from 'modules/administratives/redux/councilActions';

const getUpdatedParticipants = (
  currentCouncil: Council,
  actualSociety: Society
) => {
  let hasDifferentEmails = false;

  const updatedParticipants = currentCouncil?.participants?.map(
    (participant) => {
      const currentParticipant = participant;
      const currentDirector = getDirectorFromParticipant(
        participant,
        actualSociety
      );

      if (currentDirector?.email !== currentParticipant.email) {
        hasDifferentEmails = true;
        currentParticipant.email =
          currentDirector?.email || currentParticipant.email;
      }

      return currentParticipant;
    }
  );

  return { updatedParticipants, hasDifferentEmails };
};

export const updateParticipantsEmails = async (
  currentCouncil: Council,
  actualSociety: Society
) => {
  if (!currentCouncil?.participants || !actualSociety) return;

  try {
    const { updatedParticipants, hasDifferentEmails } = getUpdatedParticipants(
      currentCouncil,
      actualSociety
    );

    if (hasDifferentEmails) {
      await store.dispatch(
        updateCouncil({
          ...currentCouncil,
          participants: updatedParticipants,
        })
      );

      return true;
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};
