/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { updateSociety } from 'modules/society/redux/societyActions';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import documentLinkTypes from 'constants/documentLinkTypes';

import UploadDocumentsSection from 'modules/documents/components/UploadDocumentsSection';
import removeAdminDeletedDocuments from 'utils/removeAdminDeletedDocuments';
import uploadAdminDocuments from 'utils/uploadAdminDocuments';
import ImageInput from 'modules/documents/components/ImageInput';
import { store } from 'redux/store';
import CustomLoading from '../../CustomLoading';

function AddSocietyAuditor({
  societyId,
  action,
  auditorData,
  auditorId,
  userId,
}) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [initialDocuments, setInitialDocuments] = useState([]);
  const [socialDenomination, setSocialDenomination] = useState('');
  const [nationality, setNationality] = useState('');
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [files, setFiles] = useState([]);
  const [cif, setCif] = useState('');
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getAuditorDocuments = async (auditorId) => {
    await removeAdminDeletedDocuments(initialDocuments, files);
    const documents = await uploadAdminDocuments(
      files,
      userId,
      auditorId,
      societyId,
      initDate,
      documentLinkTypes.AUDITOR,
      t('FileAssociatedAuditor')
    );
    return documents;
  };

  const handleChangeImage = (event) => {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        const result = e.target?.result || '';
        setImage(result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleUpdateAuditor = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      let newAuditorsArray = [];
      let updatedAuditors = [];

      const newAuditor = {
        socialDenomination,
        cif,
        nationality,
        address,
        email,
        initDate,
        endDate,
        documents: [],
        image,
      };

      const documents = await getAuditorDocuments(auditorId);
      newAuditor.documents = documents;

      updatedAuditors = actualSociety?.auditors.map((auditor) => {
        if (auditor['_id'] === auditorId) {
          return { ...newAuditor };
        }
        return auditor;
      });

      newAuditorsArray =
        action === 'ADD' ? [...updatedAuditors, newAuditor] : updatedAuditors;

      const newSocietyData = {
        auditors: newAuditorsArray,
      };

      await store.dispatch(updateSociety(societyId, newSocietyData, false));

      return Swal.fire({
        icon: 'success',
        title: `<h4 class="nk-modal-title">${
          action === 'ADD'
            ? t('AuditorAddedSuccessfully')
            : t('AuditorUpdatedSuccessfully')
        }<br></h4>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      dispatch(setModal(null));
    }
  };

  const loadInitialValues = () => {
    setSocialDenomination(auditorData.socialDenomination);
    setCif(auditorData.cif);
    setNationality(auditorData.nationality);
    setAddress(auditorData.address);
    setEmail(auditorData.email);
    if (auditorData.initDate)
      setInitDate(format(new Date(auditorData.initDate), 'yyyy-MM-dd'));
    if (auditorData.endDate)
      setEndDate(format(new Date(auditorData.endDate), 'yyyy-MM-dd'));
    if (auditorData.documents) {
      setInitialDocuments(auditorData.documents);
    }
    if (auditorData.image) {
      setImage(auditorData.image);
    }
  };

  useEffect(() => {
    if (action === 'EDIT' && auditorData) {
      loadInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditorData]);

  useEffect(() => {
    if (auditorId && societyId) {
      dispatch(
        getDocuments({ auditor: auditorId, society: societyId }, setFiles)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditorId, societyId]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === 'ADD' ? `${t('Add')} ` : `${t('Edit')} `}
          {t('Auditor')}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <form className="form-validate is-alter" onSubmit={handleUpdateAuditor}>
        <Modal.Body>
          <div className="row gy-4">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyAuditor-socialDenom"
                >
                  {t('NameAndSurnameSociety')} *
                </label>
                <input
                  type="text"
                  id="societyAuditor-socialDenom"
                  className="form-control form-control-lg"
                  value={socialDenomination}
                  onChange={(event) =>
                    setSocialDenomination(event.target.value)
                  }
                  placeholder={t('SocialDenomination')}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyAuditor-email">
                  {t('Email')} *
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="societyAuditor-email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder={t('Email')}
                  required
                  pattern={EMAIL_PATTERN}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyAuditor-initDate">
                  {t('AppointmentDate')}
                </label>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyAuditor-initDate"
                  value={initDate}
                  onChange={(event) => setInitDate(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="d-flex">
                  <label
                    className="form-label"
                    htmlFor="societyAuditor-endDate"
                  >
                    {t('EndDate')}
                  </label>
                  <span className="sub-text ml-1 fs-12px">
                    {t('IndefiniteDefault')}
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyAuditor-endDate"
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyAuditor-cif">
                  {t('Cif')}
                </label>
                <input
                  type="text"
                  id="societyAuditor-cif"
                  className="form-control form-control-lg"
                  value={cif}
                  onChange={(event) => setCif(event.target.value)}
                  placeholder={t('Cif')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyAuditor-nationality"
                >
                  {t('Nationality')}
                </label>
                <input
                  type="text"
                  id="societyAuditor-nationality"
                  className="form-control form-control-lg"
                  value={nationality}
                  onChange={(event) => setNationality(event.target.value)}
                  placeholder={t('Nationality')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyAuditor-address">
                  {t('Address')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="societyAuditor-address"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  placeholder={t('Address')}
                />
              </div>
            </div>

            <div className="col-12">
              <UploadDocumentsSection
                initialDocumentsCount={auditorData?.documents?.length || 0}
                documents={files}
                setDocuments={setFiles}
              />
            </div>

            <ImageInput
              value={image}
              onChange={handleChangeImage}
              name={socialDenomination}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={!socialDenomination || !email}
              >
                {t('Save')}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-light ml-3"
                onClick={() => dispatch(setModal(null))}
              >
                {t('ToClose')}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>

      {isLoading && <CustomLoading />}
    </>
  );
}

export default AddSocietyAuditor;
