import { Council } from 'types';

type CouncilsByStatus = {
  preparingCouncils: number;
  activeCouncils: number;
  celebratingCouncils: number;
  celebratedCouncils: number;
  signPendingCouncils: number;
  closedCouncils: number;
  canceledCouncils: number;
};

const statuses = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  CELEBRATING: 'CELEBRATING',
  CELEBRATED: 'CELEBRATED',
  SIGNATURE_PENDING: 'SIGNATURE_PENDING',
  CLOSED: 'CLOSED',
  CANCELED: 'CANCELED',
};

export const getCouncilsByStatus = (councils: Council[]) => {
  const councilsByStatus: CouncilsByStatus = {
    preparingCouncils: 0,
    activeCouncils: 0,
    celebratingCouncils: 0,
    celebratedCouncils: 0,
    signPendingCouncils: 0,
    closedCouncils: 0,
    canceledCouncils: 0,
  };

  councils.forEach((council) => {
    switch (council.status) {
      case statuses.PENDING:
        councilsByStatus.preparingCouncils += 1;
        break;
      case statuses.ACTIVE:
        councilsByStatus.activeCouncils += 1;
        break;
      case statuses.CELEBRATING:
        councilsByStatus.celebratingCouncils += 1;
        break;
      case statuses.CELEBRATED:
        councilsByStatus.celebratedCouncils += 1;
        break;
      case statuses.SIGNATURE_PENDING:
        councilsByStatus.signPendingCouncils += 1;
        break;
      case statuses.CLOSED:
        councilsByStatus.closedCouncils += 1;
        break;
      case statuses.CANCELED:
        councilsByStatus.canceledCouncils += 1;
        break;

      default:
        break;
    }
  });

  return councilsByStatus;
};
