import { useEffect, useState } from 'react';
import { SocietyAuditor } from 'types';
import { getSocietyAuditorStatusData } from '../utils/getSocietyAuditorStatusData';

type Props = {
  auditor: SocietyAuditor;
};

export default function useGetSelectedValues({ auditor }: Props) {
  const [auditorStatusLabel, setAuditorStatusLabel] = useState<string>('');
  const [auditorStatusBadge, setAuditorStatusBadge] = useState<string>('');

  useEffect(() => {
    const { statusLabel, statusBadge } = getSocietyAuditorStatusData(auditor);
    setAuditorStatusLabel(statusLabel);
    setAuditorStatusBadge(statusBadge);
  }, [auditor]);

  return {
    data: {
      auditorStatusLabel,
      auditorStatusBadge,
    },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
