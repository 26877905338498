import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import sizes from 'constants/sizes';
import Button from 'modules/_shared/components/Buttons';
import GlobalSearch from 'modules/_shared/components/Modals/GlobalSearch';
import { setModal } from 'modules/_shared/redux/modalActions';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import AddCommitteeModal from './AddCommitteeModal';

type Props = {
  societyId: string | undefined;
  societyName: string;
  committeesCount: number;
};

const CommitteesPageHeader: FC<Props> = ({
  societyId,
  societyName,
  committeesCount,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleAddCommittee = () => {
    if (societyId) {
      dispatch(setModal(<AddCommitteeModal societyId={societyId} />));
    }
  };

  const handleOpenGlobalSearch = () => {
    dispatch(setModal(<GlobalSearch size={sizes.XL} />));
  };

  return (
    <>
      <PageListHeader
        title={t('Committees')}
        subTitle={societyName}
        description={`${societyName || ''} ${t('HaveCommittees', {
          count: committeesCount,
        })}`}
        actions={[
          <Button
            id="open-global-search-button"
            icon="ni ni-search"
            variant="light"
            onClick={handleOpenGlobalSearch}
          />,
          <Button
            icon="ni ni-plus"
            variant="primary"
            onClick={handleAddCommittee}
          >
            {t('AddCommittee')}
          </Button>,
        ]}
      />

      <Tooltip
        anchorSelect="#open-global-search-button"
        content={t('GlobalSearch')}
      />
    </>
  );
};

export default CommitteesPageHeader;
