import { Council } from 'types';

export const getCouncilDetailsUrl = (council: Council): string =>
  `/detalle-consejo/${council.society}/${council._id}`;

export const getCouncilVoteUrl = (council: Council, memberId: string): string =>
  `/voto-consejo/${council.society}/${council._id}/${memberId}`;

export const getCouncilDetailsUrlByRole = (
  council: Council,
  isAdmin: boolean,
  isDirector: boolean,
  directorId: string
): string => {
  if (isAdmin) {
    return getCouncilDetailsUrl(council);
  }

  if (isDirector) {
    return getCouncilVoteUrl(council, directorId);
  }

  return '';
};
