import operationTypes from 'constants/operationTypes';
import { Society } from 'types';
import { getFilteredOperations } from '../../../utils/getFilteredOperations';

export const getNextAndPreviousOperations = (
  society: Society,
  operationId: string | undefined
): {
  nextOperation: string | null;
  previousOperation: string | null;
} => {
  if (!society.operations || !operationId) {
    return { nextOperation: null, previousOperation: null };
  }

  const filteredTransactions = getFilteredOperations(society);

  const currentIndex = filteredTransactions.findIndex(
    (operation) => operation['_id'] === operationId
  );

  const nextOperation =
    currentIndex === 0
      ? null
      : filteredTransactions[currentIndex - 1]._id || null;

  const previousOperation =
    currentIndex === filteredTransactions.length - 1
      ? null
      : filteredTransactions[currentIndex + 1]._id || null;

  return {
    nextOperation,
    previousOperation,
  };
};
