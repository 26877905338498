import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { axiosV1 } from 'interceptors/axios';

export const getDashboardData =
  (societyId: string, view: string) => async (dispatch: any) => {
    try {
      const { data } = await axiosV1.get(
        `/societyDashboard/${view}/${societyId}`
      );

      const decodedData = await decryptResponse(data);

      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };
