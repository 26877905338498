import { Council, CouncilParticipant, Society } from 'types';
import { isParticipantAllowedForAnnouncement } from './isParticipantAllowedForAnnouncement';

export const getTotalParticipantsAllowed = (
  council: Council,
  society: Society
): number =>
  council?.participants?.filter((participant: CouncilParticipant) =>
    isParticipantAllowedForAnnouncement(participant, society)
  )?.length || 0;
