/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import { setPreview } from 'modules/_shared/redux/previewActions';

import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import MenuDots from 'modules/_shared/components/MenuDots';
import AddBoard from 'modules/_shared/components/Modals/AddBoard';
import AnnouncementTemplateViewer, {
  AnnouncementType,
} from 'modules/_shared/components/Modals/AnnouncementTemplateViewer';
import EditAnnouncementModal from 'modules/_shared/components/Modals/EditAnnouncement';
import TableLoader from 'modules/_shared/components/Tables/TableLoader';
import BoardAnnouncementPreview from 'modules/boards/components/BoardAnnouncementPreview';
import menuOptions from 'modules/boards/components/BoardDocuments/menuOptions';
import DocumentsGenerator from 'modules/documents/components/Creators/DocumentsGenerator';

import actionTypes from 'constants/actionTypes';
import attendanceTypes from 'constants/attendanceTypes';
import boardDocumentSections from 'constants/boardDocumentSections';
import boardTypes from 'constants/boardTypes';
import documentTypes from 'constants/documentTypes';
import { formatDateToTime } from 'constants/formats';
import sizes from 'constants/sizes';
import votePeriods from 'constants/votePeriods';
import voteTypes from 'constants/voteTypes';
import { getDocuments } from 'modules/documents/redux/documentActions';

import downloadDocument, { getSingleDocument } from 'utils/downloadDocument';

import DocumentModal from 'modules/documents/pages/Documents/documentModal';

import { getDocumentIcon } from 'utils/documentIcons';
import { useGetBoardsSectionPermissions } from 'modules/boards/hooks/useGetBoardsSectionPermissions';
import { updateBoard } from 'modules/boards/redux/boardActions';
import useGetBoardDocuments from './hooks/useGetBoardAnnouncementDocuments';

const BoardAnnouncement = ({ board }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const printRef = useRef();

  const { isReadOnly } = useGetBoardsSectionPermissions().data;

  const { user, actualSociety, societyDocuments, preview } = useSelector(
    (state) => ({
      user: state?.user,
      actualSociety: state.society?.actualSociety,
      societyDocuments: state.documents,
      preview: state.preview,
    })
  );

  const {
    date,
    place,
    announcement,
    announcementComm,
    attachedDocuments,
    attendanceType,
    votePeriod,
    boardType,
  } = board;

  const [announcementUrl, setAnnouncementUrl] = useState();
  const [isTemplate, setIsTemplate] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [boardDocs, setBoardDocs] = useState([]);
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const { announcementDocument, announcementTemplate } =
    useGetBoardDocuments(board);

  const [printOption, setPrintOption] = useState();

  const handleEditBoard = () => {
    if (isReadOnly) return;
    dispatch(setModal(<AddBoard action={actionTypes.EDIT} board={board} />));
  };

  const handleReplaceAnnouncementDocument = () => {
    if (isReadOnly) return;
    dispatch(
      setModal(
        <DocumentModal
          userId={user['_id']}
          societyId={actualSociety['_id']}
          boardId={board['_id']}
          boardSection={boardDocumentSections.ANNOUNCEMENT}
        />
      )
    );
  };

  const handleAddBoardDocument = () => {
    if (isReadOnly) return;
    dispatch(
      setModal(
        <DocumentModal
          userId={user['_id']}
          societyId={actualSociety['_id']}
          boardId={board['_id']}
        />
      )
    );
  };

  const generateAnnouncementTemplatePreview = async () => {
    try {
      const documentUpdated = updateDocumentWithValues(
        announcementTemplate?.editorModel,
        tags({
          society: actualSociety,
          board,
        })
      );
      const dataToDocument = (
        <DocumentsGenerator
          invitationModel={documentUpdated?.html}
          hasConsolidationCalendar={false}
          consolidationCalendar={{}}
        />
      );

      await store.dispatch(
        setPreview({
          name: announcementTemplate.name,
          bdDocument: announcementTemplate,
          document: dataToDocument,
          documentModel: documentUpdated?.html,
          template: announcementTemplate?.['_id'],
        })
      );
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  };

  const fetchDocument = async (docId) => {
    try {
      const fileBlob = await getSingleDocument(docId);
      const pdfUrl = URL.createObjectURL(fileBlob);
      setAnnouncementUrl(pdfUrl);
      setIsLoadingData(false);
    } catch (error) {
      console.log('Error fetching data:', error); // eslint-disable-line
    }
  };

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocuments({ society: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (societyDocuments.length) {
      const docs = societyDocuments.filter(
        (doc) =>
          doc?.category !== documentTypes.DELETED &&
          attachedDocuments?.includes(doc['_id'])
      );
      setBoardDocs(docs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyDocuments]);

  useEffect(() => {
    if (announcementDocument) {
      setPrintOption({
        icon: 'ni-printer',
        text: i18n.t('Print'),
        print: true,
        content: printRef.current,
        documentTitle: document?.name,
        disabled: false,
      });
      if (announcementDocument.subcategory === documentTypes.BOARDS) {
        fetchDocument(announcementDocument['_id']);
        setIsTemplate(false);
      } else {
        setIsTemplate(true);
      }
    } else setIsTemplate(true);
  }, [announcementDocument]);

  useEffect(() => {
    if (isTemplate && actualSociety && announcementTemplate) {
      generateAnnouncementTemplatePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementTemplate, isTemplate]);

  useEffect(() => {
    if (preview) {
      setIsLoadingData(false);
    }
  }, [preview]);

  return (
    <>
      <div className="nk-block pt-4">
        <div className="row">
          <div className="col-xl-8">
            <div className="card card-bordered">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="row sp-plan-head">
                    <h6 className="title col-6">{t('Information')}</h6>
                    {!isReadOnly && (
                      <div className="col-6 text-right">
                        <button
                          type="button"
                          className="link text-primary"
                          onClick={handleEditBoard}
                        >
                          <span className="title">{t('Edit')}</span>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="sp-plan-desc sp-plan-desc-mb">
                    <ul className="row gx-1">
                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="d-block text-soft fw-normal">
                            {t('Date')}
                          </span>{' '}
                          {format(new Date(date), 'dd/MM/yyyy')}
                        </p>
                      </li>
                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="d-block text-soft fw-normal">
                            {t('StartTime')}
                          </span>{' '}
                          {formatDateToTime(date)}
                        </p>
                      </li>

                      {attendanceType === attendanceTypes.FACE_TO_FACE.value ||
                      attendanceType === attendanceTypes.HYBRID.value ? (
                        <li className="col-6 col-lg-4">
                          <span className="d-block text-soft fw-normal">
                            {t('PlaceOfCelebration')}
                          </span>{' '}
                          <div
                            id="PlaceOfCelebration"
                            className="w-150px d-block text-ellipsis"
                          >
                            {place || '-'}
                          </div>
                          <Tooltip
                            anchorId="PlaceOfCelebration"
                            place="bottom"
                            style={{
                              zIndex: 9999,
                            }}
                          >
                            {place}
                          </Tooltip>
                        </li>
                      ) : (
                        <></>
                      )}
                      <li className="col-6 col-lg-4">
                        <span className="d-block text-soft fw-normal">
                          {t('AssistanceForm')}
                        </span>{' '}
                        <div
                          id="AssistanceForm"
                          className="w-150px d-block text-ellipsis"
                        >
                          {attendanceTypes?.[attendanceType]?.text || '-'}
                        </div>
                        <Tooltip
                          anchorId="AssistanceForm"
                          place="bottom"
                          style={{
                            zIndex: 9999,
                          }}
                        >
                          {attendanceTypes?.[attendanceType]?.text}
                        </Tooltip>
                      </li>
                      <li className="col-6 col-lg-4">
                        <span className="d-block text-soft fw-normal">
                          {t('VotingPeriod')}
                        </span>{' '}
                        <div
                          id="VotingPeriod"
                          className="w-150px d-block text-ellipsis"
                        >
                          {votePeriods.find(
                            (period) => period.value === votePeriod
                          )?.text || '-'}
                        </div>
                        <Tooltip
                          anchorId="VotingPeriod"
                          place="bottom"
                          style={{
                            zIndex: 9999,
                          }}
                        >
                          {
                            votePeriods.find(
                              (period) => period.value === votePeriod
                            )?.text
                          }
                        </Tooltip>
                      </li>
                      <li className="col-6 col-lg-4">
                        <span className="d-block text-soft fw-normal">
                          {t('BoardType')}
                        </span>{' '}
                        {boardTypes?.[boardType]?.text || '-'}
                      </li>
                    </ul>
                  </div>
                </div>

                {board.orders.length ? (
                  <div className="card-inner">
                    <div className="row sp-plan-head">
                      <h6 className="title col-6">{t('Subjects')}</h6>
                      {!isReadOnly && (
                        <div className="col-6 text-right">
                          <button
                            type="button"
                            className="link text-primary"
                            onClick={handleEditBoard}
                          >
                            <span className="title">{t('Edit')}</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <br />
                    {board.orders.map((order, index) => (
                      <div
                        className=" row sp-plan-head-group mb-1"
                        key={order._id}
                      >
                        <div className="col-md-9 nk-file-name">
                          <div className="nk-file-name-text">
                            <div className="row">
                              <div className="col-md-12">
                                <span
                                  id={`subject${index + 1}`}
                                  className="title pb-3"
                                >
                                  {`${index + 1}. ${order.subject}`}
                                </span>
                              </div>
                              <div className="col-md-12">
                                <span className="title font-italic text-soft">
                                  {order?.description || ''}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 text-right nk-file-name">
                          <div className="nk-file-name-text pr-0">
                            <span className="title">
                              {voteTypes?.[order.voteType]?.text || '-'}
                            </span>
                          </div>
                        </div>

                        {index < board.orders.length - 1 && (
                          <hr className="w-100 border-top my-2 mx-2" />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}

                <div className="card-inner">
                  <div className="sp-plan-head-group">
                    <div className="row sp-plan-head mb-2">
                      <h6 className="col-md-6 title">{t('Announcement')}</h6>

                      <div className="col-md-6">
                        {!isReadOnly && (
                          <ul className="nk-block-tools justify-content-md-end flex-wrap g-2">
                            <li>
                              <button
                                type="button"
                                className="link text-primary"
                                onClick={handleReplaceAnnouncementDocument}
                              >
                                <span className="title">{t('Replace')}</span>
                              </button>
                            </li>
                          </ul>
                        )}
                      </div>

                      <span className="col-12 ff-regular text-soft">
                        {t('AnnouncementText')}
                      </span>
                    </div>
                  </div>
                  <div className="nk-files nk-files-view-list">
                    <div className="nk-files-head">
                      <div className="nk-file-item">
                        <div className="nk-file-info">
                          <span> {t('Name')}</span>
                        </div>
                        <div className="nk-file-meta">
                          <span> {t('Date')}</span>
                        </div>
                        <div className="nk-file-members">
                          <span> {t('Link')}</span>
                        </div>
                        <div className="nk-file-members" />
                      </div>
                    </div>
                    <div className="nk-files-list">
                      {announcementDocument ? (
                        <div className="nk-file-item nk-file">
                          <div className="nk-file-info">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <span className="nk-file-icon-type">
                                  <img
                                    src={getDocumentIcon(announcementDocument)}
                                    alt="icon-file"
                                  />
                                </span>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">
                                    {announcementDocument?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="nk-file-meta">
                            <div className="tb-lead">
                              {announcementDocument?.date
                                ? format(
                                    new Date(announcementDocument?.date),
                                    'dd/MM/yy'
                                  )
                                : '-'}
                            </div>
                          </div>
                          <div className="nk-file-members">
                            <span
                              className="btn btn-icon btn-trigger"
                              onClick={async () =>
                                downloadDocument({
                                  documentId: announcementDocument?.['_id'],
                                  documentName: announcementDocument?.name,
                                })
                              }
                            >
                              <em className="icon ni ni-file-download" />
                            </span>
                          </div>
                          <div className="nk-file-members">
                            <MenuDots
                              menuOptions={currentMenuOptions.map((option) =>
                                option.index === 3
                                  ? { ...option, disabled: true }
                                  : option
                              )}
                              id={announcementDocument?.['_id']}
                              params={{
                                documentId: announcementDocument?.['_id'],
                                documentName: announcementDocument?.name,
                                document: announcementDocument,
                                society: actualSociety,
                                user,
                                boardId: board['_id'],
                              }}
                              direction="left"
                              disabled={isReadOnly}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="card-inner">
                  <div className="sp-plan-head-group">
                    <div className="row sp-plan-head mb-2">
                      <h6 className="col-md-6 title">
                        {t('DocumentsForPartners')}
                      </h6>

                      <div className="col-md-6">
                        {!isReadOnly && (
                          <ul className="nk-block-tools justify-content-md-end flex-wrap g-2">
                            <li>
                              <button
                                type="button"
                                className="link text-primary"
                                onClick={handleAddBoardDocument}
                              >
                                <span className="title">{t('Add')}</span>
                              </button>
                            </li>
                          </ul>
                        )}
                      </div>

                      <span className="col-12 ff-regular text-soft">
                        {t('DocumentsForPartnersText')}
                      </span>
                    </div>
                  </div>
                  <div className="nk-files nk-files-view-list">
                    <div className="nk-files-head">
                      <div className="nk-file-item">
                        <div className="nk-file-info">
                          <span> {t('Name')}</span>
                        </div>
                        <div className="nk-file-meta">
                          <span> {t('Date')}</span>
                        </div>
                        <div className="nk-file-members">
                          <span> {t('Link')}</span>
                        </div>
                        <div className="nk-file-members" />
                      </div>
                    </div>
                    <div className="nk-files-list">
                      {boardDocs.map((doc) => (
                        <div className="nk-file-item nk-file">
                          <div className="nk-file-info">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <span className="nk-file-icon-type">
                                  <img
                                    src={getDocumentIcon(doc)}
                                    alt="icon-file"
                                  />
                                </span>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">{doc?.name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="nk-file-meta">
                            <div className="tb-lead">
                              {doc.date
                                ? format(new Date(doc?.date), 'dd/MM/yy')
                                : '-'}
                            </div>
                          </div>
                          <div className="nk-file-members">
                            <span
                              className="btn btn-icon btn-trigger"
                              onClick={async () =>
                                downloadDocument({
                                  documentId: doc?.['_id'],
                                  documentName: doc?.name,
                                })
                              }
                            >
                              <em className="icon ni ni-file-download" />
                            </span>
                          </div>

                          <div className="nk-file-members">
                            <MenuDots
                              menuOptions={currentMenuOptions.filter(
                                (option) => option.index !== 1
                              )}
                              id={doc?.['_id']}
                              params={{
                                documentId: doc?.['_id'],
                                documentName: doc?.name,
                                document: doc,
                                society: actualSociety,
                                user,
                              }}
                              direction="left"
                              disabled={isReadOnly}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card card-bordered card-top d-none d-xl-block">
              <div className="nk-help-plain card-inner text-left">
                <div className="sp-plan-action card-inner">
                  <div className="card-title-group">
                    <div className="sp-plan-head">
                      <h6 className="title">
                        {t('SendState')}:{' '}
                        <span
                          className={`badge rounded-pill ${
                            announcementComm
                              ? 'bg-outline-success'
                              : 'bg-outline-warning'
                          }`}
                        >
                          {announcementComm ? t('NOTIFIED') : t('Pending')}
                        </span>{' '}
                      </h6>
                      <button
                        type="button"
                        className="link text-primary mb-2"
                        style={{ paddingLeft: 0 }}
                        onClick={() =>
                          dispatch(
                            setModal(
                              <AnnouncementTemplateViewer
                                meeting={board}
                                announcementType={AnnouncementType.BOARD}
                                size={sizes.XL}
                              />
                            )
                          )
                        }
                      >
                        {t('ViewComm')}
                      </button>
                      <em className="icon ni ni-external" />
                      {announcementComm && (
                        <>
                          <Link
                            to={`/comunicaciones/${actualSociety['_id']}/${announcementComm}/certificacion`}
                            className={`${!announcementComm && 'is-disable'}`}
                          >
                            <span className="link text-primary">
                              {t('ViewSendCertificate')}
                            </span>{' '}
                          </Link>
                          <em className="icon ni ni-external" />
                        </>
                      )}

                      <h6 className="title" style={{ marginTop: '10px' }}>
                        {t('TextComm')}:{' '}
                      </h6>
                      <span
                        className="ff-regular text-soft d-block w-200px"
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {announcement}
                      </span>
                      {!announcementComm && !isReadOnly && (
                        <div className="sp-plan">
                          <button
                            type="button"
                            className="link text-primary"
                            style={{ paddingLeft: 0 }}
                            onClick={() =>
                              dispatch(
                                setModal(
                                  <EditAnnouncementModal
                                    description={t(
                                      'CommunicationTextDescription'
                                    )}
                                    source={board}
                                    onSave={(announcement) =>
                                      dispatch(
                                        updateBoard(board['_id'], {
                                          announcement,
                                        })
                                      )
                                    }
                                  />
                                )
                              )
                            }
                            disabled={isReadOnly}
                          >
                            <span className="title">{t('Edit')}</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      {!isLoadingData && isTemplate && announcementTemplate && preview ? (
        <div className="nk-block" id="announcement-box">
          <BoardAnnouncementPreview board={board} />
        </div>
      ) : (
        isLoadingData && (
          <div className="nk-block">
            <TableLoader rows={10} />
          </div>
        )
      )}
      {!isLoadingData && !isTemplate && announcementUrl ? (
        <div className="nk-block">
          <div className="row">
            <div className="col-12">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between position-relative">
                  <div className="nk-block-head-content">
                    <h5 className="title nk-block-title">
                      {t('Announcement')}:
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card card-bordered">
                <div className="card-inner p-0">
                  <iframe
                    src={announcementUrl}
                    title={announcementDocument?.name || 'PDF Viewer'}
                    width="100%"
                    height="600px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        isLoadingData && (
          <div className="nk-block">
            <TableLoader rows={10} />
          </div>
        )
      )}
    </>
  );
};

export default BoardAnnouncement;
