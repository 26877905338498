/* eslint-disable import/imports-first */
import { axiosV1 } from 'interceptors/axios';

import operationTypes from 'constants/operationTypes';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { addAlert } from './alertActions';
import { createBoard } from '../../boards/redux/boardActions';
import { createStockPlan } from '../../beneficiaries/redux/planActions';
import { reSetSociety } from '../../society/redux/societyActions';
import { createTenderOffer } from '../../transactions/redux/tenderOfferActions';
import { createTenderOfferShares } from '../../transactions/redux/tenderOffersSharesActions';

async function modalCall(
  body,
  dispatch,
  showAlert = true,
  updateOperation = false
) {
  if (updateOperation) {
    try {
      const { data } = await axiosV1.put(`/operations`, body);
      const decodedData = await decryptResponse(data);
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  } else {
    const { operationType } = body;
    let route = '';
    switch (operationType) {
      case operationTypes.ADD_TENDER_OFFER:
        route = 'tenderOffers';
        break;

      case operationTypes.ADD_TENDER_OFFER_SHARES:
        route = 'tenderOffersShares';
        break;

      case operationTypes.ADD_EMPLOYEES_PLAN:
        route = 'employeesPlans';
        break;

      case operationTypes.ADD_BOARD:
        route = 'boards';
        break;

      case operationTypes.STOCK_OPTION:
      case operationTypes.PHANTOM_SHARE:
      case operationTypes.WARRANT:
        route = 'stockPlans';
        break;

      default:
        route = 'operations';
        break;
    }

    try {
      const { data } = await axiosV1.post(`/${route}`, body);
      const decodedData = await decryptResponse(data);
      switch (operationType) {
        case operationTypes.ADD_TENDER_OFFER:
          dispatch(createTenderOffer(decodedData));
          break;

        case operationTypes.ADD_TENDER_OFFER_SHARES:
          dispatch(createTenderOfferShares(decodedData));
          break;

        case operationTypes.ADD_BOARD:
          dispatch(createBoard(decodedData));
          break;

        case operationTypes.STOCK_OPTION:
        case operationTypes.PHANTOM_SHARE:
        case operationTypes.WARRANT:
          dispatch(createStockPlan(decodedData));
          break;

        default:
          dispatch(reSetSociety(decodedData));
          break;
      }

      // TODO* Remove this condition when AddBeneficiaries op is ready
      if (
        operationType !== operationTypes.ADD_BENEFICIARY &&
        operationType !== operationTypes.ADD_INVESTOR &&
        showAlert
      ) {
        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  }
}

export const updateOperation =
  (operationId, operationData) => async (dispatch) => {
    const body = { _id: operationId, ...operationData };
    try {
      await modalCall(body, dispatch, false, true);
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_ADDING_INVESTORS));
    }
  };

export const addInvestor = (investorData) => async (dispatch) => {
  const operationType = operationTypes.ADD_INVESTOR;
  const body = { ...investorData, operationType };
  try {
    await modalCall(body, dispatch);
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.ERROR_ADDING_INVESTORS));
  }
};

export const addPartner =
  (partnerData, showAlert = true) =>
  async (dispatch) => {
    const operationType = operationTypes.ADD_PARTNER;
    const body = { ...partnerData, operationType };
    try {
      const response = await modalCall(body, dispatch, showAlert);
      return response;
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.DUPLICATED_EMAIL));
    }
  };

export const addPartners = (partnersData) => async (dispatch) => {
  const operationType = operationTypes.ADD_PARTNERS;
  const body = { partnersData, operationType };
  try {
    const response = await modalCall(body, dispatch);
    return response?.partners;
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.DUPLICATED_EMAIL));
  }
};

export const addBeneficiary = (partnerData) => async (dispatch) => {
  const operationType = operationTypes.ADD_BENEFICIARY;
  const body = { ...partnerData, operationType };
  try {
    const response = await modalCall(body, dispatch);
    return response?.beneficiaries?.slice(-1)[0];
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.DUPLICATED_EMAIL));
  }
};

export const addBeneficiaryDraft = (partnerData) => async (dispatch) => {
  const operationType = operationTypes.ADD_BENEFICIARY_DRAFT;
  const body = { ...partnerData, operationType };
  try {
    await modalCall(body, dispatch);
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.DUPLICATED_EMAIL));
  }
};

export const constitution =
  (constitutionData, showAlert = true) =>
  async (dispatch) => {
    const operationType = operationTypes.CONSTITUTION;
    const body = { ...constitutionData, operationType };
    try {
      await modalCall(body, dispatch, showAlert);
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const capitalIncreaseAction = (increaseData) => async (dispatch) => {
  const operationType = operationTypes.CAPITAL_INCREASE;
  const body = { ...increaseData, operationType };
  try {
    const data = await modalCall(body, dispatch);
    return data;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const capitalDecreaseAction = (decreaseData) => async (dispatch) => {
  const operationType = operationTypes.CAPITAL_DECREASE;
  const body = { ...decreaseData, operationType };
  try {
    await modalCall(body, dispatch);
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const heritageAction =
  (sellData, showAlert = true) =>
  async (dispatch) => {
    const operationType = operationTypes.HERITAGE;
    const body = { ...sellData, operationType };
    try {
      const data = await modalCall(body, dispatch, showAlert);
      return data;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const donationAction =
  (sellData, showAlert = true) =>
  async (dispatch) => {
    const operationType = operationTypes.DONATION;
    const body = { ...sellData, operationType };
    try {
      const data = await modalCall(body, dispatch, showAlert);
      return data;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const sellParticipationsAction =
  (sellData, showAlert = true) =>
  async (dispatch) => {
    const operationType = operationTypes.SELL_PARTICIPATION;
    const body = { ...sellData, operationType };
    try {
      const data = await modalCall(body, dispatch, showAlert);
      return data;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const stockOptionAction = (stockData) => async (dispatch) => {
  const operationType = operationTypes.STOCK_OPTION;
  const body = { ...stockData, operationType };
  try {
    const data = await modalCall(body, dispatch);
    return data;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const phantomSharesAction = (phantomData) => async (dispatch) => {
  const operationType = operationTypes.PHANTOM_SHARE;
  const body = { ...phantomData, operationType };
  try {
    const data = await modalCall(body, dispatch);
    return data;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const warrantAction = (warrantData) => async (dispatch) => {
  const operationType = operationTypes.WARRANT;
  const body = { ...warrantData, operationType };
  try {
    const data = await modalCall(body, dispatch);
    return data;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const splitAction = (splitData) => async (dispatch) => {
  const operationType = operationTypes.SPLIT;
  const body = { ...splitData, operationType };
  try {
    await modalCall(body, dispatch);
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const renumerationAction = (renumData) => async (dispatch) => {
  const operationType = operationTypes.RENUMERATION;
  const body = { ...renumData, operationType };
  try {
    await modalCall(body, dispatch);
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const dividendsDistributionAction =
  (dividendsData) => async (dispatch) => {
    const operationType = operationTypes.DIVIDENDS_DISTRIBUTION;
    const body = { ...dividendsData, operationType };
    try {
      await modalCall(body, dispatch);
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const reclassificationAction = (data) => async (dispatch) => {
  const operationType = operationTypes.RECLASSIFICATION;
  const body = { ...data, operationType };
  try {
    await modalCall(body, dispatch);
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const rollbackAction =
  (rollbackData, showAlert = true) =>
  async (dispatch) => {
    const operationType = operationTypes.ROLLBACK;
    const body = { ...rollbackData, operationType };
    try {
      await modalCall(body, dispatch, showAlert);
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const tenderOfferAction = (tenderOfferData) => async (dispatch) => {
  const operationType = operationTypes.ADD_TENDER_OFFER;
  const body = { ...tenderOfferData, operationType };
  try {
    await modalCall(body, dispatch);
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const tenderOfferSharesAction =
  (tenderOfferSharesData, showAlert) => async (dispatch) => {
    const operationType = operationTypes.ADD_TENDER_OFFER_SHARES;
    const body = { ...tenderOfferSharesData, operationType };
    try {
      await modalCall(body, dispatch, showAlert);
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const boardAction =
  (boardData, showAlert = true) =>
  async (dispatch) => {
    const operationType = operationTypes.ADD_BOARD;
    const body = { ...boardData, operationType };
    try {
      const data = await modalCall(body, dispatch, showAlert);
      return data;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const employeesPlanAction = (employeesPlanData) => async (dispatch) => {
  const operationType = operationTypes.ADD_EMPLOYEES_PLAN;
  const body = { ...employeesPlanData, operationType };
  try {
    await modalCall(body, dispatch);
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};
