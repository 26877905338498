export default [
  { value: 'UNIQUE_ADMIN', label: 'UniqueAdmin' },
  { value: 'POOLED_ADMIN', label: 'JointAdmin' },
  { value: 'COUNSELOR', label: 'Adviser' },
  { value: 'DELEGATE_COUNSELOR', label: 'CEO' },
  { value: 'COUNCIL_SECRETARY', label: 'BoardSecretary' },
  { value: 'INDEPENDENT_COUNSELOR', label: 'IndependentDirector' },
  { value: 'NON_EXECUTIVE_COUNSELOR', label: 'NonExecutiveCounselor' },
  { value: 'JOINT_DELEGATE_COUNSELOR', label: 'JointDelegateCounselor' },
  { value: 'COUNCIL_PRESIDENT', label: 'PresidentCouncil' },
  { value: 'NON_COUNSELOR_VOWEL', label: 'NonDirectorMember' },
  { value: 'SOLIDARY_ADMIN', label: 'SolidaryAdmin' },
  { value: 'SECRETARY_NO_DIRECTOR', label: 'SecretaryNoDirector' },
  { value: 'COUNCIL_VICE_PRESIDENT', label: 'VicePresidentCouncil' },
  {
    value: 'NON_DIRECTOR_DEPUTY_SECRETARY',
    label: 'NonDirectorDeputySecretary',
  },
  { value: 'AUDITOR', label: 'Auditor' },
  { value: 'TREASURER', label: 'Treasurer' },
];
