import { useEffect, useState } from 'react';
import { SocietyDirector } from 'types';

import { getAdminMemberTypeLabel } from '../utils/getAdminMemberTypeLabel';
import { getAdminMemberStatusData } from '../utils/getAdminMemberStatusData';

type Props = {
  member: SocietyDirector;
};

export default function useGetSelectedValues({ member }: Props) {
  const [memberStatusLabel, setMemberStatusLabel] = useState<string>('');
  const [memberStatusBadge, setMemberStatusBadge] = useState<string>('');
  const [memberTypeLabel, setMemberTypeLabel] = useState<string>('');

  useEffect(() => {
    const { statusLabel, statusBadge } = getAdminMemberStatusData(member);
    setMemberStatusLabel(statusLabel);
    setMemberStatusBadge(statusBadge);

    const memberTypeLabel = getAdminMemberTypeLabel(member?.directorType);
    setMemberTypeLabel(memberTypeLabel);
  }, [member]);

  return {
    data: { memberStatusLabel, memberStatusBadge, memberTypeLabel },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
