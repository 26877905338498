export default [
  {
    value: 'PENDING',
    label: 'CouncilStatus_InPreparation',
    badge: 'info',
    icon: 'ni ni-clock',
  },
  {
    value: 'ACTIVE',
    label: 'CouncilStatus_OnGoing',
    badge: 'primary',
    icon: 'ni ni-check-circle',
  },
  {
    value: 'CELEBRATING',
    label: 'CouncilStatus_Celebrating',
    badge: 'primary',
    icon: 'ni ni-map-pin',
  },
  {
    value: 'CELEBRATED',
    label: 'CouncilStatus_Celebrated',
    badge: 'success',
    icon: 'ni ni-calendar-check',
  },
  {
    value: 'SIGNATURE_PENDING',
    label: 'CouncilStatus_SignaturePending',
    badge: 'danger',
    icon: 'ni ni-pen',
  },
  {
    value: 'CLOSED',
    label: 'CouncilStatus_Completed',
    badge: 'secondary',
    icon: 'ni ni-na',
  },
  {
    value: 'CANCELED',
    label: 'CouncilStatus_Canceled',
    badge: 'danger',
    icon: 'ni ni-cross',
  },
];
