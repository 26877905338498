/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

import { RealtyPlot } from 'types';
import PlotsTableRow from './PlotTableRow';

type Props = {
  plots: RealtyPlot[];
  // eslint-disable-next-line no-unused-vars
  handleRemovePlot: (index: number) => void;
};

const PlotsTableBody: FC<Props> = ({ plots, handleRemovePlot }) => (
  <>
    <tbody>
      {plots?.map((plot: RealtyPlot, index: number) => (
        <tr className="nk-tb-item" key={`plot-row-${index}`}>
          <PlotsTableRow
            index={index}
            plot={plot}
            handleRemovePlot={handleRemovePlot}
          />
        </tr>
      ))}
    </tbody>
  </>
);

export default PlotsTableBody;
