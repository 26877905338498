/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import i18n from 'i18n/config';

import { store } from 'redux/store';
import { Society, User } from 'types';

import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';
import operationTypes from 'constants/operationTypes';

import { addDocument } from 'modules/documents/redux/documentActions';

type AddCouncilDocumentsData = {
  user: User;
  files: File[];
  society: Society;
};

export const addCouncilDocuments = async ({
  files,
  user,
  society,
}: AddCouncilDocumentsData) => {
  if (!files.length) {
    return [];
  }

  const documents = [];

  for (const file of files) {
    const newDocument = {
      file,
      date: new Date(),
      size: file.size,
      name: `${operationTypes.ADD_COUNCIL_FILENAME}-${file.name}`,
      author: user['_id'],
      society: society['_id'],
      fileType: fileTypes[file.type as keyof typeof fileTypes],
      category: documentTypes.DOCUMENTS,
      description: i18n.t('DocumentLinkedToOperation'),
    };

    const documentData = await store.dispatch(addDocument(newDocument));

    if (documentData) {
      documents.push(documentData);
    }
  }

  return documents;
};
