import { FC } from 'react';

import DownloadPartnerBookModalHeader from './components/DownloadPartnerBookModalHeader';
import DownloadPartnerBookModalFooter from './components/DownloadPartnerBookModalFooter';
import DownloadPartnerBookModalBody from './components/DownloadPartnerBookModalBody';

const DownloadPartnerBookModal: FC = () => (
  <>
    <DownloadPartnerBookModalHeader />
    <DownloadPartnerBookModalBody />
    <DownloadPartnerBookModalFooter />
  </>
);

export default DownloadPartnerBookModal;
