import { FC, memo, ReactNode } from 'react';
import bigDecimal from 'js-big-decimal';

import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';
import {
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
  percentFormatDecimals,
} from 'constants/formats';

import { getActualPartner } from 'utils/filters';
import { Operation, Partner, Society } from 'types';

import { getTransactionHoldingClass } from 'modules/transactions/utils/getTransactionHoldingClass';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { getOperationNominalValue } from 'modules/transactions/utils/getOperationNominalValue';
import getTableCell from '../utils/getTableCell';
import { operationLabels } from '../constants/operationLabels';
import { getOperationTotalShares } from '../utils/getOperationTotalShares';

type TransactionDetailsOperationRowProps = {
  operation: Operation;
  actualSociety: Society;
  societyShares: number;
  onUpdateTotalShares: (value: number) => void; // eslint-disable-line
  onUpdateTotalSum: (value: number) => void; // eslint-disable-line
};

const TransactionDetailsOperationRow: FC<
  TransactionDetailsOperationRowProps
> = ({
  operation,
  actualSociety,
  societyShares,
  onUpdateTotalShares,
  onUpdateTotalSum,
}): ReactNode => {
  const holdingClasses = useSelector((state: State) => state.holdingClasses);

  if (!operation || !actualSociety) {
    return null;
  }

  let totalShares = 0;
  let totalSum = 0;

  const handleUpdateTotalShares = (value: number) => {
    onUpdateTotalShares(value);
  };

  const handleUpdateTotalSum = (value: number) => {
    onUpdateTotalSum(value);
  };

  const operationName =
    operationLabels[operation?.alias || operation.operationType];

  const totalOperationShares = societyShares;

  if (operation?.operationType === operationTypes.DIVIDENDS_DISTRIBUTION) {
    const rows = operation?.dividends.map((dividend: any) => {
      const partner: Partner = getActualPartner(
        actualSociety,
        dividend?.partner
      );
      const holdingClass = getTransactionHoldingClass(holdingClasses, dividend);
      const nominalValue = getOperationNominalValue({
        holdingClass,
        operation,
        society: actualSociety,
      });

      const partnerName = partner?.name || '';
      const shares = 0;
      const sharePremium = 0;
      const total = dividend.amount;
      const percent = (shares / totalOperationShares) * 100;

      totalSum += total;

      const sharesCell = getTableCell(shares, numberFormat);
      const nominalValueCell = getTableCell(
        nominalValue,
        currencyFormatDecimals
      );
      const sharePremiumCell = getTableCell(
        sharePremium,
        currencyFormatDecimals
      );
      const totalCell = getTableCell(total, currencyFormatDecimals);
      const percentCell = getTableCell(percent, percentFormatDecimals);

      return (
        <tr key={dividend['_id']}>
          <td>{partnerName}</td>
          <td>{operationName}</td>
          {sharesCell}
          {nominalValueCell}
          {sharePremiumCell}
          {totalCell}
          {percentCell}
        </tr>
      );
    });

    handleUpdateTotalShares(totalShares);
    handleUpdateTotalSum(totalSum);

    return rows;
  }

  const rows = operation?.transactions.map((transaction: any) => {
    const partner: Partner = getActualPartner(
      actualSociety,
      transaction?.partner
    );
    const holdingClass = getTransactionHoldingClass(
      holdingClasses,
      transaction
    );
    const nominalValue = getOperationNominalValue({
      holdingClass,
      operation,
      society: actualSociety,
    });

    const partnerName = partner?.name || '';
    const shares = transaction?.shareTo - transaction?.shareFrom + 1;
    const sharePremium = transaction?.sharePremium || 0;
    const sharePrice = transaction?.sharePrice || 0;

    let nominalValueCell;
    let sharePremiumCell;
    let sharePriceCell;
    let sharesCell;
    let totalCell;
    let percentCell;
    let totalNominalValueCell;
    let totalSharesPremiumCell;

    const transactionType = transaction?.transactionType;

    switch (operation.operationType) {
      case operationTypes.SELL_PARTICIPATION:
      case operationTypes.HERITAGE:
      case operationTypes.DONATION: {
        const sign = transactionType === transactionTypes.SELL ? '-' : '+';
        const total = sharePrice * shares;
        const percent = (shares / totalOperationShares) * 100;

        if (transactionType === transactionTypes.BUY) {
          totalSum += total;
          totalShares += shares;
        }

        const renderText = (value: any) => (
          <div>
            {sign}
            {value}
          </div>
        );

        nominalValueCell = getTableCell(nominalValue, currencyFormatDecimals);
        sharePremiumCell = getTableCell(sharePremium, currencyFormatDecimals);
        sharePriceCell = getTableCell(sharePrice, currencyFormatDecimals);
        sharesCell = getTableCell(shares, numberFormat, renderText);
        totalCell = getTableCell(total, currencyFormatDecimals, renderText);
        percentCell = getTableCell(percent, percentFormatDecimals);
        break;
      }

      default: {
        const sign =
          transactionType === transactionTypes.CAPITAL_DECREASE &&
          transaction?.sharePrice
            ? '+'
            : '';
        const total =
          transactionType === transactionTypes.CAPITAL_DECREASE &&
          transaction?.sharePrice
            ? transaction.sharePrice * shares
            : shares * (nominalValue + sharePremium);
        totalSum += total;
        totalShares += shares;
        const totalNominalValue = +bigDecimal.multiply(shares, nominalValue);
        const totalSharePremium = +bigDecimal.multiply(shares, sharePremium);

        const percent = (shares / totalOperationShares) * 100;

        const renderText = (value: any) => (
          <div>
            {sign}
            {value}
          </div>
        );

        sharesCell = getTableCell(shares, numberFormat);
        nominalValueCell = getTableCell(nominalValue, currencyFormatDecimals);
        sharePremiumCell = getTableCell(sharePremium, currencyFormatDecimals);
        totalNominalValueCell = getTableCell(
          totalNominalValue,
          currencyFormatDecimals
        );
        totalSharesPremiumCell = getTableCell(
          totalSharePremium,
          currencyFormatDecimals
        );
        totalCell = getTableCell(total, currencyFormatDecimals, renderText);
        percentCell = getTableCell(percent, numberFormatDecimals);
        break;
      }
    }

    return (
      <tr key={transaction['_id']}>
        <td className="mw-250px">{partnerName}</td>
        <td>{operationName}</td>
        {sharesCell}
        {nominalValueCell}
        {operation.operationType === operationTypes.SELL_PARTICIPATION
          ? sharePriceCell
          : sharePremiumCell}
        {operation.operationType === operationTypes.CAPITAL_INCREASE &&
          totalNominalValueCell}
        {operation.operationType === operationTypes.CAPITAL_INCREASE &&
          totalSharesPremiumCell}
        {totalCell}
        {percentCell}
      </tr>
    );
  });

  handleUpdateTotalShares(totalShares);
  handleUpdateTotalSum(totalSum);

  return rows;
};

export default memo(TransactionDetailsOperationRow);
