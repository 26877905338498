import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  replaceAnnouncementDocument?: boolean;
  handleCloseModal: any;
};

const AddCouncilDocumentModalHeader: FC<Props> = ({
  replaceAnnouncementDocument,
  handleCloseModal,
}) => {
  const { t } = useTranslate();

  const title = replaceAnnouncementDocument
    ? t('ReplaceDocument')
    : t('AddDocument');

  return (
    <>
      <Modal.Header>
        <h5 className="title mb-0">{title}</h5>

        <a className="close cursor-pointer" onClick={handleCloseModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
    </>
  );
};

export default AddCouncilDocumentModalHeader;
