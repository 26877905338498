/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

import { useTranslate } from 'hooks/useTranslate';
import { Council, Society } from 'types';

import { useDispatch } from 'react-redux';
import { setModal } from 'modules/_shared/redux/modalActions';
import TemplateViewer from 'modules/_shared/components/Modals/TemplateViewer';
import DocumentViewer from 'modules/_shared/components/Modals/DocumentViewer';
import { generateDocumentGroups } from '../utils/generateDocumentGroups';
import MembersDocumentsSection from '../../CouncilDetails/components/MembersDocumentsSection';

type CouncilMembersDocumentsCardProps = {
  council: Council;
  documents: any;
  society: Society;
};

export type DocumentType = 'EMAIL' | 'PDF' | 'TEMPLATE' | 'OTHER';

export type DocumentsGroupItem = {
  name: string;
  size: number;
  date: string;
  description: string;
  content: any;
  contentType: DocumentType;
};

type DocumentsGroupProps = {
  title: string;
  items: DocumentsGroupItem[];
};

export enum ContentTypes {
  EMAIL = 'EMAIL',
  PDF = 'PDF',
  TEMPLATE = 'TEMPLATE',
  OTHER = 'OTHER',
}

const getIconByContentType = (contentType: DocumentType) => {
  switch (contentType) {
    case 'EMAIL':
      return 'ni ni-mail text-soft';
    case 'PDF':
      return 'ni ni-file-pdf text-danger';
    case 'OTHER':
      return 'ni ni-notes-alt text-soft';
    default:
      return 'ni ni-notes-alt text-soft';
  }
};

const DocumentsGroup: FC<DocumentsGroupProps> = ({ title, items }) => {
  const dispatch = useDispatch();

  const getItemStyle = (item: DocumentsGroupItem) => {
    if (!item.content) {
      return 'data-item cursor-not-allowed';
    }

    return 'data-item cursor-pointer';
  };

  const handleClickDocument = (item: DocumentsGroupItem) => {
    if (!item.content) return;

    if (item.contentType === ContentTypes.PDF && item.content._id) {
      dispatch(setModal(<DocumentViewer document={item.content} size="xl" />));
    }

    if (
      item.contentType === ContentTypes.TEMPLATE ||
      item.contentType === ContentTypes.EMAIL
    ) {
      dispatch(
        setModal(
          <TemplateViewer
            template={item.content}
            contentType={item.contentType}
            size="xl"
          />
        )
      );
    }
  };

  return (
    <>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title mb-0">{title}</h5>
        </div>
      </div>

      <div className="card rounded-xl">
        <ul className="data-list is-compact">
          {items.map((item, index) => (
            <li
              className={getItemStyle(item)}
              key={`${item.name}-${index}`}
              onClick={() => handleClickDocument(item)}
            >
              <div className="data-col d-flex align-items-center">
                <div className="document-icon mr-3 flex-shrink-0">
                  <em
                    className={`icon fs-5 ${getIconByContentType(
                      item.contentType
                    )}`}
                  />
                </div>
                <div className="data-col flex-grow-1 min-w-500px mw-75 g-2">
                  <div className="data-value fw-medium text-truncate">
                    {item.name}:
                  </div>
                  <small className="data-label text-muted">
                    {item.description}
                    {item.contentType !== ContentTypes.EMAIL && (
                      <> | {item.size} MB</>
                    )}
                  </small>
                </div>
                <div className="data-col text-nowrap flex-shrink-0">
                  <div className="sub-text">{item.date || '-'}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const CouncilMembersDocumentsCard: FC<CouncilMembersDocumentsCardProps> = ({
  council,
  documents,
  society,
}) => {
  const { t } = useTranslate();

  const { announcementItems, actItems, signItems } = generateDocumentGroups(
    council,
    documents,
    society,
    t
  );

  return (
    <section className="council-member-basic-info-card col">
      <DocumentsGroup title={t('Announcement')} items={announcementItems} />

      <DocumentsGroup title={t('Act')} items={actItems} />

      <DocumentsGroup title={t('Signatures')} items={signItems} />

      <MembersDocumentsSection
        council={council}
        fetchCouncilData={() => {}}
        isAdminView={false}
        isSummaryVersion
      />
    </section>
  );
};

export default CouncilMembersDocumentsCard;
