import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

const TitlesTableHeader: FC = () => {
  const { t } = useTranslate();

  return (
    <>
      <thead className="table-light">
        <tr>
          <th className="text-left">{t('ConditionC')}</th>
          <th className="text-left">{t('Description')}</th>
          <th className="text-center" />
        </tr>
      </thead>
    </>
  );
};

export default TitlesTableHeader;
