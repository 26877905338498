import allowedImageMimeTypes from 'constants/allowedImageMimeTypes';
import { fileTypeFromBuffer } from 'file-type';

export const allowedFileImage = async (file: File) => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const fileTypeResult = await fileTypeFromBuffer(
      new Uint8Array(arrayBuffer)
    );

    if (
      !fileTypeResult ||
      !allowedImageMimeTypes.includes(fileTypeResult.mime)
    ) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};
