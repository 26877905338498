import { getDaysBetweenDates } from './getDaysBetweenDates';

type Props = {
  planStartDate: string;
  cliff: number;
  vestingPeriod: number;
  consolidationPeriods: number[];
  isCustomDilutionByUnits: boolean;
};

const getPeriodPercents = (
  startDate: Date,
  cliffDate: Date,
  consolidationDate: Date
) => {
  const periodPercents = [];

  const cliffDays = getDaysBetweenDates(startDate, cliffDate);
  const vestingDays = getDaysBetweenDates(cliffDate, consolidationDate);

  for (let i = 0; i < cliffDays - 1; i += 1) {
    periodPercents.push(0);
  }

  const dailyPercent = 100 / vestingDays;

  for (let i = 0; i <= vestingDays; i += 1) {
    periodPercents.push(dailyPercent);
  }
  return periodPercents;
};

export const getDailyPeriods = ({
  planStartDate,
  cliff,
  vestingPeriod,
  consolidationPeriods,
  isCustomDilutionByUnits,
}: Props) => {
  const periods: {
    date: Date;
    units?: number;
    percent?: number;
  }[] = [];

  const startDate = new Date(planStartDate);

  const cliffDate = new Date(planStartDate);
  cliffDate.setMonth(cliffDate.getMonth() + (Number(cliff) || 0));

  const consolidationDate = new Date(planStartDate);
  consolidationDate.setMonth(
    consolidationDate.getMonth() + Number(vestingPeriod || 0)
  );

  let periodPercents = [];

  if (consolidationPeriods?.length > 0) {
    periodPercents = consolidationPeriods;
  }
  periodPercents = getPeriodPercents(startDate, cliffDate, consolidationDate);

  periodPercents.forEach((percent, index) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + index);

    if (isCustomDilutionByUnits) {
      periods.push({
        date,
        units: percent,
      });
    } else {
      periods.push({
        date,
        percent,
      });
    }
  });

  return periods;
};
