/* eslint-disable import/imports-first */
import { axiosV1 } from 'interceptors/axios';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';
import { dispatchError } from 'utils/dispatchError';
import { decryptResponse } from 'utils/token';

import { addAlert } from '../../_shared/redux/alertActions';

export function createTenderOfferShares(tenderOffersShares) {
  return {
    type: types.CREATE_TENDER_OFFER_SHARES,
    tenderOffersShares,
  };
}

export const getTenderOffersShares = (societyId) => async (dispatch) => {
  try {
    const query = societyId ? { params: { societyId } } : { params: {} };

    const { data } = await axiosV1.get(`/tenderOffersShares`, query);

    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.GET_TENDER_OFFERS_SHARES,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updateTenderOfferShares =
  (tenderOfferSharesId, updateTenderOfferSharesData) => async (dispatch) => {
    const body = {
      _id: tenderOfferSharesId,
      ...updateTenderOfferSharesData,
    };

    try {
      const { data } = await axiosV1.put(`/tenderOffersShares`, body);

      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.UPDATE_TENDER_OFFER_SHARES,
        tenderOffersShares: decodedData,
      });
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deleteTenderOfferShares =
  (tenderOfferShares) => async (dispatch) => {
    try {
      const { data } = await axiosV1.delete(
        `/tenderOffersShares/${tenderOfferShares}`
      );

      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.DELETE_TENDER_OFFER_SHARES,
        tenderOfferShares: decodedData,
      });
      dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      return decodedData['_id'];
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };
