import { useEffect, ReactNode, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setupInterceptors } from 'interceptors/axios';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'modules/profile/redux/userActions';

interface AxiosAuthProviderProps {
  children: ReactNode;
}

export const AxiosAuthProvider = ({ children }: AxiosAuthProviderProps) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const dispatch = useDispatch();

  const onLogout = useCallback(async () => {
    try {
      // First dispatch the logout action and wait for it to complete
      await dispatch(logoutUser(logout));
      // Then perform the Auth0 logout
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
      console.log('✅ cclog Logout successful'); // eslint-disable-line no-console
    } catch (error) {
      console.error('❌ cclog Logout failed:', error); // eslint-disable-line no-console
      // Still attempt Auth0 logout even if Redux logout fails
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  }, [dispatch, logout]);

  useEffect(() => {
    setupInterceptors(getAccessTokenSilently, onLogout);
  }, [getAccessTokenSilently, onLogout]);

  return children;
};
