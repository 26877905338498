import { format } from 'date-fns';
import { dateFormat } from 'constants/formats';

export const fileToArrayBuffer = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });

export const fileToDataUrl = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });

export const fileToText = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsText(file);
  });

export const fileToBinaryString = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsBinaryString(file);
  });

export const getFileDate = (file, outputFormar = dateFormat) => {
  const fileDate = file?.lastModifiedDate || file?.date;

  if (fileDate) {
    return format(new Date(fileDate), outputFormar);
  }

  return '';
};

export const getFileSize = (file) => {
  if (!file) {
    return 0;
  }

  if (typeof file.size === 'number') {
    return Number((file.size / 1024 / 1024).toFixed(2));
  }

  return 0;
};
