import { FC } from 'react';
import { store } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';
import Swal from 'sweetalert2';

import ToggleMenu from 'modules/_shared/components/ToggleMenu';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';
import { setModal } from 'modules/_shared/redux/modalActions';
import AddRealtyModal from 'modules/administratives/modals/AddRealtyModal';
import { AddRealtyModalProvider } from 'modules/administratives/modals/AddRealtyModal/context/AddRealtyModalContext';
import { deleteRealty } from 'modules/administratives/redux/realtyActions';

import { Realty } from 'types';
import sizes from 'constants/sizes';
import actionTypes from 'constants/actionTypes';
import successSwal from 'utils/successSwal';

type Props = {
  index: number;
  realty: Realty;
};

const RealtiesTableRow: FC<Props> = ({ index, realty }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const realties = useSelector((state: State) => state.realties);
  const { isReadOnly } = useGetSocietySectionPermissions().data;

  const societyId = realty?.society;
  const realtyId = realty?._id;

  const handleEditRealty = () => {
    const realty = realties.find((realty) => realty._id === realtyId);

    dispatch(
      setModal(
        <AddRealtyModalProvider initialRealtyData={realty}>
          <AddRealtyModal
            societyId={societyId}
            action={actionTypes.EDIT as keyof typeof actionTypes}
            realtyId={realtyId}
            size={sizes.XL}
          />
        </AddRealtyModalProvider>
      )
    );
  };

  const handleRemoveRealty = () => {
    Swal.fire({
      icon: 'warning',
      title: `<h4 className="nk-block-title page-title">${t(
        'AreYouSureAboutDelRealty'
      )}</h4>`,
      html: `<h3 class="text-primary fw-normal">${
        realty?.name
      } </h3><br /><div class="caption-text">${t(
        'ThisProcedureCantUndo'
      )}</div>`,
      confirmButtonText: t('OkDelete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await store.dispatch(deleteRealty(realtyId));
        successSwal(t('RealtySuccessfullyRemoved'));
      }
    });
  };

  const toggleOptions = [
    <a
      className="d-flex cursor-pointer"
      key={`realty-edit-option-${index}`}
      onClick={handleEditRealty}
    >
      <em className="icon ni ni-edit fs-20px" />
      <span className="ml-1">{t('Edit')}</span>
    </a>,
    <a
      className="d-flex cursor-pointer"
      key={`realty-delete-option-${index}`}
      onClick={handleRemoveRealty}
    >
      <em className="icon ni ni-trash fs-20px" />
      <span className="ml-1">{t('Delete')}</span>
    </a>,
  ];

  return (
    <>
      <td className="nk-tb-col" key={`realty-name-${index}`}>
        <Link to={`/detalle-inmueble/${societyId}/${realtyId}`}>
          <span className="text-primary font-weight-bold cursor-pointer">
            {realty?.name}
          </span>
        </Link>
      </td>

      <td className="nk-tb-col" key={`realty-address-${index}`}>
        <span>{realty?.location?.address}</span>
      </td>

      <td className="nk-tb-col" key={`realty-city-${index}`}>
        <span>{realty?.location?.city}</span>
      </td>

      <td className="nk-tb-col" key={`realty-title-${index}`}>
        <span>
          {realty?.ownership} / {realty?.lessee}
        </span>
      </td>

      {!isReadOnly && (
        <td className="nk-tb-col" key={`realty-options-${index}`}>
          <ToggleMenu toggleOptions={toggleOptions} />
        </td>
      )}
    </>
  );
};

export default RealtiesTableRow;
