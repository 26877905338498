import { axiosV1 } from 'interceptors/axios';
import { decryptResponse } from 'utils/token';

export const getDocumentBlobFromStorage = async (
  documentId: string
): Promise<Blob | MediaSource> => {
  try {
    const { data } = await axiosV1.get(
      `${process.env.REACT_APP_API_URL}/download/${documentId}`
    );
    const decodedData = await decryptResponse(data);
    // @ts-ignore
    const documentUrl = decodedData?.url;
    const response = await fetch(documentUrl);

    const blob = await response.blob();
    return blob;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting document file from storage', error);
  }

  throw new Error('Failed to get document blob from storage');
};
