import { FC, ReactNode, useState } from 'react';
import CouncilDetailsNavigationHeader from './CouncilDetailsNavigationHeader';
import CouncilDetailsAnnouncement from './CouncilDetailsAnnouncement';
import CouncilDetailsDocuments from './CouncilDetailsDocuments';
import CouncilDetailsAct from './CouncilDetailsAct';
import CouncilDetailsCertification from './CouncilDetailsCertification';
import CouncilDetailsSignature from './CouncilDetailsSignature';
import CouncilDetailsAttendance from './CouncilDetailsAttendance';

type Props = {
  councilId: string;
  handleEditCouncil: () => void;
};

const CouncilDetailsPageBody: FC<Props> = ({
  councilId,
  handleEditCouncil,
}: Props) => {
  const [page, setPage] = useState<number>(0);
  const pages: ReactNode[] = [
    <CouncilDetailsAnnouncement
      councilId={councilId}
      handleEditCouncil={handleEditCouncil}
    />,
    <CouncilDetailsAttendance councilId={councilId} />,
    <CouncilDetailsAct councilId={councilId} />,
    <CouncilDetailsCertification councilId={councilId} />,
    <CouncilDetailsSignature councilId={councilId} />,
    <CouncilDetailsDocuments councilId={councilId} />,
  ];

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  return (
    <div className="nk-block">
      <CouncilDetailsNavigationHeader
        page={page}
        handleChangePage={handleChangePage}
      />
      {pages[page]}
    </div>
  );
};

export default CouncilDetailsPageBody;
