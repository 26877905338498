/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import {
  formatCurrencyDecimals,
  formatCurrencyDecimalsLong,
} from 'utils/formats';
import { getTheme } from 'utils/theme';
import { isMobileScreen } from 'utils/getScreenSize';
import { kFormatter } from 'utils/filters';

import Page from 'modules/_shared/components/Pages/Page';
import WidgetChartDoughnut, {
  legendModes,
} from 'modules/dashboard/components/WidgetChartDoughnut';
import WidgetList from 'modules/dashboard/components/WidgetList';
import WidgetChartBar from 'modules/dashboard/components/WidgetChartBar';
import WidgetChartLine from 'modules/dashboard/components/WidgetChartLine';
import WidgetTableSimple from 'modules/dashboard/components/WidgetTableSimple';
import WidgetTableExtended from 'modules/dashboard/components/WidgetTableExtended';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import LegalDashboard from 'modules/dashboard/pages/LegalDashboard';
import useGetSocietyDashboardData from 'modules/society/hooks/useGetSocietyDashboardData';
import { useGetDashboardSectionPermissions } from 'modules/dashboard/hooks/useGetDashboardSectionPermissions';
import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';

import WidgetChartLineLoader from 'modules/dashboard/components/WidgetChartLineLoader';
import WidgetTableExtendedLoader from 'modules/dashboard/components/WidgetTableExtendedLoader';
import WidgetTableSimpleLoader from 'modules/dashboard/components/WidgetTableSimpleLoader';
import WidgetChartDoughnutLoader from 'modules/dashboard/components/WidgetChartDougnutLoader';
import { getDataCapitalDetailsChart } from './getDataCapitalDetailsChart';

const dashboardTypes = {
  STANDARD: 'STANDARD',
  LEGAL: 'LEGAL',
};

const dashboardIcons = {
  STANDARD: 'ni-chart-up',
  LEGAL: 'ni-list-thumb',
};

const SocietyDashboard = () => {
  const { t } = useTranslate();
  const { societyId } = useParams();
  const printRef = useRef();

  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const { isAdmin, isReadOnly } = useGetDashboardSectionPermissions().data;
  const { data, loadingState } = useGetSocietyDashboardData();

  const [selectedTheme, setSelectedTheme] = useState(getTheme());
  const [dashboardTypeSelected, setDashboardTypeSelected] = useState(
    dashboardTypes.STANDARD
  );

  const societyName = actualSociety?.name;

  const handleSwitchDashboardType = (e) => {
    setDashboardTypeSelected(e.target.value);
  };

  useGetMenu();

  useEffect(() => {
    window.addEventListener('storage', () => {
      const theme = getTheme();
      setSelectedTheme(theme);
    });
  }, []);

  return (
    <Page>
      <PageListHeader
        subTitle={societyName || t('Society')}
        description={`${t('SocietyDashboardTitle')} ${societyName || ''}`}
        actions={
          isAdmin && [
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  id="screen-only"
                  className="btn btn-white btn-outline-light"
                >
                  <em className="icon ni ni-printer-fill" />
                  <span>{t('Print')}</span>
                </button>
              )}
              content={() => printRef.current}
              documentTitle={`${societyName} - Sttok ${format(
                new Date(),
                'dd-MM-yyyy'
              )}`}
            />,
            <div className="form-group cursor-pointer">
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <div className="form-icon form-icon-left">
                    <em
                      className={`icon fw-bold text-base ni ${dashboardIcons[dashboardTypeSelected]}`}
                    />
                  </div>
                  <select
                    id="dashboard-view"
                    name="dashboard-view"
                    value={dashboardTypeSelected}
                    onChange={handleSwitchDashboardType}
                    className="form-control cursor-pointer fw-bold text-base pr-4"
                  >
                    <option
                      value={dashboardTypes.STANDARD}
                      className="cursor-pointer"
                    >
                      {t('ViewStandardDashboard')}
                    </option>
                    <option
                      value={dashboardTypes.LEGAL}
                      className="cursor-pointer"
                    >
                      {t('ViewLegalDashboard')}
                    </option>
                  </select>
                </div>
              </div>
            </div>,
          ]
        }
      />

      <div
        className="nk-block d-flex flex-wrap justify-content-between"
        ref={printRef}
        id="margin-print-only"
      >
        <PrintHeader title={t('PrintHeaderDashboardSociety')} />

        {dashboardTypeSelected === dashboardTypes.LEGAL ? (
          <LegalDashboard />
        ) : (
          <div className="row">
            {!loadingState?.summaryChartViewData &&
              data?.summaryChartViewData && (
                <WidgetChartLine
                  title={t('CompanySummary')}
                  subtitle={t('CompanyMainIndicators', { societyName })}
                  kpis={[
                    {
                      title: t('Partners'),
                      value: kFormatter(
                        data?.summaryChartViewData?.kpisData?.partnersCount
                      ),
                      icon: 'ni-user',
                      link: `/socios/${societyId}`,
                    },
                    {
                      title: t('Shares'),
                      value: kFormatter(
                        data?.summaryChartViewData?.kpisData
                          ?.societyActualShares
                      ),
                      icon: 'ni-layers',
                      link: `/socios/${societyId}`,
                    },
                    !isMobileScreen()
                      ? {
                          title: t('Nominal'),
                          value: formatCurrencyDecimalsLong(
                            data?.summaryChartViewData?.kpisData
                              ?.societyNominalValue
                          ),
                          icon: 'ni-layers',
                          link: `/socios/${societyId}`,
                        }
                      : null,
                    {
                      title: t('NoteConv.'),
                      value: kFormatter(
                        data?.summaryChartViewData?.kpisData
                          ?.convertibleNotesShares
                      ),
                      icon: 'ni-invest',
                      link: `/borradores/${societyId}`,
                    },
                    {
                      title: t('Incentives'),
                      value: kFormatter(
                        data?.summaryChartViewData?.kpisData?.plansUsedShares
                      ),
                      icon: 'ni-layers',
                      link: `/beneficiarios-planes/${societyId}`,
                    },
                  ].filter((item) => item)}
                  data={data?.summaryChartViewData}
                  extraLabel={t('SocialCapital')}
                  extraValue={formatCurrencyDecimals(
                    data?.summaryChartViewData?.kpisData?.societyActualShares *
                      data?.summaryChartViewData?.kpisData
                        ?.societyNominalValue || 0
                  )}
                  isReadOnly={isReadOnly}
                />
              )}

            {!data?.summaryChartViewData && (
              <WidgetChartLineLoader columns={5} />
            )}

            {!loadingState?.financialChartViewData &&
              data?.financialChartViewData && (
                <WidgetChartBar
                  title={t('Financing')}
                  subtitle={t('FinancingEvolution')}
                  height={222}
                  kpis={[
                    {
                      title: t('Shar.'),
                      value: kFormatter(
                        data?.financialChartViewData?.kpisData
                          ?.societyActualShares
                      ),
                      icon: 'ni-layers',
                    },
                    {
                      title: t('Investment.'),
                      value: kFormatter(
                        data?.financialChartViewData?.kpisData?.inversionValue
                      ),
                      icon: 'ni-invest',
                    },
                    {
                      title: t('ValueShar.'),
                      value: `${data?.financialChartViewData?.kpisData?.sharesValue?.toFixed(
                        2
                      )}€`,
                      icon: 'ni-layers',
                    },
                  ]}
                  data={data?.financialChartViewData}
                />
              )}

            {loadingState?.financialChartViewData && (
              <WidgetChartLineLoader columns={3} />
            )}

            {!loadingState?.partnersViewData && data?.partnersViewData && (
              <WidgetTableExtended
                title={t('MainPartners')}
                linkText={t('SeeAlls')}
                linkUrl={`/socios/${societyId}`}
                columns={[t('Partner'), t('Shar'), t('%ND'), t('%FD')]}
                data={data?.partnersViewData}
                isReadOnly={isReadOnly}
              />
            )}

            {loadingState?.partnersViewData && <WidgetTableExtendedLoader />}

            {!loadingState?.partnersChartViewData &&
              data?.partnersChartViewData && (
                <WidgetChartDoughnut
                  title={t('Partners')}
                  linkText={t('SeeAlls')}
                  linkUrl={`/socios/${societyId}`}
                  data={data?.partnersChartViewData?.chart}
                  legendMode={legendModes.VERTICAL}
                  legend={data?.partnersChartViewData?.partners}
                  isReadOnly={isReadOnly}
                />
              )}

            {loadingState?.partnersChartViewData && (
              <WidgetChartDoughnutLoader legendMode={legendModes.VERTICAL} />
            )}

            {!loadingState?.capitalViewData && data?.capitalViewData && (
              <WidgetTableSimple
                title={t('Capital')}
                subtitle={t('CapitalDistributionTitle')}
                data={data?.capitalViewData}
              />
            )}

            {loadingState?.capitalViewData && (
              <WidgetTableSimpleLoader rows={10} />
            )}

            {!loadingState?.capitalChartViewData &&
              data?.capitalChartViewData && (
                <WidgetChartDoughnut
                  title={t('CapitalDetail')}
                  data={getDataCapitalDetailsChart({
                    capitalChartViewData: data?.capitalChartViewData,
                    selectedTheme,
                    t,
                  })}
                  legendMode={legendModes.HORIZONTAL}
                  legend={[
                    {
                      title: t('Share.'),
                      icon: 'ni-reports-alt',
                      color: '#798bff',
                      value: kFormatter(
                        data?.capitalChartViewData?.societyActualShares
                      ),
                      percent:
                        data?.capitalChartViewData?.societyActualSharesPercentage?.toFixed(
                          2
                        ),
                    },
                    {
                      title: t('NoteConv.'),
                      icon: 'ni-report-profit',
                      color: '#b8acff',
                      value: kFormatter(
                        data?.capitalChartViewData?.convertibleNotesShares
                      ),
                      percent:
                        data?.capitalChartViewData?.convertibleNotesSharesPercentage?.toFixed(
                          2
                        ),
                    },
                    {
                      title: t('StockOptions'),
                      icon: 'ni-users',
                      color: '#ffa9ce',
                      value: kFormatter(
                        data?.capitalChartViewData?.stockOptionsShares
                      ),
                      percent:
                        data?.capitalChartViewData?.stockOptionsSharesPercentage?.toFixed(
                          2
                        ),
                    },
                    {
                      title: t('Phantoms'),
                      icon: 'ni-users-fill',
                      color: '#7de1f8',
                      value: kFormatter(
                        data?.capitalChartViewData?.phantomShares
                      ),
                      percent:
                        data?.capitalChartViewData?.phantomSharesPercentage?.toFixed(
                          2
                        ),
                    },
                  ]}
                />
              )}

            {loadingState?.capitalChartViewData && (
              <WidgetChartDoughnutLoader legendMode={legendModes.HORIZONTAL} />
            )}

            {!loadingState?.plansViewData && data?.plansViewData && (
              <WidgetTableSimple
                title={t('IncentivePlans')}
                subtitle={t('SocietyHasXPlans', {
                  societyName,
                  plansCount: data.plansViewData.length - 1, // One row is the total
                })}
                linkText={t('SeeAlls')}
                linkUrl={`/beneficiarios-planes/${societyId}`}
                data={data?.plansViewData}
                isReadOnly={isReadOnly}
              />
            )}

            {loadingState?.plansViewData && (
              <WidgetTableSimpleLoader rows={10} />
            )}

            {!loadingState?.operationsViewData && data?.operationsViewData && (
              <WidgetList
                title={t('Transactions')}
                subtitle={t('LastTransactions')}
                linkText={t('SeeAll')}
                linkUrl={`/transacciones/${societyId}`}
                data={data?.operationsViewData}
                isReadOnly={isReadOnly}
              />
            )}

            {loadingState?.operationsViewData && (
              <WidgetTableSimpleLoader rows={10} />
            )}

            {!loadingState?.beneficiariesViewData &&
              data?.beneficiariesViewData && (
                <WidgetTableExtended
                  title={t('MainBeneficiaries')}
                  linkText={t('SeeAlls')}
                  linkUrl={`/beneficiarios/${societyId}`}
                  columns={[t('Beneficiary'), t('Unit'), t('%FD')]}
                  data={data?.beneficiariesViewData}
                  isReadOnly={isReadOnly}
                />
              )}

            {loadingState?.beneficiariesViewData && (
              <WidgetTableExtendedLoader rows={10} />
            )}

            {!loadingState?.draftsViewData && data?.draftsViewData && (
              <WidgetTableExtended
                title={t('OperationsSimulator')}
                linkText={t('SeeAlls')}
                linkUrl={`/borradores/${societyId}`}
                columns={[t('Operation'), t('Amount')]}
                data={data?.draftsViewData}
                isReadOnly={isReadOnly}
              />
            )}

            {loadingState?.draftsViewData && (
              <WidgetTableExtendedLoader rows={10} />
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

export default SocietyDashboard;
