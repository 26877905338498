import { updateSociety } from 'modules/society/redux/societyActions';
import { store } from 'redux/store';
import { SocietyRepresentative } from 'types';

export const removeSocietyRepresentative = async (
  societyId: string,
  representatives: SocietyRepresentative[],
  representativeId?: string
) => {
  if (representativeId) {
    const filteredRepresentatives = representatives.filter(
      (representative) => representative['_id'] !== representativeId
    );
    const newSocietyData = {
      representatives: filteredRepresentatives,
    };
    await store.dispatch(updateSociety(societyId, newSocietyData, false));
  }
};
