import { store } from 'redux/store';
import types from 'redux/actions/actionTypes';

import { axiosV2 } from 'interceptors/axios';
import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { Council } from 'types';

export const getCouncil = async (councilId: string) => {
  try {
    const { data } = await axiosV2.get(`/councils/${councilId}`);
    const decodedData = (await decryptResponse(data)) as Council;

    if (!decodedData) {
      throw new Error('Council not found');
    }

    return decodedData;
  } catch (error) {
    return dispatchError(error, store.dispatch);
  }
};

export const getCouncils = (societyId: string) => async (dispatch: any) => {
  const query = { params: { societyId } };

  try {
    const { data } = await axiosV2.get(`/councils`, query);
    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_COUNCILS,
      councils: decodedData,
    });

    return decodedData;
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const createCouncil =
  (councilData: Council) => async (dispatch: any) => {
    try {
      const body = { ...councilData };

      const { data } = await axiosV2.post(`/councils`, body);
      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.CREATE_COUNCIL,
        council: decodedData,
      });

      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };

export const updateCouncil =
  (councilData: Partial<Council>) => async (dispatch: any) => {
    try {
      const body = { ...councilData };

      const { data } = await axiosV2.put(`/councils`, body);
      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_COUNCIL,
        council: decodedData,
      });

      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };

export const deleteCouncil = (councilId: string) => async (dispatch: any) => {
  try {
    await axiosV2.delete(`/councils/${councilId}`);

    dispatch({
      type: types.DELETE_COUNCIL,
      council: { _id: councilId },
    });
  } catch (error) {
    dispatchError(error, dispatch);
  }
};
