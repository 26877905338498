import { useTranslate } from 'hooks/useTranslate';
import alertIcon from 'assets/images/icon-sections/alert-primary.svg';
import { FC } from 'react';

type Props = {
  title?: string;
  message?: string;
};

const NoDocumentsAlert: FC<Props> = ({ title, message }: Props) => {
  const { t } = useTranslate();

  const titleText = title || t('NoDocumentsFound');
  const messageText = message || '';

  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="card w-100 h-100 border">
        <div className="card-body d-flex flex-column align-items-center justify-content-center text-center">
          <div className="nk-help">
            <div className="nk-help-img mb-3">
              <img src={alertIcon} alt="section" className="img-fluid" />
            </div>
            <div className="nk-help-text">
              <h5>{titleText}</h5>
              <p className="text-muted">{messageText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDocumentsAlert;
