import { useEffect, useState } from 'react';

import { Society, SocietyAuditor } from 'types';
import societyAuditorStatus from '../constants/societyAuditorStatus';
import { getSocietyAuditorStatus } from '../utils/getSocietyAuditorStatus';

type Props = {
  society: Society;
  currentSocietyAuditorsView: boolean;
};

const getActiveSocietyAuditorsCount = (society: Society) => {
  let count = 0;
  society?.auditors?.forEach((auditor) => {
    const status = getSocietyAuditorStatus(auditor);
    if (status?.value === societyAuditorStatus[0].value) count += 1;
  });
  return count;
};

const getSocietyAuditors = (
  society: Society,
  currentSocietyAuditorsView: boolean
) => {
  const filteredAuditors: SocietyAuditor[] = [];

  society?.auditors?.forEach((auditor) => {
    const activeStatus = societyAuditorStatus[0].value;
    const status = getSocietyAuditorStatus(auditor);

    if (currentSocietyAuditorsView && status?.value === activeStatus) {
      filteredAuditors.push(auditor);
    } else if (!currentSocietyAuditorsView) {
      filteredAuditors.push(auditor);
    }
  });
  return filteredAuditors;
};

export default function useGetSocietyAuditors({
  society,
  currentSocietyAuditorsView,
}: Props) {
  const [societyAuditors, setSocietyAuditors] = useState<SocietyAuditor[]>([]);
  const [activeSocietyAuditorsCount, setActiveSocietyAuditorsCount] =
    useState<number>(0);

  useEffect(() => {
    if (society) {
      const activeSocietyAuditorsCount = getActiveSocietyAuditorsCount(society);
      setActiveSocietyAuditorsCount(activeSocietyAuditorsCount);

      const societyAuditors = getSocietyAuditors(
        society,
        currentSocietyAuditorsView
      );
      setSocietyAuditors(societyAuditors);
    }
  }, [society, currentSocietyAuditorsView]);

  return {
    data: { societyAuditors, activeSocietyAuditorsCount },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
