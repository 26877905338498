import { Council } from 'types';
import councilStatus from 'modules/administratives/constants/councilStatus';

export const getCouncilStatusData = (council: Council | null | undefined) => {
  const status = councilStatus.find(
    (status) => status.value === council?.status
  );

  const {
    value: statusValue,
    label: statusLabel,
    badge: statusBadge,
    icon: statusIcon,
  } = status || {
    value: '',
    label: '-',
    badge: '-',
    icon: '-',
  };

  return { statusLabel, statusBadge, statusIcon, statusValue };
};
