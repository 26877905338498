import { translate } from 'hooks/useTranslate';
import { CouncilAttendanceTypes, CouncilTypes } from 'types';

import councilTypes from 'modules/administratives/constants/councilTypes';
import councilAttendanceTypes from 'modules/administratives/constants/councilAttendanceTypes';

import { AddCouncilFormState } from '../types/AddCouncilForm';
import { generateCouncilName } from '../utils/generateCouncilName';

const councilTypeOrdinary = councilTypes.find(
  (councilType) => councilType.value === 'ORDINARY'
) as { value: CouncilTypes; label: string };

const attendanceTypeHybrid = councilAttendanceTypes.find(
  (attendanceType) => attendanceType.value === 'HYBRID'
) as { value: CouncilAttendanceTypes; label: string };

export const addCouncilInitialState: AddCouncilFormState = {
  name: generateCouncilName(),
  date: new Date(),
  details: '',
  topics: '',
  digitalVoting: true,
  attendanceType: attendanceTypeHybrid?.value as CouncilAttendanceTypes,
  place: '',
  onlineAccess: '',
  councilType: councilTypeOrdinary?.value as CouncilTypes,
  minQuorum: 50,
  president: '',
  secretary: '',
  files: [],
  announcementText: '',
  announcementFile: null,
  isLoading: false,
  isUploadModalVisible: false,
  selectedAttendanceOption: {
    value: attendanceTypeHybrid?.value,
    label: translate(attendanceTypeHybrid?.label),
  },
  selectedCouncilTypeOption: {
    value: councilTypeOrdinary?.value,
    label: translate(councilTypeOrdinary?.label),
  },
  selectedPresidentOption: null,
  selectedSecretaryOption: null,
};
