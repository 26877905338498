import { FC } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { State } from 'redux/initialState';

import { dateFormat } from 'constants/formats';

import { SocietyRepresentative } from 'types';

import CellData from 'modules/_shared/components/Tables/CellData';
import DocumentsColumn from 'modules/_shared/components/DocumentsColumn';
import ToggleMenu from 'modules/_shared/components/ToggleMenu';
import { setModal } from 'modules/_shared/redux/modalActions';
import AddSocietyRepresentative from 'modules/_shared/components/Modals/AddSocietyRepresentative';

import Swal from 'sweetalert2';
import successSwal from 'utils/successSwal';

import { removeSocietyRepresentative } from '../utils/removeSocietyRepresentative';
import useGetSelectedValues from '../hooks/useGetSelectedValues';
import useGetSocietyRepresentativeDocuments from '../hooks/useGetSocietyRepresentativeDocuments';

type Props = {
  index: number;
  societyId: string;
  representative: SocietyRepresentative;
  isReadOnly?: boolean;
};

const SocietyRepresentativesTableRow: FC<Props> = ({
  index,
  societyId,
  representative,
  isReadOnly,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const representativeId = representative?._id;
  const representativeName = representative?.socialDenomination || '-';
  const representativeImage = representative?.image;
  const representativeEmail = representative?.email;
  const representativeInitDate = representative?.initDate;
  const representativeEndDate = representative?.endDate;

  const {
    representativeStatusLabel,
    representativeStatusBadge,
    representativeTypeLabel,
  } = useGetSelectedValues({ representative }).data;

  const { representativeDocuments } = useGetSocietyRepresentativeDocuments({
    societyId,
    representativeId: representative?._id,
  }).data;

  const handleDeleteRepresentative = () => {
    Swal.fire({
      icon: 'warning',
      title: `<h4 className="nk-block-title page-title">${t(
        'AreYouSureAboutDelRepresentative'
      )}  `,
      html: `<h3 class="text-primary fw-normal">${representativeName} </h3><br /><div class="caption-text">${t(
        'ThisProcedureCantUndo'
      )}</div>`,
      confirmButtonText: t('OkDelete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await removeSocietyRepresentative(
          societyId,
          actualSociety?.representatives,
          representative?._id
        );
        successSwal(t('RepresentativeSuccessfullyRemoved'));
      }
    });
  };

  const handleEditRepresentative = () => {
    const representativeData = {
      socialDenomination: representativeName,
      nif: representative?.nif,
      nationality: representative?.nationality,
      address: representative?.address,
      email: representativeEmail,
      tag: representative?.tag,
      comments: representative?.comments,
      representativeType: representative?.representativeType,
      maximumAmount: representative?.maximumAmount,
      initDate: representativeInitDate,
      endDate: representativeEndDate,
      documents: representativeDocuments,
      image: representativeImage,
    };

    dispatch(
      setModal(
        <AddSocietyRepresentative
          societyId={societyId}
          action="EDIT"
          representativeData={representativeData}
          representativeId={representativeId}
          userId={user['_id']}
        />
      )
    );
  };

  const toggleOptions = [
    <a
      className="d-flex cursor-pointer"
      key={`representative-edit-option-${index}`}
      onClick={handleEditRepresentative}
    >
      <em className="icon ni ni-edit fs-20px" />
      <span className="ml-1">{t('Edit')}</span>
    </a>,
    <a
      className="d-flex cursor-pointer"
      key={`representative-delete-option-${index}`}
      onClick={handleDeleteRepresentative}
    >
      <em className="icon ni ni-trash fs-20px" />
      <span className="ml-1">{t('Delete')}</span>
    </a>,
  ];

  return (
    <>
      <td className="nk-tb-col tb-col-xl" key={`representative-name-${index}`}>
        <span className="text-primary font-weight-bold cursor-pointer">
          {CellData.Adminstrative({
            name: representativeName,
            image: representativeImage,
            email: representativeEmail,
            url: `/detalle-apoderado/${societyId}/${representativeId}`,
          })}
        </span>
      </td>

      <td
        className="nk-tb-col tb-col-xl"
        key={`representative-initdate-${index}`}
      >
        <span>
          {representativeInitDate
            ? format(new Date(representativeInitDate), dateFormat)
            : '-'}
        </span>
      </td>

      <td
        className="nk-tb-col tb-col-xl"
        key={`representative-enddate-${index}`}
      >
        <span>
          {representativeEndDate
            ? format(new Date(representativeEndDate), dateFormat)
            : t('Indefinite')}
        </span>
      </td>

      <td className="nk-tb-col" key={`representative-type-${index}`}>
        <span>{t(representativeTypeLabel)}</span>
      </td>

      <td className="nk-tb-col" key={`representative-status-${index}`}>
        <span className={`badge badge-dot badge-${representativeStatusBadge}`}>
          {t(representativeStatusLabel)}
        </span>
      </td>

      <DocumentsColumn
        index={index}
        documentsCount={representativeDocuments?.length || 0}
        documents={representativeDocuments}
      />

      {!isReadOnly && (
        <td className="nk-tb-col" key={`representative-options-${index}`}>
          <ToggleMenu toggleOptions={toggleOptions} />
        </td>
      )}
    </>
  );
};

export default SocietyRepresentativesTableRow;
