import { useEffect, useState } from 'react';

import { Council } from 'types';

type Props = {
  council: Council | undefined;
};

const getHasMissingSigners = (council: Council | undefined) => {
  const { president, secretary } = council || {};
  return !president || !secretary;
};

const getMissingText = (council: Council | undefined) => {
  const { president, secretary } = council || {};

  let missingText = '';

  if (!president && !secretary) {
    missingText = 'CouncilWithoutPresidentAndSecretary';
  } else if (!president) {
    missingText = 'CouncilWithoutPresident';
  } else if (!secretary) {
    missingText = 'CouncilWithoutSecretary';
  }

  return missingText;
};

export const useGetMissingSigners = ({ council }: Props) => {
  const [hasMissingSigners, setHasMissingSigners] = useState<boolean>(false);
  const [missingText, setMissingText] = useState<string>('');

  useEffect(() => {
    if (council) {
      const hasMissingSigners = getHasMissingSigners(council);
      setHasMissingSigners(hasMissingSigners);

      const missingText = getMissingText(council);
      setMissingText(missingText);
    }
  }, [council]);

  return {
    data: { hasMissingSigners, missingText },
    isLoading: false,
    isError: false,
    actions: {},
  };
};
