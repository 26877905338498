import { axiosV1 } from 'interceptors/axios';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import { addAlert } from '../../_shared/redux/alertActions';

export default function sendContactEmail(newEmailData) {
  const { file, ...emailData } = newEmailData;
  const formData = new FormData();

  return async (dispatch) => {
    try {
      if (file) formData.append('file', file);
      formData.append('data', JSON.stringify(emailData));

      await axiosV1.post(`/contact`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      dispatch(addAlert(alertBodyTypes['CONTACT_EMAIL_SENT']));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_CONTACT_EMAIL']));
    }
  };
}
