import { getDocumentBlobFromStorage } from 'modules/_shared/utils/getDocumentBlobFromStorage';
import { useEffect, useState } from 'react';
import { Document } from 'types';

type Props = {
  document: Document;
};

export const useGetDocumentFrame = ({ document }: Props) => {
  const [documentFrame, setDocumentFrame] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getDocuemntUrl = async (document: Document) => {
    try {
      setIsLoading(true);
      const documentId = document._id;
      const blob = await getDocumentBlobFromStorage(documentId);
      if (blob) {
        const url = URL.createObjectURL(blob);
        setDocumentFrame(url);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (document) {
      getDocuemntUrl(document);
    }
  }, [document]);

  return {
    data: { documentFrame },
    isLoading,
    isError,
    actions: {},
  };
};
