import { getDailyPeriods } from './getDailyPeriods';

type Props = {
  planStartDate: string;
  cliff: number;
  vestingPeriod: number;
  consolidationPeriods: number[];
  isCustomDilutionByUnits: boolean;
};

export const getConsolidationPercentForDailyVesting = ({
  planStartDate,
  cliff,
  vestingPeriod,
  consolidationPeriods,
  isCustomDilutionByUnits,
}: Props) => {
  const periods = getDailyPeriods({
    planStartDate,
    cliff,
    vestingPeriod,
    consolidationPeriods,
    isCustomDilutionByUnits,
  });

  const todayDate = new Date();

  const consolidationPercent = periods.reduce((acc, period) => {
    if (period.date <= todayDate) {
      return acc + (period?.percent || period?.units || 0);
    }
    return acc;
  }, 0);

  return +consolidationPercent.toFixed(2);
};
