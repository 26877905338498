import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { useGetTemplateOptions } from '../hooks/useGetTemplateOptions';

type Props = {
  societyId: string;
  // eslint-disable-next-line no-unused-vars
  handleChangeSelectedTemplate: (option: any) => void;
};

const ChangeCouncilTemplateModalBody: FC<Props> = ({
  societyId,
  handleChangeSelectedTemplate,
}: Props) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const templatesOptionsData = useGetTemplateOptions(societyId);
  const { templateOptions } = templatesOptionsData.data;
  const { isLoading } = templatesOptionsData;

  return (
    <>
      <Modal.Body>
        <div className="row mb-4">
          <div className="col">
            <div className="form-group">
              <label className="form-label" htmlFor="council-template-select">
                {t('Template')}
              </label>
              <Select
                id="council-template-select"
                closeMenuOnSelect
                className="react-select-lg"
                options={templateOptions}
                components={animatedComponents}
                onChange={handleChangeSelectedTemplate}
                placeholder={t('Select')}
                isLoading={isLoading}
                loadingMessage={() => t('LoadingTemplates')}
                noOptionsMessage={() => t('NoTemplatesAvailable')}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ChangeCouncilTemplateModalBody;
