import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';

const OwnershipView: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useAddRealtyModal();

  return (
    <div className="row gy-4">
      <div className="col-md-12 d-flex align-items-center">
        <h5 className="mb-0">{t('Ownership')}</h5>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-owner">
            {t('Owner')}
          </label>

          <input
            id="realty-owner"
            type="text"
            className="form-control form-control-lg"
            value={data.owner}
            onChange={actions.handleChangeOwner}
            placeholder={t('IntroduceOwner')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-lessee">
            {t('Lessee')}
          </label>

          <input
            id="realty-lessee"
            type="text"
            className="form-control form-control-lg"
            value={data.lessee}
            onChange={actions.handleChangeLessee}
            placeholder={t('IntroduceLessee')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label
            className="form-label w-100"
            htmlFor="realty-sale-price-amount"
          >
            {t('SalePrice')} (€)
          </label>

          <input
            id="realty-sale-price-amount"
            type="number"
            min={0}
            className="form-control form-control-lg"
            value={data.salePriceAmount}
            onChange={actions.handleChangeSalePriceAmount}
          />
        </div>
      </div>
    </div>
  );
};

export default OwnershipView;
