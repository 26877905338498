import Loading from 'modules/_shared/components/Loading';
import PDFAdobeViewer from 'modules/_shared/components/PDFAdobeViewer';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useGetDocumentFrame } from 'modules/administratives/pages/CouncilDetails/hooks/useGetDocumentFrame';
import { useDispatch } from 'react-redux';

import { Document } from 'types';

type Props = {
  document: Document;
  size: string;
};

const DocumentViewer = ({ document, ...props }: Props) => {
  const dispatch = useDispatch();

  const documentName = document.name;
  const documentFrameResult = useGetDocumentFrame({ document });

  const { documentFrame } = documentFrameResult.data;
  const { isLoading } = documentFrameResult;

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  return (
    <section>
      <div className="nk-block-head nk-block-head-sm mt-3 mb-0">
        <div className="nk-block-between position-relative">
          <div className="nk-block-head-content d-flex justify-content-between w-100 px-3">
            <h5 className="title nk-block-title mb-0">{documentName}</h5>

            <a className="close cursor-pointer" onClick={handleCloseModal}>
              <em className="icon ni ni-cross" />
            </a>
          </div>
        </div>
      </div>

      {documentFrame && (
        <PDFAdobeViewer
          fileUrl={documentFrame}
          fileName={documentName}
          height="750px"
          className="border rounded"
        />
      )}

      {isLoading && <Loading />}
    </section>
  );
};

export default DocumentViewer;
