import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  hasOptions?: boolean;
};

const DocumentsHeader: FC<Props> = ({ hasOptions = true }) => {
  const { t } = useTranslate();

  return (
    <div className="nk-files-head">
      <div className="nk-file-item">
        <div className="nk-file-info">
          <span> {t('Name')}</span>
        </div>
        <div className="nk-file-meta">
          <span> {t('Date')}</span>
        </div>
        <div className="nk-file-members">
          <span> {t('Link')}</span>
        </div>
        {hasOptions && <div className="nk-file-members" />}
      </div>
    </div>
  );
};

export default DocumentsHeader;
