import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { useHistory } from 'react-router-dom';

import { Society } from 'types';
import Button from 'modules/_shared/components/Buttons';
import { useTranslate } from 'hooks/useTranslate';
import { getNextAndPreviousOperations } from '../utils/getNextAndPreviousOperations';
import { getOperationTooltip } from '../utils/getOperationTooltip';

type TransactionDetailsNavigationButtonsProps = {
  society: Society;
  operationId: string | undefined;
};

const baseURL = '/detalle-transacciones';
const tooltipClass = 'w-max-300px z-1000';
const tooltipPlace = 'bottom';

const TransactionDetailsNavigationButtons: FC<
  TransactionDetailsNavigationButtonsProps
> = ({ society, operationId }) => {
  const { t } = useTranslate();
  const history = useHistory();

  const { nextOperation, previousOperation } = getNextAndPreviousOperations(
    society,
    operationId
  );

  const handleClickNext = () => {
    if (nextOperation) {
      history.push(`${baseURL}/${society._id}/${nextOperation}`);
    }
  };

  const handleClickPrevious = () => {
    if (previousOperation) {
      history.push(`${baseURL}/${society._id}/${previousOperation}`);
    }
  };

  return (
    <>
      <ul className="transaction-details-nav-buttons-wrapper d-flex g-3">
        <li>
          <Button
            variant="light"
            icon="chevron-left"
            disabled={!previousOperation}
            onClick={handleClickPrevious}
            data-tooltip-id="previousTooltip"
          />
        </li>

        <li>
          <Button
            variant="light"
            icon="chevron-right"
            disabled={!nextOperation}
            onClick={handleClickNext}
            data-tooltip-id="nextTooltip"
          />
        </li>
      </ul>

      <Tooltip
        id="previousTooltip"
        className={tooltipClass}
        place={tooltipPlace}
      >
        {previousOperation
          ? getOperationTooltip('PREV', previousOperation, society)
          : t('NoPreviousOperation')}
      </Tooltip>

      <Tooltip id="nextTooltip" className={tooltipClass} place={tooltipPlace}>
        {nextOperation
          ? getOperationTooltip('NEXT', nextOperation, society)
          : t('NoNextOperation')}
      </Tooltip>
    </>
  );
};

export default TransactionDetailsNavigationButtons;
