import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Council, Society } from 'types';

import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';

type CouncilMembersHeaderProps = {
  council: Council | null;
  society: Society | null;
};

const CouncilMembersHeader: FC<CouncilMembersHeaderProps> = ({
  council,
  society,
}) => {
  const history = useHistory();

  const handleGoBackToCouncilsList = () => {
    if (!society) return;
    history.push(`/consejos/${society._id}`);
  };

  return (
    <PageListHeader
      subTitle={council?.name || ''}
      subTitleSuffix={society?.name || ''}
      actions={[
        <GoBackButton customAction onClick={handleGoBackToCouncilsList} />,
      ]}
    />
  );
};

export default CouncilMembersHeader;
