import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  documentName: string | undefined;
  documentFile: File | undefined;
  handleAddDocument: any;
};

const AddCouncilDocumentModalFooter: FC<Props> = ({
  documentName,
  documentFile,
  handleAddDocument,
}) => {
  const { t } = useTranslate();

  const isButtonDisabled = !documentName || !documentFile;

  return (
    <>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-primary "
          onClick={handleAddDocument}
          disabled={isButtonDisabled}
        >
          {t('SaveDocument')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default AddCouncilDocumentModalFooter;
