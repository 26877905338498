/* eslint-disable no-use-before-define */
import { Document } from './Document';
import { Operation } from './Operation';
import { Partner } from './Partner';
import { Share } from './Share';

export type Society = {
  _id: string;
  name: string;
  cif: string;
  web: string;
  phone: string;
  creationDate: Date;
  legalAddress: {
    line1: string;
    zip: string;
    city: string;
    country: string;
  };
  additional: SocietyAdditional;
  language: string;
  societyLanguage: string;
  societyType: keyof typeof SocietyTypes;
  activePartners: number;
  currentPartner: number;
  currentBeneficiary: number;
  currentInvestor: number;
  nominalValue: number;
  annualValues: {
    year: string;
    contableValue: number;
    billing: number;
    ebitda: number[];
    modifiedDate: Date;
  }[];
  sharesCount: {
    actual: number;
    future: number;
    total?: number;
    drafts: number;
  };
  mainAdmin: string;
  documents: Document[];
  shares: Share[];
  operations: Operation[];
  stockPlans: Record<string, any>[];
  drafts: Record<string, any>[];
  tenderOffers: Record<string, any>[];
  boards: Record<string, any>[];
  partners: Partner[];
  beneficiaries: Record<string, any>[];
  investors: Record<string, any>[];
  administrators: Record<string, any>[];
  guests: Record<string, any>[];
  employees: Record<string, any>[];
  employeesPlans: Record<string, any>[];
  sendNotifications: boolean;
  societyValues: SocietyValue[];
  minutebookFolders: { label: string }[];
  myFolders: { label: string }[];
  councilMinutes: { label: string }[];
  mainDirector: string;
  directors: SocietyDirector[];
  representatives: SocietyRepresentative[];
  auditors: SocietyAuditor[];
  communications: Record<string, any>[];
  updateSocietyValueND: boolean;
  updateSocietyValueFD: boolean;
  integrations: {
    factorial: {
      enabled: boolean;
      key: string;
      validated: boolean;
    };
    personio: {
      enabled: boolean;
      key: string;
      validated: boolean;
    };
  };
  isSocietyHolding: boolean;
  isSocietyDemo: boolean;
  hasAccessCaptable: boolean;
  hasShareTags: boolean;
  sharesCertificateTemplate: string;
  demos: Record<string, any>[];
  customization: SocietyCustomization;
  configuration: SocietyConfiguration;
  metadata: SocietyAdditional;
};

export type SocietyChart = {
  holdingId: string;
  holdingName: string;
  holdingCIF: string;
  holdingLogo: string;
  holdingShares: number;
  holdingPercentND: number | null;
  holdingPercentFD: number | null;
  holdingReference: string[] | null;
  holdingReferenceLevel: number;
  holdingKey: string;
  holdingValue?: number;
  holdingPartners?: any;
  holdingEmail?: string;
  holdingUserType?: string;
};

export type SocietyCustomization = {
  theme: {
    logo: string;
    headerColor: string;
    headerTextColor: string;
    headerTextHoverColor: string;
    emailFrameColor: string;
    emailFrameLogo: string;
    emailHeadingsColor: string;
    emailBodyTextColor: string;
    emailLinkColor: string;
    emailButtonColor: string;
    emailButtonTextColor: string;
    buttonRounded: boolean;
  };
};

export type SocietyConfiguration = {
  hasWhiteBranding: boolean;
  hasCustomLanding?: boolean;
  hasLoginRedirect?: boolean;
  hasOldPartnersOn?: boolean;
  hasIncentiveChart?: boolean;
  hasBeneficiaryStrikeDisabled?: boolean;
  hasBeneficiaryPercentDisabled?: boolean;
  communications?: SocietyCommunicationsConfiguration;
  customLanding?: Record<string, any>;
};

export type SocietyEmailTheme = {
  logo: string;
  frameColor: string;
  headingsColor: string;
  bodyTextColor: string;
  linkColor: string;
  buttonColor: string;
  buttonTextColor: string;
  buttonRounded: string;
};

export type SocietyCommunicationsConfiguration = {
  senderName: string;
  senderEmail: string;
  senderDomain?: string;
  replyEnabled: boolean;
  replyEmail: string;
  customLogo: string;
  customBackgroundColor: string;
  hasWhiteBranding: boolean;
  emailSignatureEnabled: boolean;
  emailSignatureHTML: string;
  emailSignature?: SocietyEmailSignature;
  theme: SocietyEmailTheme;
};

export type SocietyEmailSignature = {
  name: string;
  jobTitle: string;
  logo: string;
  website: string;
  phone: string;
  address: string;
  footer: string;
};

export enum SocietyTypes {
  SL = 'SL', // eslint-disable-line
  SA = 'SA', // eslint-disable-line
  COOP = 'COOP', // eslint-disable-line
  OTHER = 'OTHER', // eslint-disable-line
}

export type SocietyPermissions = {
  customLanding: { enabled: boolean };
  dashboard: { enabled: boolean };
  partners: { enabled: boolean };
  portfolio: { enabled: boolean };
  drafts: { enabled: boolean };
  plans: { enabled: boolean };
  transactions: { enabled: boolean };
  communications: { enabled: boolean };
  boards: { enabled: boolean };
  documents: { enabled: boolean };
  societyConfiguration: { enabled: boolean };
};

export type SocietyValue = {
  date: Date;
  value: number;
  shareValue: number;
  isPriceByShare: boolean;
  futureValues?: number[];
};

export type SocietyDirectorType =
  | 'UNIQUE_ADMIN'
  | 'POOLED_ADMIN'
  | 'COUNSELOR'
  | 'DELEGATE_COUNSELOR'
  | 'COUNCIL_SECRETARY'
  | 'INDEPENDENT_COUNSELOR'
  | 'NON_EXECUTIVE_COUNSELOR'
  | 'JOINT_DELEGATE_COUNSELOR'
  | 'COUNCIL_PRESIDENT'
  | 'NON_COUNSELOR_VOWEL'
  | 'SOLIDARY_ADMIN'
  | 'SECRETARY_NO_DIRECTOR'
  | 'COUNCIL_VICE_PRESIDENT'
  | 'NON_DIRECTOR_DEPUTY_SECRETARY'
  | 'AUDITOR'
  | 'TREASURER';

export type SocietyDirector = {
  _id?: string;
  socialDenomination: string;
  cif: string;
  nationality: string;
  image: string;
  address: string;
  birthDate: string;
  initDate: string;
  endDate: string;
  condition: string;
  email: string;
  directorType: string;
  directorStatus: string;
  documents: string[];
  isNaturalPerson: boolean;
  representative: {
    name: string;
    cif: string;
    email: string;
    address: string;
    nationality: string;
  };
};

export type SocietyAuditor = {
  _id?: string;
  socialDenomination: string;
  cif: string;
  nationality: string;
  image?: string;
  address: string;
  email: string;
  initDate: string;
  endDate: string;
  auditorStatus: string;
  documents: string[];
};

export type SocietyRepresentative = {
  _id: string;
  address: string;
  comments: string;
  documents: string[];
  email: string;
  endDate: string;
  initDate: string;
  nationality: string;
  image?: string;
  nif: string;
  representativeStatus: string;
  representativeType: string;
  socialDenomination: string;
  tag: string;
  maximumAmount: number;
  notarialRegistration?: {
    notaryName: string;
    notaryCity: string;
    protocolNr: string;
  };
};

export type SocietyAdditional = {
  logo: string;
  description: string;
  certificateSign: string;
  identificationNumbers?: { key: string; value: string }[];
  incorporation?: string;
  fiscalYear?: string | null;
  reservedMatters?: string;
  [key: string]: any;
};
