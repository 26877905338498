import { ChangeEvent, useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import 'assets/styles/Components.scss';
import { Accordion, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

type Props = {
  className?: string;
  defaultPermissions?: Array<Record<string, any>>;
  onChangePermissions?: (permissions: Array<Record<string, any>>) => void; // eslint-disable-line no-unused-vars
};

const SocietyGuestsPermissionsForm = (props: Props) => {
  const { t } = useTranslate();
  const customLandingTitle = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.customLanding?.menuLabel ||
      ''
  );

  const {
    className = '',
    defaultPermissions = [],
    onChangePermissions,
  } = props;

  const permissionPrefixes = {
    customLanding: customLandingTitle,
  };

  const [permissions, setPermissions] = useState(defaultPermissions);
  const [isOpened, setIsOpened] = useState(false);

  const handleToggleAccordion = () => {
    setIsOpened(!isOpened);
  };

  const handleSelectPermission = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;

    const permission = permissions?.find(
      (permission) => `checkbox-${permission.name}` === id
    );

    if (!permission?.enabled) {
      return;
    }

    permission.checked = checked;

    const newPermissions = permissions?.map((p) =>
      p.name === permission.name ? permission : p
    );

    setPermissions(newPermissions);

    if (onChangePermissions) {
      onChangePermissions(newPermissions);
    }
  };

  const getPermissionPrefix = (
    permissionName: keyof typeof permissionPrefixes
  ) => {
    const prefix = permissionPrefixes?.[permissionName] || '';

    return prefix ? (
      <>
        <span className="text-muted ml-1">({prefix})</span>
      </>
    ) : null;
  };

  return (
    <Accordion id="accordion" className={`accordion border ${className}`}>
      <Accordion.Toggle
        as={Button}
        variant="link"
        eventKey="0"
        onClick={handleToggleAccordion}
      >
        <span
          className={`accordion-head cursor-pointer text-left pl-2 ${
            isOpened ? '' : 'collapsed'
          }`}
        >
          <h6 className="title">
            <em className="icon ni ni-lock-fill fs-16px mr-2" />
            {t('LimitAccessToSections')}
          </h6>
          <span className="accordion-icon" />
        </span>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey="0">
        <div className="accordion-inner">
          <p>{t('SelectSectionsWithGuestAccess')}</p>

          <li className="list list-unstyled">
            {permissions?.map((permission) => (
              <ul className="list list-unstyled">
                <div className="custom-control custom-control-xs custom-checkbox">
                  <input
                    type="checkbox"
                    id={`checkbox-${permission.name}`}
                    className="custom-control-input"
                    checked={permission.checked}
                    disabled={!permission.enabled}
                    onChange={handleSelectPermission}
                  />
                  <label
                    className={`custom-control-label ${
                      !permission.enabled ? 'text-muted cursor-not-allowed' : ''
                    }`}
                    htmlFor={`checkbox-${permission.name}`}
                  >
                    {t(permission.label)}
                    {getPermissionPrefix(permission.name)}
                  </label>
                </div>
              </ul>
            ))}
          </li>
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default SocietyGuestsPermissionsForm;
