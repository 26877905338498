export const contextInitValue = {
  data: {
    date: '',
    minDate: '',
  },
  actions: {
    // eslint-disable-next-line no-unused-vars, no-undef
    handleChangeDate(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleCloseModal(): void {
      throw new Error('Function not implemented.');
    },
    handleDownloadBook(): void {
      throw new Error('Function not implemented.');
    },
  },
};
