import { FC, useEffect, useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { setModal } from 'modules/_shared/redux/modalActions';

import { useGlobalSearch } from './hooks/useGlobalSearch';
import { tableColumns } from './constants/tableColumns';
import CustomDataTable from '../../Tables/CustomDataTable';
import TableSkeleton from '../../Tables/TableSkeleton';
import GlobalSearchSummaryBar from './components/GlobalSearchSummaryBar';

type GlobalSearchProps = {
  size: string;
};

const GlobalSearch: FC<GlobalSearchProps> = ({ ...props }) => {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const { data, isLoading } = useGlobalSearch();

  const [tableData, setTableData] = useState<any[]>([]);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const fullData = [
      ...data.directors,
      ...data.representatives,
      ...data.auditors,
      ...data.committeeMembers,
    ];

    setTableData(fullData);
  }, [data, i18n.language]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title mb-0">
          {t('GlobalSearch')}
          <span className="fs-14px fw-normal d-block mt-1 text-soft">
            {t('GlobalSearchDescription')}
          </span>
        </h5>

        <a className="close cursor-pointer" onClick={handleCloseModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        {isLoading && (
          <>
            <div className="card-inner">
              <p className="placeholder-glow">
                <span className="placeholder col-12 h-40px" />
              </p>
            </div>

            <TableSkeleton rows={10} />
          </>
        )}

        {!isLoading && data && (
          <>
            <GlobalSearchSummaryBar summary={data} />

            <div className="mt-4">
              <CustomDataTable
                data={tableData}
                columns={tableColumns}
                searchBy={['name', 'email', 'cif']}
                searchByPlaceholder={[t('name'), t('email'), t('CIF')].join()}
                pagination
                className="nk-tb-list"
                rowsPerPage={10}
                actions
                showDense
                showDownload={false}
              />
            </div>
          </>
        )}
      </Modal.Body>
    </>
  );
};

export default GlobalSearch;
