import { axiosV1 } from 'interceptors/axios';
import actionTypes from 'redux/actions/actionTypes';
import employeesSource from 'constants/employeesSource';
import { decryptResponse } from 'utils/token';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { addAlert } from 'modules/_shared/redux/alertActions';

const URL_EMPLOYEES = process.env.REACT_APP_FACTORIAL_EMPLOYEES_URL;
const URL_CONTRACTS = process.env.REACT_APP_FACTORIAL_CONTRACTS_URL;
const URL_TEAMS = process.env.REACT_APP_FACTORIAL_TEAMS_URL;
const URL_SUPPLEMENTS = process.env.REACT_APP_FACTORIAL_SUPPLEMENTS_URL;
const URL_COMPENSATIONS = process.env.REACT_APP_FACTORIAL_COMPENSATIONS_URL;

const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'x-api-key': process.env.REACT_APP_FACTORIAL_KEY,
  },
};

export const getContractsFromFactorial = () => async () => {
  let contracts = [];
  await fetch(URL_CONTRACTS, options)
    .then((response) => response.json())
    .then((response) => {
      contracts = response;
      return response;
    })
    .catch(() => {
      throw new Error('ERROR_GETTING_EMPLOYEES');
    });
  return contracts;
};

export const getTeamsFromFactorial = () => async () => {
  let teams = [];
  await fetch(URL_TEAMS, options)
    .then((response) => response.json())
    .then((response) => {
      teams = response;
      return response;
    })
    .catch(() => {
      throw new Error('ERROR_GETTING_EMPLOYEES');
    });
  return teams;
};

export const getEmployeesFromFactorial = () => async () => {
  let employees = [];

  await fetch(URL_EMPLOYEES, options)
    .then((response) => response.json())
    .then((response) => {
      employees = response;
      return response;
    })
    .catch(() => {
      throw new Error('ERROR_GETTING_EMPLOYEES');
    });

  return employees;
};

export const getSupplementsFromFactorial = () => async () => {
  let supplements = [];

  await fetch(URL_SUPPLEMENTS, options)
    .then((response) => response.json())
    .then((response) => {
      supplements = response;
      return response;
    })
    .catch(() => {
      throw new Error('ERROR_GETTING_EMPLOYEES_SUPPLEMENTS');
    });

  return supplements;
};

export const getCompensationsFromFactorial = () => async () => {
  let compensations = [];

  await fetch(URL_COMPENSATIONS, options)
    .then((response) => response.json())
    .then((response) => {
      compensations = response;
      return response;
    })
    .catch(() => {
      throw new Error('ERROR_GETTING_EMPLOYEES_COMPENSATIONS');
    });

  return compensations;
};

const parseFactorialEmployee = (employee) => {
  const employeeTeams =
    employee?.teams.map((team) => ({
      externalId: team.id,
      name: team.name,
      avatar: team.avatar,
      description: team.description,
      companyId: team.company_id,
      employees: team.employee_ids,
      leads: team.lead_ids,
    })) || [];

  const employeeContracts =
    employee?.contracts.map((contract) => ({
      externalId: contract.id,
      employeeId: contract.employee_id,
      jobTitle: contract.job_title,
      role: contract.role,
      level: contract.level,
      startDate: contract.starts_on,
      endDate: contract.ends_on,
      effectiveDate: contract.effective_on,
      salaryAmount: contract.salary_amount,
      salaryFrequency: contract.salary_frequency,
    })) || [];

  const employeeSupplements =
    employee?.supplements.map((supplement) => ({
      externalId: supplement.id,
      employeeId: supplement.employee_id,
      contractsCompensationId: supplement.contracts_compensation_id,
      contractsTaxonomyId: supplement.contracts_taxonomy_id,
      amountInCents: supplement.amount_in_cents,
      unit: supplement.unit,
      effectiveOn: supplement.effective_on,
      updatedAt: supplement.updated_at,
      descriptionEntity: supplement.description_entity,
      createdByAccessId: supplement.created_by_access_id,
      payrollPolicyPeriodId: supplement.payroll_policy_period_id,
    })) || [];

  const employeeCompensations =
    employee?.compensations.map((compensation) => ({
      externalId: compensation.id,
      contractVersionId: compensation.contract_version_id,
      description: compensation.description,
      compensationType: compensation.compensation_type,
      amount: compensation.amount,
      recurrence: compensation.recurrence,
      firstPaymentOn: compensation.first_payment_on,
      syncWithSupplements: compensation.sync_with_supplements,
      contractsTaxonomyId: compensation.contracts_taxonomy_id,
      payrollPolicyId: compensation.payroll_policy_id,
      recurrenceCount: compensation.recurrence_count,
      startsOn: compensation.starts_on,
      unit: compensation.unit,
      calculation: compensation.calculation,
    })) || [];

  return {
    externalId: employee.id,
    firstName: employee.first_name || '',
    lastName: employee.last_name || '',
    fullName: employee.full_name || '',
    email: employee.email || '',
    birthDate: employee.birthday_on || null,
    gender: employee.gender || '',
    nationality: employee.nationality || '',
    createdAt: employee.created_at || null,
    updatedAt: employee.updated_at || null,
    cif: employee.identifier || '',
    cifType: employee.identifier_type || '',
    socialSecurityNumber: employee.social_security_number || '',
    companyId: employee.company_id || null,
    companyCif: employee.company_identifier || '',
    managerId: employee.manager_id || null,
    legalEntityId: employee.legal_entity_id || null,
    timeOffManagerId: employee.timeoff_manager_id || null,
    timeOffPolicyId: employee.timeoff_policy_id || null,
    terminationDate: employee.terminated_on || null,
    terminationReason: employee.termination_reason || '',
    terminationObservations: employee.termination_observations || '',
    address: {
      line1: employee.address_line_1 || '',
      line2: employee.address_line_2 || '',
      zip: employee.postal_code || '',
      city: employee.city || '',
      state: employee.state || '',
      country: employee.country || '',
    },
    teams: employeeTeams,
    contracts: employeeContracts,
    supplements: employeeSupplements,
    compensations: employeeCompensations,
  };
};

export const getEmployeesInfo =
  (source, currentEmployees) => async (dispatch) => {
    try {
      if (source === employeesSource.FACTORIAL) {
        const teams = await dispatch(getTeamsFromFactorial());
        const contracts = await dispatch(getContractsFromFactorial());
        const employees = await dispatch(getEmployeesFromFactorial());
        const supplements = await dispatch(getSupplementsFromFactorial());
        const compensations = await dispatch(getCompensationsFromFactorial());

        const employeesWithContractsAndTeams = employees.map((employee) => {
          const employeeContracts = contracts.filter(
            (contract) => contract.employee_id === employee.id
          );

          const employeeTeams = teams.filter((team) =>
            team.employee_ids.includes(employee.id)
          );

          const employeeSupplements = supplements.filter(
            (supplement) => supplement.employee_id === employee.id
          );

          const employeeContractsIds = employeeContracts.map(
            (contract) => contract.id
          );

          const employeeCompensations = compensations.filter((compensation) =>
            employeeContractsIds.includes(compensation.id)
          );

          return parseFactorialEmployee({
            ...employee,
            contracts: employeeContracts,
            teams: employeeTeams,
            supplements: employeeSupplements,
            compensations: employeeCompensations,
          });
        });

        const currentEmployeesIds = currentEmployees.map(
          (employee) => employee.externalId
        );
        const newEmployees = [];
        const updatedEmployees = [];

        employeesWithContractsAndTeams.forEach((employee) => {
          if (!currentEmployeesIds.includes(employee.externalId)) {
            newEmployees.push(employee);
          } else {
            updatedEmployees.push(employee);
          }
        });

        return { newEmployees, updatedEmployees };
      }
    } catch (error) {
      throw new Error('ERROR_GETTING_EMPLOYEES', error);
    }
  };

export const getSocietyEmployees = (societyId) => async (dispatch) => {
  try {
    const query = societyId ? { params: { societyId } } : { params: {} };

    const { data } = await axiosV1.get(`/employees`, query);

    const decodedData = await decryptResponse(data);

    dispatch({
      type: actionTypes.GET_EMPLOYEES,
      data: decodedData,
    });
  } catch (error) {
    return dispatch(addAlert(alertBodyTypes.ERROR_IMPORTING_EMPLOYEES));
  }
};

export const getEmployeeById = (employeeId) => async (dispatch) => {
  try {
    const { data } = await axiosV1.get(`/employees/${employeeId}`);

    const decodedData = await decryptResponse(data);

    return decodedData;
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.ERROR_GETTING_EMPLOYEE));
  }
};

export const saveEmployees =
  (employees, source, societyId) => async (dispatch) => {
    const newEmployeesForUpload = employees.newEmployees?.map((employee) => ({
      ...employee,
      source,
      society: societyId,
      importationDate: Date.now(),
    }));

    const oldEmployeesForUpdate = employees.updatedEmployees?.map(
      (employee) => ({
        ...employee,
        source,
        society: societyId,
        importationDate: Date.now(),
      })
    );

    const body = {
      societyId,
      newEmployees: newEmployeesForUpload,
      existentEmployees: oldEmployeesForUpdate,
    };

    try {
      const response = await axiosV1.post(`/employees`, body);

      const decodedData = await decryptResponse(response);

      dispatch({
        type: actionTypes.GET_EMPLOYEES,
        data: decodedData.data,
      });

      if (decodedData && decodedData.status === 200) {
        dispatch(addAlert(alertBodyTypes.EMPLOYEES_IMPORTED_SUCCESSFULLY));
      }
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_IMPORTING_EMPLOYEES));
    }
  };
