import { FC } from 'react';

import { RealtyTitle } from 'types';
import TitlesTableHeader from './TitlesTableHeader';
import TitlesTableBody from './TitlesTableBody';

type Props = {
  titles: RealtyTitle[];
  // eslint-disable-next-line no-unused-vars
  handleRemoveTitle: (index: number) => void;
};

const TitlesTable: FC<Props> = ({ titles, handleRemoveTitle }) => (
  <>
    <table className="table my-2">
      <TitlesTableHeader />
      <TitlesTableBody titles={titles} handleRemoveTitle={handleRemoveTitle} />
    </table>
  </>
);

export default TitlesTable;
