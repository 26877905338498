import { FC } from 'react';

import { SocietyAuditor } from 'types';

import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';
import SocietyAuditorsTableHeader from './SocietyAuditorsTableHeader';
import SocietyAuditorsTableBody from './SocietyAuditorsTableBody';

type Props = {
  societyId: string;
  societyAuditors: SocietyAuditor[];
};

const SocietyAuditorsTable: FC<Props> = ({ societyId, societyAuditors }) => {
  const { isReadOnly } = useGetSocietySectionPermissions().data;

  return (
    <div className="card card-bordered card-preview">
      <div className="table-responsive">
        <table className="nk-tb-list nk-tb-ulist">
          <SocietyAuditorsTableHeader isReadOnly={isReadOnly} />

          <SocietyAuditorsTableBody
            societyId={societyId}
            societyAuditors={societyAuditors}
            isReadOnly={isReadOnly}
          />
        </table>
      </div>
    </div>
  );
};

export default SocietyAuditorsTable;
