import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import Button from 'modules/_shared/components/Buttons';
import { Tooltip } from 'react-tooltip';

type Props = {
  societyName: string;
  activeSocietyRepresentativesCount: number;
  currentSocietyRepresentativesView: boolean;
  handleAddRepresentative: () => void;
  handleChangeRepresentativesView: () => void;
  handleOpenGlobalSearch: () => void;
};

const SocietyRepresentativesPageHeader: FC<Props> = ({
  societyName,
  activeSocietyRepresentativesCount,
  currentSocietyRepresentativesView,
  handleAddRepresentative,
  handleChangeRepresentativesView,
  handleOpenGlobalSearch,
}) => {
  const { t } = useTranslate();

  const representativesViewIcon = currentSocietyRepresentativesView
    ? 'icon ni ni-eye'
    : 'icon ni ni-eye-off';

  const representativesViewTooltipText = currentSocietyRepresentativesView
    ? t('SeeNotCurrent')
    : t('HideNotCurrent');

  return (
    <>
      <PageListHeader
        title={t('Representatives')}
        subTitle={societyName}
        description={`${societyName || ''} ${t('HasActiveRepresentatives', {
          count: activeSocietyRepresentativesCount,
        })}`}
        actions={[
          <Button
            id="open-global-search-button"
            icon="ni ni-search"
            variant="light"
            onClick={handleOpenGlobalSearch}
          />,
          <Button
            id="toggle-members-view-button"
            icon={representativesViewIcon}
            variant="light"
            onClick={handleChangeRepresentativesView}
          />,
          <Button
            icon="ni ni-plus"
            variant="primary"
            onClick={handleAddRepresentative}
          >
            {t('AddRepresentative')}
          </Button>,
        ]}
      />

      <Tooltip
        anchorSelect="#toggle-members-view-button"
        content={representativesViewTooltipText}
      />

      <Tooltip
        anchorSelect="#open-global-search-button"
        content={t('GlobalSearch')}
      />
    </>
  );
};

export default SocietyRepresentativesPageHeader;
