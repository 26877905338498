import { SocietyDirector } from 'types';
import adminMemberStatus from '../constants/adminMemberStatus';

export const getAdminMemberStatus = (member: SocietyDirector) => {
  const activeStatus = adminMemberStatus[0];
  const inactiveStatus = adminMemberStatus[1];

  const datesDiff = new Date(member?.endDate).getTime() - new Date().getTime();
  if (!member?.endDate || datesDiff > 0) return activeStatus;
  return inactiveStatus;
};
