import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';

const CadastralView: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useAddRealtyModal();

  return (
    <div className="row gy-4">
      <div className="col-md-12 d-flex align-items-center">
        <h5 className="mb-0">{t('CadastralValue')}</h5>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-amount">
            {t('Amount')} (€)
          </label>

          <input
            id="realty-amount"
            type="number"
            min={0}
            className="form-control form-control-lg"
            value={data.cadastralAmount}
            onChange={actions.handleChangeCadastralAmount}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-year">
            {t('Year')}
          </label>

          <input
            id="realty-year"
            type="number"
            min={0}
            className="form-control form-control-lg"
            value={data.cadastralYear}
            onChange={actions.handleChangeCadastralYear}
          />
        </div>
      </div>
    </div>
  );
};

export default CadastralView;
