import { Council, Society } from 'types';
import { generateDocumentFromTemplate } from 'utils/generateDocumentFromTemplate.ts';

type Props = {
  announcementTemplateId?: string;
  actTemplateId?: string;
  certificationTemplateId?: string;
  society: Society;
  council: Council;
  userId: string;
};

export const generateCouncilDocuments = async ({
  announcementTemplateId,
  actTemplateId,
  certificationTemplateId,
  society,
  council,
  userId,
}: Props) => {
  let actDocumentId;
  let certificationDocumentId;

  if (actTemplateId) {
    const actDocument = await generateDocumentFromTemplate({
      templateId: actTemplateId,
      society,
      council,
      userId,
    });
    actDocumentId = actDocument?._id;
  }

  if (certificationTemplateId) {
    const certificationDocument = await generateDocumentFromTemplate({
      templateId: certificationTemplateId,
      society,
      council,
      userId,
    });
    certificationDocumentId = certificationDocument?._id;
  }

  return {
    announcementTemplateId,
    actDocumentId,
    certificationDocumentId,
  };
};
