// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';

const cleanText = (text, options = {}) => {
  if (text === null || text === undefined) {
    return '';
  }

  // Convertir a string si no lo es
  const stringValue = String(text);
  const trimmedText = stringValue.trim();

  // Decodificar entidades HTML antes de sanitizar
  const decoder = document.createElement('textarea');
  decoder.innerHTML = trimmedText;
  const decodedText = decoder.value;

  // Configuración por defecto de DOMPurify
  const defaultConfig = {
    ALLOWED_TAGS: [], // No permitir ninguna etiqueta HTML por defecto
    ALLOWED_ATTR: [], // No permitir ningún atributo
    ALLOW_DATA_ATTR: false, // No permitir atributos data-*
    USE_PROFILES: {
      html: false, // Deshabilitar HTML
      svg: false, // Deshabilitar SVG
      svgFilters: false, // Deshabilitar filtros SVG
    },
    SANITIZE_DOM: true, // Sanitizar el DOM
    KEEP_CONTENT: true, // Mantener el contenido de texto
  };

  // Mezclar la configuración por defecto con las opciones proporcionadas
  const sanitizeConfig = {
    ...defaultConfig,
    ...options,
    USE_PROFILES: {
      ...defaultConfig.USE_PROFILES,
      ...(options.USE_PROFILES || {}),
    },
  };

  // Sanitizar el texto
  const sanitizedText = DOMPurify.sanitize(decodedText, sanitizeConfig);

  // Solo aplicar los reemplazos adicionales si no se permiten etiquetas HTML
  if (!options.ALLOWED_TAGS || options.ALLOWED_TAGS.length === 0) {
    const finalText = sanitizedText
      .replace(/javascript:/gi, '')
      .replace(/data:/gi, '')
      .replace(/vbscript:/gi, '')
      .replace(/on\w+=/gi, '');

    return finalText;
  }

  return sanitizedText;
};

// Ejemplos de uso con diferentes configuraciones
export const cleanTextStrict = (text) => cleanText(text); // Más estricto, sin HTML

export const cleanTextWithBasicFormatting = (text) => {
  const result = cleanText(text, {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong'],
    ALLOWED_ATTR: [],
    USE_PROFILES: {
      html: true,
    },
  });

  return result;
};

export const cleanTextWithLinks = (text) => {
  if (!text) return '';

  // Primero sanitizamos permitiendo solo enlaces
  const sanitized = DOMPurify.sanitize(text, {
    ALLOWED_TAGS: ['a'],
    ALLOWED_ATTR: ['href'],
    ALLOWED_URI_REGEXP: /^(?:(?:(?:f|ht)tps?|mailto):)/i,
    USE_PROFILES: {
      html: true,
    },
  });

  // Luego removemos todas las etiquetas excepto <a>
  return sanitized.replace(/<(?!\/?(a|a\s[^>]*)>)[^>]*>/g, '').trim();
};

export const isTextInvalid = (text) => {
  if (!text) return true;

  const sanitized = cleanText(text);
  return sanitized !== text;
};

export const isValidEmailSubject = (text) => {
  const sanitized = cleanText(text);

  return sanitized === text;
};

export const isValidEmailBody = (text) => {
  const sanitized = cleanText(text, {
    ALLOWED_TAGS: ['a', 'b', 'i', 'em', 'strong', 'br', 'p'],
    ALLOWED_ATTR: ['href'],
    USE_PROFILES: {
      html: true,
    },
  });

  return sanitized === text;
};

export default cleanText;
