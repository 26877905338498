export default [
  {
    value: 'HYBRID',
    label: 'HybridAttendance',
  },
  {
    value: 'ONLINE',
    label: 'OnlineAttendance',
  },
  {
    value: 'FACE_TO_FACE',
    label: 'FaceToFaceAttendance',
  },
  {
    value: 'WRITTEN_NO_SESSION',
    label: 'WrittenNoSessionAttendance',
  },
];
