import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import sizes from 'constants/sizes';
import GlobalSearch from 'modules/_shared/components/Modals/GlobalSearch';
import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import AddSocietyAuditor from 'modules/_shared/components/Modals/AddSocietyAuditor';
import { useGetActualSociety } from 'hooks/useGetActualSociety';
import { useGetMenu } from '../../hooks/useGetMenu';
import useGetSocietyAuditors from './hooks/useGetSocietyAuditors';
import SocietyAuditorsPageHeader from './components/SocietyAuditorsPageHeader';
import SocietyAuditorsPageBody from './components/SocietyAuditorsPageBody';

function SocietyAuditors() {
  const { societyId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const [currentSocietyAuditorsView, setCurrentSocietyAuditorsView] =
    useState<boolean>(true);

  const { societyAuditors, activeSocietyAuditorsCount } = useGetSocietyAuditors(
    {
      society: actualSociety,
      currentSocietyAuditorsView,
    }
  ).data;

  useGetMenu();
  useGetActualSociety(societyId);

  const handleChangeSocietyAuditorsView = () => {
    setCurrentSocietyAuditorsView((prev) => !prev);
  };

  const handleOpenGlobalSearch = () => {
    dispatch(setModal(<GlobalSearch size={sizes.XL} />));
  };

  const handleAddAuditor = () => {
    if (societyId) {
      dispatch(
        setModal(
          <AddSocietyAuditor
            societyId={societyId}
            action="ADD"
            auditorData={null}
            auditorId={null}
            userId={user['_id']}
          />
        )
      );
    }
  };

  return actualSociety ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <SocietyAuditorsPageHeader
          societyName={actualSociety?.name}
          activeSocietyAuditorsCount={activeSocietyAuditorsCount}
          currentSocietyAuditorsView={currentSocietyAuditorsView}
          handleAddAuditor={handleAddAuditor}
          handleChangeAuditorsView={handleChangeSocietyAuditorsView}
          handleOpenGlobalSearch={handleOpenGlobalSearch}
        />

        <SocietyAuditorsPageBody
          societyId={actualSociety['_id']}
          societyAuditors={societyAuditors}
        />
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
}

export default SocietyAuditors;
