import { FC } from 'react';
import { RealtyTitle } from 'types';

type Props = {
  index: number;
  title: RealtyTitle;
  // eslint-disable-next-line no-unused-vars
  handleRemoveTitle: (index: number) => void;
};

const TitlesTableRow: FC<Props> = ({ index, title, handleRemoveTitle }) => (
  <>
    <td className="text-left" key={`title-label-${index}`}>
      {title?.label}
    </td>

    <td className="text-left" key={`title-description-${index}`}>
      {title?.description}
    </td>

    <td className="text-center" key={`title-remove-${index}`}>
      <button
        type="button"
        className="text-soft btn btn-icon pt-0 pb-0"
        onClick={() => handleRemoveTitle(index)}
      >
        <em className="icon ni ni-trash" />
      </button>
    </td>
  </>
);

export default TitlesTableRow;
