import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import MandatoryField from 'modules/_shared/components/MandatoryField';
import UploadDocument from 'modules/documents/components/UploadDocument';

type Props = {
  documentName: string;
  documentFile: File | undefined;
  isPrivateDocument: boolean;
  replaceAnnouncementDocument?: boolean;
  // eslint-disable-next-line no-unused-vars
  handleChangeDocumentName: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeDocumentFile: (file: File) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeIsPrivateDocument: () => void;
};

const AddCouncilDocumentModalBody: FC<Props> = ({
  documentName,
  documentFile,
  isPrivateDocument,
  replaceAnnouncementDocument,
  handleChangeDocumentName,
  handleChangeDocumentFile,
  handleChangeIsPrivateDocument,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Body>
        <UploadDocument
          file={documentFile}
          setFile={handleChangeDocumentFile}
          label="AddDocument"
          inputLabel={documentFile?.name}
          mandatory
        />

        <div className="form-group">
          <label className="form-label w-100" htmlFor="document-name">
            {t('Name')} <MandatoryField />
          </label>

          <input
            id="document-name"
            type="text"
            className="form-control form-control-lg"
            value={documentName}
            onChange={handleChangeDocumentName}
            placeholder={t('IntroduceDocumentName')}
          />
        </div>

        {!replaceAnnouncementDocument && (
          <div className="form-group">
            <div className="col-6 custom-control custom-radio ">
              <input
                type="radio"
                className="custom-control-input"
                name="private-document"
                id="private-document"
                checked={isPrivateDocument}
                onChange={handleChangeIsPrivateDocument}
              />
              <label
                className="custom-control-label"
                htmlFor="private-document"
              >
                {t('PrivateDocument')}
              </label>
            </div>

            <div className="col-6 custom-control custom-radio ">
              <input
                type="radio"
                className="custom-control-input"
                name="members-document"
                id="members-document"
                checked={!isPrivateDocument}
                onChange={handleChangeIsPrivateDocument}
              />
              <label
                className="custom-control-label"
                htmlFor="members-document"
              >
                {t('CouncelorsDocument')}
              </label>
            </div>
          </div>
        )}
      </Modal.Body>
    </>
  );
};

export default AddCouncilDocumentModalBody;
