import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useDownloadPartnerBookModal } from '../context/DownloadPartnerBookModalContext';

const DownloadPartnerBookModalBody: FC = () => {
  const { t } = useTranslate();
  const { data, actions } = useDownloadPartnerBookModal();

  return (
    <>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-12">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="realty-license-date">
                {t('DownloadPartnerBookInfo')}
              </label>

              <input
                type="date"
                className="form-control form-control-lg date-picker"
                id="realty-license-date"
                name="realty-license-date"
                placeholder="dd/mm/yyyy"
                value={data.date}
                min={data.minDate}
                onChange={actions.handleChangeDate}
                required
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default DownloadPartnerBookModalBody;
