import { FC } from 'react';

import { SocietyAuditor } from 'types';
import SocietyAuditorsTable from './SocietyAuditorsTable';

type Props = {
  societyId: string;
  societyAuditors: SocietyAuditor[];
};

const SocietyAuditorsPageBody: FC<Props> = ({ societyId, societyAuditors }) => (
  <div className="nk-block">
    <SocietyAuditorsTable
      societyId={societyId}
      societyAuditors={societyAuditors}
    />
  </div>
);

export default SocietyAuditorsPageBody;
