import { format } from 'date-fns';

import { TranslateFn } from 'hooks/useTranslate';
import { DATE_FORMAT, DEFAULT_CURRENCY } from 'constants/defaultConstants';
import { getFullAddress } from 'utils/filters';
import { formatNumberDecimals } from 'utils/formats';
import { Realty, RealtyLicense, RealtyPlot, RealtyTitle } from 'types';

export const getBuildingDescription = (plots: RealtyPlot[], t: TranslateFn) =>
  plots
    .map((plot) => {
      const { plotType } = plot;
      const number = plot?.plotNumber
        ? `${t('number')} ${plot.plotNumber}`
        : '';
      const subType = plot?.plotSubType ? `(${plot.plotSubType})` : '';
      const plotComments = plot?.comments ? plot.comments : '';

      const plotLabel = [plotType, number, subType, plotComments]
        .filter(Boolean)
        .join(' ');

      const buildings =
        plot?.buildings?.map((building) => {
          const buildingDescription = building.description;
          const buildingArea = building.area;

          return `  - ${buildingDescription} de ${buildingArea} m2`;
        }) || [];

      return buildings.length
        ? `${plotLabel}\n  ${t('Buildings')}:\n${buildings.join('\n')}`
        : plotLabel;
    })
    .join('\n\n');

export const getTitlesDescription = (titles: RealtyTitle[]) =>
  titles
    .map((title) => {
      const { label } = title;
      const titleDescription = title.description
        .map((description) => `  - ${description}`)
        .join('\n');

      return `${label}:\n${titleDescription}`;
    })
    .join('\n\n');

export const getLicensesDescription = (licences: RealtyLicense[]) =>
  licences.reduce((acc, license, index) => {
    const key = (index + 1).toString();
    const value = license?.date
      ? `(${format(new Date(license.date), DATE_FORMAT)}) ${license.name}`
      : license.name;

    return {
      ...acc,
      [key]: value,
    };
  }, {});

export const getSummaryDetailsList = (realty: Realty, t: TranslateFn) => [
  {
    label: t('Surface'),
    value: {
      TotalArea: `${realty.area.total} m2`,
      BuiltArea: `${realty.area.built} m2`,
    },
  },
  {
    label: t('Building'),
    value: getBuildingDescription(realty.plots, t),
  },
  { label: t('Location'), value: getFullAddress(realty.location) },
  { label: t('Lessee'), value: realty.lessee },
  { label: t('Owner'), value: realty.ownership },
  { label: t('Titles'), value: getTitlesDescription(realty.titles) },
  { label: t('Usage'), value: realty.usage },
  { label: t('Encumbrances'), value: realty.encumbrances.join(', ') },
  {
    label: t('Licenses'),
    value: getLicensesDescription(realty.licenses),
  },
  {
    label: t('SalePrice'),
    value: realty?.salePrice?.amount
      ? `${formatNumberDecimals(realty.salePrice?.amount)} ${
          realty.salePrice?.currency || DEFAULT_CURRENCY
        }`
      : t('NotAvailable'),
  },
  {
    label: t('CadastralValue'),
    value: {
      Amount: realty?.cadastralValue?.amount
        ? `${formatNumberDecimals(realty.cadastralValue.amount)} ${
            realty.cadastralValue.currency
          }`
        : t('NotAvailable'),
      Date: realty?.cadastralValue?.year
        ? realty.cadastralValue.year
        : t('NotAvailable'),
    },
  },
  {
    label: t('References'),
    value: {
      CADASTRALREFERENCE: realty.references?.cadastral || '-',
      REGISTRALREFERENCE: realty.references?.registry || '-',
    },
  },
  {
    label: t('LastUpdated'),
    value: format(new Date(realty.lastUpdated), DATE_FORMAT),
  },
];
