/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

const resetStateSociety = () => initialState.realties;

const createRealty = (realties: any, action: any) => {
  if (action.realty) {
    return [...realties, action.realty];
  }
  return [...realties];
};

const getRealties = (action: any) => action.realties;

const updateRealty = (realties: any, action: any) => {
  const currentId = action.realty['_id'];

  const updatedRealties = realties?.map((realty: any) => {
    if (realty['_id'] === currentId) {
      return action.realty;
    }
    return realty;
  });

  return [...updatedRealties];
};

const deleteRealty = (realties: any, action: any) => [
  ...realties.filter(
    (currentRealty: any) => currentRealty['_id'] !== action.realty['_id']
  ),
];

export default function reducer(realties = initialState.realties, action: any) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return resetStateSociety();

    case actionTypes.CREATE_REALTY:
      return createRealty(realties, action);

    case actionTypes.GET_REALTIES:
      return getRealties(action);

    case actionTypes.UPDATE_REALTY:
      return updateRealty(realties, action);

    case actionTypes.DELETE_REALTY:
      return deleteRealty(realties, action);

    default:
      return realties;
  }
}
