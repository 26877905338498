/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */

import screens from 'constants/screens';
import { store } from 'redux/store';
import { translate } from 'hooks/useTranslate';

import CellData from 'modules/_shared/components/Tables/CellData';
import { setModal } from 'modules/_shared/redux/modalActions';

export const tableColumns = [
  {
    name: translate('Nr'),
    field: 'index',
    hide: screens.SM,
    selector: (row, index) => index + 1,
    cell: (row, index) => index + 1,
    disableSortBy: true,
    disableFilters: true,
    center: true,
    grow: 0.3,
    compact: true,
    minWidth: 20,
    export: true,
  },
  {
    name: translate('Name'),
    field: 'name',
    selector: (row) => row.name,
    cell: (row) =>
      row.link
        ? CellData.PlainTextLink({
            text: row.name,
            url: row.link,
            onClick: () => {
              store.dispatch(setModal(null));
            },
          })
        : CellData.PlainText({
            text: row.name,
          }),
    sortable: true,
    export: true,
  },
  {
    name: translate('Email'),
    field: 'email',
    selector: (row) => row.email,
    cell: (row) =>
      CellData.PlainText({
        text: row.email,
        className: 'd-block text-ellipsis',
      }),
    sortable: true,
    export: true,
    omit: false,
  },
  {
    name: translate('CIF'),
    field: 'cif',
    selector: (row) => row.cif,
    cell: (row) =>
      CellData.PlainText({
        text: row.cif,
      }),
    sortable: true,
    export: true,
  },
  {
    name: translate('Role'),
    field: 'role',
    selector: (row) => row.role,
    cell: (row) =>
      CellData.PlainText({
        text: row.role,
      }),
    sortable: true,
    export: true,
  },
  {
    name: translate('Society'),
    field: 'society',
    selector: (row) => row.society,
    cell: (row) =>
      CellData.PlainText({
        text: row.society,
        className: 'd-block text-ellipsis',
      }),
    sortable: true,
    export: true,
  },
];
