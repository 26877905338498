import { Editor } from 'ckeditor5';

type Props = {
  editorInstance: Editor | null;
  // eslint-disable-next-line no-unused-vars
  handleChangeIsEditing: (value: boolean) => void;
};

export const saveTemplate = ({
  editorInstance,
  handleChangeIsEditing,
}: Props) => {
  if (editorInstance) {
    editorInstance.enableReadOnlyMode('readOnly');
    handleChangeIsEditing(false);

    const newContent = editorInstance.getData();
    return newContent || '';
  }
  return '';
};
