/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
// eslint-disable-next-line no-use-before-define
import React, { FC, useEffect, useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State } from 'redux/initialState';

import { Council, Society, User } from 'types';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import { saveCouncil } from 'modules/administratives/utils/saveCouncil';
import { editCouncil } from 'modules/administratives/utils/editCouncil';

import actionTypes from 'constants/actionTypes';
import eventTypes from 'constants/eventTypes';

import trackEvent from 'utils/trackEvent';
import { getFullAddress } from 'utils/filters';
import { hasShowDetails } from 'utils/showDetails';

import '../Modals.scss';
import UploadAnnouncementModal from './components/uploadAnnouncementModal';
import AddCouncilHeader from './components/AddCouncilHeader';
import AddCouncilBody from './components/AddCouncilBody';

import { AddCouncilFormState } from './types/AddCouncilForm';
import { addCouncilInitialState } from './constants/addCouncilInitialState';
import { getSelectorOptions } from './utils/getSelectorOptions';
import { getExistentPresidentAndSecretary } from './utils/getExistentPresidentAndSecretary';

type AddCouncilProps = {
  action?: keyof typeof actionTypes;
  council?: Council;
};

const AddCouncil: FC<AddCouncilProps> = ({
  action = actionTypes.ADD,
  council,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const history = useHistory();

  const user: User = useSelector((state: State) => state?.user);
  const actualSociety: Society = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const societyDocuments = useSelector((state: State) => state.documents);

  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState<AddCouncilFormState>(
    addCouncilInitialState
  );

  const { attendanceOptions, councilTypeOptions, directorOptions } =
    getSelectorOptions(actualSociety, t);

  const isSaveButtonDisabled = () =>
    (formState.president !== '' &&
      formState.president === formState.secretary) ||
    (formState.secretary !== '' && formState.president === formState.secretary);

  const updateFormState = (updates: Partial<AddCouncilFormState>) => {
    setFormState((prev) => ({ ...prev, ...updates }));
  };

  async function handleSaveCouncil(event: React.FormEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const councilData = {
        ...formState,
        user,
        society: actualSociety,
      };

      const council = await saveCouncil(councilData);

      const currentEvent = eventTypes.ADD_COUNCIL;
      trackEvent(mixpanel, currentEvent, {
        userId: user?.['_id'],
        userName: user?.name,
        userEmail: user?.email,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
        operation: currentEvent,
      });

      if (council) {
        dispatch(addAlert(alertBodyTypes.COUNCIL_CREATED_SUCCESSFULLY));
        const path = `/detalle-consejo/${actualSociety['_id']}/${council['_id']}`;
        history.push(path);
      }

      dispatch(setModal(null));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_ADDING_COUNCIL));
    } finally {
      setIsLoading(false);
    }
  }

  async function handleEditCouncil(event: React.FormEvent) {
    event.preventDefault();

    try {
      setIsLoading(true);

      const councilData = {
        ...formState,
        _id: council?._id,
        date: formState.date ? new Date(formState.date) : new Date(),
        society: actualSociety._id,
      };

      await editCouncil({
        councilData,
        actualSociety,
        societyDocuments,
        user,
      });

      const currentEvent = eventTypes.EDIT_COUNCIL;
      trackEvent(mixpanel, currentEvent, {
        userId: user?.['_id'],
        userName: user?.name,
        userEmail: user?.email,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
        operation: currentEvent,
      });

      dispatch(addAlert(alertBodyTypes.COUNCIL_UPDATED));
      setIsLoading(false);
      dispatch(setModal(null));
    } catch (error) {
      setIsLoading(false);
      console.error(error); // eslint-disable-line
    }
  }

  useEffect(() => {
    if (action === actionTypes.ADD) {
      const initialData = {
        ...getExistentPresidentAndSecretary(actualSociety),
        place: getFullAddress(actualSociety.legalAddress),
      };
      updateFormState(initialData);
    }

    if (action === actionTypes.EDIT && council) {
      const {
        name,
        topics,
        president,
        secretary,
        councilType,
        attendanceType,
        date,
        place,
        onlineAccess,
        digitalVoting,
      } = council;

      const selectedCouncilTypeOption = councilTypeOptions.find(
        (option) => option.value === councilType
      );

      const selectedAttendanceOption = attendanceOptions.find(
        (option) => option.value === attendanceType
      );

      const selectedPresidentOption = directorOptions.find(
        (option) => option.value === president
      );

      const selectedSecretaryOption = directorOptions.find(
        (option) => option.value === secretary
      );

      updateFormState({
        name,
        topics,
        president,
        secretary,
        councilType,
        attendanceType,
        date,
        place,
        onlineAccess,
        digitalVoting,
        selectedAttendanceOption,
        selectedCouncilTypeOption,
        selectedPresidentOption,
        selectedSecretaryOption,
      });
    }
  }, [action, council]);

  if (hasShowDetails()) {
    console.log('🚀 cclog ~ hasShowDetails:', council); // eslint-disable-line
  }

  return formState.isUploadModalVisible ? (
    <UploadAnnouncementModal updateFormState={updateFormState} />
  ) : (
    <>
      <AddCouncilHeader action={action as keyof typeof actionTypes} />
      <AddCouncilBody
        action={action as keyof typeof actionTypes}
        formState={formState}
        updateFormState={updateFormState}
        attendanceOptions={attendanceOptions}
        directorOptions={directorOptions}
        isSaveButtonDisabled={isSaveButtonDisabled}
        onEditCouncil={handleEditCouncil}
        onSaveCouncil={handleSaveCouncil}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default AddCouncil;
