import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { Beneficiary, Society } from 'types';
import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimalsLong,
} from 'utils/formats';

type BeneficiarySocietyMetricsProps = {
  beneficiary: Beneficiary;
  summary: {
    units: number;
    vestedUnits: number;
    FDpercent: number;
    exercisePrice: number;
  };
  decimalScale: number;
  onChangePage: (page: number) => void; // eslint-disable-line
};

const getTotalItems = (actualSociety: Society) => {
  let totalItems = 4;
  const { hasBeneficiaryPercentDisabled, hasBeneficiaryStrikeDisabled } =
    actualSociety?.configuration || {};

  if (hasBeneficiaryPercentDisabled) {
    totalItems -= 1;
  }

  if (hasBeneficiaryStrikeDisabled) {
    totalItems -= 1;
  }

  return totalItems;
};

const BeneficiarySocietyMetrics: FC<BeneficiarySocietyMetricsProps> = ({
  beneficiary,
  summary,
  decimalScale,
  onChangePage,
}) => {
  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const cardStyles = {
    4: 'col-6 col-lg-3 mb-4 mb-lg-0',
    3: 'col-6 col-lg-4 mb-4 mb-lg-0',
    2: 'col-6 col-lg-6 mb-4 mb-lg-0',
    1: 'col-12 col-lg-12 mb-4 mb-lg-0',
  };

  const totalItems = getTotalItems(actualSociety);

  return (
    <div className="card-inner">
      <h6 className="overline-title-alt mb-2">
        {t('ParticipationOfFullnameIn', {
          fullName: beneficiary?.name,
          societyName: actualSociety?.name,
        })}
      </h6>

      <div className="row text-center">
        <div
          className={`${
            cardStyles[totalItems as keyof typeof cardStyles]
          } cursor-pointer`}
          onClick={() => onChangePage(2)}
        >
          <div className="profile-balance-amount">
            <div className="number">{formatNumber(summary?.units) || 0}</div>
          </div>
          <div className="profile-stats">
            <span className="badge btn-outline-primary ">
              {t('TotalUnitsGranted')}
            </span>
          </div>
        </div>

        <div
          className={`${
            cardStyles[totalItems as keyof typeof cardStyles]
          } cursor-pointer`}
          onClick={() => onChangePage(3)}
        >
          <div className="profile-balance-amount">
            <div className="number">
              {formatNumber(summary?.vestedUnits) || 0}
            </div>
          </div>
          <div className="profile-stats ">
            <span className="badge btn-outline-success">
              {t('UnitsVested')}
            </span>
          </div>
        </div>

        {!actualSociety?.configuration?.hasBeneficiaryPercentDisabled && (
          <div className={cardStyles[totalItems as keyof typeof cardStyles]}>
            <div className="profile-balance-amount">
              <div className="number">
                {formatNumberDecimalsLong(
                  +(summary?.FDpercent ?? 0).toFixed(decimalScale)
                )}
                %
              </div>
            </div>
            <div className="profile-stats">
              <span className="badge badge-outline">{t('Percentage')}</span>
            </div>
          </div>
        )}

        {!actualSociety?.configuration?.hasBeneficiaryStrikeDisabled && (
          <div className={cardStyles[totalItems as keyof typeof cardStyles]}>
            <div className="profile-balance-amount">
              <div className="number">
                {formatCurrencyDecimals(summary?.exercisePrice) || 0}
              </div>
            </div>
            <div className="profile-stats">
              <span className="badge badge-outline">{t('ExercisePrice')}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BeneficiarySocietyMetrics;
