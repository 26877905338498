/* eslint-disable no-underscore-dangle */

import { useTranslate } from 'hooks/useTranslate';

import Page from 'modules/_shared/components/Pages/Page';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import TableLoader from 'modules/_shared/components/Tables/TableLoader';
import { useGetSocietyDirectedList } from 'modules/society/hooks/useGetSocietyDirectedList';

import './DirectedList.scss';

function DirectedList({ items = 10 }) {
  const { t } = useTranslate();

  const {
    data: { tableData, currentTableColumns },
    isLoading,
  } = useGetSocietyDirectedList();

  const getTableDescription = () =>
    tableData?.length === 0
      ? t('YouDonHaveSocietiesWhereYouAreDirector')
      : t('YouHaveSocietiesWhereYouAreDirector', {
          count: tableData?.length,
        });

  return (
    <Page>
      <PageListHeader
        subTitle={t('CouncilsTableTitle')}
        description={getTableDescription()}
      />

      {tableData?.length > 0 ? (
        <CustomDataTable
          data={tableData}
          columns={currentTableColumns}
          searchBy={t('Society')}
          pagination
          className="nk-tb-list"
          rowsPerPage={items}
          actions
          showDownload={false}
        />
      ) : (
        isLoading && <TableLoader rows={items} />
      )}
    </Page>
  );
}

export default DirectedList;
