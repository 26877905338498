import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { getDocuments } from 'modules/documents/redux/documentActions';

export const useGetSocietyDocuments = (societyId: string | undefined) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchSocietyDocuments = useCallback(async () => {
    if (!societyId) {
      return;
    }

    try {
      setIsLoading(true);
      await dispatch(getDocuments({ society: societyId }));
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [societyId, dispatch]);

  useEffect(() => {
    fetchSocietyDocuments();
  }, [fetchSocietyDocuments]);

  return { isLoading, isError };
};
