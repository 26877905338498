import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { useTranslate } from 'hooks/useTranslate';

import { format } from 'date-fns';

import { Council } from 'types';
import { formatDateToTime } from 'constants/formats';
import { isPhysicalAttendanceType } from '../utils/isPhysicalAttendanceType';
import { getCouncilAttendanceTypeLabel } from '../utils/getCouncilAttendanceTypeLabel';
import { getCouncilTypeLabel } from '../utils/getCouncilTypeLabel';

type Props = {
  council: Council;
  handleEditCouncil?: () => void;
};

const InformationCard: FC<Props> = ({ council, handleEditCouncil }: Props) => {
  const { t } = useTranslate();

  const isReadOnly = false; // TODO: Implement permissions

  const councilDate = council.date;
  const councilPlace = council.place;

  const pyshicalAttendance = isPhysicalAttendanceType(council);
  const attendanceTypeLabel = getCouncilAttendanceTypeLabel(council);
  const councilTypeLabel = getCouncilTypeLabel(council);

  return (
    <div className="card-inner">
      <div className="row sp-plan-head">
        <h6 className="title col-6">{t('Information')}</h6>
        {!isReadOnly && handleEditCouncil && (
          <div className="col-6 text-right">
            <button
              type="button"
              className="link text-primary"
              onClick={handleEditCouncil}
            >
              <span className="title">{t('Edit')}</span>
            </button>
          </div>
        )}
      </div>

      <div className="sp-plan-desc sp-plan-desc-mb">
        <ul className="row gx-1">
          <li className="col-6 col-lg-3">
            <p>
              <span className="d-block text-soft fw-normal">{t('Date')}</span>{' '}
              {format(new Date(councilDate), 'dd/MM/yyyy')}
            </p>
          </li>
          <li className="col-6 col-lg-3">
            <p>
              <span className="d-block text-soft fw-normal">
                {t('StartTime')}
              </span>{' '}
              {formatDateToTime(councilDate)}
            </p>
          </li>

          {pyshicalAttendance && (
            <li className="col-6 col-lg-3">
              <span className="d-block text-soft fw-normal">{t('Place')}</span>{' '}
              <div
                data-tooltip-id="council-place"
                data-tooltip-content={councilPlace}
                className="w-150px d-block text-ellipsis"
              >
                <span>{councilPlace}</span>
                <Tooltip id="council-place" place="top" />
              </div>
            </li>
          )}

          <li className="col-6 col-lg-3">
            <span className="d-block text-soft fw-normal">
              {t('AssistanceForm')}
            </span>{' '}
            <div
              data-tooltip-id="attendance-type"
              data-tooltip-content={t(attendanceTypeLabel)}
              className="w-150px d-block text-ellipsis"
            >
              <span>{t(attendanceTypeLabel)}</span>
              <Tooltip id="attendance-type" place="top" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InformationCard;
