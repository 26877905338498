import Swal from 'sweetalert2';

type Props = {
  swalTitle: string;
  swalElementName: string;
  swalInfoText: string;
  swalConfirmButtonText: string;
  callback: () => Promise<void> | void;
};

export default function asyncActionSwal({
  swalTitle,
  swalElementName,
  swalInfoText,
  swalConfirmButtonText,
  callback,
}: Props) {
  const title = `<h4 class="nk-modal-title">${swalTitle}</h4>`;

  const html = `<h5 class="text-primary">${swalElementName}</h5><br /><div class="caption-text">${swalInfoText}</div>`;

  Swal.fire({
    icon: 'warning',
    title,
    html,
    confirmButtonText: swalConfirmButtonText,
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      await callback();
    }
  });
}
