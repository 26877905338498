import { updateEnvelope } from 'modules/_shared/redux/envelopeActions';
import { store } from 'redux/store';

export const updateInactiveEnvelope = async (
  envelopeId: string | undefined
) => {
  if (!envelopeId) return;

  await store.dispatch(
    updateEnvelope({
      _id: envelopeId,
      status: 'INACTIVE',
    })
  );
};
