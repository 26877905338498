/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { store } from 'redux/store';

import {
  ANADIR_SOCIO,
  NUMERATE_FROM_START,
  SELECCIONAR_SOCIO,
  TYPE_FILES_ALLOWED,
} from 'constants/defaultConstants';
import sizes from 'constants/sizes';
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import { cleanValue, numberFormat } from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';

import cleanText from 'utils/cleanText';
import trackEvent from 'utils/trackEvent';
import { allowedFileDocument } from 'utils/allowedFileDocument';
import errorSwal from 'utils/errorSwal';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { constitution } from 'modules/_shared/redux/modalsActions';
import { addDocument } from 'modules/documents/redux/documentActions';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import CommentsCharCounter from 'modules/_shared/components/CommentsCharCounter';
import ConstitutionAI from 'modules/_shared/components/OpenAI/Constitution';
import UploadDocument from 'modules/documents/components/UploadDocument';

import starsIcon from 'assets/images/icon-stars.png';

import AddPartnerEmbeddedModal from '../AddPartnerEmbedded';
import '../Modals.scss';

const showDetails = JSON.parse(localStorage.getItem('showDetails'));

function ConstitutionModal() {
  const { t } = useTranslate();
  const mixpanel = useMixpanel();
  const { user, actualSociety, holdingClasses } = useSelector(
    ({ user, society, holdingClasses }) => ({
      user,
      holdingClasses,
      actualSociety: society?.actualSociety,
    })
  );
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [missingParticipations, setMissingParticipations] = useState(0);
  const [movements, setMovements] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [file, setFile] = useState();
  const [date, setDate] = useState('');
  const [share, setShare] = useState('');
  const [comments, setComments] = useState('');
  const [notaryName, setNotaryName] = useState('');
  const [notaryCity, setNotaryCity] = useState('');
  const [protocolNr, setProtocolNr] = useState('');

  const [customizeSharePremium, setCustomizeSharePremium] = useState(false);
  const [holdingClass, setHoldingClass] = useState(
    holdingClasses?.[0]?.['_id']
  );
  const [actualFrom, setActualFrom] = useState();
  const [numerationRefs, setNumerationRefs] = useState();
  const [participationsValue, setParticipationsValue] = useState('');
  const [totalParticipationsToAdd, setTotalParticipationsToAdd] = useState('');
  const [selectedPartner, setSelectedPartner] = useState(SELECCIONAR_SOCIO);
  const [partnerParticipations, setPartnerParticipations] = useState('');
  const [createPartnerInsite, setCreatePartnerInsite] = useState(false);
  const [holdingClassSelectedStartFrom0, setHoldingClassSelectedStartFrom0] =
    useState(false);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [selectedPartnerOption, setSelectedPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: SELECCIONAR_SOCIO,
  });
  const [aiFile, setAiFile] = useState();
  const animatedComponents = makeAnimated();

  const handlePartnerOptionChange = (selectedOption) => {
    setSelectedPartner(selectedOption.value);
    setSelectedPartnerOption(selectedOption);
  };

  const handleChangeFile = async (event) => {
    const newFile = event.target.files[0];
    const isAllowedFileDocument = await allowedFileDocument(newFile);
    if (isAllowedFileDocument) {
      setAiFile(event.target.files[0]);
      return;
    }
    errorSwal(t('InvalidFile'));
  };

  async function saveConstitution() {
    const finalMovements = [
      ...movements,
      {
        partner: selectedPartner,
        shareFrom: actualFrom[numerationRefs[holdingClass]],
        shareTo:
          actualFrom[numerationRefs[holdingClass]] + partnerParticipations - 1,
        shareClass: holdingClass,
        sharePremium: +share,
        operation: 'THIS',
      },
    ];
    const finalTransactions = [
      ...transactions,
      {
        partner: selectedPartner,
        shareFrom: actualFrom[numerationRefs[holdingClass]],
        shareTo:
          actualFrom[numerationRefs[holdingClass]] + partnerParticipations - 1,
        shareClass: holdingClass,
        sharePremium: +share,
        transactionType: transactionTypes.CONSTITUTION,
      },
    ];

    let uploadedDocument = null;

    if (file) {
      const newDocument = {
        file,
        date,
        size: file.size,
        name: operationTypes.CONSTITUTION_FILENAME,
        author: user['_id'],
        society: actualSociety['_id'],
        fileType: fileTypes[file.type],
        category: documentTypes.SCRIPTURES,
        description: t('DocumentLinkedToOperation'),
      };
      uploadedDocument = await store.dispatch(addDocument(newDocument));
    }

    dispatch(
      constitution({
        movements: finalMovements,
        transactions: finalTransactions,
        date,
        nominalValue: participationsValue,
        society: actualSociety['_id'],
        user: user['_id'],
        comments: cleanText(comments),
        notarialRegistration: {
          notaryName,
          notaryCity,
          protocolNr,
        },
        documents: uploadedDocument?.['_id'] ? [uploadedDocument['_id']] : [],
      })
    );
    dispatch(setModal(null));

    trackEvent(mixpanel, eventTypes.CONSTITUTION, {
      userId: user?._id,
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?._id,
      societyName: actualSociety?.name,
      operation: eventTypes.CONSTITUTION,
    });
  }

  function nextPartner(event) {
    event.preventDefault();
    if (selectedPartner === SELECCIONAR_SOCIO)
      dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else {
      if (missingParticipations - partnerParticipations === 0)
        return saveConstitution();
      const newMovement = {
        partner: selectedPartner,
        shareFrom: actualFrom[numerationRefs[holdingClass]],
        shareTo:
          actualFrom[numerationRefs[holdingClass]] + partnerParticipations - 1,
        shareClass: holdingClass,
        sharePremium: +share,
        operation: 'THIS',
      };
      const newTransaction = {
        partner: selectedPartner,
        shareFrom: actualFrom[numerationRefs[holdingClass]],
        shareTo:
          actualFrom[numerationRefs[holdingClass]] + partnerParticipations - 1,
        shareClass: holdingClass,
        sharePremium: +share,
        transactionType: transactionTypes.CONSTITUTION,
      };

      setMovements([...movements, newMovement]);
      setTransactions([...transactions, newTransaction]);
      setPartnerParticipations('');
      setSelectedPartner(SELECCIONAR_SOCIO);
      setSelectedPartnerOption({
        value: SELECCIONAR_SOCIO,
        label: SELECCIONAR_SOCIO,
      });
      setShare('');
      setMissingParticipations(missingParticipations - partnerParticipations);

      const currentFrom = actualFrom;
      currentFrom[numerationRefs[holdingClass]] += partnerParticipations;
      setActualFrom(currentFrom);
    }
  }

  useEffect(() => {
    if (actualSociety) {
      const options = actualSociety?.partners.map((partner) => ({
        value: partner?.['_id'],
        label: `${partner?.cif} | ${partner?.name}`,
      }));

      options.push({
        value: 'disabled',
        label: '─────────────────',
        isDisabled: true,
      });

      options.push({
        value: ANADIR_SOCIO,
        label: ANADIR_SOCIO,
      });

      setPartnerOptions(options);
    }
  }, [actualSociety]);

  useEffect(() => {
    if (selectedPartner === ANADIR_SOCIO) {
      setCreatePartnerInsite(true);
    } else {
      setCreatePartnerInsite(false);
    }
  }, [selectedPartner]);

  useEffect(() => {
    if (!customizeSharePremium) {
      setShare('');
    }
  }, [customizeSharePremium]);

  useEffect(() => {
    if (holdingClasses?.length) {
      const initial = holdingClasses?.reduce(
        (acc, currentClass) => {
          acc.from[currentClass['_id']] = 1;
          acc.refs[currentClass['_id']] =
            currentClass.numerationOption || currentClass['_id'];
          return acc;
        },
        {
          from: {},
          refs: {},
        }
      );
      const finalRefs = Object.entries(initial.refs).reduce(
        (acc, [id, ref]) => {
          acc[id] = acc[initial.refs[ref]] || initial.refs[ref];
          return acc;
        },
        {}
      );
      setActualFrom(initial.from);
      setNumerationRefs(finalRefs);
    }
  }, [holdingClasses]);

  useEffect(() => {
    if (holdingClasses?.length) {
      const currentClass = holdingClasses.find(
        (curr) => curr['_id'] === holdingClass
      );
      const numeration = currentClass?.numerationOption
        ? holdingClasses.find(
            (curr) => curr['_id'] === currentClass.numerationOption
          ).name
        : NUMERATE_FROM_START;
      setHoldingClassSelectedStartFrom0(numeration === NUMERATE_FROM_START);
    }
  }, [holdingClass, holdingClasses]);

  useEffect(() => {
    if (aiFile) dispatch(setModal(<ConstitutionAI file={aiFile} fullScreen />));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiFile]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('Constitution')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        {!step ? (
          <form
            className="form-validate is-alter"
            onSubmit={(event) => {
              event.preventDefault();
              setParticipationsValue(+participationsValue);
              setShare(+share);
              setMissingParticipations(totalParticipationsToAdd);
              setStep(1);
            }}
          >
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                {t('ConstitutionDate')}
                <sup className="mandatory-field">*</sup>
              </label>
              <div className="form-control-wrap">
                <input
                  type="date"
                  className="form-control date-picker"
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                  required
                  max="2100-01-01"
                  min="1000-01-01"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                {t('NºNewParticipations')}
                <sup className="mandatory-field">*</sup>
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">{t('Nr')}</span>
                </div>
                <NumberFormat
                  id="outlined-normal"
                  className="form-control form-control-outlined"
                  placeholder={t('NumberOfNewParticipations')}
                  value={totalParticipationsToAdd}
                  onChange={(e) =>
                    setTotalParticipationsToAdd(
                      cleanValue(e.target.value) || ''
                    )
                  }
                  isAllowed={(inputObj) => {
                    const { floatValue, formattedValue } = inputObj;
                    if (formattedValue === '') return true;
                    if (floatValue >= 1 && Number.isInteger(floatValue))
                      return true;
                    return false;
                  }}
                  required
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...numberFormat}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                {t('NominalValue')}
                <sup className="mandatory-field">*</sup>
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Eur</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder={t('NominalValueOfParticipati')}
                  value={participationsValue}
                  onChange={(event) =>
                    setParticipationsValue(event.target.value)
                  }
                  onWheel={(event) => event.target.blur()}
                  min="0.000000001"
                  step="0.000000001"
                  required
                />
              </div>
            </div>

            <h6 className="mt-5 mb-2">{t('OptionalData')}</h6>
            <hr />

            <UploadDocument
              file={file}
              setFile={setFile}
              label="AddWriting"
              accept={TYPE_FILES_ALLOWED}
            />

            <div className="form-group">
              <label className="form-label" htmlFor="operation-comments">
                {t('CommentsLabel')}
              </label>
              <div className="form-control-wrap">
                <div className="form-editor-custom">
                  <textarea
                    id="operation-comments"
                    maxLength="500"
                    className="form-control form-control-lg no-resize"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-note d-flex justify-content-between">
                <>{t('CommentsVisibleOnlyAdmins')}</>
                <CommentsCharCounter comments={comments} />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label">{t('NotaryName')}</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder={t('Mr. John Smith')}
                      value={notaryName}
                      onChange={(e) => setNotaryName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label">
                    {t('NotaryProtocolNumber')}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder={t('P-8234290A')}
                      value={protocolNr}
                      onChange={(e) => setProtocolNr(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label">{t('NotaryCity')}</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder={t('Barcelona')}
                      value={notaryCity}
                      onChange={(e) => setNotaryCity(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {showDetails && (
              <div className="form-group">
                <div className="card card-bordered card-preview">
                  <div className="card-inner">
                    <div className="d-flex gx-8 align-items-center">
                      <img src={starsIcon} alt="" width={20} height={26} />
                      <p className="mx-2 mb-0">
                        {t('AIScriptureUploadMessage')}
                      </p>
                      <input
                        type="file"
                        onChange={handleChangeFile}
                        className="rounded p-1"
                        accept=".doc,.docx,.pdf"
                        style={{
                          height: '40px',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <button
              type="submit"
              className="btn btn-lg btn-primary"
              disabled={!+participationsValue}
            >
              {t('Following')}
            </button>
          </form>
        ) : (
          <form className="form-validate is-alter" onSubmit={nextPartner}>
            <label className="form-label">
              <span className="bg-warning p-2">
                {t('TotalNewParticipationsPen')}:
              </span>
              <span className="badge-primary rounded px-2 py-1 ml-2">
                <NumberFormat
                  displayType="text"
                  value={missingParticipations}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...numberFormat}
                />
              </span>
            </label>
            <hr />

            {createPartnerInsite && date ? (
              <AddPartnerEmbeddedModal
                setSelectedPartner={setSelectedPartner}
                setSelectedPartnerOption={setSelectedPartnerOption}
                setCreatePartnerInsite={setCreatePartnerInsite}
                date={date}
              />
            ) : (
              <div className="form-group">
                <label className="form-label" htmlFor="default-01">
                  {t('PartnerName')}
                </label>
                <div className="form-control-wrap">
                  <Select
                    closeMenuOnSelect
                    className="react-select"
                    value={selectedPartnerOption}
                    options={partnerOptions}
                    components={animatedComponents}
                    onChange={handlePartnerOptionChange}
                  />
                </div>
                {createPartnerInsite && !date && (
                  <span className="sub-text mt-1 text-danger">
                    {t('SelectATransmissionDate')}
                  </span>
                )}
              </div>
            )}

            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                {t('NºNewParticipations')}
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">{t('Nr')}</span>
                </div>
                <NumberFormat
                  id="outlined-normal"
                  className="form-control form-control-outlined"
                  placeholder={t('NumberOfNewParticipations')}
                  value={partnerParticipations}
                  onChange={(e) =>
                    setPartnerParticipations(cleanValue(e.target.value) || '')
                  }
                  isAllowed={(inputObj) => {
                    const { floatValue, formattedValue } = inputObj;
                    if (formattedValue === '') return true;
                    if (
                      Number.isInteger(floatValue) &&
                      floatValue >= 1 &&
                      floatValue <= missingParticipations
                    )
                      return true;
                    return false;
                  }}
                  required
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...numberFormat}
                />
              </div>
            </div>

            <div
              className="form-group"
              style={{
                zIndex: 0,
              }}
            >
              <div className="custom-control custom-control-xs custom-checkbox mt-1">
                <input
                  type="checkbox"
                  id="checkbox-toggle"
                  className="custom-control-input"
                  value={customizeSharePremium}
                  onChange={(event) =>
                    setCustomizeSharePremium(event.target.checked)
                  }
                />
                <label
                  className="custom-control-label mb-2"
                  htmlFor="checkbox-toggle"
                >
                  {t('PremiumAmountForParticipati')}
                </label>
              </div>
              {customizeSharePremium && (
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Eur</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={t('PremiumAmountForParticipati')}
                    value={share}
                    onChange={(event) => setShare(event.target.value)}
                    min="0"
                    step="0.000000001"
                    required
                  />
                </div>
              )}
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                {t('ClassOfParticipations')}
              </label>
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select
                    className="form-control"
                    value={holdingClass}
                    onChange={(event) => setHoldingClass(event.target.value)}
                  >
                    {holdingClasses.map((holdingClass) => (
                      <option
                        value={holdingClass['_id']}
                        key={`class-${holdingClass['_id']}`}
                      >
                        {holdingClass.name}
                      </option>
                    ))}
                  </select>
                </div>
                {holdingClassSelectedStartFrom0 && (
                  <span className="sub-text text-primary">
                    {t('ThisClassListsFromNo1')}
                  </span>
                )}
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                {t('OfTheNumber')}
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">{t('Numeration')}</span>
                </div>
                <NumberFormat
                  id="outlined-normal"
                  className="form-control form-control-outlined"
                  value={actualFrom[numerationRefs[holdingClass]]}
                  readOnly
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...numberFormat}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                {t('ToTheNumber')}
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">{t('Numeration')}</span>
                </div>
                <NumberFormat
                  id="outlined-normal"
                  className="form-control form-control-outlined"
                  value={
                    actualFrom[numerationRefs[holdingClass]] +
                    partnerParticipations -
                    (partnerParticipations ? 1 : 0)
                  }
                  readOnly
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...numberFormat}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-lg btn-primary"
              disabled={
                !partnerParticipations ||
                selectedPartner === ANADIR_SOCIO ||
                selectedPartner === SELECCIONAR_SOCIO
              }
            >
              {missingParticipations - partnerParticipations
                ? t('Following')
                : t('Save')}
            </button>
          </form>
        )}
      </Modal.Body>
    </>
  );
}

export default ConstitutionModal;
