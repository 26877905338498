import { FC } from 'react';

import { SocietyRepresentative } from 'types';

import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';
import SocietyRepresentativesTableHeader from './SocietyRepresentativesTableHeader';
import SocietyRepresentativesTableBody from './SocietyRepresentativeTableBody';

type Props = {
  societyId: string;
  societyRepresentatives: SocietyRepresentative[];
};

const SocietyRepresentativesTable: FC<Props> = ({
  societyId,
  societyRepresentatives,
}) => {
  const { isReadOnly } = useGetSocietySectionPermissions().data;

  return (
    <div className="card card-bordered card-preview">
      <div className="table-responsive">
        <table className="nk-tb-list nk-tb-ulist">
          <SocietyRepresentativesTableHeader isReadOnly={isReadOnly} />

          <SocietyRepresentativesTableBody
            societyId={societyId}
            societyRepresentatives={societyRepresentatives}
            isReadOnly={isReadOnly}
          />
        </table>
      </div>
    </div>
  );
};

export default SocietyRepresentativesTable;
