import { format } from 'date-fns';
import { translate } from 'hooks/useTranslate';
import { dateFormat } from 'constants/formats';

export const generateCouncilName = (): string => {
  const todayText = format(new Date(), dateFormat);
  const councilText = translate('Council');

  return `${councilText} ${todayText}`;
};
