import { Council } from 'types';
import councilAttendanceTypes from 'modules/administratives/constants/councilAttendanceTypes';

export const getCouncilAttendanceTypeLabel = (council: Council) => {
  const attendanceType = councilAttendanceTypes.find(
    (type) => type.value === council?.attendanceType
  );

  const statusLabel = attendanceType ? attendanceType.label : '-';

  return statusLabel;
};
