import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { State } from 'redux/initialState';
import { Council } from 'types';
import menuTypes from 'constants/menuTypes';

import Page from 'modules/_shared/components/Pages/Page';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';

import 'modules/administratives/styles/Administratives.scss';

import { useGetActualSociety } from 'hooks/useGetActualSociety';
import { useGetSocietyDocuments } from 'modules/society/hooks/useGetSocietyDocuments';
import { useCouncilMember } from './hooks/useCouncilMember';
import { useGetCouncilsList } from '../CouncilsList/hooks/useGetCouncilsList';

import CouncilMembersHeader from './components/CouncilMembersHeader';
import CouncilMembersBasicInfoCard from './components/CouncilMembersBasicInfoCard';
import CouncilMembersDirectorsCard from './components/CouncilMembersDirectorsCard';
import CouncilMembersDocumentsCard from './components/CouncilMembersDocumentsCard';

const CouncilMembers = () => {
  const { societyId } = useParams();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const {
    data: { council, documents },
    isLoading,
  } = useCouncilMember();

  useGetMenu(menuTypes.NULL);
  useGetActualSociety(societyId);
  useGetCouncilsList(societyId);
  useGetSocietyDocuments(societyId);

  return (
    <Page className="bg-lighter pb-5">
      <CouncilMembersHeader
        council={council as Council}
        society={actualSociety}
      />

      <div className="row">
        <div className="col-lg-5">
          <CouncilMembersBasicInfoCard
            council={council as Council}
            society={actualSociety}
          />
          <CouncilMembersDirectorsCard society={actualSociety} />
        </div>

        <div className="col-lg-7">
          <CouncilMembersDocumentsCard
            council={council as Council}
            documents={documents}
            society={actualSociety}
          />
        </div>
      </div>

      {isLoading && <CustomLoading />}
    </Page>
  );
};

export default CouncilMembers;
