import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { Council, Document } from 'types';
import { State } from 'redux/initialState';

import { setModal } from 'modules/_shared/redux/modalActions';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import SendCouncilAnnouncementModal from 'modules/administratives/modals/CouncilAnnouncementModal/SendCouncilAnnouncementModal';

import { getCouncilStatusData } from '../utils/getCouncilStatusData';
import { councilActions } from '../constants/councilActions';

type Props = {
  council: Council;
};

const postCelebratingStatus = ['CLOSED', 'CANCELED', 'CELEBRATED'];

const CouncilDetailsPageHeader: FC<Props> = ({ council }) => {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const isAdmin = useSelector((state: State) => state.society?.role?.isAdmin);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const documents = useSelector((state: State) => state.documents);

  const [announcementTemplateDoc, setAnnouncementTemplateDoc] =
    useState<Document>();

  const councilName = council.name;
  const { statusLabel, statusBadge } = getCouncilStatusData(council);

  const statusClass = `badge badge-pill badge-${statusBadge}`;

  const isEditButtonEnabled = () => !!isAdmin;
  const isSendButtonEnabled = () => {
    if (!council) return false;

    const currentStatus = getCouncilStatusData(council);
    if (postCelebratingStatus.includes(currentStatus.statusValue)) return false;

    return true;
  };
  // TODO: Implement these methods
  const isCloseButtonEnabled = () => false;

  const handleSendAnnouncement = async () => {
    if (council && actualSociety) {
      dispatch(
        setModal(
          <SendCouncilAnnouncementModal
            currentCouncil={council}
            actualSociety={actualSociety}
            announcementTemplateDoc={announcementTemplateDoc as Document}
          />
        )
      );
    }
  };

  const handleSendReminder = () => alert('[Send reminder'); // eslint-disable-line
  const handleCloseCouncil = () => alert('[Close council]'); // eslint-disable-line

  useEffect(() => {
    if (council && documents) {
      const currentTemplate = documents.find(
        (document) =>
          council.announcementTemplate &&
          document._id === council.announcementTemplate
      ) as Document;

      setAnnouncementTemplateDoc(currentTemplate);
    }
  }, [documents, council]);

  return (
    <>
      <PageListHeader
        subTitle={councilName}
        actions={[
          isAdmin ? (
            <OperationMenuDots
              title={t('Actions')}
              menuOptions={councilActions(i18n)}
              params={{
                currentCouncil: council,
                isEditButtonEnabled,
                isSendButtonEnabled,
                isCloseButtonEnabled,
                handleSendAnnouncement,
                handleSendReminder,
                handleCloseCouncil,
              }}
              disabled={false}
              handleModal={null}
            />
          ) : null,
          <GoBackButton />,
        ]}
        description={<span className={statusClass}>{t(statusLabel)}</span>}
      />
    </>
  );
};

export default CouncilDetailsPageHeader;
