import { FC } from 'react';

import { SocietyDirector } from 'types';
import AdminMembersTable from './AdminMembersTable';
import { getAdminMemberStatus } from '../utils/getAdminMemberStatus';

type Props = {
  societyId: string;
  adminMembers: SocietyDirector[];
  currentMembersView: boolean;
};

const AdminMembersPageBody: FC<Props> = ({
  societyId,
  adminMembers,
  currentMembersView,
}) => {
  const isActiveMember = (member: SocietyDirector) => {
    const status = getAdminMemberStatus(member);
    return status?.value === 'ACTIVE';
  };

  const filteredMembers = currentMembersView
    ? adminMembers.filter((member: SocietyDirector) => isActiveMember(member))
    : adminMembers;

  return (
    <div className="nk-block">
      <AdminMembersTable societyId={societyId} adminMembers={filteredMembers} />
    </div>
  );
};

export default AdminMembersPageBody;
