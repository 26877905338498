import { Partner, Society, StockPlan, User } from 'types';
import activePartner from 'utils/activePartner';
import {
  getDecimalScale,
  getFullAddress,
  getPartnerFDPercent,
  getPartnerNominalValue,
  getPartnerPotentialFD,
  getPartnerPremium,
  getSocietyValue,
} from 'utils/filters';
import getPartnerNDPercent from 'utils/getPartnerNDPercent';
import { getPartnersPrimaryEmails } from '../api/getPartnerPrimaryEmails';

type Props = {
  actualSociety: Society;
  plans: StockPlan[];
  user: User;
  userId: string;
  isReadOnly: boolean;
  isAdmin: boolean;
  hasAccessCaptable: boolean;
};

const isPartnerAllowed = (
  partner: Partner,
  user: User,
  userId: string,
  hasAccessCaptable: boolean,
  isAdmin: boolean
): boolean =>
  userId || (!userId && !hasAccessCaptable && !isAdmin)
    ? user?._id === partner?.user ||
      partner?.otherUsers?.includes(user?._id) ||
      partner?.readOnlyUsers?.includes(user?._id) ||
      user?.usersWithAccess.some(
        (currentUser: any) => partner?.user === currentUser?.['_id']
      )
    : true;

const parsePrimaryEmails = (partnerId: string, primaryEmails: any) => {
  const partnerEmails = primaryEmails.find(
    (emails: { _id: string; userEmails: string[] }) =>
      emails?.['_id'] === partnerId
  );

  return partnerEmails?.userEmails?.join(', ') || '-';
};

export const getPartnerList = async (props: Props) => {
  const {
    actualSociety,
    plans,
    user,
    userId,
    isReadOnly,
    isAdmin,
    hasAccessCaptable,
  } = props;
  const searchParams = new URLSearchParams(location.href.split('?')[1]);
  const categoryId = searchParams.get('categoria');

  const partners = categoryId
    ? actualSociety.partners?.filter(
        (partner: Partner) => partner?.category === categoryId
      )
    : actualSociety.partners;

  const societyValue = getSocietyValue(actualSociety);
  const decimalScale = societyValue ? getDecimalScale(societyValue.value) : 2;

  const primaryEmails = await getPartnersPrimaryEmails(actualSociety?._id);

  const newData = partners
    .filter((partner) => partner?.sharesCount?.actual >= 0)
    .filter((partner) =>
      isPartnerAllowed(partner, user, userId, hasAccessCaptable, isAdmin)
    )
    .map((partner) => ({
      index: partner?.partnerNumber,
      name: partner.name,
      email: partner.email,
      cif: partner?.cif || '-',
      shares: partner.sharesCount.actual,
      decimals: decimalScale,
      NDPercent: getPartnerNDPercent(partner, actualSociety),
      FDPercent: getPartnerFDPercent(partner, actualSociety),
      FDPotential: getPartnerPotentialFD(partner, actualSociety, plans),
      partner,
      society: actualSociety,
      societyId: actualSociety?._id,
      partnerNominalValue: parseFloat(
        getPartnerNominalValue(partner?.shares, actualSociety).toFixed(2)
      ),
      partnerPremium: parseFloat(
        getPartnerPremium(partner?.shares, actualSociety).toFixed(2)
      ),
      isActive: activePartner(partner),
      hasLink:
        partner?.user === user?._id ||
        partner?.otherUsers?.includes(user?._id) ||
        partner?.readOnlyUsers?.includes(user?._id) ||
        isAdmin,
      userId: user?.['_id'],
      nationality: partner?.nationality || '-',
      isReadOnly,
      isReadOnlyUser: partner?.readOnlyUsers?.includes(user?.['_id']),
    }));

  const newCustomData = actualSociety?.partners
    .filter((partner) => partner?.sharesCount?.actual >= 0)
    .filter((partner) =>
      isPartnerAllowed(partner, user, userId, hasAccessCaptable, isAdmin)
    )
    .map((partner) => ({
      NOMBRE: partner.name,
      CIF: partner?.cif,
      EMAIL: partner?.email,
      EMAILS_PRIMARIOS: parsePrimaryEmails(partner?._id, primaryEmails),
      NACIONALIDAD: partner?.nationality || '-',
      PARTICIPACIONES: partner.sharesCount.actual,
      DIRECCION: getFullAddress(partner?.address),
      '%ND': parseFloat(getPartnerNDPercent(partner, actualSociety).toFixed(2)),
      '%FD': parseFloat(getPartnerFDPercent(partner, actualSociety).toFixed(2)),
      '% Potential FD': parseFloat(
        getPartnerPotentialFD(partner, actualSociety, plans).toFixed(2)
      ),
      Nominal: parseFloat(
        getPartnerNominalValue(partner?.shares, actualSociety).toFixed(2)
      ),
      'Total Prima': parseFloat(
        getPartnerPremium(partner?.shares, actualSociety).toFixed(2)
      ),
    }));

  return { newData, newCustomData };
};
