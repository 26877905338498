import { useEffect, useState } from 'react';
import { store } from 'redux/store';
import { getDocument } from 'modules/documents/redux/documentActions';

import { Document } from 'types';

type Props = {
  announcementDocumentId: string;
};

export const useGetAnnouncementDocument = ({
  announcementDocumentId,
}: Props) => {
  const [announcementDocument, setAnnouncementDocument] = useState<Document>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    try {
      setIsLoading(true);
      if (announcementDocumentId) {
        const getAnnouncementDocument = async () => {
          const document = await store.dispatch(
            getDocument(announcementDocumentId)
          );

          setAnnouncementDocument(document);
        };

        getAnnouncementDocument();
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [announcementDocumentId]);

  return { data: { announcementDocument }, isLoading, isError };
};
