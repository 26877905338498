import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import isSuperAdmin from 'utils/isSuperAdmin';

import { useTranslate } from 'hooks/useTranslate';
import toggleSideNav from 'modules/_shared/redux/sideNavActions';
import ToggleWidthButton from '../Buttons/ToggleWidthButton';
import WizardButton from '../Buttons/WizardButton';
import UserMenuDots from '../MenuDots/UserMenuDots';
import menuOptions from './menuOptions';

type HeaderToolsProps = {
  data: Record<string, any>;
};

const HeaderTools = ({ data }: HeaderToolsProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  return (
    <div className="nk-header-tools">
      <ul className="nk-quick-nav">
        {data?.hasSocietyButtonEnabled && (
          <Link
            to="/nueva-sociedad"
            className="btn btn-outline-primary d-none d-md-inline-flex no-wrap"
          >
            {t('AddSociety')}
          </Link>
        )}

        {isSuperAdmin(data?.user) && data?.hasSocietyButtonEnabled && (
          <li className="d-none d-md-inline">
            <WizardButton />
          </li>
        )}

        <li className="d-none d-md-inline pl-0">
          <ToggleWidthButton />
        </li>

        <li className="dropdown user-dropdown ml-2">
          <UserMenuDots menuOptions={menuOptions} />
        </li>

        <li className="d-lg-none">
          {data?.showSideNav}
          <a
            className="toggle nk-quick-nav-icon mr-n1"
            data-target="sideNav"
            aria-label="temp"
            onClick={() => dispatch(toggleSideNav())}
          >
            <em className="icon ni ni-menu" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default HeaderTools;
