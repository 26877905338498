import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';

const LocationView: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useAddRealtyModal();

  return (
    <div className="row gy-4">
      <div className="col-md-12 d-flex align-items-center">
        <h5 className="mb-0">{t('Location')}</h5>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-address">
            {t('Address')}
          </label>

          <input
            id="realty-address"
            type="text"
            className="form-control form-control-lg"
            value={data.address}
            onChange={actions.handleChangeAddress}
            placeholder={t('IntroduceAddress')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-city">
            {t('City')}
          </label>

          <input
            id="realty-city"
            type="text"
            className="form-control form-control-lg"
            value={data.city}
            onChange={actions.handleChangeCity}
            placeholder={t('IntroduceCity')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-zipcode">
            {t('PostalCode')}
          </label>

          <input
            id="realty-zipcode"
            type="text"
            className="form-control form-control-lg"
            value={data.zipCode}
            onChange={actions.handleChangeZipCode}
            placeholder={t('IntroduceZipCode')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-province">
            {t('Province')}
          </label>

          <input
            id="realty-province"
            type="text"
            className="form-control form-control-lg"
            value={data.province}
            onChange={actions.handleChangeProvince}
            placeholder={t('IntroduceProvince')}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationView;
