import { ReactNode } from 'react';
import { translate } from 'hooks/useTranslate';
import { Council, Society } from 'types';
import { getParticipantsWithNullEmails } from './getParticipantsWithNullEmails';

export const getInvalidEmailsMessage = (
  council: Council,
  society: Society
): ReactNode => {
  const participants = getParticipantsWithNullEmails(council, society);
  const initialMessage = translate('ParticipantsWithInvalidEmailsMessage');

  return (
    <div className="text-left">
      <b>{translate('InvalidEmails')}</b>
      <br />
      <p className="text-justify">
        {initialMessage} <br />
      </p>
      <ul>
        {participants.map((participant) => (
          <li>• {participant.name}</li>
        ))}
      </ul>
    </div>
  );
};
