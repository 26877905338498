import { Society, SocietyDirector } from 'types';

import councilTypes from 'modules/administratives/constants/councilTypes';
import councilAttendanceTypes from 'modules/administratives/constants/councilAttendanceTypes';
import { TranslateFn } from 'hooks/useTranslate';

type SelectOptions = {
  value: string;
  label: string;
};

export const getSelectorOptions = (society: Society, t: TranslateFn) => {
  const attendanceOptions: SelectOptions[] = councilAttendanceTypes.map(
    (attendanceType) => ({
      value: attendanceType.value,
      label: t(attendanceType.label),
    })
  );

  const councilTypeOptions: SelectOptions[] = councilTypes.map(
    (councilType) => ({
      value: councilType.value,
      label: t(councilType.label),
    })
  );

  const directorOptions: SelectOptions[] = society?.directors?.map(
    (director: SocietyDirector) => ({
      value: director?.['_id'] as string,
      label: `${director?.socialDenomination} | ${director?.email}`,
    })
  );

  return {
    attendanceOptions,
    councilTypeOptions,
    directorOptions,
  };
};
