import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Council } from 'types';
import { State } from 'redux/initialState';

import Page from 'modules/_shared/components/Pages/Page';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';
import { useGetActualSociety } from 'hooks/useGetActualSociety';
import { useGetCouncilsList } from './hooks/useGetCouncilsList';

import CouncilsListHeader from './components/CouncilsListHeader';
import CouncilsListBody from './components/CouncilsListBody';
import CouncilsListBanner from './components/CouncilsListBanner';
import CouncilsSummarySection from './components/CouncilsSummarySection';
import CouncilsCtaSection from './components/CouncilsCtaSection';
import CouncilsRecentsSection from './components/CouncilsRecentsSection';

type CouncilsListProps = {};

export type CouncilsView = 'LIST' | 'DASHBOARD';

export enum CouncilsViews {
  LIST = 'LIST',
  DASHBOARD = 'DASHBOARD',
}

const CouncilsList: FC<CouncilsListProps> = () => {
  const { societyId, memberId } = useParams();

  const councils: Council[] = useSelector((state: State) => state.councils);
  const { isAdmin, isDirector } = useSelector(
    (state: State) => state.society?.role || {}
  );

  const [councilsView, setCouncilsView] = useState<CouncilsView>(
    CouncilsViews.DASHBOARD
  );

  const { isLoading, isError } = useGetCouncilsList(societyId);

  useGetMenu();
  useGetActualSociety(societyId);

  const handleChangeView = () => {
    setCouncilsView(
      councilsView === CouncilsViews.LIST
        ? CouncilsViews.DASHBOARD
        : CouncilsViews.LIST
    );
  };

  return (
    <Page id="councils-list-page">
      <CouncilsListHeader
        councilsView={councilsView}
        onChangeView={handleChangeView}
      />

      {councilsView === CouncilsViews.DASHBOARD ? (
        <>
          <CouncilsSummarySection councils={councils} />
          {isAdmin && <CouncilsCtaSection />}
          <CouncilsRecentsSection councils={councils} />
        </>
      ) : (
        <>{isAdmin && <CouncilsListBody councils={councils} />}</>
      )}

      <CouncilsListBanner />

      {isLoading && <CustomLoading />}
    </Page>
  );
};

export default CouncilsList;
