import { FC } from 'react';
import { Council } from 'types';
import { useTranslate } from 'hooks/useTranslate';
import { getCouncilsByStatus } from '../utils/getCouncilsByStatus';

type CouncilsSummarySectionProps = {
  councils: Council[];
};

const CouncilsSummarySection: FC<CouncilsSummarySectionProps> = ({
  councils,
}) => {
  const { t } = useTranslate();
  const {
    preparingCouncils,
    activeCouncils,
    signPendingCouncils,
    closedCouncils,
  } = getCouncilsByStatus(councils);

  const summaryCards = [
    {
      key: 'preparation-card',
      value: preparingCouncils,
      title: t('CouncilStatus_InPreparations'),
      description: t('CouncilStatus_InPreparationDescription'),
      color: 'info',
      icon: 'ni-clock',
    },
    {
      key: 'active-card',
      value: activeCouncils,
      title: t('CouncilStatus_OnGoings'),
      description: t('CouncilStatus_OnGoingDescription'),
      color: 'primary',
      icon: 'ni-calendar-check',
    },
    {
      key: 'sign-pending-card',
      value: signPendingCouncils,
      title: t('CouncilStatus_SignaturePendings'),
      description: t('CouncilStatus_SignaturePendingDescription'),
      color: 'warning',
      icon: 'ni-pen',
    },
    {
      key: 'closed-card',
      value: closedCouncils,
      title: t('CouncilStatus_Completeds'),
      description: t('CouncilStatus_CompletedDescription'),
      color: 'success',
      icon: 'ni-check-circle',
    },
  ];

  return (
    <div className="nk-block">
      <div className="row g-gs">
        {summaryCards.map((card) => (
          <div className="col-sm-6 col-xxl-3" key={card.key}>
            <div className={`card card-full rounded-xl bg-${card.color}`}>
              <div className="card-inner">
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <div className="fs-6 text-white text-opacity-75 mb-0">
                    {card.title}
                  </div>
                  <a href="#" className="link link-white text-light d-none">
                    Ver
                  </a>
                  <em className={`icon ni ${card.icon} text-white`} />
                </div>

                <h5 className="fs-1 text-white">
                  {card.value} <small className="fs-3">{card.title}</small>
                </h5>

                <div className="fs-11px text-white text-opacity-75 mt-1">
                  <span className="text-white" />
                  {card.description}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CouncilsSummarySection;
