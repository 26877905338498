/* eslint-disable react/no-array-index-key */
const EnvelopeSignatureLoading = () => (
  <div className="pt-4">
    <p className="placeholder-glow">
      <span className="placeholder col-3 h-25px mb-4" />
      <br />
      <span className="placeholder col-2 h-20px mb-2 mt-1" />
      <span className="placeholder offset-1 h-20px mb-2 mt-1" />
      <span className="placeholder col-3 h-20px mb-2 mt-1" />
      <span className="placeholder offset-1 h-20px mb-2 mt-1" />
      <span className="placeholder col-2 h-20px mb-2 mt-1" />
      <span className="placeholder offset-1 h-20px mb-2 mt-1" />
      <span className="placeholder col-2 h-20px mb-2 mt-1" />
    </p>
    <p className="placeholder-glow text-left">
      <span className="placeholder col-12 h-25px mb-3" />

      {Array.from({ length: 5 }).map((_, index) => (
        <span key={`envelop-signature-row-${index}`}>
          <span className="placeholder col-5 h-20px mb-3" />
          <span className="placeholder offset-1 h-20px mb-3" />
          <span className="placeholder col-2 h-20px mb-3" />
          <span className="placeholder offset-1 h-20px mb-3" />
          <span className="placeholder col-3 h-20px mb-3" />
        </span>
      ))}
    </p>
  </div>
);

export default EnvelopeSignatureLoading;
