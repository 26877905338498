import { TranslateFn } from 'hooks/useTranslate';
import { Realty, Society } from 'types';

export const getSummaryProfileData = (
  realty: Realty,
  society: Society,
  t: TranslateFn
) => {
  const societyName = society?.name || '-';

  const name = realty?.name || '-';

  const image = society?.additional?.logo || '';

  const position = realty?.ownership || '';

  const description = t('ThisIsARealtyFromSocietyName', { societyName });

  return { name, image, position, description };
};
