import { DATE_FORMAT } from 'constants/defaultConstants';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import { format } from 'date-fns';
import { translate } from 'hooks/useTranslate';
import { Society } from 'types';

export type OperationPosition = 'PREV' | 'NEXT';

export const getOperationTooltip = (
  operationPosition: OperationPosition,
  operationId: string | null,
  society: Society
) => {
  const operationLabel =
    operationPosition === 'PREV'
      ? translate('PreviousOperation')
      : translate('NextOperation');

  const operation = society.operations.find(
    (operation) => operation['_id'] === operationId
  );

  if (!operation) {
    return operationLabel;
  }

  const operationDate = operation?.date
    ? format(new Date(operation.date), DATE_FORMAT)
    : '';

  const operationType = operationTypesRealNames[operation?.operationType];

  const operationComments = operation?.comments;

  return (
    <div className="d-flex flex-column">
      <span className="mb-1">
        <b>{operationLabel}</b>
      </span>

      <span>
        <em className="icon ni ni-calendar text-muted mr-1" />
        {operationDate}
      </span>

      <span>
        <em className="icon ni ni-user text-muted mr-1" />
        {operationType}
      </span>

      {operationComments && (
        <span>
          <em className="icon ni ni-notes-alt text-muted mr-1" />
          {operationComments || null}
        </span>
      )}
    </div>
  );
};
