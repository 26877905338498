import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import MandatoryField from 'modules/_shared/components/MandatoryField';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';

const BuildingView: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useAddRealtyModal();

  return (
    <div className="row gy-4">
      <div className="col-md-12 d-flex align-items-center">
        <h5 className="mb-0">{t('Building')}</h5>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-name">
            {t('Name')} <MandatoryField />
          </label>

          <input
            id="realty-name"
            type="text"
            className="form-control form-control-lg"
            value={data.name}
            onChange={actions.handleChangeName}
            placeholder={t('IntroduceName')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-total-area">
            {t('TotalArea')} (m²)
          </label>

          <input
            id="realty-total-area"
            type="number"
            min={0}
            className="form-control form-control-lg"
            value={data.totalArea}
            onChange={actions.handleChangeTotalArea}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-built-area">
            {t('BuiltArea')} (m²)
          </label>

          <input
            id="realty-built-area"
            type="number"
            min={0}
            className="form-control form-control-lg"
            value={data.builtArea}
            onChange={actions.handleChangeBuiltArea}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-usage">
            {t('Usage')}
          </label>

          <input
            id="realty-usage"
            type="text"
            className="form-control form-control-lg"
            value={data.usage}
            onChange={actions.handleChangeUsage}
            placeholder={t('IntroduceUsage')}
          />
        </div>
      </div>
    </div>
  );
};

export default BuildingView;
