import { store } from 'redux/store';

import { Society, User } from 'types';

import {
  addDocument,
  getDocument,
} from 'modules/documents/redux/documentActions';
import announcementTemplates from 'constants/announcementTemplates';
import actTemplates from 'constants/actTemplates';
import actCertificateTemplates from 'constants/actCertificateTemplates';
import i18n from 'i18n/config';
import fileTypes from 'constants/fileTypes';

type GenerateCouncilTemplatesData = {
  user: User;
  society: Society;
};

const templateIds = {
  ANNOUNCEMENT: announcementTemplates[process.env.NODE_ENV],
  ACT: actTemplates[process.env.NODE_ENV],
  ACT_CERTIFICATE: actCertificateTemplates[process.env.NODE_ENV],
};

export const generateCouncilTemplates = async ({
  user,
  society,
}: GenerateCouncilTemplatesData) => {
  const globalAnnouncementTemplate = await store.dispatch(
    getDocument(templateIds.ANNOUNCEMENT)
  );

  const globalActTemplate = await store.dispatch(getDocument(templateIds.ACT));

  const globalActCertificateTemplate = await store.dispatch(
    getDocument(templateIds.ACT_CERTIFICATE)
  );

  const baseTemplate = {
    author: user['_id'],
    category: 'TEMPLATES',
    subcategory: 'GENERATED',
    date: new Date(),
    fileType: fileTypes.TEMPLATE,
    isFavorite: false,
    isGenerated: false,
    lastAccess: new Date(),
    society: society['_id'],
  };

  const newAnnouncementTemplate = {
    ...baseTemplate,
    name: `${i18n.t('AnnouncementTemplate')}_${name}`,
    editorModel: globalAnnouncementTemplate.editorModel,
  };

  const newActTemplate = {
    ...baseTemplate,
    name: `${i18n.t('ActTemplate')}_${name}`,
    editorModel: globalActTemplate.editorModel,
  };

  const newActCertificateTemplate = {
    ...baseTemplate,
    name: `${i18n.t('ActCertificateTemplate')}_${name}`,
    editorModel: globalActCertificateTemplate.editorModel,
  };

  const announcementTemplate = await store.dispatch(
    addDocument(newAnnouncementTemplate)
  );

  const actTemplate = await store.dispatch(addDocument(newActTemplate));

  const actCertificateTemplate = await store.dispatch(
    addDocument(newActCertificateTemplate)
  );

  return {
    announcementTemplate,
    actTemplate,
    actCertificateTemplate,
  };
};
