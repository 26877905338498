import { Council, CouncilParticipant, Society } from 'types';
import { isParticipantAllowedForAnnouncement } from './isParticipantAllowedForAnnouncement';

export const getParticipantsWithNullEmails = (
  council: Council,
  society: Society
): CouncilParticipant[] =>
  (council?.participants?.filter(
    (participant: CouncilParticipant) =>
      isParticipantAllowedForAnnouncement(participant, society) &&
      !participant.email
  ) as CouncilParticipant[]) || [];
