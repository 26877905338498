/* eslint-disable no-undef */

import { RealtyLicense, RealtyPlot, RealtyTitle } from 'types';

/* eslint-disable no-unused-vars */
export const addRealtyModalContextInitValue = {
  data: {
    name: '',
    totalArea: 0,
    builtArea: 0,
    address: '',
    city: '',
    zipCode: '',
    province: '',
    owner: '',
    lessee: '',
    usage: '',
    plots: [],
    titles: [],
    licenses: [],
    encumbrances: [],
    cadastralAmount: 0,
    cadastralYear: 0,
    cadastralReference: '',
    registryReference: '',
    salePriceAmount: 0,
    salePriceCurrency: '',
  },
  actions: {
    handleChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangeTotalArea(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangeBuiltArea(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangeAddress(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangeCity(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangeZipCode(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangeProvince(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangeOwner(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangeLessee(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangeUsage(event: React.ChangeEvent<HTMLInputElement>): void {
      throw new Error('Function not implemented.');
    },
    handleChangePlots(newPlots: RealtyPlot[]): void {
      throw new Error('Function not implemented.');
    },
    handleChangeTitles(newTitles: RealtyTitle[]): void {
      throw new Error('Function not implemented.');
    },
    handleChangeLicenses(newLicenses: RealtyLicense[]): void {
      throw new Error('Function not implemented.');
    },
    handleChangeEncumbrances(newEncumbrances: string[]): void {
      throw new Error('Function not implemented.');
    },
    handleChangeCadastralAmount(
      event: React.ChangeEvent<HTMLInputElement>
    ): void {
      throw new Error('Function not implemented.');
    },
    handleChangeCadastralYear(
      event: React.ChangeEvent<HTMLInputElement>
    ): void {
      throw new Error('Function not implemented.');
    },
    handleChangeCadastralReference(
      event: React.ChangeEvent<HTMLInputElement>
    ): void {
      throw new Error('Function not implemented.');
    },
    handleChangeRegistryReference(
      event: React.ChangeEvent<HTMLInputElement>
    ): void {
      throw new Error('Function not implemented.');
    },
    handleChangeSalePriceAmount(
      event: React.ChangeEvent<HTMLInputElement>
    ): void {
      throw new Error('Function not implemented.');
    },
    handleChangeSalePriceCurrency(
      event: React.ChangeEvent<HTMLInputElement>
    ): void {
      throw new Error('Function not implemented.');
    },
  },
};
