import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import signatureStatus from 'constants/signatureStatus';
import { format } from 'date-fns';

type Props = {
  signatureEnvelopeStatus: string;
  sentDate: Date | undefined;
  signedSignersCount: number;
  totalSigners: number;
  isReadOnly: boolean;
  refetchSigners: () => void;
};

const EnvelopeSignatureStatusHeader: FC<Props> = ({
  signatureEnvelopeStatus,
  sentDate,
  signedSignersCount,
  totalSigners,
  isReadOnly,
  refetchSigners,
}) => {
  const { t } = useTranslate();

  const handleRefetchSigners = () => {
    if (isReadOnly) return;
    refetchSigners();
  };

  const requestButtoDisabled = isReadOnly || !(totalSigners > 0);

  const statusBadge =
    signatureStatus.badge[
      signatureEnvelopeStatus as keyof typeof signatureStatus.badge
    ];

  const statusText =
    signatureStatus.text[
      signatureEnvelopeStatus as keyof typeof signatureStatus.text
    ] || '-';

  return (
    <>
      <div className="nk-block pt-4 pb-3">
        <div className="card-inner-group">
          <div className="d-flex w-100 flex-row justify-content-start align-items-center">
            <h5 className="">{t('SignDetails')}</h5>
          </div>
          <div className="d-flex w-100 flex-row justify-content-between align-items-center mt-2">
            <div className="d-flex justify-content-start">
              <span className="font-weight-bold">{t('SentDate')}:</span>
              <span className="text-soft ml-1">
                {sentDate ? format(new Date(sentDate), 'dd/MM/yyyy') : '-'}
              </span>
            </div>

            <div className="d-flex justify-content-start">
              <span className="font-weight-bold">
                {t('HaveSigned')}/{t('TotalSigners')}:
              </span>
              <span className="text-soft ml-1">
                {signedSignersCount}/{totalSigners}
              </span>
            </div>

            <div className="d-flex justify-content-start">
              <span className="font-weight-bold">{t('SignStatus')}:</span>
              <span
                className={
                  statusBadge
                    ? `ml-1 badge badge-pill badge-${statusBadge}`
                    : 'ml-1'
                }
              >
                {statusText ? t(statusText) : '-'}
              </span>
            </div>

            <div className="d-flex justify-content-start">
              <button
                type="button"
                className="btn btn-primary float-right"
                onClick={handleRefetchSigners}
                disabled={requestButtoDisabled}
              >
                {t('UpdateList')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnvelopeSignatureStatusHeader;
