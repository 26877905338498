import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { State } from 'redux/initialState';

import sizes from 'constants/sizes';
import { SocietyDirector } from 'types';

import GlobalSearch from 'modules/_shared/components/Modals/GlobalSearch';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import AddSocietyDirector from 'modules/_shared/components/Modals/AddSocietyDirector';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';
import { useGetActualSociety } from 'hooks/useGetActualSociety';

import { getAdminMembersData } from './utils/getAdminMembersData';
import AdminMembersPageHeader from './components/AdminMembersPageHeader';
import AdminMembersPageBody from './components/AdminMembersPageBody';

const AdminMembers = () => {
  const { societyId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const [currentMembersView, setCurrentMembersView] = useState<boolean>(true);
  const [adminMembers, setAdminMembers] = useState<SocietyDirector[]>([]);
  const [adminMembersCount, setAdminMembersCount] = useState<number>(0);

  useGetMenu();
  useGetActualSociety(societyId);

  const handleChangeMembersView = () => {
    setCurrentMembersView(!currentMembersView);
  };

  const handleAddMember = () => {
    if (societyId) {
      dispatch(
        setModal(
          <AddSocietyDirector
            societyId={societyId}
            action="ADD"
            directorData={null}
            directorId={null}
            userId={user['_id']}
          />
        )
      );
    }
  };

  const handleOpenGlobalSearch = () => {
    dispatch(setModal(<GlobalSearch size={sizes.XL} />));
  };

  useEffect(() => {
    if (actualSociety) {
      const { members, membersCount } = getAdminMembersData({
        society: actualSociety,
      });
      setAdminMembers(members);
      setAdminMembersCount(membersCount);
    }
  }, [actualSociety]);

  return actualSociety ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <AdminMembersPageHeader
          societyName={actualSociety?.name}
          adminMembersCount={adminMembersCount}
          currentMembersView={currentMembersView}
          handleAddMember={handleAddMember}
          handleChangeMembersView={handleChangeMembersView}
          handleOpenGlobalSearch={handleOpenGlobalSearch}
        />

        <AdminMembersPageBody
          societyId={actualSociety['_id']}
          adminMembers={adminMembers}
          currentMembersView={currentMembersView}
        />
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default AdminMembers;
