/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Document, Partner, PartnerCategory } from 'types';

import { format } from 'date-fns';

import { dateFormatInverted } from 'constants/formats';
import normalizeEmail from 'utils/normalizeEmail';

import { State } from 'redux/initialState';
import { PartnerSindication } from 'types/Partner';
import { sindicationTypes } from 'modules/partners/constants/sindicationTypes';
import { boardDelegationRoles } from 'modules/boards/constants/boardDelegation';
import { sindicationRefs } from 'modules/boards/constants/sindicationRefs';
import { allowedFileImage } from 'utils/allowedFileImage';
import errorSwal from 'utils/errorSwal';
import { useGetPartnerOptions } from '../hooks/useGetPartnerOptions';
import { useGetPartnerSelectedOptions } from '../hooks/useGetPartnerSelectedOptions';
import { getSindicationOptions } from '../utils/getSindicationOptions';

import PersonalDataViewFullName from './PersonalDataViewFullName';
import PersonalDataViewFiscalIdentity from './PersonalDataViewFiscalIdentity';
import PersonalDataViewEmails from './PersonalDataViewEmails';
import PersonalDataViewBirthDate from './PersonalDataViewBirthDate';
import PersonalDataViewEmployeeNr from './PersonalDataViewEmployeeNr';
import PersonalDataViewNationality from './PersonalDataViewNationality';
import PersonalDataViewPhoneNr from './PersonalDataViewPhoneNr';
import PersonalDataViewCategory from './PersonalDataViewCategory';
import PersonalDataViewSindication from './PersonalDataViewSindication';
import PersonalDataViewCivilStatus from './PersonalDataViewCivilStatus';
import PersonalDataViewAvatar from './PersonalDataViewAvatar';
import PersonalDataViewSindicationDocument from './PersonalDataViewSindicationDocument';
import { getDocumentById } from '../hooks/useGetLegalDocuments';
import { useGetSindicationDocument } from '../hooks/useGetSindicationDocument';

type Option = {
  label: string;
  value: string;
  role?: string;
};

type Props = {
  isNaturalPerson: boolean;
  originalPartner: Partner;
  partner: Partner;
  sindicationFile: File | undefined;
  handleChangePartnerData: any;
  handleChangeSindicationFile: any;
};

const PersonalDataView: FC<Props> = ({
  isNaturalPerson,
  originalPartner,
  partner,
  sindicationFile,
  handleChangePartnerData,
  handleChangeSindicationFile,
}) => {
  const { t } = useTranslate();

  const user = useSelector((state: State) => state.user);
  const isAdmin: boolean = useSelector(
    (state: State) => state.society?.role?.isAdmin
  );
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const partnerCategories = useSelector(
    (state: State) => state?.partnerCategories
  ) as PartnerCategory[];
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );

  const {
    data: { categoryOptions, sindicationOptions, civilStatusOptions },
  } = useGetPartnerOptions({
    currentPartner: partner,
    actualSociety,
    partnerCategories,
  });

  const selectedOptionsResult = useGetPartnerSelectedOptions({
    currentPartner: partner,
    actualSociety,
    partnerCategories,
  });

  const {
    selectedCategoryOption,
    selectedSindicationOption,
    selectedCivilStatusOption,
  } = selectedOptionsResult.data;

  const {
    setSelectedCategoryOption,
    setSelectedSindicationOption,
    setSelectedCivilStatusOption,
  } = selectedOptionsResult.actions;

  const [name, setName] = useState<string>(partner?.name || '');
  const [cif, setCif] = useState<string>(partner?.cif || '');
  const [email, setEmail] = useState<string>(partner?.email || '');
  const [birthdate, setBirthdate] = useState<string>(
    partner?.birthdate
      ? format(new Date(partner?.birthdate), dateFormatInverted)
      : ''
  );
  const [employeeNumber, setEmployeeNumber] = useState<string>(
    partner?.employeeNumber || ''
  );
  const [nationality, setNationality] = useState<string>(
    partner?.nationality || ''
  );
  const [phone, setPhone] = useState<string>(partner?.phone || '');
  const [image, setImage] = useState<string>(partner?.image || '');
  const [sindicationType, setSindicationType] = useState<PartnerSindication>(
    partner?.sindicationType ||
      (sindicationTypes.STANDARD as PartnerSindication)
  );
  const [hasSelectedPartnerRepresentant, setHasSelectedPartnerRepresentant] =
    useState<boolean>(
      partner?.sindicationRef === sindicationRefs.PARTNER_REPRESENTANT
    );

  const { data: sindicationDocument } = useGetSindicationDocument(partner);

  const handleChangeName = (event: any) => {
    setName(event.target.value);
    handleChangePartnerData({ ...partner, name: event.target.value });
  };

  const handleChangeCif = (event: any) => {
    setCif(event.target.value);
    handleChangePartnerData({ ...partner, cif: event.target.value });
  };

  const handleChangeEmail = (event: any) => {
    const newEmail = event.target.value;
    const normalizedEmail = normalizeEmail(newEmail);

    setEmail(normalizedEmail);
    handleChangePartnerData({ ...partner, email: normalizedEmail });
  };

  const handleChangeBirthdate = (event: any) => {
    setBirthdate(event.target.value);
    handleChangePartnerData({ ...partner, birthdate: event.target.value });
  };

  const handleChangeEmployeeNumber = (event: any) => {
    setEmployeeNumber(event.target.value);
    handleChangePartnerData({ ...partner, employeeNumber: event.target.value });
  };

  const handleChangeNationality = (event: any) => {
    setNationality(event.target.value);
    handleChangePartnerData({ ...partner, nationality: event.target.value });
  };

  const handleChangePhone = (event: any) => {
    setPhone(event.target.value);
    handleChangePartnerData({ ...partner, phone: event.target.value });
  };

  const handleChangeCategory = (selectedOption: any) => {
    setSelectedCategoryOption(selectedOption);
    handleChangePartnerData({ ...partner, category: selectedOption.value });
  };

  const handleChangeSindication = (selectedOption: any) => {
    setSelectedSindicationOption(selectedOption);
    setHasSelectedPartnerRepresentant(
      selectedOption?.role === boardDelegationRoles.PARTNER_REPRESENTANT
    );
    setSindicationType(sindicationTypes.STANDARD as PartnerSindication);

    const partnerData = {
      ...partner,
      sindication: selectedOption?.value,
      sindicationRef:
        selectedOption?.role === boardDelegationRoles.PARTNER_REPRESENTANT
          ? sindicationRefs.PARTNER_REPRESENTANT
          : sindicationRefs.PARTNER,
    };

    if (selectedOption === null) {
      partnerData.sindication = null;
      partnerData.sindicationRef = '';
      partnerData.sindicationType = null;
      partnerData.sindicationDocument = null;
      handleChangeSindicationFile(null);
    }

    handleChangePartnerData(partnerData);
  };

  const handleChangeSindicationType = (selectedSindicationType: any) => {
    const newSindicationType =
      selectedSindicationType.target.value === sindicationTypes.FULL
        ? sindicationTypes.STANDARD
        : sindicationTypes.FULL;

    setSindicationType(newSindicationType as PartnerSindication);
    handleChangePartnerData({
      ...partner,
      sindicationType: newSindicationType,
    });
  };

  const handleChangeCivilStatus = (selectedOption: any) => {
    setSelectedCivilStatusOption(selectedOption);
    handleChangePartnerData({ ...partner, civilStatus: selectedOption.value });
  };

  const handleChangeImage = async (event: any) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      const isAllowedImage = await allowedFileImage(file);

      if (!isAllowedImage) {
        errorSwal(t('InvalidFile'));
        return;
      }

      const reader = new FileReader();
      reader.onloadend = (e) => {
        const result = e.target?.result || '';
        setImage(result as string);
        handleChangePartnerData({ ...partner, image: result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="tab-pane active">
          <div className="row gy-4">
            <PersonalDataViewFullName
              label={isNaturalPerson ? t('Name') : t('SocialDenomination')}
              value={name}
              onChange={handleChangeName}
            />

            <PersonalDataViewFiscalIdentity
              value={cif}
              onChange={handleChangeCif}
            />

            <PersonalDataViewEmails
              value={email}
              onChange={handleChangeEmail}
              isAdmin={isAdmin}
              user={user}
              partner={partner}
              originalPartner={originalPartner}
              onUpdatePartner={handleChangePartnerData}
            />

            <PersonalDataViewBirthDate
              label={isNaturalPerson ? t('DateOfBirth') : t('ConstitutionDate')}
              value={birthdate}
              onChange={handleChangeBirthdate}
            />

            <PersonalDataViewEmployeeNr
              value={employeeNumber}
              onChange={handleChangeEmployeeNumber}
            />

            <PersonalDataViewNationality
              value={nationality}
              onChange={handleChangeNationality}
            />

            <PersonalDataViewPhoneNr
              value={phone}
              onChange={handleChangePhone}
            />

            {isAdmin && (
              <PersonalDataViewCategory
                value={selectedCategoryOption as Option}
                options={categoryOptions}
                onChange={handleChangeCategory}
              />
            )}

            <PersonalDataViewSindication
              value={selectedSindicationOption as Option}
              options={
                getSindicationOptions(
                  sindicationOptions,
                  partnerRepresentants
                ) as any
              }
              onChange={handleChangeSindication}
              valueSindicationType={sindicationType}
              onChangeSindicationType={handleChangeSindicationType}
              hasSelectedPartnerRepresentant={hasSelectedPartnerRepresentant}
              isAdmin={isAdmin}
            />

            {hasSelectedPartnerRepresentant && (
              <PersonalDataViewSindicationDocument
                file={sindicationFile}
                fileName={sindicationDocument?.name || ''}
                onChange={handleChangeSindicationFile}
              />
            )}

            {isNaturalPerson && (
              <PersonalDataViewCivilStatus
                value={selectedCivilStatusOption as Option}
                options={civilStatusOptions}
                onChange={handleChangeCivilStatus}
              />
            )}

            <PersonalDataViewAvatar
              value={image}
              onChange={handleChangeImage}
              name={name}
            />
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default PersonalDataView;
