import { axiosV1 } from 'interceptors/axios';
import { Envelope } from 'types';
import { dispatchError } from 'utils/dispatchError';
import { decryptResponse } from 'utils/token';

type ReferenceType = {
  boardId?: string;
  councilId?: string;
};

export const getSignersStatus = (envelopeId: string) => async () => {
  try {
    const { data } = await axiosV1.get(`/signersStatus/${envelopeId}`);
    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    throw error;
  }
};

export const getEnvelopesByReference =
  (reference: ReferenceType) => async () => {
    const query = { params: { ...reference } };
    try {
      const { data } = await axiosV1.get(`/envelopes/`, query);
      const decodedData = await decryptResponse(data);
      return decodedData;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
      throw error;
    }
  };

export const updateEnvelope =
  (envelopeData: Partial<Envelope>) => async (dispatch: any) => {
    try {
      const body = { ...envelopeData };

      const { data } = await axiosV1.put(
        `${process.env.REACT_APP_API_URL}/envelopes`,
        body
      );
      const decodedData = await decryptResponse(data);

      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };
