import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { store } from 'redux/store';

import downloadDocument from 'utils/downloadDocument';

import { Council } from 'types';
import { setModal } from 'modules/_shared/redux/modalActions';

import asyncActionSwal from 'utils/asyncActionSwal';
import successSwal from 'utils/successSwal';
import errorSwal from 'utils/errorSwal';
import DocumentsHeader from './DocumentsHeader';
import DocumentsList from './DocumentsList';

import useGetDocuments from '../hooks/useGetDocuments';
import AddCouncilDocumentModal from './AddCouncilDocumentModal';
import { removeDocumentFromCouncil } from '../utils/removeDocumentFromCouncil';

type Props = {
  council: Council;
  documentsIds: string[];
  fetchCouncilData: () => void;
};

const MembersDocumentsCard: FC<Props> = ({
  council,
  documentsIds,
  fetchCouncilData,
}: Props) => {
  const { t } = useTranslate();

  const { documents } = useGetDocuments({ documentsIds }).data;

  const hasDocuments = documents.length > 0;

  const isReadOnly = false; // TODO: Implement permissions

  const handleAddDocument = () => {
    store.dispatch(
      setModal(
        <AddCouncilDocumentModal
          council={council}
          fetchCouncilData={fetchCouncilData}
        />
      )
    );
  };

  const handleDownloadDocument = (index: number) => {
    downloadDocument({
      documentId: documents[index]?.['_id'],
      documentName: documents[index]?.name,
      documents: [],
    });
  };

  const handleRemoveDocument = (index: number) => {
    const documentId = documents[index]?._id;
    const documentName = documents[index]?.name;

    asyncActionSwal({
      swalTitle: t('AreYouSureAboutDelDocument'),
      swalElementName: documentName,
      swalInfoText: t('ThisProcedureCantUndo'),
      swalConfirmButtonText: t('OkDelete'),
      callback: async () => {
        try {
          if (council) {
            await removeDocumentFromCouncil({
              documentId,
              council,
              privateDocument: false,
            });
          }
          successSwal(t('DocumentDeletedSuccessfully'));
        } catch (error) {
          errorSwal(t('ErrorRemovingDocument'));
        } finally {
          fetchCouncilData();
        }
      },
    });
  };

  return (
    <div className="card-inner">
      <div className="sp-plan-head">
        <div className="row sp-plan-head mb-2">
          <h6 className="col-md-6 title">{t('DocumentsForMembers')}</h6>

          <div className="col-md-6 text-right">
            {!isReadOnly && (
              <ul>
                <li>
                  <button
                    type="button"
                    className="link text-primary"
                    onClick={handleAddDocument}
                  >
                    <span className="title">{t('Add')}</span>
                  </button>
                </li>
              </ul>
            )}
          </div>

          <span className="col-12 ff-regular text-soft">
            {t('DocumentsForMembersInfo')}
          </span>
        </div>
      </div>
      <div className="nk-files nk-files-view-list">
        {hasDocuments && (
          <>
            <DocumentsHeader />

            <DocumentsList
              documents={documents}
              handleDownloadDocument={handleDownloadDocument}
              handleRemoveDocument={handleRemoveDocument}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MembersDocumentsCard;
