import { FC } from 'react';
import { Council } from 'types';

import CouncilsCardDetails from './CouncilsCardDetails';
import CouncilsCardActions from './CouncilsCardActions';

type CouncilsCardProps = {
  council: Council;
};

const CouncilsCard: FC<CouncilsCardProps> = ({ council }) => (
  <div className="card card-bordered sp-plan hover-shadow">
    <div className="row no-gutters">
      <CouncilsCardDetails council={council} />
      <CouncilsCardActions council={council} />
    </div>
  </div>
);

export default CouncilsCard;
