import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

const RealtiesTableHeader: FC = () => {
  const { t } = useTranslate();

  return (
    <thead>
      <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
        <th className="nk-tb-col">
          <span>{t('Name')}</span>
        </th>
        <th className="nk-tb-col">
          <span>{t('Address')}</span>
        </th>
        <th className="nk-tb-col">
          <span>{t('City')}</span>
        </th>
        <th className="nk-tb-col">
          <span>{t('Owner')}</span> / <span>{t('Lessee')}</span>
        </th>
        <th className="nk-tb-col" />
      </tr>
    </thead>
  );
};

export default RealtiesTableHeader;
