/* eslint-disable react/no-array-index-key */
import { FC, useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { CouncilParticipant, Council, Society } from 'types';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import ParticipantsRow from './ParticipantsRow';

type ParticipantsTableProps = {
  currentCouncil: Council;
  handleEditParticipant: (participant: CouncilParticipant) => void; // eslint-disable-line
  isParticipantAllowedForAnnouncement: (
    participant: CouncilParticipant, // eslint-disable-line
    society: Society // eslint-disable-line
  ) => boolean;
  getTotalParticipantsAllowed: (council: Council, society: Society) => number; // eslint-disable-line
};

const ParticipantsTable: FC<ParticipantsTableProps> = ({
  currentCouncil,
  handleEditParticipant,
  getTotalParticipantsAllowed,
  isParticipantAllowedForAnnouncement,
}) => {
  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const [expandView, setExpandView] = useState<boolean>(false);
  const [expandDirection, setExpandDirection] = useState<string>('down');

  const handleViewChange = (): void => {
    const newView = !expandView;
    const newDirection = newView ? 'up' : 'down';
    setExpandView(newView);
    setExpandDirection(newDirection);
  };

  return (
    <div key="participants-card" className="card card-bordered mb-4">
      <table className="table mb-0" id="participants-table">
        <thead>
          <tr className="tb-tnx-head">
            <th className="text-left" colSpan={2}>
              <span>
                {t('Participants')} (
                {getTotalParticipantsAllowed(currentCouncil, actualSociety)})
              </span>
            </th>
            <th className="col-2 text-right">
              <button
                type="button"
                className="badge btn-primary"
                onClick={handleViewChange}
              >
                <em className={`icon ni ni-chevron-${expandDirection}`} />
              </button>
            </th>
          </tr>
        </thead>
      </table>

      <div key="participants-card" className="mh-200px overflow-y-auto">
        <table className="table" id="participants-body">
          {expandView && (
            <tbody>
              {currentCouncil?.participants
                ?.filter((participant: CouncilParticipant) =>
                  isParticipantAllowedForAnnouncement(
                    participant,
                    actualSociety
                  )
                )
                ?.map((participant: CouncilParticipant, index: number) => (
                  <ParticipantsRow
                    key={`participant-${index}`}
                    index={index}
                    participant={participant}
                    handleEditParticipant={handleEditParticipant}
                  />
                ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default ParticipantsTable;
