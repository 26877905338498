import { useEffect, useState } from 'react';

import { CouncilParticipant } from 'types';
import attendanceStatus from '../constants/attendanceStatus';

type Props = {
  participant: CouncilParticipant;
};

const getAttendanceStatus = (assists: boolean) => {
  const assistsStatus = attendanceStatus[0];
  const notAssistsStatus = attendanceStatus[1];

  if (assists) {
    return {
      assistsStatusLabel: assistsStatus.label,
      assistsStatusBadge: assistsStatus.badge,
    };
  }
  return {
    assistsStatusLabel: notAssistsStatus.label,
    assistsStatusBadge: notAssistsStatus.badge,
  };
};

export const useGetParticipantAttendance = ({ participant }: Props) => {
  const [attendanceStatusLabel, setAttendanceStatusLabel] =
    useState<string>('-');
  const [attendanceStatusBadge, setAttendanceStatusBadge] =
    useState<string>('');
  const [participantAttendance, setParticipantAttendance] = useState<boolean>(
    participant?.assists
  );

  useEffect(() => {
    const { assistsStatusLabel, assistsStatusBadge } = getAttendanceStatus(
      participantAttendance
    );

    setAttendanceStatusLabel(assistsStatusLabel);
    setAttendanceStatusBadge(assistsStatusBadge);
  }, [participant, participantAttendance]);

  return {
    data: {
      participantAttendance,
      attendanceStatusLabel,
      attendanceStatusBadge,
    },
    actions: { setParticipantAttendance },
  };
};
