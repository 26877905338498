import { FC } from 'react';
import { CouncilParticipant } from 'types';

type ParticipantsRowProps = {
  index: number;
  participant: CouncilParticipant;
  handleEditParticipant: (participant: CouncilParticipant) => void; // eslint-disable-line
};

const ParticipantsRow: FC<ParticipantsRowProps> = ({
  index,
  participant,
  handleEditParticipant,
}) => (
  <tr className="tb-tnx-item">
    <td
      className="tb-tnx-info text-left py-0 lh-36px"
      id={`participant-${index}`}
    >
      <span className="title">{participant.name}</span>
    </td>

    <td
      className="tb-tnx-info text-soft text-left py-0 lh-36px"
      id={`participant-${index}-email`}
    >
      <span className="title d-block">{participant.email}</span>
    </td>

    <td
      className="tb-tnx-info text-soft text-left py-0"
      id={`participant-${index}-blank`}
    >
      <div className="nk-block float-right">
        <button
          type="button"
          className="btn btn-icon btn-trigger px-0"
          onClick={() => {
            handleEditParticipant(participant);
          }}
        >
          <em className="icon ni ni-edit" />
        </button>
      </div>
    </td>
  </tr>
);

export default ParticipantsRow;
