import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { useTranslate } from 'hooks/useTranslate';

import Button from 'modules/_shared/components/Buttons';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';

type Props = {
  societyName: string;
  activeSocietyAuditorsCount: number;
  currentSocietyAuditorsView: boolean;
  handleAddAuditor: () => void;
  handleChangeAuditorsView: () => void;
  handleOpenGlobalSearch: () => void;
};

const SocietyAuditorsPageHeader: FC<Props> = ({
  societyName,
  activeSocietyAuditorsCount,
  currentSocietyAuditorsView,
  handleAddAuditor,
  handleChangeAuditorsView,
  handleOpenGlobalSearch,
}) => {
  const { t } = useTranslate();

  const currentAuditorsViewIcon = currentSocietyAuditorsView
    ? 'icon ni ni-eye'
    : 'icon ni ni-eye-off';

  const currentAuditorsViewTooltipText = currentSocietyAuditorsView
    ? t('SeeNotCurrent')
    : t('HideNotCurrent');

  return (
    <>
      <PageListHeader
        title={t('Auditors')}
        subTitle={societyName}
        description={`${societyName || ''} ${t('HasActiveAuditors', {
          count: activeSocietyAuditorsCount,
        })}`}
        actions={[
          <Button
            id="open-global-search-button"
            icon="ni ni-search"
            variant="light"
            onClick={handleOpenGlobalSearch}
          />,
          <Button
            id="toggle-members-view-button"
            icon={currentAuditorsViewIcon}
            variant="light"
            onClick={handleChangeAuditorsView}
          />,
          <Button
            icon="ni ni-plus"
            variant="primary"
            onClick={handleAddAuditor}
          >
            {t('AddAuditor')}
          </Button>,
        ]}
      />

      <Tooltip
        anchorSelect="#toggle-members-view-button"
        content={currentAuditorsViewTooltipText}
      />

      <Tooltip
        anchorSelect="#open-global-search-button"
        content={t('GlobalSearch')}
      />
    </>
  );
};

export default SocietyAuditorsPageHeader;
