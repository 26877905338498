import { getEnvelopesByReference } from 'modules/_shared/redux/envelopeActions';
import { useEffect, useState } from 'react';
import { store } from 'redux/store';

import { Envelope } from 'types';

type Props = {
  councilId: string;
};

export const useGetCouncilEnvelopes = ({ councilId }: Props) => {
  const [councilEnvelopes, setCouncilEnvelopes] = useState<Envelope[]>([]);

  useEffect(() => {
    const getBoardEnvelopes = async () => {
      try {
        const envelopes = await store.dispatch(
          getEnvelopesByReference({ councilId })
        );
        setCouncilEnvelopes(envelopes);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    getBoardEnvelopes();
  }, [councilId]);

  return { data: { councilEnvelopes } };
};
