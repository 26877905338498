import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { Modal } from 'react-bootstrap';
import { FC } from 'react';
import actionTypes from 'constants/actionTypes';
import { setModal } from 'modules/_shared/redux/modalActions';

type AddCouncilHeaderProps = {
  action: keyof typeof actionTypes;
};

const AddCouncilHeader: FC<AddCouncilHeaderProps> = ({ action }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  return (
    <Modal.Header>
      {action === actionTypes.ADD && (
        <h4 className="modal-title">
          <span>{t('CouncilPreparation')}</span>
          <small className="d-block fs-14px text-soft mt-1">
            {t('LaterCanModify')}
          </small>
        </h4>
      )}

      {action === actionTypes.EDIT && (
        <h4 className="modal-title">{t('EditCouncil')}</h4>
      )}

      <a
        className="close cursor-pointer"
        onClick={() => dispatch(setModal(null))}
      >
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default AddCouncilHeader;
