/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import LogRocket from 'logrocket';
import { User } from 'types';

const ANONYMOUS_USER = 'anonymous_user';

export const initializeLogRocket = () => {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID || '');
};

export const identifyUser = (user: Partial<User>) => {
  LogRocket.identify(user?._id || ANONYMOUS_USER, {
    name: user?.name || '',
    email: user?.email || '',
  });
};
