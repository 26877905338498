import { FC } from 'react';

import { CouncilParticipant } from 'types';

import AttendanceTableHeader from './AttendanceTableHeader';
import AttendanceTableBody from './AttendanceTableBody';

type Props = {
  societyId: string | undefined;
  councilId: string;
  participants: CouncilParticipant[];
};

const AttendanceTable: FC<Props> = ({ societyId, councilId, participants }) => (
  <div className="card card-bordered card-preview">
    <div className="table-responsive">
      <table className="nk-tb-list nk-tb-ulist">
        <AttendanceTableHeader />

        <AttendanceTableBody
          societyId={societyId}
          councilId={councilId}
          participants={participants}
        />
      </table>
    </div>
  </div>
);

export default AttendanceTable;
