import { State } from 'redux/initialState';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDocuments } from 'modules/documents/redux/documentActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import { getActualSociety, getSocietyValue } from 'utils/filters';

import DirectorSociety from 'modules/society/components/DirectorSociety';
import DirectorPersonal from 'modules/society/components/DirectorPersonal';
import DirectorDocuments from 'modules/society/components/DirectorDocuments';

import { dateFormat } from 'constants/formats';
import { Partner, SocietyDirector } from 'types';
import { getUserSocieties } from 'modules/society/redux/societyActions';
import { getEntitiesFromSocieties } from 'modules/_shared/components/Modals/GlobalSearch/hooks/useGlobalSearch';
import DirectorRoaming from 'modules/society/components/DirectorRoaming';

export const useDirectorDetail = () => {
  const dispatch = useDispatch();
  const { societyId, directorId } = useParams();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const [date, setDate] = useState<string>('');
  const [pages, setPages] = useState<ReactNode[]>([]);
  const [isOutdated, setIsOutdated] = useState<boolean>(false);
  const [director, setDirector] = useState<SocietyDirector>();
  const [roamingDirectors, setRoamingDirectors] = useState([]);

  const getRoamingDirector = async () => {
    try {
      const societies = (await getUserSocieties(
        user['_id'],
        'admin',
        null,
        'search'
      )) as any;

      const directors = getEntitiesFromSocieties(societies, 'directors');

      setRoamingDirectors(directors as any);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getHoldingClasses(actualSociety?.['_id']));
      dispatch(getDocuments({ society: actualSociety?.['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (user && actualSociety) {
      const societyValue = getSocietyValue(actualSociety);
      const actualDirector = actualSociety?.directors?.find(
        (dir: SocietyDirector) => dir['_id'] === directorId
      );
      if (actualDirector) {
        if (
          actualDirector?.endDate &&
          new Date() > new Date(actualDirector?.endDate)
        ) {
          setDate(format(new Date(actualDirector.endDate), dateFormat));
          setIsOutdated(true);
        } else if (actualDirector?.initDate) {
          setDate(format(new Date(actualDirector.initDate), dateFormat));
        }
        const partner = actualSociety?.partners?.find(
          (p: Partner) => p?.cif && p?.cif === actualDirector?.cif
        );
        const partnerData = partner
          ? {
              ...partner,
              societyValue,
            }
          : null;

        setDirector(actualDirector);

        setPages([
          <DirectorSociety
            key="DirectorSociety"
            society={actualSociety}
            director={actualDirector}
            partner={partnerData}
          />,
          <DirectorPersonal
            key="DirectorPersonal"
            society={actualSociety}
            director={actualDirector}
          />,
          <DirectorRoaming
            key="DirectorRoaming"
            society={actualSociety}
            director={actualDirector}
            roamingDirectors={roamingDirectors}
          />,
          <DirectorDocuments
            key="DirectorDocuments"
            society={actualSociety}
            director={actualDirector}
          />,
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directorId, societyId, user, actualSociety, roamingDirectors]);

  useEffect(() => {
    if (user) {
      getRoamingDirector();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data: { date, pages, director, isOutdated },
  };
};
