import { SocietyRepresentative } from 'types';
import societyRepresentativeTypes from '../constants/societyRepresentativeTypes';

export const getSocietyRepresentativeTypeLabel = (
  representative: SocietyRepresentative
) => {
  const representativeType = societyRepresentativeTypes.find(
    (type) => type.value === representative?.representativeType
  );

  const representativeTypeLabel = representativeType
    ? representativeType.label
    : '-';

  return representativeTypeLabel;
};
