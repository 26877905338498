import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch } from 'react-redux';

import { useLegalDashboard } from 'modules/dashboard/hooks/useLegalDashboard';
import { useGetDashboardSectionPermissions } from 'modules/dashboard/hooks/useGetDashboardSectionPermissions';
import WidgetLegalTableExtended from 'modules/dashboard/components/WidgetLegalTableExtended';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import WidgetCard from 'modules/dashboard/components/WidgetCard';
import WidgetChartDoughnut, {
  legendModes,
} from 'modules/dashboard/components/WidgetChartDoughnut';
import { getCommittees } from 'modules/administratives/redux/committeeActions';

const LegalDashboard = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const { isReadOnly } = useGetDashboardSectionPermissions().data;
  const { data, isLoading } = useLegalDashboard(t);

  useEffect(() => {
    if (societyId) {
      dispatch(getCommittees(societyId));
    }
  }, [dispatch, societyId]);

  return (
    <div className="nk-block d-flex flex-wrap justify-content-between">
      <div className="row row-g-1">
        {data.summary?.identificationNumbers && (
          <WidgetCard
            title={t('IdentificationNumbers')}
            data={data.summary?.identificationNumbers}
            isReadOnly={isReadOnly}
            isFullWidth
          />
        )}

        {data.summary?.addresses && (
          <WidgetCard
            title={t('Address')}
            data={data.summary?.addresses}
            isReadOnly={isReadOnly}
            isFullWidth
          />
        )}

        {data.summary?.incorporation && (
          <WidgetCard
            title={t('Incorporation')}
            data={data.summary?.incorporation}
            isReadOnly={isReadOnly}
            isFullWidth
          />
        )}

        {data.summary?.fiscalYear && (
          <WidgetCard
            title={t('FiscalYear')}
            data={data.summary?.fiscalYear}
            isReadOnly={isReadOnly}
            isFullWidth={false}
          />
        )}

        {data.directors.length > 0 && (
          <WidgetLegalTableExtended
            title={t('Council')}
            linkText={t('SeeAlls')}
            linkUrl={`/organo-admin/${societyId}`}
            columns={[t('Member'), t('Position'), t('ConditionC'), t('Term')]}
            data={data.directors}
            isReadOnly={isReadOnly}
            isFullWidth
          />
        )}

        {data.committees?.map((committee: any) => (
          <WidgetLegalTableExtended
            title={t(committee.title)}
            linkText={t('SeeAlls')}
            linkUrl={`${committee.linkUrl}/${societyId}/${committee.id}`}
            columns={[t('Member'), t('Office'), t('ConditionC'), t('Term')]}
            data={committee.data}
            isReadOnly={isReadOnly}
            isFullWidth
          />
        ))}

        {data.capital && (
          <WidgetCard
            title={t('ShareCapital')}
            data={data.capital?.description}
            isReadOnly={isReadOnly}
            isFullWidth
          />
        )}

        {data.partners && (
          <WidgetLegalTableExtended
            title={t('MainPartners')}
            linkText={t('SeeAlls')}
            linkUrl={`/socios/${societyId}`}
            columns={[t('Partner'), t('Shar'), t('%ND'), t('%FD')]}
            data={data.partners}
            isReadOnly={isReadOnly}
            isFullWidth={false}
          />
        )}

        {data.partnersChart && (
          <WidgetChartDoughnut
            title={t('Partners')}
            subtitle=""
            height={0}
            linkText={t('SeeAlls')}
            linkUrl={`/socios/${societyId}`}
            data={data.partnersChart?.chart}
            legendMode={legendModes.VERTICAL}
            legend={data.partnersChart?.partners}
            isReadOnly={isReadOnly}
          />
        )}

        {data.auditors.length > 0 && (
          <WidgetLegalTableExtended
            title={t('Auditors')}
            linkText={t('SeeAlls')}
            linkUrl={`/auditores/${societyId}`}
            columns={[t('Auditor'), t('Status'), t('Term')]}
            data={data.auditors}
            isReadOnly={isReadOnly}
            isFullWidth
          />
        )}

        {data.representatives.length > 0 && (
          <WidgetLegalTableExtended
            title={t('Representatives')}
            linkText={t('SeeAlls')}
            linkUrl={`/apoderados/${societyId}`}
            columns={[t('Representative'), t('TypeOfPoa/Limits'), t('PoaDate')]}
            data={data.representatives}
            isReadOnly={isReadOnly}
            isFullWidth
          />
        )}

        {data.summary?.reservedMatters && (
          <WidgetCard
            title={t('ReservedMatters')}
            data={data.summary?.reservedMatters}
            isReadOnly={isReadOnly}
            isFullWidth
          />
        )}
      </div>

      {isLoading && <CustomLoading />}
    </div>
  );
};

export default LegalDashboard;
