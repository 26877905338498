import { store } from 'redux/store';
import { updateDocument } from 'modules/documents/redux/documentActions';
import documentTypes from 'constants/documentTypes';

export const updateDocumentToDeleted = async (
  documentId: string | undefined
) => {
  if (!documentId) return;
  await store.dispatch(
    updateDocument({ _id: documentId, category: documentTypes.DELETED }, false)
  );
};
