/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';

import { cleanValue } from 'constants/formats';
import invitationStatus from 'constants/invitationStatus';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import { format, isValid } from 'date-fns';
import LineCharts from 'modules/_shared/components/Charts/LineChart';
import {
  getBeneficiaryFDPercent,
  getBeneficiaryUnits,
  getDecimalScale,
  getSocietyValueAtDate,
  getSoldUnitsAtDate,
  getVestedPercent,
  getVestedUnits,
} from 'utils/filters';
import { formatCurrencyDecimals, formatNumber } from 'utils/formats';
import { isMobileScreen } from 'utils/getScreenSize';

import BeneficiarySocietyValueSimulator from 'modules/beneficiaries/components/BeneficiarySocietyValueSimulator';
import getSimulatorValues from 'modules/beneficiaries/components/BeneficiarySocietyValueSimulator/utils/getSimulatorValues';
import { getVestingDates } from 'utils/vestingDates';
import { useDispatch, useSelector } from 'react-redux';
import BeneficiarySell from 'modules/beneficiaries/modals/BeneficiarySell';
import { setModal } from 'modules/_shared/redux/modalActions';
import sizes from 'constants/sizes';
import BeneficiarySocietyMetrics from './BeneficiarySocietyMetrics';

const getIncentiveChart = (configuration) =>
  configuration?.hasIncentiveChart !== false;

function BeneficiarySociety({
  beneficiary,
  society,
  beneficiaryAllPlans,
  setPage,
  simulatorValue,
  setSimulatorValue,
}) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { societyValue } = beneficiary;
  const configuration = useSelector(
    (state) => state.society?.actualSociety?.configuration
  );

  const [decimalScale] = useState(
    societyValue ? getDecimalScale(societyValue.value) : 2
  );
  const [FDpercent, setFDpercent] = useState(0);

  const [hasIncentiveChart, setHasIncentiveChart] = useState(true);
  const [incentivesValues, setIncentivesValues] = useState([]);
  const [simulatorSocietyValue, setSimulatorSocietyValue] = useState();
  const [vestingValues, setVestingValues] = useState([]);
  const [valuation, setValuation] = useState(0);
  const [summary, setSummary] = useState({
    units: 0,
    vestedUnits: 0,
    FDpercent: 0,
    exercisePrice: 0,
  });

  const [isAcceptButtonVisible, setIsAcceptButtonVisible] = useState(false);

  const chartOptions = (data, options) => {
    const allData = data?.simulatorSocietyValue
      ? [...data.incentivesValues, data.simulatorSocietyValue]
      : [...data.incentivesValues];
    const societyValuesData = allData?.reduce((acc, curr) => {
      acc[curr?.incentive.toString()] = curr?.value || 0;
      return acc;
    }, {});
    const pendingValues = data?.vestingValues.reduce((acc, curr) => {
      acc[format(new Date(curr?.date), 'dd-MM-yyyy')] = curr?.pending || 0;
      return acc;
    }, {});

    return {
      responsive: true,
      plugins: {
        legend: {
          display: !!options?.legend,
          position: 'top',
        },
        title: {
          display: !!options?.title,
          text: options?.title,
        },
        filler: {
          propagate: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (context) => {
              if (
                context.datasetIndex === 2 &&
                context.raw !== data?.simulatorSocietyValue?.incentive
              ) {
                return '';
              }
              if (context.datasetIndex === 1) {
                return `${t(
                  'IncentiveValueConsolidated'
                )}: ${formatCurrencyDecimals(context.raw)}`;
              }
              return `${t('IncentiveValueEstimated')}: ${formatCurrencyDecimals(
                context.raw
              )}`;
            },
            afterLabel: (context) => {
              if (
                context.datasetIndex === 2 &&
                context.raw !== data?.simulatorSocietyValue?.incentive
              ) {
                return '';
              }
              if (context.datasetIndex === 1) {
                const v = formatCurrencyDecimals(
                  pendingValues?.[format(new Date(context.label), 'dd-MM-yyyy')]
                );
                return `${t('ConsolidatedValuePending')}: ${v}`;
              }
              const v = formatNumber(societyValuesData?.[context.raw]);
              return `${t('SocietyValueEstimated')}: ${v}€`;
            },
          },
        },
      },
      tooltips: {
        enabled: true,
        backgroundColor: '#eff6ff',
        titleFontSize: 13,
        titleFontColor: '#6783b8',
        titleMarginBottom: 6,
        bodyFontColor: '#9eaecf',
        bodyFontSize: 12,
        bodySpacing: 4,
        yPadding: 10,
        xPadding: 10,
        footerMarginTop: 0,
        displayColors: false,
      },
      showLine: true,
      spanGaps: true,
      response: true,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'month',
          },
        },
      },
      ...options,
    };
  };

  const convertData = (values) => {
    if (!values) {
      return {};
    }
    const allValues = values?.simulatorSocietyValue
      ? [
          ...values.incentivesValues,
          ...values.vestingValues,
          values.simulatorSocietyValue,
        ]
      : [...values.incentivesValues, ...values.vestingValues];
    const sortedValues = allValues.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    const filteredValues = sortedValues.reduce((acc, curr, index) => {
      if (index === 0) acc.push(curr);
      else if (
        new Date(sortedValues[index - 1].date).getTime() !==
        new Date(curr.date).getTime()
      )
        acc.push(curr);
      return acc;
    }, []);

    const incentivesData = filteredValues.map((curr) => {
      const value = values?.incentivesValues?.find(
        (elem) =>
          new Date(curr.date).getTime() === new Date(elem.date).getTime()
      );
      return value ? value.incentive : null;
    });

    const vestingData = filteredValues.map((curr) => {
      const value = values?.vestingValues?.find(
        (elem) =>
          new Date(curr.date).getTime() === new Date(elem.date).getTime()
      );
      return value ? value.incentive : null;
    });

    const lastIncentive = values.incentivesValues.reduce((acc, curr) => {
      if (new Date(curr.date) > new Date(acc.date)) return curr;
      return acc;
    }, values.incentivesValues[0]);

    const simulatorData = filteredValues.map((curr) => {
      if (!values?.simulatorSocietyValue) return null;
      if (
        new Date(lastIncentive.date).getTime() === new Date(curr.date).getTime()
      )
        return lastIncentive.incentive;
      if (
        new Date(values?.simulatorSocietyValue?.date).getTime() ===
        new Date(curr.date).getTime()
      )
        return values?.simulatorSocietyValue?.incentive;
      return null;
    });

    const chartData = {
      labels: filteredValues?.map((elem) => new Date(elem.date)),
      datasets: [
        {
          label: t('IncentiveValueLegend'),
          data: incentivesData,
          fill: false,
          borderColor: '#6576ff',
          backgroundColor: '#6576ff',
          tension: 0,
          pointHoverRadius: 10,
          pointRadius: 2,
        },
        {
          label: t('ConsolidatedValueLegend'),
          data: vestingData,
          fill: false,
          borderColor: '#20c997',
          backgroundColor: '#20c997',
          tension: 0.1,
          pointHoverRadius: 10,
          pointRadius: 2,
        },
        {
          label: t('SimulatedValue'),
          data: simulatorData,
          borderDash: [2, 4],
          fill: false,
          borderColor: '#6576ff',
          backgroundColor: '#6576ff50',
          tension: 0.1,
          pointHoverRadius: 10,
          pointRadius: 2,
        },
      ],
    };
    return chartData;
  };

  const hasPendingInvitations = (invitations) =>
    invitations.some((elem) => elem.status === invitationStatus.PENDING.value);

  const hasPlanWithInvitation = (invitations) =>
    invitations.some((elem) => elem.hasInvitation);

  const handleChangeSlider = (value) => {
    const { FDpercent: currentFD, exercisePrice } = getSimulatorValues({
      society,
      societyValue,
      beneficiaryAllPlans,
    });

    const newValue = cleanValue(value[0]);
    const valuation = (currentFD * newValue) / 100;
    const higherValue = Math.max(valuation, exercisePrice);

    setSimulatorValue(newValue);
    setValuation(higherValue);
  };

  function getUniqueVestingDates(beneficiaries) {
    const uniqueDates = new Set();
    beneficiaries.forEach((beneficiary) => {
      // Get the vesting dates.
      const vestingDates = getVestingDates(beneficiary, beneficiary?.planData);
      // Add the unique dates to the set.
      vestingDates?.forEach((vestingDate) => {
        if (!vestingDate || !isValid(vestingDate)) return;
        uniqueDates.add(new Date(vestingDate));
      });
    });

    // Convert Set to Array and sort it.
    const sortedDates = [...uniqueDates].sort((a, b) => a - b);

    // Return the unique dates as an array.
    return sortedDates;
  }

  // Returns the total vested units for beneficiaries at the date.
  function getVestedUnitsAtDate(beneficiaries, date) {
    let beneficiaryVestedUnits = 0;

    // Get the vested units for each beneficiary.
    beneficiaries.forEach((beneficiary) => {
      // Get the vested units for the beneficiary at the date.
      const vestedUnits = getVestedUnits(
        beneficiary,
        beneficiary?.planData,
        new Date(date)
      );
      // Get the sold units for the beneficiary at the date.
      let soldUnits = 0;
      if (beneficiary?.tenderOffers?.length > 0) {
        soldUnits = getSoldUnitsAtDate(
          beneficiary,
          beneficiary.tenderOffers,
          date
        );
      }
      beneficiaryVestedUnits += vestedUnits - soldUnits;
    });
    return beneficiaryVestedUnits;
  }

  // Returns the vested data for each vesting date, sorted by date.
  function getVestingData(beneficiaries, summaryData) {
    const vestingDates = getUniqueVestingDates(beneficiaries);

    // Vesting dates is sorted so we can get the last date.
    const lastVestingDate = vestingDates[vestingDates.length - 1];

    const vestingData = [];
    vestingDates.forEach((date) => {
      // Get the vested units for each beneficiary.
      const vestedUnits = getVestedUnitsAtDate(beneficiaries, date);

      // Get the society value at the date.
      const societyValueAtDate = getSocietyValueAtDate(society, date)?.value;

      // Get the current incentive.
      const currentIncentive =
        (getVestedPercent(vestedUnits, society) * societyValueAtDate) / 100;

      // Add the data.
      vestingData.push({
        date,
        vested: vestedUnits,
        pending:
          date.getTime() === lastVestingDate.getTime()
            ? 0
            : +(
                (+FDpercent * societyValueAtDate) / 100 -
                currentIncentive
              ).toFixed(0),
        value: societyValueAtDate,
        incentive:
          date.getTime() === lastVestingDate.getTime()
            ? Math.max(+currentIncentive.toFixed(2), summaryData.exercisePrice)
            : +currentIncentive.toFixed(2),
      });
    });

    // Return the vesting data.
    // As the dates are cycled through in an orderly manner, the result is ordered by date.
    return vestingData;
  }

  // Returns the summary data for the beneficiaries.
  function getSummaryData(beneficiaries) {
    const summary = beneficiaries.reduce(
      (acc, beneficiary) => {
        acc.vestedUnits +=
          getVestedUnits(beneficiary, beneficiary?.planData, new Date()) -
            beneficiary?.soldUnits || 0;
        acc.units += getBeneficiaryUnits(beneficiary);
        acc.FDpercent += getBeneficiaryFDPercent(beneficiary, society, true);
        acc.exercisePrice +=
          beneficiary.amount -
          (beneficiary?.soldUnits || 0) * beneficiary?.planData?.sharePrice;
        acc.invitations.push({
          status: beneficiary?.invitationStatus || null,
          planName: beneficiary?.planData?.name,
          hasInvitation: !!beneficiary?.planData?.hasInvitation,
        });

        if (beneficiary?.tenderOffers?.length) {
          const matchedParticipants = beneficiary.tenderOffers.flatMap(
            (tenderOffer) =>
              tenderOffer.participants.filter(
                (participant) => participant.id === beneficiary._id
              )
          );
          acc.tenderOffers.push(...matchedParticipants);
        }

        return acc;
      },
      {
        units: 0,
        vestedUnits: 0,
        FDpercent: 0,
        exercisePrice: 0,
        invitations: [],
        tenderOffers: [],
      }
    );
    // Return the summary data.
    return summary;
  }

  // Returns the society values with the FD percent.
  function getSocietyValuesWithFd(beneficiaries, societyData) {
    const result = [];

    societyData?.societyValues?.forEach((societyValue) => {
      let fdPercent = 0;

      beneficiaries.forEach((beneficiary) => {
        // Get the sold units at the date.
        let soldUnits = 0;
        if (beneficiary?.tenderOffers?.length) {
          soldUnits = getSoldUnitsAtDate(
            beneficiary,
            beneficiary.tenderOffers,
            societyValue.date
          );
        }

        // Get the FD value.
        const fdValue = getBeneficiaryFDPercent(
          beneficiary,
          societyData,
          !!soldUnits,
          soldUnits
        );

        fdPercent += fdValue;
      });

      result.push({
        ...societyValue,
        fdPercent,
      });
    });

    // Return the result.
    return result;
  }

  // Returns the society values with the FD percent.
  function getIncentivesData(beneficiaries, societyData) {
    const firstVestingDate = vestingValues[0]?.date;
    const lastVestingDate = vestingValues[vestingValues.length - 1]?.date;

    // Add FD percent to society values.
    const societyValuesWithFD = getSocietyValuesWithFd(
      beneficiaries,
      societyData
    );

    // Get the values before the last vesting date.
    const filteredValues = societyValuesWithFD?.filter(
      (societyValue) => new Date(societyValue.date) < new Date(lastVestingDate)
    );

    // Add the data for all society values before the last vesting date.
    const result = filteredValues.map((societyValue) => {
      const incentive = +(
        (societyValue.fdPercent * societyValue.value) /
        100
      ).toFixed(2);
      return {
        date: new Date(societyValue.date),
        value: societyValue.value,
        incentive:
          new Date(societyValue.date) > new Date(firstVestingDate)
            ? Math.max(incentive, summary.exercisePrice)
            : incentive,
      };
    });

    const lastSocietyValue = getSocietyValueAtDate(
      societyData,
      lastVestingDate
    );

    const lastIncentive = +(
      (+FDpercent * lastSocietyValue?.value) /
      100
    ).toFixed(2);

    // Add the data for last vesting date.
    result.push({
      date: lastVestingDate,
      value: lastSocietyValue?.value,
      incentive:
        new Date(lastVestingDate) > new Date(firstVestingDate)
          ? Math.max(lastIncentive, summary.exercisePrice)
          : lastIncentive,
    });

    return result;
  }

  const handleOpenSellView = () => {
    dispatch(
      setModal(
        // @ts-ignore
        <BeneficiarySell
          size={sizes.XL}
          society={society}
          beneficiary={beneficiary}
          beneficiaryAllPlans={beneficiaryAllPlans}
          setSimulatorValue={setSimulatorValue}
          societyValue={societyValue}
        />
      )
    );
  };

  useEffect(() => {
    if (configuration) {
      const hasIncentiveChart = getIncentiveChart(configuration);
      setHasIncentiveChart(hasIncentiveChart);
    }
  }, [configuration]);

  useEffect(() => {
    if (beneficiaryAllPlans?.length) {
      // Set summary values.
      const summary = getSummaryData(beneficiaryAllPlans);
      setFDpercent(summary.FDpercent);
      setSummary({ ...summary });

      // Set vesting values.
      const vestingData = getVestingData(beneficiaryAllPlans, summary);
      setVestingValues(vestingData);
    }
  }, [beneficiaryAllPlans]);

  useEffect(() => {
    if (vestingValues?.length > 0) {
      // Set incentives values.
      const incentivesData = getIncentivesData(beneficiaryAllPlans, society);
      setIncentivesValues(incentivesData);
    }
  }, [vestingValues, summary]);

  useEffect(() => {
    if (
      simulatorValue &&
      simulatorValue > societyValue?.value &&
      incentivesValues?.length
    ) {
      const lastDate = incentivesValues.reduce((acc, curr) => {
        if (new Date(curr.date) > new Date(acc)) return curr.date;
        return acc;
      }, incentivesValues[0].date);

      const simValueDate = new Date(lastDate);
      simValueDate.setMonth(simValueDate.getMonth() + 1);
      const incentive = +((+summary?.FDpercent * simulatorValue) / 100).toFixed(
        2
      );
      const newSocietyValue = {
        date: simValueDate,
        value: simulatorValue,
        incentive,
      };
      setSimulatorSocietyValue(newSocietyValue);
    } else setSimulatorSocietyValue();
  }, [simulatorValue]);

  useEffect(() => {
    const hasPlansPending = !!(
      summary?.invitations?.length &&
      hasPlanWithInvitation(summary.invitations) &&
      hasPendingInvitations(summary.invitations)
    );

    const shouldShowAcceptButton = hasPlansPending;

    setIsAcceptButtonVisible(shouldShowAcceptButton);
  }, [summary]);

  useEffect(() => {
    if (society && beneficiaryAllPlans.length) {
      const { incentiveValue } = getSimulatorValues({
        society,
        societyValue,
        beneficiaryAllPlans,
      });
      setValuation(incentiveValue);
    }
  }, []);

  return (
    <div className="card-inner">
      <div className="card-inner-group" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
          <div className="simplebar-content" style={{ padding: '0px' }}>
            <div className="card-inner">
              <div className="user-card user-card-s2">
                <div className="user-avatar lg bg-primary">
                  {society.additional.logo ? (
                    <img src={society.additional.logo} alt="Logo" />
                  ) : (
                    <span>{beneficiary?.name?.slice(0, 2)?.toUpperCase()}</span>
                  )}
                </div>
                <div className="user-info">
                  <h5>{beneficiary?.name}</h5>
                  <span className="sub-text">{beneficiary?.jobTitle}</span>
                  <div className="badge badge-outline badge-pill text-primary ucap">
                    {t('Beneficiary')}
                  </div>
                  <span className="sub-text">
                    {t('ItIsABeneficiaryOfAPlanOf', {
                      planType: operationTypesRealNames[beneficiary?.planType],
                      societyName: society?.name,
                    })}
                  </span>
                </div>
              </div>
            </div>

            {isAcceptButtonVisible ? (
              <div className="card-inner card-inner-sm">
                <ul className="btn-toolbar justify-center gx-1">
                  <button
                    type="button"
                    className="btn btn-success report-button m-1 col-12 col-md-5 d-flex justify-content-center align-items-center"
                    onClick={() => setPage(2)}
                  >
                    <em className="icon ni ni-check-round-cut mr-1" />
                    {t('LinkToPlansTab')}
                  </button>
                </ul>
              </div>
            ) : null}

            <BeneficiarySocietyMetrics
              beneficiary={beneficiary}
              society={society}
              summary={summary}
              decimalScale={decimalScale}
              onChangePage={setPage}
            />

            {incentivesValues?.length > 0 &&
            vestingValues?.length > 0 &&
            hasIncentiveChart ? (
              <div className="nk-fmg-status px-0">
                <h6 className="nk-fmg-status-title">
                  <em className="icon ni ni-chart-up" />
                  <span>{t('IncentiveValueEvolution')}</span>
                </h6>

                <div className="">
                  <div className="d-flex flex-column flex-md-row justify-content-around mb-2">
                    <div className="d-flex flex-row">
                      <em
                        className="icon ni ni-circle-fill text-primary mr-2 "
                        style={{
                          lineHeight: 1.5,
                          verticalAlign: 'middle',
                        }}
                      />
                      <span
                        className="sub-text text-capitalize"
                        style={{
                          lineHeight: 1.5,
                          verticalAlign: 'middle',
                        }}
                      >
                        {t('TotalIncentiveValue')}
                      </span>
                      <em
                        className="card-hint icon ni ni-help ml-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title={t('IncentiveValueLegendInfo')}
                        style={{
                          lineHeight: 1.15,
                          verticalAlign: 'middle',
                          cursor: 'help',
                        }}
                      />
                    </div>

                    <div className="d-flex flex-row">
                      <em
                        className="icon ni ni-circle-fill text-teal mr-2"
                        style={{
                          lineHeight: 1.5,
                          verticalAlign: 'middle',
                        }}
                      />
                      <span
                        className="sub-text text-capitalize"
                        style={{
                          lineHeight: 1.5,
                          verticalAlign: 'middle',
                        }}
                      >
                        {t('ActualVestedValue')}
                      </span>
                      <em
                        className="card-hint icon ni ni-help ml-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title={t('ConsolidatedValueLegendInfo')}
                        style={{
                          lineHeight: 1.15,
                          verticalAlign: 'middle',
                          cursor: 'help',
                        }}
                      />
                    </div>
                  </div>

                  <LineCharts
                    data={convertData({
                      incentivesValues,
                      simulatorSocietyValue,
                      vestingValues,
                    })}
                    options={chartOptions(
                      {
                        incentivesValues,
                        simulatorSocietyValue,
                        vestingValues,
                      },
                      {
                        legend: false,
                      }
                    )}
                    height={isMobileScreen() ? 300 : null}
                  />

                  {isMobileScreen() && (
                    <>
                      <hr className="mt-5" />
                      <BeneficiarySocietyValueSimulator
                        societyValue={societyValue}
                        valuation={valuation}
                        onChange={handleChangeSlider}
                        onClickSimulatorButton={handleOpenSellView}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeneficiarySociety;
