import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import i18n from 'i18n/config';
import menuTypes from 'constants/menuTypes';

import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';
import transformData from 'modules/_shared/components/Tables/helpers';
import { getUserSocieties } from '../redux/societyActions';
import tableColumns from '../pages/DirectedList/tableColumns';

const getDefaultDirectorRoute = (
  societyId: string,
  directorId: string
): string => `/consejos/${societyId}/${directorId}`;

export const useGetSocietyDirectedList = () => {
  const user = useSelector((state: State) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  const [directedData, setDirectedData] = useState([]);

  useGetMenu(menuTypes.MAIN);

  useEffect(() => {
    if (user && user?.societies?.directed?.length) {
      getUserSocieties(user['_id'], 'director', setDirectedData, 'table').then(
        () => setIsLoading(false)
      );
    } else {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (i18n.language) {
      setCurrentTableColumns(tableColumns(i18n));
    }
  }, [i18n.language]); // eslint-disable-line

  const memoizedData = useMemo(() => {
    if (user && directedData?.length) {
      const dataForTable = directedData.map((currentData: any) => ({
        society: currentData.society,
        societyName: currentData.society?.name,
        countilsTotal: 0,
        link: getDefaultDirectorRoute(
          currentData.society._id,
          currentData.directorId
        ),
      }));

      return dataForTable;
    }
    return [];
  }, [user, directedData]);

  useEffect(() => {
    if (memoizedData.length) {
      setTableData(
        transformData({
          data: memoizedData,
          columns: tableColumns(i18n),
        })
      );
    }
  }, [memoizedData, i18n.language]); // eslint-disable-line

  return { data: { tableData, currentTableColumns }, isLoading };
};
