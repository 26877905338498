import { FC } from 'react';

import InformationCard from './InformationCard';
import AnnouncementDocumentCard from './AnnouncementDocumentCard';
import MembersDocumentsCard from './MembersDocumentsCard';
import SendStatusCard from './SendStatusCard';

import { useGetAnnouncementDocument } from '../hooks/useGetAnnouncementDocument';
import { useGetCouncil } from '../hooks/useGetCouncil';

type Props = {
  councilId: string;
  handleEditCouncil: () => void;
};

const CouncilDetailsAnnouncement: FC<Props> = ({
  councilId,
  handleEditCouncil,
}: Props) => {
  const { data: council, action: fetchCouncilData } = useGetCouncil(councilId);

  const announcementDocumentId = council?.announcementDocument || '';

  const documentData = useGetAnnouncementDocument({
    announcementDocumentId,
  });
  const { announcementDocument } = documentData.data;

  const membersDocumentsIds = council?.publicDocuments || [];

  return (
    <>
      {council && (
        <div className="nk-block pt-4">
          <div className="row">
            <div className="col-xl-8">
              <div className="card card-bordered">
                <div className="card-inner-group">
                  <InformationCard
                    council={council}
                    handleEditCouncil={handleEditCouncil}
                  />

                  <AnnouncementDocumentCard
                    council={council}
                    announcementDocument={announcementDocument}
                    fetchCouncilData={fetchCouncilData}
                  />

                  <MembersDocumentsCard
                    council={council}
                    documentsIds={membersDocumentsIds}
                    fetchCouncilData={fetchCouncilData}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <SendStatusCard council={council} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CouncilDetailsAnnouncement;
