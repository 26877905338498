/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { resetStateSociety } from 'modules/_shared/redux/resetActions';
import { getUserSocieties } from 'modules/society/redux/societyActions';

import CardLoader from 'modules/_shared/components/Cards/CardLoader';
import SocietyCard from 'modules/society/components/SocietyCard';

import './ParticipatedTable.scss';

function ParticipatedTable({ alternativeMenuOptions }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const [cards, setCards] = useState([]);
  const [participatedData, setParticipatedData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    if (user) {
      getUserSocieties(
        user['_id'],
        'partner',
        setParticipatedData,
        'cards'
      ).then((result) => {
        if (!result?.length) {
          setIsLoadingData(false);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    setCards(
      participatedData.map((data) => (
        <SocietyCard
          data={data}
          userId={user['_id']}
          isAdmin={data?.isUserAdmin}
          alternativeMenuOptions={alternativeMenuOptions}
          key={data?.society?.['_id']}
        />
      ))
    );
  }, [participatedData]);

  useEffect(() => {
    dispatch(resetStateSociety());
  }, []);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('ParticipatedTitle')}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {participatedData?.length === 0
                    ? t('ParticipatedNoSocieties')
                    : t('ParticipatedHaveSocieties', {
                        count: participatedData?.length,
                      })}
                </p>
              </div>
            </div>

            <div className="nk-block-head-content">
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content" data-content="pageMenu">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Link to="/diagrama-participadas">
                        <button type="button" className="btn btn-primary">
                          <em className="icon ni ni-network" />
                          <span className="d-none d-md-block">
                            {t('Chart')}
                          </span>
                        </button>
                      </Link>
                    </li>
                    <li>
                      <Link to="/lista-participadas">
                        <button type="button" className="btn btn-primary">
                          <em className="icon ni ni-view-list-wd" />
                          <span className="d-none d-md-block">{t('View')}</span>
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cuadro-participadas__main d-flex flex-wrap g-gs">
          {cards?.length > 0 ? (
            cards
          ) : (
            <>
              <div className="w-100 pt-1">
                {isLoadingData && <CardLoader cards={6} />}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ParticipatedTable;
