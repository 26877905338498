import { DATE_FORMAT } from 'constants/defaultConstants';
import { format } from 'date-fns';
import { TranslateFn } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import statusTypes from 'modules/society/components/SocietyDirectors/statusTypes';
import { SocietyDirector } from 'types';

export const getDirectorDetailsList = (
  director: SocietyDirector,
  t: TranslateFn
) => [
  { label: t('Name'), value: director?.socialDenomination || '-' },
  { label: t('CIF'), value: director?.cif || '-' },
  { label: t('Email'), value: director?.email || '-' },
  {
    label: t('StartDate'),
    value: director?.initDate
      ? format(new Date(director.initDate), DATE_FORMAT)
      : '-',
  },
  {
    label: t('EndDate'),
    value: director?.endDate
      ? format(new Date(director?.endDate), DATE_FORMAT)
      : '-',
  },
  {
    label: t('Position'),
    value:
      directorTypes(i18n).name?.[
        director?.directorType as keyof typeof directorTypes
      ] || '-',
  },
  {
    label: t('Status'),
    value:
      statusTypes(i18n).name?.[
        director?.directorStatus as keyof typeof statusTypes
      ] || '-',
  },
  { label: t('Nationality'), value: director?.nationality || '-' },
  { label: t('Address'), value: director?.address || '-' },
];
