import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'hooks/useTranslate';

import CouncilsBannerImage from 'assets/images/cards-group.svg';

const CouncilsListBanner: FC = () => {
  const { t } = useTranslate();

  return (
    <div className="nk-block my-5">
      <div className="card card-bordered hover-shadow">
        <div className="card-inner card-inner-lg">
          <div className="nk-help">
            <div className="nk-help-img">
              <img
                src={CouncilsBannerImage}
                alt="Councils list"
                width={116}
                height={116}
              />
            </div>

            <div className="nk-help-text">
              <h5>{t('ManagesCouncils')}</h5>
              <p className="text-soft">{t('CouncilsBannerText')}</p>
            </div>

            <div className="nk-help-action">
              <Link to="/contacto" className="btn btn-lg btn-outline-primary">
                {t('MoreInfo')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CouncilsListBanner);
