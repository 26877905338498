import * as XLSX from 'xlsx';

import { getOperationOffset } from './getOperationOffset';

const getOpertationsList = (operations: string[]): string[] => {
  const operationsList: string[] = [];

  operations.forEach((operation) => {
    if (operation !== '') {
      operationsList.push(operation);
    }
  });

  return operationsList;
};

export const getXLSMerges = (operations: string[]): XLSX.Range[] => {
  const merges: XLSX.Range[] = [];

  let mergeIndex = 0;

  const operationsList = getOpertationsList(operations);

  operationsList.forEach((operation, index) => {
    const offset = getOperationOffset(operation);
    if (operation !== '') {
      const start = index === 0 ? 1 : 0;
      merges.push({
        s: { r: 0, c: mergeIndex + start },
        e: { r: 0, c: mergeIndex + start + offset },
      });

      mergeIndex += start + offset + 1;
    }
  });

  return merges;
};
