import { useSelector } from 'react-redux';

import {
  administratedItems,
  participatedItems,
  plansItems,
} from 'modules/_shared/constants/headerMenuItems';

import { State } from 'redux/initialState';

import { useTranslate } from 'hooks/useTranslate';
import HeaderMenuDropdown from '../HeaderMenuDropdown';
import HeaderMenuItem from '../HeaderMenuItem';

type HeaderMenuProps = {
  data: Record<string, any>;
};

const HeaderMenu = ({ data }: HeaderMenuProps) => {
  const { t, i18n } = useTranslate();

  const headerOptions = useSelector(
    (state: State) =>
      state.society?.actualSociety?.customization?.theme?.headerOptions
  );

  const hasCustomHeaderItems =
    !!headerOptions?.length && data?.hasWhiteBranding;
  const hasOriginalHeaderItems = !data?.hasWhiteBranding;

  return (
    <>
      {hasOriginalHeaderItems && (
        <div className="nk-header-menu d-none d-xl-block">
          <ul className="nk-menu nk-menu-main">
            <HeaderMenuItem to="/" text={t('Dashboard')} data={data} />

            {data?.userValidations.hasAdministrated && (
              <>
                <HeaderMenuDropdown title={t('Administrated')} data={data}>
                  {administratedItems(i18n).map((item) => (
                    <HeaderMenuItem
                      key={item.to}
                      to={item.to}
                      text={item.text}
                      data={data}
                      isSub
                      isDisabled={item.isDisabled}
                    />
                  ))}
                </HeaderMenuDropdown>
              </>
            )}

            {data?.userValidations.hasParticipated && (
              <>
                <HeaderMenuDropdown title={t('Participated')} data={data}>
                  {participatedItems(i18n).map((item) => (
                    <HeaderMenuItem
                      key={item.to}
                      to={item.to}
                      text={item.text}
                      data={data}
                      isSub
                      isDisabled={item.isDisabled}
                    />
                  ))}
                </HeaderMenuDropdown>
              </>
            )}

            {data?.userValidations.hasBeneficiated && (
              <>
                <HeaderMenuDropdown title={t('Plans')} data={data}>
                  {plansItems(i18n).map((item) => (
                    <HeaderMenuItem
                      key={item.to}
                      to={item.to}
                      text={item.text}
                      data={data}
                      isSub
                    />
                  ))}
                </HeaderMenuDropdown>
              </>
            )}

            {data?.userValidations.hasDirected && (
              <HeaderMenuItem to="/consejos" text={t('Councils')} data={data} />
            )}
          </ul>
        </div>
      )}

      {hasCustomHeaderItems && (
        <div className="nk-header-menu">
          <ul className="nk-menu nk-menu-main">
            {headerOptions.map((item: any) => (
              <HeaderMenuItem
                key={item.label}
                to={item.url}
                text={item.label}
                type={item.type}
                data={data}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default HeaderMenu;
