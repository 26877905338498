import { useMemo } from 'react';
import { User } from 'types';

const useUserValidation = (user: Pick<User, 'societies'>) => {
  const hasAdministrated = useMemo(
    () => user.societies?.administrated?.length > 0,
    [user]
  );

  const hasParticipated = useMemo(
    () => user.societies?.participated?.length > 0,
    [user]
  );

  const hasBeneficiated = useMemo(
    () => user.societies?.beneficiated?.length > 0,
    [user]
  );

  const hasInvested = useMemo(
    () => user.societies?.invested?.length > 0,
    [user]
  );

  const hasInvited = useMemo(() => user.societies?.invited?.length > 0, [user]);

  const hasDirected = useMemo(
    () => user.societies?.directed?.length > 0,
    [user]
  );

  const isAdministratorOnly = useMemo(
    () =>
      hasAdministrated &&
      !hasParticipated &&
      !hasBeneficiated &&
      !hasInvested &&
      !hasInvited &&
      !hasDirected,
    [
      hasAdministrated,
      hasBeneficiated,
      hasInvested,
      hasInvited,
      hasParticipated,
      hasDirected,
    ]
  );

  const isParticipantOnly = useMemo(
    () =>
      !hasAdministrated &&
      hasParticipated &&
      !hasBeneficiated &&
      !hasInvested &&
      !hasInvited &&
      !hasDirected,
    [
      hasAdministrated,
      hasBeneficiated,
      hasInvested,
      hasInvited,
      hasParticipated,
      hasDirected,
    ]
  );

  const isBeneficiaryOnly = useMemo(
    () =>
      !hasAdministrated &&
      !hasParticipated &&
      hasBeneficiated &&
      !hasInvested &&
      !hasInvited &&
      !hasDirected,
    [
      hasAdministrated,
      hasBeneficiated,
      hasInvested,
      hasInvited,
      hasParticipated,
      hasDirected,
    ]
  );

  const isInvestorOnly = useMemo(
    () =>
      !hasAdministrated &&
      !hasParticipated &&
      !hasBeneficiated &&
      hasInvested &&
      !hasInvited &&
      !hasDirected,
    [
      hasAdministrated,
      hasBeneficiated,
      hasInvested,
      hasInvited,
      hasParticipated,
      hasDirected,
    ]
  );

  const isGuestOnly = useMemo(
    () =>
      !hasAdministrated &&
      !hasParticipated &&
      !hasBeneficiated &&
      !hasInvested &&
      hasInvited &&
      !hasDirected,
    [
      hasAdministrated,
      hasBeneficiated,
      hasInvested,
      hasInvited,
      hasParticipated,
      hasDirected,
    ]
  );

  const isDirectorOnly = useMemo(
    () =>
      !hasAdministrated &&
      !hasParticipated &&
      !hasBeneficiated &&
      !hasInvested &&
      !hasInvited &&
      hasDirected,
    [
      hasAdministrated,
      hasBeneficiated,
      hasInvested,
      hasInvited,
      hasParticipated,
      hasDirected,
    ]
  );

  return {
    hasAdministrated,
    hasParticipated,
    hasBeneficiated,
    hasInvested,
    hasInvited,
    hasDirected,
    isAdministratorOnly,
    isParticipantOnly,
    isBeneficiaryOnly,
    isInvestorOnly,
    isGuestOnly,
    isDirectorOnly,
  };
};

export default useUserValidation;
