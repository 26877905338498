import { FC } from 'react';
import EncumbrancesTableHeader from './EncumbrancesTableHeader';
import EncumbrancesTableBody from './EncumbrancesTableBody';

type Props = {
  encumbrances: string[];
  // eslint-disable-next-line no-unused-vars
  handleRemoveEncumbrance: (index: number) => void;
};

const EncumbrancesTable: FC<Props> = ({
  encumbrances,
  handleRemoveEncumbrance,
}) => (
  <>
    <table className="table my-2">
      <EncumbrancesTableHeader />
      <EncumbrancesTableBody
        encumbrances={encumbrances}
        handleRemoveEncumbrance={handleRemoveEncumbrance}
      />
    </table>
  </>
);

export default EncumbrancesTable;
