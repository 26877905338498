/* eslint-disable react/no-danger */
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { Council, Society } from 'types';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getCouncilAttendanceTypeLabel } from 'modules/administratives/pages/CouncilDetails/utils/getCouncilAttendanceTypeLabel';

type SendCouncilAnnouncementModalHeaderProps = {
  date: string;
  time: string;
  actualSociety: Society;
  currentCouncil: Council;
};

const SendCouncilAnnouncementModalHeader: FC<
  SendCouncilAnnouncementModalHeaderProps
> = ({ actualSociety, currentCouncil, date, time }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const headerText = t('SendCouncilAnnouncementMessage', {
    date,
    time,
    societyName: actualSociety.name,
    attendanceType: t(getCouncilAttendanceTypeLabel(currentCouncil)),
  });

  return (
    <>
      <button
        type="button"
        className="close"
        onClick={() => dispatch(setModal(null))}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-primary-alt" />
      <h4 className="nk-modal-title">{t('SendAnnouncement')}</h4>

      <div className="nk-modal-text mt-4">
        <p
          className="lead text-justify"
          dangerouslySetInnerHTML={{ __html: headerText }}
        />
        <p className="text-soft fs-14px">{t('SendAnnouncementFootMessage')}</p>
      </div>
    </>
  );
};

export default SendCouncilAnnouncementModalHeader;
