import { axiosV1 } from 'interceptors/axios';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';
import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { addAlert } from '../../_shared/redux/alertActions';

export function createDraft(draft) {
  return {
    type: types.CREATE_DRAFT,
    draft,
  };
}

// TODO* create a function for getting drafts by Reference
export const getDrafts = (societyId) => async (dispatch) => {
  const query = { params: { societyId } };
  try {
    const { data } = await axiosV1.get(`/drafts`, query);
    const decodedData = await decryptResponse(data);
    const draftsPartnersPopulated = decodedData?.map((draft) => ({
      ...draft,
      draftPartners: draft?.draftPartners
        ? JSON.parse(draft.draftPartners)
        : [],
    }));
    dispatch({
      type: types.GET_DRAFTS,
      data: draftsPartnersPopulated,
    });

    return draftsPartnersPopulated;
  } catch (error) {
    return dispatch(addAlert(alertBodyTypes['ERROR_GETTING_DRAFTS']));
  }
};

export const addDraft = (draftData) => async (dispatch) => {
  const body = { ...draftData };
  const { data } = await axiosV1.post(`/drafts`, body);
  const decodedData = await decryptResponse(data);
  dispatch({
    type: types.CREATE_DRAFT,
    draft: decodedData,
  });
  return decodedData;
};

export const updateDraft =
  (draftId, updateDraftData, showAlert = true) =>
  async (dispatch) => {
    const body = {
      _id: draftId,
      ...updateDraftData,
    };
    try {
      const { data } = await axiosV1.put(`/drafts`, body);
      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_DRAFT,
        draft: decodedData,
      });

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deleteDraft = (draftId) => async (dispatch) => {
  try {
    const { data } = await axiosV1.delete(`/drafts/${draftId}`);
    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.DELETE_DRAFT,
      draft: decodedData,
    });
    dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));

    return decodedData;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};
