import { useTranslate } from 'hooks/useTranslate';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import UploadDocument from 'modules/documents/components/UploadDocument';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import '../Modals.scss';

const UploadAnnouncementModal = ({
  setAnnouncementFile,
  setIsUploadModalVisible,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [file, setFile] = useState();

  const handleChangeFile = (file) => {
    setFile(file);
  };

  const saveDocument = (event) => {
    event.preventDefault();
    if (file) {
      setAnnouncementFile(file);
      setIsUploadModalVisible(false);
    }
  };

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('UpdateAnnouncement')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => {
            dispatch(addAlert(alertBodyTypes.BOARD_UPDATED));
            dispatch(setModal(null));
          }}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveDocument}>
          <UploadDocument
            label="YouHaveModifiedTheBoard"
            inputLabel={file?.name || t('DragDocumentToUpload')}
            file={file}
            setFile={handleChangeFile}
          />

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!file}
          >
            {t('Accept')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
};

export default UploadAnnouncementModal;
