import { useEffect, useState } from 'react';
import { store } from 'redux/store';

import { Document } from 'types';
import { getDocuments } from 'modules/documents/redux/documentActions';

type Props = {
  societyId: string;
  auditorId?: string;
};

export default function useGetSocietyAuditorDocuments({
  societyId,
  auditorId,
}: Props) {
  const [auditorDocuments, setAuditorDocuments] = useState<Document[]>([]);

  useEffect(() => {
    if (societyId && auditorId) {
      const getDocumentsData = async () => {
        try {
          store.dispatch(
            getDocuments(
              { auditor: auditorId, society: societyId },
              setAuditorDocuments
            )
          );
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      };

      getDocumentsData();
    }
  }, [societyId, auditorId]);

  return {
    data: { auditorDocuments },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
