/* eslint-disable no-undef */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import MandatoryField from 'modules/_shared/components/MandatoryField';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';

const EncumbrancesViewForm: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useAddRealtyModal();

  const [encumbrance, setEncumbrance] = useState<string>('');

  const handleChangeEncumbrance = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEncumbrance(event.target.value);
  };

  const handleSaveEncumbrance = () => {
    const newEncumbrance = encumbrance;
    const newEncumbrances = [...data.encumbrances, newEncumbrance];
    setEncumbrance('');

    actions.handleChangeEncumbrances(newEncumbrances);
  };

  return (
    <div className="row gy-4">
      <div className="col-12">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-encumbrance">
            {t('Encumbrance')} <MandatoryField />
          </label>

          <input
            id="realty-encumbrance"
            type="text"
            className="form-control form-control-lg"
            value={encumbrance}
            onChange={handleChangeEncumbrance}
            placeholder={t('IntroduceEncumbrance')}
          />
        </div>
      </div>

      <div className="col-12 text-right">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSaveEncumbrance}
          disabled={!encumbrance}
        >
          {t('AddEncumbrance')}
        </button>
      </div>
    </div>
  );
};

export default EncumbrancesViewForm;
