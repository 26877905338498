import AdminCenter from 'modules/_shared/pages/AdminCenter';
import AdministratedDiagram from 'modules/society/pages/AdministratedDiagram';
import AdministratedList from 'modules/society/pages/AdministratedList';
import AdministratedTable from 'modules/society/pages/AdministratedTable';
import AdminMembers from 'modules/administratives/pages/AdminMembers';
import BeneficiaryDetails from 'modules/beneficiaries/pages/BeneficiaryDetails';
import BeneficiaryDrafts from 'modules/beneficiaries/pages/BeneficiaryDrafts';
import BeneficiaryFiles from 'modules/beneficiaries/pages/BeneficiaryFiles';
import BeneficiaryList from 'modules/beneficiaries/pages/BeneficiaryList';
import BeneficiaryPlanList from 'modules/beneficiaries/pages/BeneficiaryPlanList';
import BeneficiatedList from 'modules/beneficiaries/pages/BeneficiatedList';
import BoardDetails from 'modules/boards/pages/BoardDetails';
import BoardPartner from 'modules/boards/pages/BoardPartner';
import BoardsList from 'modules/boards/pages/BoardsList';
import BoardsPartnerList from 'modules/boards/pages/BoardsPartnerList';
import CertificateSignature from 'modules/society/pages/CertificateSignature';
import CommitteeMemberDetails from 'modules/administratives/pages/CommitteeMemberDetails';
import CommitteeMembers from 'modules/administratives/pages/CommitteeMembers';
import Committees from 'modules/administratives/pages/Committees';
import Communications from 'modules/communications/pages/Communications';
import Contact from 'modules/contact/pages/Contact';
import CouncilDetails from 'modules/administratives/pages/CouncilDetails';
import CouncilMembers from 'modules/administratives/pages/CouncilMembers';
import CouncilsList from 'modules/administratives/pages/CouncilsList';
import CustomDashboard from 'modules/dashboard/pages/CustomDashboard';
import CustomDashboardDetails from 'modules/dashboard/pages/CustomDashboardDetails';
import Dashboard from 'modules/dashboard/pages/Dashboard';
import DelegationPartner from 'modules/boards/pages/DelegationPartner';
import DelegationSignature from 'modules/boards/pages/DelegationSignature';
import DemoTS from 'modules/_shared/components/_DemoTS/index';
import DirectorDetail from 'modules/society/pages/DirectorDetail';
import Documents from 'modules/documents/pages/Documents';
import DraftCapitalIncrease from 'modules/drafts/pages/DraftCapitalIncrease';
import DraftConvertibleNote from 'modules/drafts/pages/DraftConvertibleNote';
import DraftSafe from 'modules/drafts/pages/DraftSafe';
import DraftSell from 'modules/drafts/pages/DraftSell';
import DraftSellParticipations from 'modules/drafts/pages/DraftSellParticipations';
import DraftsList from 'modules/drafts/pages/DraftsList';
import EmployeeDetail from 'modules/beneficiaries/pages/EmployeeDetail';
import EmployeeList from 'modules/beneficiaries/pages/EmployeeList';
import EmployeesNotificationsList from 'modules/beneficiaries/pages/EmployeesNotificationsList';
import EmployeesPlanDetailsList from 'modules/beneficiaries/pages/EmployeesPlanDetailsList';
import EmployeesPlanList from 'modules/beneficiaries/pages/EmployeesPlanList';
import HoldingClassList from 'modules/partners/pages/HoldingClassList';
import InvestorDetail from 'modules/partners/pages/InvestorDetail';
import InvestorsList from 'modules/partners/pages/InvestorsList';
import OpenAiIntegration from 'modules/_shared/pages/OpenAiIntegration';
import OrgChartLayoutView from 'modules/society/pages/ChartJointJS';
import ParticipatedDiagram from 'modules/society/pages/ParticipatedDiagram';
import ParticipatedList from 'modules/society/pages/ParticipatedList';
import ParticipatedTable from 'modules/society/pages/ParticipatedTable';
import PartnerBook from 'modules/partners/pages/PartnerBook';
import PartnerCategoriesList from 'modules/partners/pages/PartnerCategoriesList';
import PartnerDetail from 'modules/partners/pages/PartnerDetail';
import PartnerFiles from 'modules/partners/pages/PartnerFiles';
import PartnerList from 'modules/partners/pages/PartnerList';
import PartnerRepresentantDetail from 'modules/partners/pages/PartnerRepresentantDetail';
import PartnerRepresentantsList from 'modules/partners/pages/PartnerRepresentantsList';
import PortfolioCards from 'modules/society/pages/PortfolioCards';
import PortfolioList from 'modules/society/pages/PortfolioList';
import RealtyDetails from 'modules/administratives/pages/RealtyDetails';
import RepresentativeDetail from 'modules/society/pages/RepresentativeDetail';
import SignConfirmation from 'modules/beneficiaries/pages/SignConfirmation';
import SocietiesAdd from 'modules/society/pages/SocietiesAdd';
import SocietyAnnualValue from 'modules/society/pages/SocietyAnnualValue';
import SocietyAuditors from 'modules/administratives/pages/SocietyAuditors';
import SocietyDashboard from 'modules/society/pages/SocietyDashboard';
import SocietyDocuments from 'modules/society/pages/SocietyDocuments';
import SocietyProfile from 'modules/society/pages/SocietyProfile';
import SocietyRepresentatives from 'modules/administratives/pages/SocietyRepresentatives';
import SummaryBook from 'modules/beneficiaries/pages/SummaryBook';
import Templates from 'modules/documents/pages/Templates';
import TenderOfferDetails from 'modules/transactions/pages/TenderOfferDetails';
import TenderOfferList from 'modules/transactions/pages/TenderOfferList';
import TenderOfferParticipation from 'modules/transactions/pages/TenderOfferParticipation';
import TenderOfferSharesDetails from 'modules/transactions/pages/TenderOfferSharesDetails';
import TenderOfferSharesList from 'modules/transactions/pages/TenderOfferSharesList';
import TransactionDetails from 'modules/transactions/pages/TransactionDetails';
import TransactionsList from 'modules/transactions/pages/TransactionsList';
import UserProfile from 'modules/profile/pages/UserProfile';
import Realties from 'modules/administratives/pages/Realty';
import DirectedList from 'modules/society/pages/DirectedList';

export const protectedRoutes = [
  {
    id: 'dashboard',
    path: '/',
    exact: true,
    component: Dashboard,
  },
  {
    id: 'admin-center',
    path: '/admin-center',
    component: AdminCenter,
  },
  {
    id: 'participated-cards',
    path: '/cuadro-participadas',
    component: ParticipatedTable,
  },
  {
    id: 'participated-diagram',
    path: '/diagrama-participadas',
    component: ParticipatedDiagram,
  },
  {
    id: 'participated-list',
    path: '/lista-participadas',
    component: ParticipatedList,
  },
  {
    id: 'beneficiated-list',
    path: '/lista-beneficiadas',
    component: BeneficiatedList,
  },
  {
    id: 'administrated-cards',
    path: '/cuadro-administradas',
    component: AdministratedTable,
  },
  {
    id: 'participated-diagram',
    path: '/diagrama-administradas',
    component: AdministratedDiagram,
  },
  {
    id: 'administrated-list',
    path: '/lista-administradas',
    component: AdministratedList,
  },
  {
    id: 'portfolio-cards',
    path: '/cuadro-portafolio/:societyId',
    component: PortfolioCards,
  },
  {
    id: 'portfolio-cards-filter',
    path: '/cuadro-portafolio/:societyId/:filterIndex',
    component: PortfolioCards,
  },
  {
    id: 'portfolio-list',
    path: '/lista-portafolio/:societyId',
    component: PortfolioList,
  },
  {
    id: 'portfolio-list-filter',
    path: '/lista-portafolio/:societyId/:filterIndex',
    component: PortfolioList,
  },
  {
    id: 'custom-dashboard',
    path: '/home/:societyId/:sectionIndex/:folderIndex',
    component: CustomDashboardDetails,
  },
  {
    id: 'custom-dashboard',
    path: '/home/:societyId',
    component: CustomDashboard,
  },
  {
    id: 'society-dashboard',
    path: '/sociedad/:societyId',
    component: SocietyDashboard,
  },
  {
    id: 'transactions-society-partner',
    path: '/transacciones/:societyId/:partnerId',
    component: TransactionsList,
  },
  {
    id: 'transactions-society',
    path: '/transacciones/:societyId',
    component: TransactionsList,
  },
  {
    id: 'transactions',
    path: '/transacciones/',
    component: TransactionsList,
  },
  {
    id: 'transaction-details',
    path: '/detalle-transacciones/:societyId/:transactionId',
    component: TransactionDetails,
  },
  {
    id: 'new-society',
    path: '/nueva-sociedad',
    component: SocietiesAdd,
  },
  {
    id: 'investors-list',
    path: '/inversores/:societyId',
    component: InvestorsList,
  },
  {
    id: 'partners-list-user',
    path: '/socios/:societyId/:userId',
    component: PartnerList,
  },
  {
    id: 'partners-list',
    path: '/socios/:societyId',
    component: PartnerList,
  },
  {
    id: 'partner-cards',
    path: '/socios-fichas/:societyId',
    component: PartnerFiles,
  },
  {
    id: 'partners-book',
    path: '/socios-libro/:societyId',
    component: PartnerBook,
  },
  {
    id: 'investor-details-page',
    path: '/detalle-inversor/:societyId/:investorId/:pageId',
    component: InvestorDetail,
  },
  {
    id: 'investor-details',
    path: '/detalle-inversor/:societyId/:investorId',
    component: InvestorDetail,
  },
  {
    id: 'partner-details-page',
    path: '/detalle-socio/:societyId/:partnerId/:pageId',
    component: PartnerDetail,
  },
  {
    id: 'partner-details',
    path: '/detalle-socio/:societyId/:partnerId',
    component: PartnerDetail,
  },
  {
    id: 'representative-details',
    path: '/detalle-apoderado/:societyId/:representativeId',
    component: RepresentativeDetail,
  },
  {
    id: 'director-details',
    path: '/detalle-miembro/:societyId/:directorId',
    component: DirectorDetail,
  },
  {
    id: 'holding-classes',
    path: '/clases-participaciones/:societyId',
    component: HoldingClassList,
  },
  {
    id: 'partners-categories',
    path: '/socios-categorias/:societyId',
    component: PartnerCategoriesList,
  },
  {
    id: 'partner-representant-details',
    path: '/detalle-representante-socio/:societyId/:partnerRepresentantId',
    component: PartnerRepresentantDetail,
  },
  {
    id: 'partners-representants',
    path: '/socios-representantes/:societyId',
    component: PartnerRepresentantsList,
  },
  {
    id: 'beneficiary-plan',
    path: '/beneficiarios/:societyId/:planId',
    component: BeneficiaryList,
  },
  {
    id: 'beneficiary-list',
    path: '/beneficiarios/:societyId',
    component: BeneficiaryList,
  },
  {
    id: 'beneficiary-cards',
    path: '/beneficiarios-fichas/:societyId',
    component: BeneficiaryFiles,
  },
  {
    id: 'employee-list',
    path: '/empleados/:societyId',
    component: EmployeeList,
  },
  {
    id: 'employee-details',
    path: '/detalle-empleado/:societyId/:employeeId',
    component: EmployeeDetail,
  },
  {
    id: 'employees-plan-list',
    path: '/empleados-planes/:societyId/:employeesPlanId',
    component: EmployeesPlanDetailsList,
  },
  {
    id: 'employees-plan-list',
    path: '/empleados-planes/:societyId',
    component: EmployeesPlanList,
  },
  {
    id: 'employees-notifications-list',
    path: '/empleados-notificaciones/:societyId',
    component: EmployeesNotificationsList,
  },
  {
    id: 'stockplans-society',
    path: '/beneficiarios-planes/:societyId',
    component: BeneficiaryPlanList,
  },
  {
    id: 'summary-plans',
    path: '/resumen-planes/:societyId',
    component: SummaryBook,
  },
  {
    id: 'beneficiary-sign',
    path: '/beneficiarios-firma/:authToken',
    component: SignConfirmation,
  },
  {
    id: 'beneficiary-drafts-plan',
    path: '/beneficiarios-borradores/:societyId/:planId',
    component: BeneficiaryDrafts,
  },
  {
    id: 'beneficiary-drafts',
    path: '/beneficiarios-borradores/:societyId',
    component: BeneficiaryDrafts,
  },
  {
    id: 'beneficiary-details',
    path: '/detalle-beneficiario/:societyId/:planId/:beneficiaryId',
    component: BeneficiaryDetails,
  },
  {
    id: 'drafts-society',
    path: '/borradores/:societyId',
    component: DraftsList,
  },
  {
    id: 'drafts-increase-id',
    path: '/borrador-ampliacion/:societyId/:draftId',
    component: DraftCapitalIncrease,
  },
  {
    id: 'drafts-increase',
    path: '/borrador-ampliacion/:societyId',
    component: DraftCapitalIncrease,
  },
  {
    id: 'drafts-sell-id',
    path: '/borrador-venta/:societyId/:draftId',
    component: DraftSell,
  },
  {
    id: 'drafts-sell',
    path: '/borrador-venta/:societyId',
    component: DraftSell,
  },
  {
    id: 'drafts-safe-id',
    path: '/borrador-safe/:societyId/:draftId',
    component: DraftSafe,
  },
  {
    id: 'drafts-safe',
    path: '/borrador-safe/:societyId',
    component: DraftSafe,
  },
  {
    id: 'drafts-note-id',
    path: '/borrador-nota/:societyId/:draftId',
    component: DraftConvertibleNote,
  },
  {
    id: 'drafts-note',
    path: '/borrador-nota/:societyId',
    component: DraftConvertibleNote,
  },
  {
    id: 'drafts-note-id',
    path: '/borrador-compra-venta/:societyId/:draftId',
    component: DraftSellParticipations,
  },
  {
    id: 'drafts-note',
    path: '/borrador-compra-venta/:societyId',
    component: DraftSellParticipations,
  },
  {
    id: 'user-profile',
    path: '/perfil-usuario/:activeTab',
    component: UserProfile,
  },
  {
    id: 'society-profile',
    path: '/perfil-sociedad/:pageType/:societyId',
    component: SocietyProfile,
  },
  {
    id: 'documents-docs',
    path: '/documentos/documentos/:societyId',
    component: Documents,
  },
  {
    id: 'documents-new',
    path: '/documentos/nuevo/:societyId',
    component: Documents,
  },
  {
    id: 'documents-recents',
    path: '/documentos/recientes/:societyId',
    component: Documents,
  },
  {
    id: 'documents-writings',
    path: '/documentos/escrituras/:societyId',
    component: Documents,
  },
  {
    id: 'documents-certificates',
    path: '/documentos/certificados/:societyId',
    component: Documents,
  },
  {
    id: 'documents-letters',
    path: '/documentos/cartas/:societyId',
    component: Documents,
  },
  {
    id: 'documents-signs',
    path: '/documentos/firmas/:societyId',
    component: Documents,
  },
  {
    id: 'documents-assignments',
    path: '/documentos/nombramientos/:societyId',
    component: Documents,
  },
  {
    id: 'documents-authority',
    path: '/documentos/poderes/:societyId',
    component: Documents,
  },
  {
    id: 'documents-annualreports',
    path: '/documentos/annualreports/:societyId',
    component: Documents,
  },
  {
    id: 'documents-templates-all',
    path: '/documentos/plantillas/:societyId',
    component: Documents,
  },
  {
    id: 'documents-minutebook',
    path: '/documentos/libro-actas/:societyId',
    component: Documents,
  },
  {
    id: 'documents-councilminutes',
    path: '/documentos/actas-consejo/:societyId',
    component: Documents,
  },
  {
    id: 'documents-templates-new',
    path: '/documentos/plantillas/nueva/:societyId',
    component: Documents,
  },
  {
    id: 'documents-deleted',
    path: '/documentos/eliminados/:societyId',
    component: Documents,
  },
  {
    id: 'mis-carpetas',
    path: '/documentos/mis-carpetas/:societyId',
    component: Documents,
  },
  {
    id: 'documents-society',
    path: '/documentos-sociedad/:societyId',
    component: SocietyDocuments,
  },
  {
    id: 'documents',
    path: '/documentos/:societyId',
    component: Documents,
  },
  {
    id: 'templates',
    path: '/plantillas-sociedad/:societyId',
    component: Templates,
  },
  {
    id: 'com-certificate',
    path: '/comunicaciones/:societyId/:communicationId/certificacion',
    component: Communications,
  },
  {
    id: 'com-communication',
    path: '/comunicaciones/:societyId/:communicationId',
    component: Communications,
  },
  {
    id: 'com-society',
    path: '/comunicaciones/:societyId',
    component: Communications,
  },
  {
    id: 'annual-value',
    path: '/valor-anual-sociedad/:societyId',
    component: SocietyAnnualValue,
  },
  {
    id: 'contact',
    path: '/contacto',
    component: Contact,
  },
  {
    id: 'boards-partner-list',
    path: '/juntas/:societyId/:memberId',
    component: BoardsPartnerList,
  },
  {
    id: 'boards-list',
    path: '/juntas/:societyId',
    component: BoardsList,
  },
  {
    id: 'board-details',
    path: '/detalle-junta/:societyId/:boardId',
    component: BoardDetails,
  },
  {
    id: 'board-partner',
    path: '/voto-junta/:societyId/:boardId/:partnerId',
    component: BoardPartner,
  },
  {
    id: 'tender-offers-list',
    path: '/tender-offers/:societyId',
    component: TenderOfferList,
  },
  {
    id: 'tender-offers-shares-list',
    path: '/tender-offers-shares/:societyId',
    component: TenderOfferSharesList,
  },
  {
    id: 'tender-offers-details',
    path: '/detalle-tender-offer/:societyId/:tenderOfferId',
    component: TenderOfferDetails,
  },
  {
    id: 'tender-offers-shares-details',
    path: '/detalle-tender-offer-shares/:societyId/:tenderOfferSharesId',
    component: TenderOfferSharesDetails,
  },
  {
    id: 'tender-offers-participation-beneficiary',
    path: '/participacion-tender-offer/:societyId/:tenderOfferId/beneficiario/:beneficiaryId',
    component: TenderOfferParticipation,
  },
  {
    id: 'tender-offers-participation-partner',
    path: '/participacion-tender-offer/:societyId/:tenderOfferId/socio/:partnerId',
    component: TenderOfferParticipation,
  },
  {
    id: 'signature-certificate',
    path: '/firma-certificado/:societyId',
    component: CertificateSignature,
  },
  {
    id: 'portfolio-chart',
    path: '/diagrama-portafolio/:societyId',
    component: OrgChartLayoutView,
  },
  {
    id: 'demo-ts',
    path: '/demo-ts',
    component: DemoTS,
  },
  {
    id: 'signature-delegation',
    path: '/firma-delegacion/:societyId/:boardId/:partnerId',
    component: DelegationSignature,
  },
  {
    id: 'partner-delegation',
    path: '/delegacion/:societyId/:boardId/:partnerId',
    component: DelegationPartner,
  },
  {
    id: 'openai-integration',
    path: '/openai',
    component: OpenAiIntegration,
  },
  {
    id: 'admin-organ',
    path: '/organo-admin/:societyId',
    component: AdminMembers,
  },
  {
    id: 'committees',
    path: '/comites/:societyId',
    component: Committees,
  },
  {
    id: 'committees-members',
    path: '/comite-miembros/:societyId/:committeeId',
    component: CommitteeMembers,
  },
  {
    id: 'committees-member-details',
    path: '/detalle-miembro-comite/:societyId/:committeeId/:memberId',
    component: CommitteeMemberDetails,
  },
  {
    id: 'representatives',
    path: '/apoderados/:societyId',
    component: SocietyRepresentatives,
  },
  {
    id: 'auditors',
    path: '/auditores/:societyId',
    component: SocietyAuditors,
  },
  {
    id: 'councils-member',
    path: '/consejos/:societyId/:memberId',
    component: CouncilsList,
  },
  {
    id: 'councils',
    path: '/consejos/:societyId',
    component: CouncilsList,
  },
  {
    id: 'councils-list',
    path: '/consejos',
    component: DirectedList,
  },
  {
    id: 'council-details',
    path: '/detalle-consejo/:societyId/:councilId/',
    component: CouncilDetails,
  },
  {
    id: 'council-members',
    path: '/voto-consejo/:societyId/:councilId/:memberId',
    component: CouncilMembers,
    isNewUI: true,
  },
  {
    id: 'realties',
    path: '/inmuebles/:societyId',
    component: Realties,
  },
  {
    id: 'realty-details',
    path: '/detalle-inmueble/:societyId/:realtyId',
    component: RealtyDetails,
  },
];
