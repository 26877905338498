import { FC, useEffect, useState } from 'react';

import { useGetCouncilEnvelopes } from '../hooks/useGetCouncilEnvelopes';
import CouncilDetailsSignatureNavigationHeader from './CouncilDetailsSignatureNavigationHeader';
import SignatureViewAct from './SignatureViewAct';
import SignatureViewCertificate from './SignatureViewCertificate';

type Props = {
  councilId: string;
};

const CouncilDetailsSignature: FC<Props> = ({ councilId }) => {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<any[]>([]);

  const { councilEnvelopes } = useGetCouncilEnvelopes({ councilId }).data;

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    setPages([
      <SignatureViewAct
        key="signature-view-act"
        councilEnvelopes={councilEnvelopes}
      />,
      <SignatureViewCertificate
        key="signature-view-certificate"
        councilEnvelopes={councilEnvelopes}
      />,
    ]);
  }, [councilEnvelopes]);

  return (
    <div>
      <CouncilDetailsSignatureNavigationHeader
        page={page}
        handleChangePage={handleChangePage}
      />
      {pages[page]}
    </div>
  );
};

export default CouncilDetailsSignature;
