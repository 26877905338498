/* eslint-disable no-undef */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';

import { RealtyLicense } from 'types';

import { useAddRealtyModal } from '../context/AddRealtyModalContext';

import LicensesViewForm from './LicensesViewForm';
import LicensesTable from './LicensesTable/LicensesTable';

const LicensesView: FC = () => {
  const { t } = useTranslate();

  const [expandedView, setExpandedView] = useState<boolean>(false);

  const { data, actions } = useAddRealtyModal();

  const { licenses } = data;

  const handleChangeView = () => {
    setExpandedView((prev) => !prev);
  };

  const handleRemoveLicense = (index: number) => {
    const newLicense = licenses.filter(
      (_: RealtyLicense, i: number) => i !== index
    );

    actions.handleChangeLicenses(newLicense);
  };

  return (
    <>
      <div className="row gy-4">
        <div className="col-6 d-flex align-items-center">
          <h5 className="mb-0">{t('Licenses')}</h5>
        </div>
        <div className="col-6 text-right">
          <button
            type="button"
            className="badge btn-primary"
            onClick={handleChangeView}
          >
            <em
              className={`icon ni ni-chevron-${expandedView ? 'up' : 'down'}`}
            />
          </button>
        </div>
      </div>

      {expandedView && (
        <>
          <LicensesViewForm />

          {licenses?.length > 0 && (
            <LicensesTable
              licenses={licenses}
              handleRemoveLicense={handleRemoveLicense}
            />
          )}
        </>
      )}
    </>
  );
};

export default LicensesView;
