import { FC } from 'react';

import { EnvelopeSigner } from 'types';

import useGetSignedSignersCount from './hooks/useGetSignedSignersCount';
import useGetSignatureEnvelopeStatus from './hooks/useGetSignatureEnvelopeStatus';

import EnvelopeSignatureStatusHeader from './components/EnvelopeSignatureStatusHeader';
import SignersStatusTable from './components/SignersStatusTable';

type Props = {
  signers: EnvelopeSigner[];
  sentDate: Date | undefined;
  isReadOnly: boolean;
  refetchSigners: () => void;
};

const EnvelopeSignatureStatus: FC<Props> = ({
  signers,
  sentDate,
  isReadOnly,
  refetchSigners,
}) => {
  const { signedSignersCount } = useGetSignedSignersCount(signers);
  const { signatureEnvelopeStatus } = useGetSignatureEnvelopeStatus(
    signedSignersCount,
    signers
  );

  return (
    <>
      <EnvelopeSignatureStatusHeader
        signatureEnvelopeStatus={signatureEnvelopeStatus}
        sentDate={sentDate}
        signedSignersCount={signedSignersCount}
        totalSigners={signers?.length}
        isReadOnly={isReadOnly}
        refetchSigners={refetchSigners}
      />
      <SignersStatusTable signers={signers} />
    </>
  );
};

export default EnvelopeSignatureStatus;
