import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Editor } from 'ckeditor5';

import PreviewDropdown from 'modules/_shared/components/Dropdowns/PreviewDropdown';

import useGetEditorModuleSectionValues from '../hooks/useGetEditorModuleSectionLabel';

type Props = {
  section: string;
  templateName: string;
  isDraft: boolean;
  isEditing: boolean;
  editorInstance: Editor | null;
  councilId: string;
  isLoading: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  handleRequestSignature: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  handleSaveTemplate: () => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeIsEditing: (value: boolean) => void;
};

const EditorModuleHeader: FC<Props> = ({
  section,
  templateName,
  isDraft,
  isEditing,
  editorInstance,
  councilId,
  isLoading,
  handleRequestSignature,
  handleSaveTemplate,
  handleChangeIsEditing,
}: Props) => {
  const { t } = useTranslate();
  const isAdmin = true; // TODO: Implement permissions
  const isReadOnly = false; // TODO: Implement permissions

  const { moduleHeaderLabel, moduleOptions } = useGetEditorModuleSectionValues({
    currentSection: section,
  }).data;

  return (
    <div className="nk-block-head nk-block-head-sm">
      <div className="nk-block-between position-relative">
        <div className="nk-block-head-content">
          <h5 className="title nk-block-title">
            {isDraft && `${t('Draft')} - `}
            {t(moduleHeaderLabel)}
          </h5>
          <div className="nk-block-des">
            <p>
              {t('UsedTemplate')}:{' '}
              <span className="text-primary">{templateName}</span>
            </p>
          </div>
        </div>
        <div className="nk-block-head-content">
          <ul className="nk-block-tools g-3">
            {!isEditing ? (
              <>
                <li>
                  <button
                    type="button"
                    className="btn btn-outline-light ml-1"
                    onClick={handleRequestSignature}
                    disabled={isReadOnly || !isAdmin || isLoading}
                  >
                    <em className="icon ni ni-edit-alt" />
                    <span>{t('RequestSignature')}</span>
                  </button>
                </li>

                <li>
                  <PreviewDropdown
                    menuOptions={moduleOptions}
                    params={{
                      editorInstance,
                      councilId,
                      handleChangeIsEditing,
                    }}
                    disabled={isReadOnly || !isAdmin || isLoading}
                    direction="down"
                  />
                </li>
              </>
            ) : (
              <li>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveTemplate}
                  disabled={isReadOnly || !isAdmin}
                >
                  <em className="icon ni ni-check-round-cut mr-2" />
                  {t('Save')}
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EditorModuleHeader;
