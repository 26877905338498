import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import useRolesValidation from 'hooks/useRolesValidation';

import PartnersMenu from './Menus/PartnersMenu';
import DraftMenu from './Menus/DraftMenu';
import PlansMenu from './Menus/PlansMenu';
import TransactionsMenu from './Menus/TransactionsMenu';
import CommunicationsMenu from './Menus/CommunicationsMenu';
import BoardsMenu from './Menus/BoardsMenu';
import DocumentsMenu from './Menus/DocumentsMenu';
import SocietyDataMenu from './Menus/SocietyDataMenu';
import PortfoliosMenu from './Menus/PortfoliosMenu';
import CustomLandingMenu from './Menus/CustomLandingMenu';
import AdministrativesMenu from './Menus/AdministrativesMenu';

import './SideNav.scss';

function ExtendedMenu({ societyId, userId = null }) {
  const { hasAccessCaptable, isSocietyHolding } = useSelector(
    (state) => state?.society?.actualSociety
  ) || {
    hasAccessCaptable: false,
    isSocietyHolding: false,
  };

  const permissions = useSelector((state) => state?.society?.permissions);
  const hasCustomLanding = useSelector(
    (state) => state?.society?.actualSociety?.configuration?.hasCustomLanding
  );

  const { role } = useSelector((state) => state?.society) || {};
  const { user } = useSelector((state) => state);

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const {
    isAdmin,
    isPartner,
    isBeneficiary,
    isInvestor,
    isRepresentant,
    isGuest,
    isHolder,
    isDirector,
  } = useSelector(
    (state) =>
      state?.society?.role || {
        isAdmin: false,
        isPartner: false,
        isBeneficiary: false,
        isInvestor: false,
        isRepresentant: false,
        isGuest: false,
        isHolder: false,
        isDirector: false,
      }
  );

  const rolesValidation = useRolesValidation(role);

  const hasNotPermissionsSetted =
    !permissions?.customLanding &&
    !permissions?.dashboard &&
    !permissions?.partners &&
    !permissions?.portfolio &&
    !permissions?.drafts &&
    !permissions?.plans &&
    !permissions?.transactions &&
    !permissions?.communications &&
    !permissions?.boards &&
    !permissions?.documents &&
    !permissions?.societyConfiguration;

  const hasCustomLandingPermission =
    hasNotPermissionsSetted || permissions?.customLanding?.enabled || false;
  const hasDashboardPermission =
    hasNotPermissionsSetted || permissions?.dashboard?.enabled || false;
  const hasPartnersPermission =
    hasNotPermissionsSetted || permissions?.partners?.enabled || false;
  const hasPortfolioPermission =
    hasNotPermissionsSetted || permissions?.portfolio?.enabled || false;
  const hasDraftsPermission =
    hasNotPermissionsSetted || permissions?.drafts?.enabled || false;
  const hasPlansPermission =
    hasNotPermissionsSetted || permissions?.plans?.enabled || false;
  const hasTransactionsPermission =
    hasNotPermissionsSetted || permissions?.transactions?.enabled || false;
  const hasCommunicationsPermission =
    hasNotPermissionsSetted || permissions?.communications?.enabled || false;
  const hasBoardPermission =
    hasNotPermissionsSetted || permissions?.boards?.enabled || false;
  const hasDocumentsPermission =
    hasNotPermissionsSetted || permissions?.documents?.enabled || false;
  const hasSocietyDataPermission =
    hasNotPermissionsSetted ||
    permissions?.societyConfiguration?.enabled ||
    false;

  const hasCustomLandingMenu =
    hasCustomLanding &&
    (rolesValidation.isAdmin ||
      rolesValidation.isPartner ||
      rolesValidation.isGuest);
  const hasDashboardMenu =
    hasDashboardPermission &&
    (rolesValidation.isAdmin || rolesValidation.isPartner);
  const hasPartnersMenu =
    hasPartnersPermission &&
    !rolesValidation.isBeneficiaryOnly &&
    !rolesValidation.isRepresentantOnly &&
    !rolesValidation.isGuestOnly;
  const hasDraftsMenu =
    hasDraftsPermission && !rolesValidation.isBeneficiaryOnly;
  const hasCommunicationsMenu =
    hasCommunicationsPermission && !rolesValidation.isBeneficiaryOnly;
  const hasBoardMenu = hasBoardPermission && !rolesValidation.isBeneficiaryOnly;
  const hasDocumentsMenu =
    hasDocumentsPermission && !rolesValidation.isBeneficiaryOnly;
  const hasSocietyDataMenu =
    hasSocietyDataPermission && !rolesValidation.isBeneficiaryOnly;
  const hasPlansMenu = hasPlansPermission;
  const hasTransactionsMenu = hasTransactionsPermission;
  const hasPortfolioMenu =
    (hasPortfolioPermission && rolesValidation.isHolder) ||
    (rolesValidation.isAdmin && isSocietyHolding);

  const hasAdministrativeMenu =
    hasSocietyDataPermission || rolesValidation.isDirectorOnly;

  const goToDashboard = () => {
    window.location.href = `#/sociedad/${societyId}`;
  };

  const getBoardLinkByRole = () => {
    if (isAdmin) {
      return `/juntas/${societyId}`;
    }

    if (isPartner) {
      return `/juntas/${societyId}/${userId}`;
    }

    if (isRepresentant) {
      return `/juntas/${societyId}/${userId}`;
    }

    return '';
  };

  return (
    <Accordion id="main-menu__extended" className="pl-4 p-md-0 bg-transparent">
      {hasCustomLandingMenu && <CustomLandingMenu societyId={societyId} />}

      {hasDashboardMenu && isAdmin && (
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          <li className="nk-menu-item has-sub" onClick={() => goToDashboard()}>
            <a className="nk-menu-link nk-menu-toggle">
              <span className="nk-menu-icon">
                <em className="icon ni ni-meter" />
              </span>
              <span className="nk-menu-text">Dashboard</span>
            </a>
          </li>
        </Accordion.Toggle>
      )}

      {hasPartnersMenu && (
        <PartnersMenu
          societyId={societyId}
          userId={userId}
          isAdmin={isAdmin}
          isPartner={isPartner}
          isInvestor={isInvestor}
          hasAccessCaptable={hasAccessCaptable}
        />
      )}

      {hasPortfolioMenu && <PortfoliosMenu societyId={societyId} />}

      {hasDraftsMenu && isAdmin && (
        <DraftMenu societyId={societyId} user={user} />
      )}

      {hasPlansMenu && (isAdmin || isBeneficiary) && (
        <PlansMenu
          societyId={societyId}
          user={user}
          isBeneficiary={isBeneficiary}
          isAdmin={isAdmin}
        />
      )}

      {hasTransactionsMenu && isAdmin && (
        <TransactionsMenu societyId={societyId} />
      )}

      {hasCommunicationsMenu && isAdmin && (
        <CommunicationsMenu societyId={societyId} />
      )}

      {hasBoardMenu && (isAdmin || isPartner || isRepresentant) && (
        <BoardsMenu getBoardLinkByRole={getBoardLinkByRole} />
      )}

      {hasAdministrativeMenu && (isAdmin || isDirector) && (
        <AdministrativesMenu
          societyId={societyId}
          isDirector={isDirector}
          rolesValidation={rolesValidation}
        />
      )}

      {hasDocumentsMenu && isAdmin && <DocumentsMenu societyId={societyId} />}

      {hasSocietyDataMenu && isAdmin && (
        <SocietyDataMenu societyId={societyId} user={user} isDemo={isDemo} />
      )}
    </Accordion>
  );
}

export default ExtendedMenu;
