import { useTranslate } from 'hooks/useTranslate';
import councilTemplateTypes from 'modules/administratives/pages/CouncilDetails/constants/councilTemplateTypes';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  hasMissingSigners: boolean;
  missingText: string;
  includeAssistants: boolean;
  templateType: string;
  handleChangeIncludeAssistants: () => void;
};

const RequestSignatureModalBody: FC<Props> = ({
  hasMissingSigners,
  missingText,
  includeAssistants,
  templateType,
  handleChangeIncludeAssistants,
}: Props) => {
  const { t } = useTranslate();

  const bodyText =
    templateType === councilTemplateTypes.ACT
      ? t('CouncilActSignRequestMessage')
      : t('CouncilCertificationSignRequestMessage');

  const showIncludeAssistants = templateType === councilTemplateTypes.ACT;

  return (
    <>
      <Modal.Body>
        <div className="py-6">
          <p className={showIncludeAssistants ? 'mb-4' : ''}>{bodyText}</p>

          {showIncludeAssistants && (
            <div className="col-6 custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="include-assistants"
                id="include-assistants"
                checked={includeAssistants}
                onChange={handleChangeIncludeAssistants}
              />
              <label
                className="custom-control-label"
                htmlFor="include-assistants"
              >
                {t('IncludeAssistants')}
              </label>
            </div>
          )}

          {hasMissingSigners && (
            <div className="alert alert-icon alert-warning alert-dismissible mt-4">
              <em className="icon ni ni-alert-circle" />
              {t(missingText)}
            </div>
          )}
        </div>
      </Modal.Body>
    </>
  );
};

export default RequestSignatureModalBody;
