import { format } from 'date-fns';
import { translate } from 'hooks/useTranslate';
import { Operation, Society } from 'types';

const transactionNames = {
  ADD_PARTNER: translate('AddPartner'),
  CONSTITUTION: translate('Constitution'),
  CAPITAL_INCREASE: translate('CapitalIncrease'),
  CAPITAL_DECREASE: translate('CapitalDecrease'),
  SELL_PARTICIPATION: translate('SELL_PARTICIPATION'),
  HERITAGE: translate('Heritage'),
  DONATION: translate('Donation'),
  SPLIT: translate('Split'),
  RENUMERATION: translate('Renumeration'),
  RECLASSIFICATION: translate('Reclassification'),
  DIVIDENDS_DISTRIBUTION: translate('DividendsDistribution'),
};

export const getDocumentTitle = (
  society: Society,
  operation: Operation | undefined
) => {
  if (!operation) {
    return '';
  }

  const operationType = operation?.alias || operation.operationType;

  const operationName =
    transactionNames[operationType as keyof typeof transactionNames];

  const operationDate = operation?.date
    ? format(new Date(operation.date), 'dd-MM-yyyy')
    : '';

  return `${society?.name || ''} - ${operationName} ${operationDate}`;
};
