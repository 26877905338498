import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { Council } from 'types';
import Page from 'modules/_shared/components/Pages/Page';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import { useGetActualSociety } from 'hooks/useGetActualSociety';
import { useGetMenu } from '../../hooks/useGetMenu';

import CouncilDetailsPageHeader from './components/CouncilDetailsPageHeader';
import CouncilDetailsPageBody from './components/CouncilDetailsPageBody';
import { useGetCouncilsList } from '../CouncilsList/hooks/useGetCouncilsList';

type Props = {};

const CouncilDetails: FC<Props> = () => {
  const { societyId, councilId } = useParams();

  const council = useSelector((state: State) =>
    state.councils.find((council: Council) => council._id === councilId)
  );

  useGetMenu();
  useGetActualSociety(societyId);
  useGetCouncilsList(societyId);

  const handleEditCouncil = () => {};

  return (
    <Page>
      {council && (
        <>
          <CouncilDetailsPageHeader council={council} />

          {societyId && councilId && (
            <CouncilDetailsPageBody
              councilId={councilId}
              handleEditCouncil={handleEditCouncil}
            />
          )}
        </>
      )}

      {!council && <CustomLoading />}
    </Page>
  );
};

export default CouncilDetails;
