import { updateSociety } from 'modules/society/redux/societyActions';
import { store } from 'redux/store';
import { SocietyAuditor } from 'types';

export const removeSocietyAuditor = async (
  societyId: string,
  auditors: SocietyAuditor[],
  auditorId?: string
) => {
  if (auditorId) {
    const filteredAuditors = auditors.filter(
      (auditor) => auditor['_id'] !== auditorId
    );
    const newSocietyData = {
      auditors: filteredAuditors,
    };
    await store.dispatch(updateSociety(societyId, newSocietyData, false));
  }
};
