/* eslint-disable no-undef */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import MandatoryField from 'modules/_shared/components/MandatoryField';
import { RealtyPlot } from 'types';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';
import PlotBuildingsView from './PlotBuildingsView';

const PlotsViewForm: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useAddRealtyModal();

  const [number, setNumber] = useState<number>(0);
  const [type, setType] = useState<string>('');
  const [subType, setSubType] = useState<string>('');
  const [area, setArea] = useState<number>(0);
  const [buildings, setBuildings] = useState<any[]>([]);

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(Number(e.target.value));
  };

  const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value);
  };

  const handleChangeSubType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubType(e.target.value);
  };

  const handleChangeArea = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArea(Number(e.target.value));
  };

  const handleAddBuilding = (newBuilding: any) => {
    setBuildings((prev) => [...prev, newBuilding]);
  };

  const handleSavePlot = () => {
    const newPlot: RealtyPlot = {
      plotNumber: number,
      plotType: type,
      plotSubType: subType,
      area,
      buildings,
    };

    setNumber(0);
    setType('');
    setSubType('');
    setArea(0);
    setBuildings([]);

    const newPlots = [...data.plots, newPlot];

    actions.handleChangePlots(newPlots);
  };

  return (
    <div className="row gy-4">
      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="plot-number">
            {t('Number')} <MandatoryField />
          </label>

          <input
            id="plot-number"
            type="number"
            className="form-control form-control-lg"
            value={number}
            onChange={handleChangeNumber}
            placeholder={t('IntroduceNumber')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="plot-type">
            {t('Type')} <MandatoryField />
          </label>

          <input
            id="plot-type"
            type="text"
            className="form-control form-control-lg"
            value={type}
            onChange={handleChangeType}
            placeholder={t('IntroduceType')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="plot-subtype">
            {t('SubType')} <MandatoryField />
          </label>

          <input
            id="plot-subtype"
            type="text"
            className="form-control form-control-lg"
            value={subType}
            onChange={handleChangeSubType}
            placeholder={t('IntroduceSubType')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="plot-area">
            {t('Area')} (m²) <MandatoryField />
          </label>

          <input
            id="plot-area"
            type="number"
            className="form-control form-control-lg"
            value={area}
            onChange={handleChangeArea}
            placeholder={t('IntroduceArea')}
          />
        </div>
      </div>

      <hr />
      <div className="col-12">
        <div className="form-group">
          <PlotBuildingsView
            buildingsCount={buildings?.length || 0}
            handleAddBuilding={handleAddBuilding}
          />
        </div>
      </div>
      <hr />

      <div className="col-12 text-right">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSavePlot}
          disabled={!number || !type || !subType || !area}
        >
          {t('AddPlot')}
        </button>
      </div>
    </div>
  );
};

export default PlotsViewForm;
