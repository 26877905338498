import { SocietyDirector } from 'types';
import { getAdminMemberStatus } from './getAdminMemberStatus';

export const getAdminMemberStatusData = (
  member: SocietyDirector | undefined
) => {
  if (!member) return { statusLabel: '-', statusBadge: '-' };
  const status = getAdminMemberStatus(member);

  const statusLabel = status ? status.label : '-';
  const statusBadge = status ? status.badge : '-';

  return { statusLabel, statusBadge };
};
