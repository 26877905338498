import { Council } from 'types';
import councilTypes from 'modules/administratives/constants/councilTypes';

export const getCouncilTypeLabel = (council: Council) => {
  const councilType = councilTypes.find(
    (type) => type.value === council?.councilType
  );

  const statusLabel = councilType ? councilType.label : '-';

  return statusLabel;
};
