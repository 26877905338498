/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  HashRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal } from 'react-bootstrap';

import { saveToStorage } from 'utils/token';
import { applyGlobalTheme } from 'utils/theme';
import sizes from 'constants/sizes';
import { APP_THEME_FF } from 'constants/defaultConstants';
import { protectedRoutes } from 'routes/protectedRoutes';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import LegalAlert from 'modules/_shared/components/Alert';
import Login from 'modules/auth/pages/Login';
import LoginPWC from 'modules/auth/pages/LoginPWC';
import Page404 from 'modules/_shared/pages/404';
import ThemeContext from 'modules/_shared/contexts/ThemeContext';
import TryDemo from 'modules/auth/pages/TryDemo';
import { initAuthenticatedUser } from 'modules/profile/redux/userActions';

import { LegalRoute } from 'LegalRoute';

import ScrollToTop from './ScrollToTop';

import './App.scss';
import './assets/styles/Bootstrap.scss';
import './assets/styles/Components.scss';
import './assets/styles/Fonts.scss';
import './assets/styles/Icons.scss';
import './assets/styles/Layouts.scss';
import './assets/styles/Misc.scss';
import './assets/styles/Placeholder.scss';
import './assets/styles/Print.scss';
import './assets/styles/ReactSelect.scss';
import './assets/styles/Stepper.scss';
import './assets/styles/Styles.scss';
import './assets/styles/Timeline.scss';
import './assets/styles/Animation.scss';

function validateAndStoreRedirection(redirectionString, storageField) {
  const redirectTokenString = redirectionString[1];
  const encodedSlash = ['%2F', '%2f'];

  const containsEncodedSlash = new RegExp(encodedSlash.join('|'), 'gi').test(
    redirectTokenString
  );

  const decodedRedirectToken = containsEncodedSlash
    ? redirectTokenString.replace(/%2F|%2f/gi, '/')
    : redirectTokenString;

  saveToStorage(storageField, decodedRedirectToken);
  window.history.replaceState({}, document.title, '/');
}
function App() {
  const dispatch = useDispatch();

  const { setDarkTheme } = useContext(ThemeContext);
  const { user: auth0User, isAuthenticated, isLoading } = useAuth0();

  const user = useSelector((state) => state.user);
  const alerts = useSelector((state) => state.alerts);
  const statemodal = useSelector((state) => state.modal);
  const currentAlert = useSelector((state) => state.alerts[0]);

  const theme = useSelector(
    (state) => state?.society?.actualSociety?.customization?.theme
  );

  const signedRedirectRegex = /signed=[^&]*&?(.*)/;
  const emailRedirectRegex = /redirect=([^&]*)/;

  const signedRedirectUrl = useLocation().search.match(signedRedirectRegex);
  const emailRedirectUrl = useLocation().search.match(emailRedirectRegex);

  const [modal, setModal] = useState(false);

  const loadSavedTheme = () => {
    const savedTheme = localStorage.getItem(APP_THEME_FF);

    if (savedTheme) {
      const isDarkTheme = savedTheme === 'dark';
      setDarkTheme(isDarkTheme);
    }
  };

  // TODO: Remove after releasing the new version
  const cleanStoredData = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('access-token');
  };

  useEffect(() => {
    applyGlobalTheme(theme);
  }, [theme]);

  useEffect(() => {
    setModal(statemodal);
  }, [statemodal]);

  useEffect(() => {
    if (signedRedirectUrl && !emailRedirectUrl) {
      validateAndStoreRedirection(signedRedirectUrl, 'sign_redirect');
    } else if (emailRedirectUrl) {
      validateAndStoreRedirection(emailRedirectUrl, 'redirect_url');
    }
  }, [signedRedirectUrl, emailRedirectUrl]);

  useEffect(() => {
    if (isAuthenticated && auth0User && !user) {
      dispatch(initAuthenticatedUser(auth0User));
    }
  }, [auth0User, isAuthenticated, user, dispatch]);

  useEffect(() => {
    loadSavedTheme();
    cleanStoredData();
  }, []); // eslint-disable-line

  return (
    <>
      {!!alerts.length && <LegalAlert {...currentAlert} />}

      <Router>
        <ScrollToTop />
        <div className="nk-body npc-default has-aside no-touch nk-nio-theme">
          <div className="nk-app-root">
            {modal ? (
              <Modal
                size={modal?.props?.size || sizes.MD}
                dialogClassName={modal?.props?.fullScreen ? 'modal-90w' : ''}
                show
                onHide={() => setModal(null)}
                backdrop="static"
                keyboard={false}
                centered
              >
                {modal}
              </Modal>
            ) : null}

            <div className="nk-main ">
              <Switch>
                {protectedRoutes.map((route) => (
                  <LegalRoute
                    key={route.id}
                    {...route}
                    user={user}
                    isLoading={isAuthenticated || isLoading}
                  />
                ))}

                <Route path="/demo" component={TryDemo} />
                <Route path="/login" component={Login} />
                <Route path="/pwc-login" component={LoginPWC} />
                <Route component={Page404} />
              </Switch>
            </div>
          </div>
        </div>
      </Router>

      {isLoading && <CustomLoading />}
    </>
  );
}

export default App;
