/* eslint-disable no-undef */
/* eslint-disable react/jsx-indent */
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslate } from 'hooks/useTranslate';
import { Tooltip } from 'react-tooltip';
import { format } from 'date-fns';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

import actionTypes from 'constants/actionTypes';
import { DATE_FORMAT, TYPE_FILES_ALLOWED } from 'constants/defaultConstants';
import { getDocumentIcon } from 'utils/documentIcons';
import { allowedFileDocument } from 'utils/allowedFileDocument';
import { getFileDate } from 'utils/files';
import { CouncilAttendanceTypes } from 'types';
import errorSwal from 'utils/errorSwal';
import { AddCouncilFormState } from '../types/AddCouncilForm';
import CommentsCharCounter from '../../../CommentsCharCounter';

type AddCouncilBodyProps = {
  action: keyof typeof actionTypes;
  formState: AddCouncilFormState;
  updateFormState: (updates: Partial<AddCouncilFormState>) => void; // eslint-disable-line
  onSaveCouncil: (e: React.FormEvent) => void; // eslint-disable-line
  onEditCouncil: (e: React.FormEvent) => void; // eslint-disable-line
  attendanceOptions: Array<{ value: string; label: string }>;
  directorOptions: Array<{ value: string; label: string }>;
  isSaveButtonDisabled: () => boolean;
};

const attendanceTypes = {
  HYBRID: 'HYBRID',
  ONLINE: 'ONLINE',
  FACE_TO_FACE: 'FACE_TO_FACE',
  WRITTEN_NO_SESSION: 'WRITTEN_NO_SESSION',
};

const AddCouncilBody: FC<AddCouncilBodyProps> = ({
  action,
  formState,
  updateFormState,
  attendanceOptions,
  directorOptions,
  isSaveButtonDisabled,
  onSaveCouncil,
  onEditCouncil,
}) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const handleDeleteDocument = (index: number) => {
    const newFiles = formState.files.filter((_, i) => i !== index);
    updateFormState({ files: newFiles });
  };

  const handleAttendanceChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    if (!selectedOption) return;

    const updates: Partial<AddCouncilFormState> = {
      attendanceType: selectedOption.value as CouncilAttendanceTypes,
      selectedAttendanceOption: selectedOption,
    };

    if (selectedOption.value === attendanceTypes.FACE_TO_FACE) {
      updates.onlineAccess = '';
    } else if (
      selectedOption.value === attendanceTypes.ONLINE ||
      selectedOption.value === attendanceTypes.WRITTEN_NO_SESSION
    ) {
      updates.place = '';
    }

    updateFormState(updates);
  };

  const handleUpdateNameWithDate = (date: string) => {
    const dateRegex = /\b(\d{1,2}\/\d{1,2}\/\d{4})\b/g;
    const dateFormatted = format(new Date(date), DATE_FORMAT);
    const updatedText = formState.name.replace(dateRegex, dateFormatted);

    updateFormState({ name: updatedText });
  };

  const handleChangeFiles = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const newFiles = [...event.target.files];

      const validationResults = await Promise.all(
        newFiles.map(async (file) => {
          const allowedFile = await allowedFileDocument(file);
          return allowedFile;
        })
      );

      const hasInvalidFiles = validationResults.some((isValid) => !isValid);
      if (hasInvalidFiles) {
        errorSwal(t('InvalidFile'));
        return;
      }

      updateFormState({
        files: [...formState.files, ...Array.from(newFiles)],
      });
    }
  };

  return (
    <form onSubmit={action === actionTypes.ADD ? onSaveCouncil : onEditCouncil}>
      <Modal.Body>
        <div className="nk-block form-contact g-2">
          <div className="row g-4">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">{t('CouncilName')}</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder={t('BoardExampleName')}
                    value={formState.name}
                    onChange={(e) => updateFormState({ name: e.target.value })}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row g-4">
            <div className="nk-block-head-content col-12">
              <h5 className="nk-block-title mb-0">{t('BasicInfo')}</h5>
            </div>

            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="council-date-time">
                  {t('DateAndTime')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="datetime-local"
                    className="form-control date-picker"
                    id="council-date-time"
                    name="council-date-time"
                    value={format(
                      new Date(formState.date),
                      "yyyy-MM-dd'T'HH:mm"
                    )}
                    placeholder="dd/mm/yyyy"
                    onChange={(e) => {
                      updateFormState({ date: new Date(e.target.value) });
                      handleUpdateNameWithDate(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="council-attendance">
                  {t('FormOfAssistance')}
                </label>
                <div>
                  <Select
                    id="council-attendance"
                    closeMenuOnSelect
                    value={formState.selectedAttendanceOption}
                    className="react-select"
                    options={attendanceOptions}
                    components={animatedComponents}
                    onChange={handleAttendanceChange}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>

            {(formState.attendanceType === attendanceTypes.FACE_TO_FACE ||
              formState.attendanceType === attendanceTypes.HYBRID) && (
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="council-place">
                    {t('PlaceOfCelebration')}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      id="council-place"
                      type="text"
                      className="form-control"
                      placeholder="Si es presencial indicar dirección. "
                      value={formState.place}
                      onChange={(e) =>
                        updateFormState({ place: e.target.value })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            )}

            {(formState.attendanceType === attendanceTypes.ONLINE ||
              formState.attendanceType === attendanceTypes.HYBRID) && (
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label className="form-label">
                    {t('OnlineAccessOptional')}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('AccessLink')}
                      value={formState.onlineAccess}
                      onChange={(e) =>
                        updateFormState({ onlineAccess: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label className="form-label">{t('President')}</label>
                <div>
                  <Select
                    closeMenuOnSelect
                    value={formState.selectedPresidentOption}
                    className="react-select"
                    options={directorOptions}
                    components={animatedComponents}
                    placeholder={t('SelectPresident')}
                    onChange={(option) =>
                      updateFormState({
                        president: option?.value,
                        selectedPresidentOption: option,
                      })
                    }
                    isMulti={false}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label className="form-label">{t('Secretary')}</label>
                <div>
                  <Select
                    closeMenuOnSelect
                    value={formState.selectedSecretaryOption}
                    className="react-select"
                    options={directorOptions}
                    components={animatedComponents}
                    placeholder={t('SelectSecretary')}
                    onChange={(option) =>
                      updateFormState({
                        secretary: option?.value,
                        selectedSecretaryOption: option,
                      })
                    }
                    isMulti={false}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label">{t('Topics')}</label>
                <div className="form-control-wrap">
                  <div className="form-editor-custom">
                    <textarea
                      id="council-topics"
                      maxLength={1000}
                      className="form-control form-control-lg no-resize"
                      value={formState.topics}
                      onChange={(e) =>
                        updateFormState({ topics: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-note d-flex justify-content-end">
                  <CommentsCharCounter comments={formState.topics} />
                </div>
              </div>
            </div>
          </div>

          {action === actionTypes.ADD && (
            <div className="row g-4">
              <div className="nk-block-head-content col-12">
                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <h5 className="nk-block-title mb-0 mb-0">
                      {t('Documentation')}
                    </h5>
                    <em
                      className="icon ni ni-info text-gray ml-2"
                      id="documentation-title"
                    />
                    <Tooltip anchorId="documentation-title" place="top">
                      {t('TheseDocumentsBeAvailablePartners')}
                    </Tooltip>
                  </div>
                  <div className="col-md-6 text-right">
                    <div className="form-group">
                      <label
                        htmlFor="custom-file"
                        className="btn btn-sm btn-dim btn-primary mb-0"
                      >
                        <em className="icon ni ni-plus" />
                        <span>{t('AddDocumentation')}</span>
                      </label>

                      <input
                        type="file"
                        id="custom-file"
                        name="custom-file"
                        style={{ display: 'none' }}
                        accept={TYPE_FILES_ALLOWED}
                        onChange={handleChangeFiles}
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>

              {formState.files?.length > 0 ? (
                <div className="card-borderer col-12 pt-0">
                  <div className="nk-files nk-files-view-list">
                    <div className="nk-files-head">
                      <div className="nk-file-item">
                        <div className="nk-file-info">
                          <div className="tb-head ml-4">{t('Name')}</div>
                        </div>

                        <div className="nk-file-meta d-none d-xl-table-cell text-center">
                          <div className="tb-head">{t('DocumentDate')}</div>
                        </div>
                      </div>
                    </div>
                    <div className="nk-files-list">
                      {formState.files?.map((file, index) => (
                        <div className="nk-file-item nk-file">
                          <div className="nk-file-info">
                            <div className="nk-file-title w-100">
                              <div className="nk-file-icon">
                                <span className="nk-file-icon-type">
                                  <img
                                    src={getDocumentIcon(file, true)}
                                    alt="icon-file"
                                  />
                                </span>
                              </div>

                              <div className="nk-file-name">
                                <div
                                  className="nk-file-name-text d-flex flex-column"
                                  style={{ maxWidth: '300px' }}
                                >
                                  <span
                                    className="title text-ellipsis"
                                    title={file?.name}
                                  >
                                    {file?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="nk-file-meta d-none d-xl-table-cell">
                            <div className="tb-lead text-center">
                              {getFileDate(file)}
                            </div>
                          </div>
                          <div className="text-center">
                            <button
                              type="button"
                              className="text-soft btn btn-icon"
                              onClick={() => handleDeleteDocument(index)}
                            >
                              <em className="icon ni ni-trash" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-primary"
          type="submit"
          disabled={isSaveButtonDisabled()}
        >
          {action === actionTypes.ADD ? t('Save') : t('UpdateCouncil')}
        </button>
      </Modal.Footer>
    </form>
  );
};

export default AddCouncilBody;
