import { Council } from 'types';
import { getCouncilStatusData } from '../pages/CouncilDetails/utils/getCouncilStatusData';

const badgeClass = 'badge badge-pill badge-sm';

export const getCouncilBadge = (council: Council) => {
  const { statusLabel, statusBadge, statusIcon } =
    getCouncilStatusData(council);

  return (
    <span className={`${badgeClass} badge-${statusBadge}`}>
      <em className={`icon mr-1 ${statusIcon}`} />
      {statusLabel}
    </span>
  );
};
