import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useDispatch } from 'react-redux';
import AddRealtyModal from 'modules/administratives/modals/AddRealtyModal';
import sizes from 'constants/sizes';
import { AddRealtyModalProvider } from 'modules/administratives/modals/AddRealtyModal/context/AddRealtyModalContext';

type Props = {
  societyId: string | undefined;
  societyName: string;
  realtiesCount: number;
};

const RealtiesPageHeader: FC<Props> = ({
  societyId,
  societyName,
  realtiesCount,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleAddRealty = () => {
    if (societyId) {
      dispatch(
        setModal(
          <AddRealtyModalProvider>
            <AddRealtyModal societyId={societyId} size={sizes.XL} />
          </AddRealtyModalProvider>
        )
      );
    }
  };

  return (
    <PageListHeader
      title={t('Realties')}
      subTitle={societyName}
      description={`${societyName || ''} ${t('HaveRealties', {
        count: realtiesCount,
      })}`}
      actions={[
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddRealty}
        >
          {t('AddRealty')}
        </button>,
      ]}
    />
  );
};

export default RealtiesPageHeader;
