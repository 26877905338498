import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import downloadDocument from 'utils/downloadDocument';
import asyncActionSwal from 'utils/asyncActionSwal';
import successSwal from 'utils/successSwal';
import errorSwal from 'utils/errorSwal';

import { Council } from 'types';

import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import DocumentsList from './DocumentsList';
import DocumentsHeader from './DocumentsHeader';

import useGetDocuments from '../hooks/useGetDocuments';

import AddCouncilDocumentModal from './AddCouncilDocumentModal';
import { removeDocumentFromCouncil } from '../utils/removeDocumentFromCouncil';

type Props = {
  council: Council;
  fetchCouncilData: () => void;
  isAdminView?: boolean;
  isSummaryVersion?: boolean;
};

const MembersDocumentsSection: FC<Props> = ({
  council,
  fetchCouncilData,
  isAdminView = true,
  isSummaryVersion = false,
}: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const isReadOnly = false; // TODO: Implement permissions

  const membersDocumentsIds = council?.publicDocuments || [];

  const documentsData = useGetDocuments({ documentsIds: membersDocumentsIds });
  const { documents } = documentsData.data;
  const { isLoading } = documentsData;
  const { setIsLoading } = documentsData.actions;

  const hasDocuments = documents.length > 0;

  const handleDownloadDocument = async (index: number) => {
    downloadDocument({
      documentId: documents[index]?.['_id'],
      documentName: documents[index]?.name,
      documents: [],
    });
  };

  const handleAddDocument = () => {
    if (council) {
      dispatch(
        setModal(
          <AddCouncilDocumentModal
            council={council}
            fetchCouncilData={fetchCouncilData}
          />
        )
      );
    }
  };

  const handleRemoveDocument = async (index: number) => {
    const documentId = documents[index]?._id;
    const documentName = documents[index]?.name;

    asyncActionSwal({
      swalTitle: t('AreYouSureAboutDelDocument'),
      swalElementName: documentName,
      swalInfoText: t('ThisProcedureCantUndo'),
      swalConfirmButtonText: t('OkDelete'),
      callback: async () => {
        try {
          setIsLoading(true);
          if (council) {
            await removeDocumentFromCouncil({
              documentId,
              council,
              privateDocument: false,
            });
          }
          successSwal(t('DocumentDeletedSuccessfully'));
        } catch (error) {
          errorSwal(t('ErrorRemovingDocument'));
        } finally {
          setIsLoading(false);
          fetchCouncilData();
        }
      },
    });
  };

  return (
    <>
      {hasDocuments && (
        <div
          className={`card-inner ${
            isSummaryVersion ? 'documents-card-version' : ''
          }`}
        >
          <div className="sp-plan-head">
            <div className="row sp-plan-head mb-2">
              <h6 className="col-md-6 title">{t('DocumentsForMembers')}</h6>
              <div className="col-md-6 text-right nk-block-head-content">
                {isAdminView && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAddDocument}
                    disabled={isReadOnly}
                  >
                    <em className="icon ni ni-upload mr-1" />
                    {t('UpDocument')}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="nk-files nk-files-view-list">
            <DocumentsHeader />

            <DocumentsList
              documents={documents}
              handleDownloadDocument={handleDownloadDocument}
              handleRemoveDocument={handleRemoveDocument}
              isAdminView={isAdminView}
              isSummaryVersion={isSummaryVersion}
            />
          </div>
        </div>
      )}
      {isLoading && <CustomLoading />}
    </>
  );
};

export default MembersDocumentsSection;
