import { store } from 'redux/store';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';

import { Council } from 'types';
import successSwal from 'utils/successSwal';
import errorSwal from 'utils/errorSwal';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updateDocumentToDeleted } from 'modules/administratives/pages/CouncilDetails/utils/updateDocumentToDeleted';
import { generateCouncilDocuments } from 'modules/administratives/utils/generateCouncilDocuments';
import { updateCouncil } from 'modules/administratives/redux/councilActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import councilTemplateTypes from 'modules/administratives/pages/CouncilDetails/constants/councilTemplateTypes';

import ChangeCouncilTemplateModalHeader from './components/ChangeCouncilTemplateModalHeader';
import ChangeCouncilTemplateModalBody from './components/ChangeCouncilTemplateModalBody';
import ChangeCouncilTemplateModalFooter from './components/ChangeCouncilTemplateModalFooter';

type Props = {
  councilId: string;
  templateType: string;
};

const ChangeCouncilTemplateModal: FC<Props> = ({
  councilId,
  templateType,
}: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const society = useSelector((state: State) => state?.society?.actualSociety);
  const user = useSelector((state: State) => state?.user);
  const council = useSelector((state: State) =>
    state?.councils.find((council: Council) => council._id === councilId)
  );

  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeSelectedTemplate = (selectedOption: any) => {
    setSelectedTemplate(selectedOption.value);
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const changeActTemplate = async () => {
    if (council) {
      const { actDocumentId } = await generateCouncilDocuments({
        actTemplateId: selectedTemplate,
        society,
        council,
        userId: user['_id'],
      });

      const newCouncilData = {
        _id: councilId,
        actTemplate: selectedTemplate,
        actDocument: actDocumentId,
      };
      await store.dispatch(updateCouncil(newCouncilData));
    }
  };

  const changeCertificationTemplate = async () => {
    if (council) {
      const { certificationDocumentId } = await generateCouncilDocuments({
        certificationTemplateId: selectedTemplate,
        society,
        council,
        userId: user['_id'],
      });

      const newCouncilData = {
        _id: councilId,
        actCertificateTemplate: selectedTemplate,
        actCertificateDocument: certificationDocumentId,
      };
      await store.dispatch(updateCouncil(newCouncilData));
    }
  };

  const handleChangeTemplate = async () => {
    try {
      setIsLoading(true);
      if (council) {
        const councilDocumentId = council?.actDocument;
        await updateDocumentToDeleted(councilDocumentId);

        if (templateType === councilTemplateTypes.ACT) {
          await changeActTemplate();
        } else {
          await changeCertificationTemplate();
        }

        successSwal(t('TemplateChangedSuccessfully'));
      }
    } catch {
      errorSwal(t('ErrorChangingTemplate'));
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <ChangeCouncilTemplateModalHeader
        templateType={templateType}
        handleCloseModal={handleCloseModal}
      />

      <ChangeCouncilTemplateModalBody
        societyId={society['_id']}
        handleChangeSelectedTemplate={handleChangeSelectedTemplate}
      />

      <ChangeCouncilTemplateModalFooter
        selectedTemplate={selectedTemplate}
        handleChangeTemplate={handleChangeTemplate}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default ChangeCouncilTemplateModal;
