import operationTypes from 'constants/operationTypes';

export const getOperationOffset = (operation: string) => {
  switch (operation) {
    case operationTypes.CONSTITUTION:
      return 1;
    case operationTypes.CAPITAL_INCREASE:
    case operationTypes.CAPITAL_DECREASE:
    case operationTypes.SPLIT:
      return 2;
    default:
      return 3;
  }
};
