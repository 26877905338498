import { FC } from 'react';

import { SocietyRepresentative } from 'types';
import SocietyRepresentativesTable from './SocietyRepresentativesTable';

type Props = {
  societyId: string;
  societyRepresentatives: SocietyRepresentative[];
};

const SocietyRepresentativesPageBody: FC<Props> = ({
  societyId,
  societyRepresentatives,
}) => (
  <div className="nk-block">
    <SocietyRepresentativesTable
      societyId={societyId}
      societyRepresentatives={societyRepresentatives}
    />
  </div>
);

export default SocietyRepresentativesPageBody;
