/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'gray'
  | 'link';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  children?: React.ReactNode;
  icon?: string;
  outlineStyle?: boolean;
  dimStyle?: boolean;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  variant = 'primary',
  children,
  icon,
  outlineStyle,
  dimStyle,
  disabled,
  ...props
}) => {
  const buttonClassName = `btn ${dimStyle ? 'btn-dim' : ''} btn${
    outlineStyle ? '-outline' : ''
  }-${variant}`;

  return (
    <button
      type="button"
      className={buttonClassName}
      disabled={disabled}
      {...props}
    >
      {icon && (
        <em className={`icon ni ni-${icon} ${children ? 'mr-1' : ''}`} />
      )}
      {children}
    </button>
  );
};

export default Button;
