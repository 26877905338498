import i18n from 'i18n/config';
import { store } from 'redux/store';
import { setModal } from 'modules/_shared/redux/modalActions';

import { Editor } from 'ckeditor5';

import { exportCKEditorWord } from 'modules/_shared/components/CKEditor/utils/exportCKEditorWord';
import { exportCKEdiorPDF } from 'modules/_shared/components/CKEditor/utils/exportCKEdiorPDF';

import ChangeCouncilTemplateModal from 'modules/administratives/modals/ChangeCouncilTemplateModal';
import { editTemplate } from '../utils/editTemplate';
import councilTemplateTypes from './councilTemplateTypes';

type Props = {
  editorInstance: Editor | null;
  councilId: string;
  // eslint-disable-next-line no-unused-vars
  handleChangeIsEditing: (value: boolean) => void;
};

export default [
  {
    id: 'download-pdf',
    icon: 'ni-file-pdf',
    text: i18n.t('DownloadPDF'),
    action: ({ editorInstance }: Props) => exportCKEdiorPDF(editorInstance),
    disabled: false,
  },
  {
    id: 'download-word',
    icon: 'ni-file-doc',
    text: i18n.t('DownloadWord'),
    action: ({ editorInstance }: Props) => exportCKEditorWord(editorInstance),
  },
  {
    id: 'edit-template',
    icon: 'ni-edit',
    text: i18n.t('Edit'),
    action: ({ editorInstance, handleChangeIsEditing }: Props) =>
      editTemplate({ editorInstance, handleChangeIsEditing }),
  },
  {
    id: 'change-template',
    icon: 'ni-files',
    text: i18n.t('ChangeTemplate'),
    action: ({ councilId }: Props) => {
      store.dispatch(
        setModal(
          <ChangeCouncilTemplateModal
            councilId={councilId}
            templateType={councilTemplateTypes.ACT}
          />
        )
      );
    },
  },
];
