import { Society } from 'types';
import { countOperationShares } from 'utils/filters';
import { getFilteredOperations } from 'modules/transactions/utils/getFilteredOperations';
import operationTypes from 'constants/operationTypes';

type GetOperationPreviousSharesProps = {
  operationId: string;
  society: Society;
};

export const getOperationPreviousShares = ({
  operationId,
  society,
}: GetOperationPreviousSharesProps): number => {
  if (!operationId || !society.operations) {
    return 0;
  }

  const includedOps = [
    operationTypes.CONSTITUTION,
    operationTypes.CAPITAL_INCREASE,
    operationTypes.CAPITAL_DECREASE,
    operationTypes.SPLIT,
  ];

  const substractionOps = [operationTypes.CAPITAL_DECREASE];

  const operations = getFilteredOperations(society, includedOps).reverse();

  const currentIndex = operations.findIndex((op) => op._id === operationId);

  const relevantOperations =
    currentIndex !== -1 ? operations.slice(0, currentIndex + 1) : operations;

  const previousShares = relevantOperations.reduce((acc, operation) => {
    const currentOperationShares = countOperationShares(operation) || 0;

    if (substractionOps.includes(operation.operationType)) {
      return acc - currentOperationShares;
    }

    return acc + currentOperationShares;
  }, 0);

  return previousShares;
};
