/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { FC, createContext, useContext, useState } from 'react';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';

import { setModal } from 'modules/_shared/redux/modalActions';
import { useGetDates } from '../hooks/useGetDates';
import { contextInitValue } from '../constants/contextInitValue';
import { getXLSData } from '../utils/getXLSData';
import { getXLSMerges } from '../utils/getXLSMerges';

type DownloadPartnerBookModalContextType = {
  data: {
    date: string;
    minDate: string;
  };
  actions: {
    handleChangeDate(event: React.ChangeEvent<HTMLInputElement>): void;
    handleCloseModal(): void;
    handleDownloadBook(): void;
  };
};

const DownloadPartnerBookModalContext =
  createContext<DownloadPartnerBookModalContextType>(contextInitValue);

export const DownloadPartnerBookModalProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const partnerBook = useSelector((state: State) => state?.partnerBook);

  const datesResult = useGetDates({
    dates: Object.values(partnerBook?.dates),
  });

  const { minDate, date } = datesResult.data;
  const { setDate } = datesResult.actions;

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleDownloadBook = () => {
    const data = getXLSData(partnerBook, date);
    const { xlsData, operations } = data;

    const worksheet = XLSX.utils.aoa_to_sheet(xlsData);

    worksheet['!merges'] = getXLSMerges(operations);

    const headerRow = xlsData[0]; // First row of headers
    // eslint-disable-next-line no-plusplus
    for (let c = 0; c < headerRow.length; c++) {
      const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c })];
      if (cell) {
        cell.s = {
          font: { bold: true },
          alignment: { horizontal: 'center' },
        };
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = `${t('PartnerBook')}.xlsx`;
    link.click();
    URL.revokeObjectURL(objectUrl);

    handleCloseModal();
  };

  return (
    <DownloadPartnerBookModalContext.Provider
      value={{
        data: {
          date,
          minDate,
        },
        actions: {
          handleChangeDate,
          handleCloseModal,
          handleDownloadBook,
        },
      }}
    >
      {children}
    </DownloadPartnerBookModalContext.Provider>
  );
};

export const useDownloadPartnerBookModal = () =>
  useContext(DownloadPartnerBookModalContext);
