/* eslint-disable no-undef */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { RealtyTitle } from 'types';
import { useAddRealtyModal } from '../context/AddRealtyModalContext';

const TitlesViewForm: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useAddRealtyModal();

  const [label, setLabel] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const handleChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleSaveTitle = () => {
    const newTitle: RealtyTitle = { label, description: [description] };
    const newTitles = [...data.titles, newTitle];
    setLabel('');
    setDescription('');

    actions.handleChangeTitles(newTitles);
  };

  return (
    <div className="row gy-4">
      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-label">
            {t('ConditionC')}
          </label>

          <input
            id="realty-label"
            type="text"
            className="form-control form-control-lg"
            value={label}
            onChange={handleChangeLabel}
            placeholder={t('IntroduceCondition')}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-group">
          <label className="form-label w-100" htmlFor="realty-description">
            {t('Description')}
          </label>

          <input
            id="realty-description"
            type="text"
            className="form-control form-control-lg"
            value={description}
            onChange={handleChangeDescription}
            placeholder={t('IntroduceDescription')}
          />
        </div>
      </div>

      <div className="col-12 text-right">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSaveTitle}
          disabled={!label || !description}
        >
          {t('AddTitle')}
        </button>
      </div>
    </div>
  );
};

export default TitlesViewForm;
