/* eslint-disable no-plusplus */
import { format } from 'date-fns';
import i18n from 'i18n/config';

import { dateFormat } from 'constants/formats';
import { getOperationOffset } from './getOperationOffset';

const getDateIndex = (dates: string[], date: string): number => {
  const targetDate = new Date(date).setHours(0, 0, 0, 0);

  let lastIndex = -1;

  dates.forEach((d, i) => {
    const currentDate = new Date(d).setHours(0, 0, 0, 0);
    if (currentDate <= targetDate) {
      lastIndex = i;
    }
  });

  return lastIndex;
};

const getOperations = (
  partnerBook: { operations: Record<string, string> },
  index: number,
  offset: number
): string[] =>
  Object.values(partnerBook.operations).slice(0, index + offset + 1);

const getHeaders = (
  partnerBook: {
    headers: Record<string, string>;
    dates: Record<string, string>;
  },
  index: number,
  offset: number
): string[] =>
  Object.values(partnerBook.headers)
    .slice(0, index + offset + 1)
    .map((header, headerIndex) => {
      const translatedHeader = header === '' ? '' : i18n.t(header);

      const date = partnerBook.dates[headerIndex];
      if (header !== '' && date) {
        const formattedDate = format(new Date(date), dateFormat);
        return `${translatedHeader} \n ${formattedDate}`;
      }
      return translatedHeader;
    });

const getColumns = (
  partnerBook: { columns: Record<string, string> },
  index: number,
  offset: number
): string[] =>
  Object.values(partnerBook.columns)
    .slice(0, index + offset + 1)
    .map((column) => i18n.t(column));

export const getXLSData = (partnerBook: any, date: string) => {
  const index = getDateIndex(Object.values(partnerBook.dates), date);
  const lastOperation: string = Object.values(partnerBook.operations)[
    index
  ] as string;

  const offset = getOperationOffset(lastOperation);

  const xlsData = [];

  const headers = getHeaders(partnerBook, index, offset);
  xlsData.push(headers);

  const columns = getColumns(partnerBook, index, offset);
  xlsData.push(columns);

  const operations = getOperations(partnerBook, index, offset);

  Object.values(partnerBook.partners).forEach((partner: any) => {
    const partnerData = partner
      .slice(0, index + offset + 1)
      .map((value: string | number) => {
        if (typeof value === 'number') {
          return value === 0 ? '' : parseFloat(value.toFixed(2));
        }
        return value;
      });
    xlsData.push(partnerData);
  });

  return { xlsData, operations };
};
