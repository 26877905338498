export default {
  PARTNER: 'PARTNER',
  BENEFICIARY: 'BENEFICIARY',
  SOCIETY: 'SOCIETY',
  TENDER_SELLER: 'TENDER_SELLER',
  TENDER_BUYER: 'TENDER_BUYER',
  EMAIL_REQUEST: 'EMAIL_REQUEST',
  BOARD_ASSISTANT: 'BOARD_ASSISTANT',
  BOARD_PRESIDENT: 'BOARD_PRESIDENT',
  BOARD_SECRETARY: 'BOARD_SECRETARY',
  COUNCIL_PRESIDENT: 'COUNCIL_PRESIDENT',
  COUNCIL_SECRETARY: 'COUNCIL_SECRETARY',
};
