// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react';
import { Dropdown } from 'react-bootstrap';

type Props = {
  toggleOptions: React.ReactNode[];
};

interface SettingsToggleProps extends React.HTMLProps<HTMLDivElement> {
  // eslint-disable-next-line no-unused-vars
  onClick: (e: React.MouseEvent) => void;
}

const ToggleMenu: FC<Props> = ({ toggleOptions }) => {
  const SettingsToggle = React.forwardRef<HTMLDivElement, SettingsToggleProps>(
    ({ children, onClick }, ref) => (
      <div
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="dropdown-toggle btn-trigger mr-lg-n1"
      >
        {children}
        <em className="icon ni ni-more-h fs-22px" />
      </div>
    )
  );

  SettingsToggle.displayName = 'SettingsToggle';

  return (
    <Dropdown className="pr-0" drop="left">
      <Dropdown.Toggle as={SettingsToggle} />
      <Dropdown.Menu title="" className="link-list-opt no-bdr dropdown-menu">
        {toggleOptions}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ToggleMenu;
