import { FC } from 'react';

type Props = {
  index: number;
  encumbrance: string;
  // eslint-disable-next-line no-unused-vars
  handleRemoveEncumbrance: (index: number) => void;
};

const EncumbrancesTableRow: FC<Props> = ({
  index,
  encumbrance,
  handleRemoveEncumbrance,
}) => (
  <>
    <td className="text-left" key={`encumbrance-${index}`}>
      {encumbrance}
    </td>

    <td className="text-center" key={`encumbrance-remove-${index}`}>
      <button
        type="button"
        className="text-soft btn btn-icon pt-0 pb-0"
        onClick={() => handleRemoveEncumbrance(index)}
      >
        <em className="icon ni ni-trash" />
      </button>
    </td>
  </>
);

export default EncumbrancesTableRow;
