import { format } from 'date-fns';

import { Realty } from 'types';
import { TranslateFn } from 'hooks/useTranslate';

import { getFullAddress } from 'utils/filters';
import { formatNumberDecimals } from 'utils/formats';
import { DATE_FORMAT, DEFAULT_CURRENCY } from 'constants/defaultConstants';
import {
  getLicensesDescription,
  getTitlesDescription,
} from './getSummaryDetailsList';

export const getPersonalDetailsList = (realty: Realty, t: TranslateFn) => [
  {
    label: t('StartDate'),
    value: realty.startDate
      ? format(new Date(realty.startDate), DATE_FORMAT)
      : t('NotAvailable'),
  },
  { label: t('TotalArea'), value: `${realty.area.total} m2` },
  { label: t('BuiltArea'), value: `${realty.area.built} m2` },
  { label: t('Location'), value: getFullAddress(realty.location) },
  { label: t('Lessee'), value: realty.lessee },
  { label: t('Owner'), value: realty.ownership },
  { label: t('Title'), value: getTitlesDescription(realty.titles) },
  { label: t('Usage'), value: realty.usage },
  { label: t('Encumbrances'), value: realty.encumbrances.join(', ') },
  {
    label: t('Licenses'),
    value: Object.values(getLicensesDescription(realty.licenses)).join('\n\n'),
  },
  {
    label: t('SalePrice'),
    value: realty?.salePrice?.amount
      ? `${formatNumberDecimals(realty.salePrice.amount)} ${
          realty?.salePrice?.currency || DEFAULT_CURRENCY
        }`
      : '-',
  },
  {
    label: t('CadastralValue'),
    value: `${formatNumberDecimals(realty?.cadastralValue?.amount || '-')} ${
      realty.cadastralValue?.currency || DEFAULT_CURRENCY
    }`,
  },
  {
    label: t('References'),
    value: `${t('Cadastral')}: ${realty?.references?.cadastral || '-'}, ${t(
      'Registry'
    )}: ${realty?.references?.registry || '-'}`,
  },
  {
    label: t('LastUpdated'),
    value: format(new Date(realty.lastUpdated), DATE_FORMAT),
  },
];
