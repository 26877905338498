import { Editor } from 'ckeditor5';

type Props = {
  editorInstance: Editor | null;
  // eslint-disable-next-line no-unused-vars
  handleChangeIsEditing: (value: boolean) => void;
};

export const editTemplate = ({
  editorInstance,
  handleChangeIsEditing,
}: Props) => {
  if (editorInstance) {
    editorInstance.disableReadOnlyMode('readOnly');

    handleChangeIsEditing(true);
  }
};
