import {
  ContentTypes,
  DocumentType,
} from 'modules/administratives/pages/CouncilMembers/components/CouncilMembersDocumentsCard';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { Document } from 'types';

type TemplateViewerBodyProps = {
  template: Document;
  contentType: DocumentType;
};

const TemplateViewerBody: FC<TemplateViewerBodyProps> = ({
  template,
  contentType,
}) => {
  const communication = template.editorModel;

  const pageWrapperStyle =
    contentType === ContentTypes.EMAIL
      ? 'w-100 p-0 bg-white shadow-lg m-5'
      : 'w-100 p-5 bg-white shadow-lg m-5';

  return (
    <>
      <Modal.Body className="flex-1 relative p-0">
        <div className="absolute inset-0 bg-lighter">
          <div className="absolute left-0 right-0 top-0 bottom-0 d-flex items-center justify-center overflow-auto">
            <div className={pageWrapperStyle}>
              <p
                style={{
                  whiteSpace:
                    typeof communication === 'string' ? 'pre-line' : 'normal',
                }}
              >
                {communication || ''}
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default TemplateViewerBody;
