import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import { hasShowDetails } from 'utils/showDetails';

import MenuItem from './MenuItem';

import '../SideNav.scss';

type Props = {
  societyId: string;
  isDirector: boolean;
  rolesValidation: Record<string, boolean>;
};

const AdministrativesMenu: FC<Props> = ({
  societyId,
  isDirector,
  rolesValidation,
}) => {
  const { t } = useTranslate();

  const hasMembersDisabled = !!(isDirector && rolesValidation?.isDirectorOnly);

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="10">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-network" />
            </span>
            <span className="nk-menu-text">{t('Governance')}</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="10">
        <ul className="nk-menu-sub">
          {!hasMembersDisabled && (
            <>
              <MenuItem
                linkTo={`/organo-admin/${societyId}`}
                text="AdminOrgan"
              />
              <MenuItem linkTo={`/consejos/${societyId}`} text="Councils" />
              <MenuItem linkTo={`/comites/${societyId}`} text="Committees" />
              <MenuItem
                linkTo={`/apoderados/${societyId}`}
                text="Representatives"
              />
              <MenuItem linkTo={`/auditores/${societyId}`} text="Auditors" />
            </>
          )}
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default AdministrativesMenu;
