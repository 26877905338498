import { FC } from 'react';

import { Envelope } from 'types';

import signatureTypes from 'constants/signatureTypes';

import EnvelopeSignatureStatus from 'modules/_shared/components/EnvelopeSignatureStatus';
import useGetSignersFromType from 'modules/_shared/components/EnvelopeSignatureStatus/hooks/useGetSignersFromType';
import useGetEnvelopeFromType from 'modules/_shared/components/EnvelopeSignatureStatus/hooks/useGetEnvelopeFromType';
import { useGetBoardsSectionPermissions } from 'modules/boards/hooks/useGetBoardsSectionPermissions';
import EnvelopeSignatureLoading from 'modules/_shared/components/EnvelopeSignatureStatus/components/EnvelopeSignatureStatusLoading';

type Props = {
  boardEnvelopes: Envelope[];
};

const SignatureViewAct: FC<Props> = ({ boardEnvelopes }) => {
  const { isReadOnly } = useGetBoardsSectionPermissions().data;

  const { signers, isLoading, refetchSigners } = useGetSignersFromType(
    boardEnvelopes,
    signatureTypes.BOARD_ACT
  );

  const { envelope } = useGetEnvelopeFromType(
    boardEnvelopes,
    signatureTypes.BOARD_ACT
  );

  const sentDate = envelope ? new Date(envelope.creationDate) : undefined;

  return (
    <>
      {!isLoading ? (
        <EnvelopeSignatureStatus
          signers={signers}
          sentDate={sentDate}
          isReadOnly={isReadOnly}
          refetchSigners={refetchSigners}
        />
      ) : (
        <EnvelopeSignatureLoading />
      )}
    </>
  );
};

export default SignatureViewAct;
