import { updateSociety } from 'modules/society/redux/societyActions';
import { store } from 'redux/store';

export const removeAdminMember = async (
  societyId: string,
  memberId?: string,
  email?: string
) => {
  const newSocietyData = {
    directors: {
      delete: {
        directorId: memberId,
        email,
      },
    },
  };
  await store.dispatch(updateSociety(societyId, newSocietyData, false));
};
