import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Realty, Society } from 'types';

import DetailsHeaderSection from 'modules/_shared/components/Sections/DetailsHeaderSection';
import DetailsContentSection from 'modules/_shared/components/Sections/DetailsContentSection';

type RealtyDetailsDocumentsTabProps = {
  realty: Realty;
  society: Society;
};

const RealtyDetailsDocumentsTab: FC<RealtyDetailsDocumentsTabProps> = ({
  realty,
  society,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <DetailsHeaderSection
        title={t('Documents')}
        description={t('RealtyDocumentation', {
          name: realty.name,
          societyName: society.name,
        })}
      />

      <DetailsContentSection>
        <div className="alert alert-icon alert-primary" role="alert">
          <em className="icon ni ni-alert-circle" />
          <strong>{t('ComingSoon')}!</strong>{' '}
          {t('RealtyDocumentsTabComingSoon')}
        </div>
      </DetailsContentSection>
    </>
  );
};

export default RealtyDetailsDocumentsTab;
