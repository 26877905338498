import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { Council } from 'types';
import sizes from 'constants/sizes';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updateCouncil } from 'modules/administratives/redux/councilActions';
import EditAnnouncementModal from 'modules/_shared/components/Modals/EditAnnouncement';
import AnnouncementTemplateViewer, {
  AnnouncementType,
} from 'modules/_shared/components/Modals/AnnouncementTemplateViewer';

type Props = {
  council: Council;
};

const SendStatusCard: FC<Props> = ({ council }: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const isReadOnly = false; // TODO: Implemet permission

  const { announcementCommunication, announcementText } = council;

  const statusClass = announcementCommunication
    ? 'bg-outline-success'
    : 'bg-outline-warning';

  const statusLabel = announcementCommunication ? t('NOTIFIED') : t('Pending');

  const handleViewCommunication = () => {
    dispatch(
      setModal(
        <AnnouncementTemplateViewer
          meeting={council}
          announcementType={AnnouncementType.COUNCIL}
          size={sizes.XL}
        />
      )
    );
  };

  const handleEditAnnouncement = () => {
    dispatch(
      setModal(
        <EditAnnouncementModal
          description={t('CouncilCommunicationTextDescription')}
          source={council}
          onSave={(announcement) =>
            dispatch(
              updateCouncil({
                ...council,
                announcementText: announcement,
              })
            )
          }
        />
      )
    );
  };

  return (
    <div className="card card-bordered card-top d-none d-xl-block">
      <div className="nk-help-plain card-inner text-left">
        <div className="sp-plan-action card-inner">
          <div className="card-title-group">
            <div className="sp-plan-head">
              <h6 className="title">
                {t('SendState')}:{' '}
                <span className={`badge rounded-pill ${statusClass}`}>
                  {statusLabel}
                </span>{' '}
              </h6>
              <button
                type="button"
                className="link text-primary mb-2"
                style={{ paddingLeft: 0 }}
                onClick={handleViewCommunication}
              >
                {t('ViewComm')}
              </button>
              <em className="icon ni ni-external" />

              {announcementCommunication && (
                <>
                  {/* <Link
                      to={`/comunicaciones/${actualSociety['_id']}/${announcementComm}/certificacion`}
                      className={`${!announcementComm && 'is-disable'}`}
                    >
                      <span className="link text-primary">
                        {t('ViewSendCertificate')}
                      </span>{' '}
                    </Link> */}
                  <span className="link text-primary">
                    {t('ViewSendCertificate')}
                  </span>{' '}
                  <em className="icon ni ni-external" />
                </>
              )}

              <h6 className="title" style={{ marginTop: '10px' }}>
                {t('TextComm')}:{' '}
              </h6>

              <span
                className="ff-regular text-soft d-block w-200px"
                style={{ whiteSpace: 'pre-line' }}
              >
                {announcementText}
              </span>

              {!announcementCommunication && !isReadOnly && (
                <div className="sp-plan">
                  <button
                    type="button"
                    className="link text-primary"
                    style={{ paddingLeft: 0 }}
                    onClick={handleEditAnnouncement}
                    disabled={isReadOnly}
                  >
                    <span className="title">{t('Edit')}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendStatusCard;
