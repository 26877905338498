const directorTypes = (i18n) => ({
  var: {
    UNIQUE_ADMIN: 'UNIQUE_ADMIN',
    POOLED_ADMIN: 'POOLED_ADMIN',
    COUNSELOR: 'COUNSELOR',
    DELEGATE_COUNSELOR: 'DELEGATE_COUNSELOR',
    COUNCIL_SECRETARY: 'COUNCIL_SECRETARY',
    INDEPENDENT_COUNSELOR: 'INDEPENDENT_COUNSELOR',
    NON_EXECUTIVE_COUNSELOR: 'NON_EXECUTIVE_COUNSELOR',
    JOINT_DELEGATE_COUNSELOR: 'JOINT_DELEGATE_COUNSELOR',
    COUNCIL_PRESIDENT: 'COUNCIL_PRESIDENT',
    NON_COUNSELOR_VOWEL: 'NON_COUNSELOR_VOWEL',
    SOLIDARY_ADMIN: 'SOLIDARY_ADMIN',
    SECRETARY_NO_DIRECTOR: 'SECRETARY_NO_DIRECTOR',
    COUNCIL_VICE_PRESIDENT: 'COUNCIL_VICE_PRESIDENT',
    NON_DIRECTOR_DEPUTY_SECRETARY: 'NON_DIRECTOR_DEPUTY_SECRETARY',
    AUDITOR: 'AUDITOR',
    TREASURER: 'TREASURER',
  },
  name: {
    UNIQUE_ADMIN: i18n.t('UniqueAdmin'),
    POOLED_ADMIN: i18n.t('JointAdmin'),
    COUNSELOR: i18n.t('Adviser'),
    DELEGATE_COUNSELOR: i18n.t('CEO'),
    COUNCIL_SECRETARY: i18n.t('BoardSecretary'), // secretario del consejo
    INDEPENDENT_COUNSELOR: i18n.t('IndependentDirector'),
    NON_EXECUTIVE_COUNSELOR: i18n.t('NonExecutiveCounselor'),
    JOINT_DELEGATE_COUNSELOR: i18n.t('JointDelegateCounselor'),
    COUNCIL_PRESIDENT: i18n.t('PresidentCouncil'), // presidente del consejo
    NON_COUNSELOR_VOWEL: i18n.t('NonDirectorMember'),
    SOLIDARY_ADMIN: i18n.t('SolidaryAdmin'),
    SECRETARY_NO_DIRECTOR: i18n.t('SecretaryNoDirector'), // secretario (en 2da opción)
    COUNCIL_VICE_PRESIDENT: i18n.t('VicePresidentCouncil'),
    NON_DIRECTOR_DEPUTY_SECRETARY: i18n.t('NonDirectorDeputySecretary'),
    AUDITOR: i18n.t('Auditor'),
    TREASURER: i18n.t('Treasurer'),
  },
});
export default directorTypes;
