/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import NumberFormat from 'react-number-format';
import { titleColumn } from '../constants/partnerBookConstants';

type PartnerBookTableFooterProps = {
  bookTotalPartners: number;
  numberFormat: any;
  bookColumns: any;
  bookTotals: any;
  bookOrder: any;
  isForScreen: boolean;
};

type FormattedCellProps = {
  value: any;
  isPercentage: boolean;
  isForScreen: boolean;
  numberFormat: any;
};

const FormattedCell = ({
  value,
  isPercentage,
  isForScreen,
  numberFormat,
}: FormattedCellProps) => {
  if (value === '') return null;

  if (isPercentage) {
    const formattedValue = parseFloat(value).toFixed(2);
    return isForScreen ? `${formattedValue}%` : formattedValue;
  }

  return isForScreen ? (
    <NumberFormat displayType="text" value={value} {...numberFormat} />
  ) : (
    value
  );
};

const PartnerBookTableFooter: FC<PartnerBookTableFooterProps> = ({
  bookTotals,
  bookColumns,
  bookTotalPartners,
  bookOrder,
  numberFormat,
  isForScreen,
}) => {
  const { t } = useTranslate();

  return (
    <tfoot>
      <tr
        className={
          !(bookTotalPartners % 2 === 0)
            ? 'bg-light font-weight-bold'
            : 'font-weight-bold'
        }
      >
        {Object.entries(bookTotals)?.map(([, total], index) => {
          if (index === 0) {
            return (
              <td key={`${total}-${index}`} className="w-200px">
                {t('TOTAL')}
              </td>
            );
          }

          const isEvenColumn = bookOrder[index] % 2 === 0;
          const isPercentColumn = bookColumns[index] === titleColumn.PERCENT;

          return (
            <td
              key={`${total}-${index}`}
              className={isEvenColumn ? 'bg-light-vert' : ''}
            >
              <FormattedCell
                value={total}
                isPercentage={isPercentColumn}
                isForScreen={isForScreen}
                numberFormat={numberFormat}
              />
            </td>
          );
        })}
      </tr>
    </tfoot>
  );
};

export default PartnerBookTableFooter;
