import { useTranslate } from 'hooks/useTranslate';
import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Tooltip } from 'react-tooltip';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import userTypes from 'constants/userTypes';
import { State } from 'redux/initialState';
import categoryTypes from '../constants/categoryTypes';
import extraEmailsAllowed from '../constants/extraEmailsAllowed';
import rolesWithEmployeeNumber from '../constants/rolesWithEmployeeNumber';
import rolesWithFiscalIdentification from '../constants/rolesWithFiscalIdentification';
import rolesWithMultipleCategories from '../constants/rolesWithMultipleCategories';
import rolesWithMultipleEmails from '../constants/rolesWithMultipleEmails';

type EmailCategory = 'PRIMARY' | 'SECONDARY';

type Props = {
  page: string;
  birthdate: string;
  cif: string;
  civilStatus: string;
  civilStatusOptionValue: any;
  currentEmail: string;
  email: string;
  emails: string[];
  categories: EmailCategory[];
  employeeNumber: string;
  fileSelected: (event: React.ChangeEvent<HTMLInputElement>) => void; // eslint-disable-line no-unused-vars
  handleChangeCivilStatus: (value: any) => void; // eslint-disable-line no-unused-vars
  handleChangeSelectedPartner: (value: any) => void; // eslint-disable-line no-unused-vars
  handlePartnerCategoryOptionChange: (value: any) => void; // eslint-disable-line no-unused-vars
  hasPersonOptions: () => boolean;
  image: string;
  name: string;
  nationality: string;
  naturalPerson: boolean;
  partnerCategoriesOptions: any;
  phone: string;
  selectedPartnerCategoryOption: any;
  setBirthdate: (value: string) => void; // eslint-disable-line no-unused-vars
  setCategories: (value: EmailCategory[]) => void; // eslint-disable-line no-unused-vars
  setCif: (value: string) => void; // eslint-disable-line no-unused-vars
  setCurrentEmail: (value: string) => void; // eslint-disable-line no-unused-vars
  setEmail: (value: string) => void; // eslint-disable-line no-unused-vars
  setEmails: (value: string[]) => void; // eslint-disable-line no-unused-vars
  setEmployeeNumber: (value: string) => void; // eslint-disable-line no-unused-vars
  setName: (value: string) => void; // eslint-disable-line no-unused-vars
  setNationality: (value: string) => void; // eslint-disable-line no-unused-vars
  setPhone: (value: string) => void; // eslint-disable-line no-unused-vars
  sindicationOptionValue: any;
  sindicationPartnerOptions: any;
  user: any;
  userType: string;
};

const EditPersonalInformationForm = ({
  page,
  birthdate,
  cif,
  civilStatus,
  civilStatusOptionValue,
  currentEmail,
  email,
  emails,
  categories,
  employeeNumber,
  fileSelected,
  handleChangeCivilStatus,
  handleChangeSelectedPartner,
  handlePartnerCategoryOptionChange,
  hasPersonOptions,
  image,
  name,
  nationality,
  naturalPerson,
  partnerCategoriesOptions,
  phone,
  selectedPartnerCategoryOption,
  setBirthdate,
  setCategories,
  setCif,
  setCurrentEmail,
  setEmail,
  setEmails,
  setEmployeeNumber,
  setName,
  setNationality,
  setPhone,
  sindicationOptionValue,
  sindicationPartnerOptions,
  user,
  userType,
}: Props) => {
  const { t } = useTranslate();

  const ALLOWED_IMAGE_TYPES = 'image/jpeg, image/png';

  const isAdmin = useSelector((state: State) => state?.society?.role?.isAdmin);
  const loggedUser = useSelector((state: State) => state.user);

  const animatedComponents = makeAnimated();

  const handleRemoveEmail = (index: number) => {
    const newEmails = [...emails];
    const newCategories = [...categories];

    newEmails[index] = currentEmail;
    newEmails.splice(index, 1);
    newCategories.splice(index, 1);

    if (index === emails.length - 1) {
      setCurrentEmail(newEmails[newEmails.length - 1]);
    }

    setEmails(newEmails);
    setCategories(newCategories);
  };

  const handleAddEmail = () => {
    const newEmails = [...emails];
    const newCategories: EmailCategory[] = [...categories];

    if (emails.length > 0) {
      newEmails[newEmails.length - 1] = currentEmail;
    }
    newEmails.push('');
    newCategories.push(categoryTypes.PRIMARY as EmailCategory);
    setCurrentEmail('');
    setEmails(newEmails);
    setCategories(newCategories);
  };

  const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    const index = parseInt(name.split('email-category-')[1], 10);
    const newCategories = [...categories];

    newCategories[index] = checked
      ? (categoryTypes.PRIMARY as EmailCategory)
      : (categoryTypes.SECONDARY as EmailCategory);

    setCategories(newCategories);
  };

  const isValidEmail = (email: string) => {
    if (email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }
  };

  return (
    <div className={`tab-pane ${page || 'active'}`}>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="full-name">
              {user?.isNaturalPerson || !hasPersonOptions()
                ? t('Name')
                : t('SocialDenomination')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder={t('IntroduceFullName')}
            />
          </div>
        </div>

        <div className="col-md-6">
          {rolesWithFiscalIdentification.includes(userType) ? (
            <div className="form-group">
              <label className="form-label" htmlFor="partner-name">
                {t('FiscalIdentification')}
              </label>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <em className="icon ni ni-cc-alt2" />
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  value={cif}
                  onChange={(event) => setCif(event.target.value)}
                  placeholder={t('EnterTheCifOrNif')}
                  required
                />
              </div>
            </div>
          ) : (
            <div className="form-group">
              <label className="form-label w-100" htmlFor="phone-no">
                {t('Telephone')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="phone-no"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                placeholder={t('IntroducePhoneNumber')}
              />
            </div>
          )}
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="birth-day">
              {t('MainEmail')}
              <em
                className="icon ni ni-info text-gray ml-2"
                id="modal-email-category-main-info"
              />
              <Tooltip
                anchorId="modal-email-category-main-info"
                place="right"
                style={{
                  zIndex: 9999,
                }}
              >
                {t('EmailCategoryMainInfo')}
              </Tooltip>
            </label>

            <input
              type="email"
              className="form-control form-control-lg"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              disabled={
                !isAdmin &&
                userType === userTypes.PARTNER &&
                loggedUser['_id'] !== user?.user
              }
              placeholder={t('IntroduceNewEmail')}
              pattern={EMAIL_PATTERN}
            />
          </div>

          {emails?.map((email: string, index: number) => (
            <React.Fragment key={email}>
              <div className="form-control-wrap mb-3">
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    value={index + 1 === emails.length ? currentEmail : email}
                    onChange={(event) => setCurrentEmail(event.target.value)}
                    placeholder={t('IntroduceNewEmail')}
                    pattern={EMAIL_PATTERN}
                  />

                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-dim px-1 mr-2"
                      style={{
                        zIndex: 0,
                      }}
                      onClick={() => handleRemoveEmail(index)}
                    >
                      <em className="icon ni ni-minus" />
                    </button>

                    {rolesWithMultipleCategories.includes(userType) && (
                      <div className="custom-control custom-checkbox custom-control-sm align-items-center">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name={`email-category-${index}`}
                          id={`email-category-${index}`}
                          value={categories[index]}
                          checked={categories[index] === categoryTypes.PRIMARY}
                          onChange={handleChangeCategory}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`email-category-${index}`}
                        >
                          <span>{t('Primary')}</span>
                          <em
                            className="icon ni ni-info text-gray ml-2"
                            id={`email-category-info-${index}`}
                          />
                          <Tooltip
                            anchorId={`email-category-info-${index}`}
                            place="top"
                            style={{
                              zIndex: 9999,
                            }}
                          >
                            <p>
                              {`${t('Primary')}: ${t(
                                'EmailCategoryPrimaryInfo'
                              )}`}{' '}
                              <br />
                              {`${t('Secondary')}: ${t(
                                'EmailCategorySecondaryInfo'
                              )}`}
                            </p>
                          </Tooltip>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}

          {rolesWithMultipleEmails.includes(userType) &&
            emails.length + 1 <= extraEmailsAllowed[userType] && (
              <button
                type="button"
                className="btn btn-lg btn-dim btn-outline-primary w-100"
                disabled={emails.length > 0 && !isValidEmail(currentEmail)}
                onClick={handleAddEmail}
              >
                <em className="icon ni ni-plus" />
                <span>{t('AddAnotherEmail')}</span>
              </button>
            )}
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="birth-day">
              {user?.isNaturalPerson || !hasPersonOptions()
                ? t('DateOfBirth')
                : t('ConstitutionDate')}
            </label>
            <input
              type="date"
              className="form-control form-control-lg date-picker"
              value={birthdate}
              onChange={(event) => setBirthdate(event.target.value)}
              placeholder={t('IntroduceBirthDate')}
              max="2100-01-01"
              min="1000-01-01"
            />
          </div>
        </div>

        {rolesWithEmployeeNumber.includes(userType) && (
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="employee-number">
                {t('EmployeeNumber')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                  value={employeeNumber}
                  onChange={(event) => setEmployeeNumber(event.target.value)}
                  placeholder={t('IntroduceEmployeeNumber')}
                />
              </div>
            </div>
          </div>
        )}

        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="nationality">
              {t('Nationality')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
              value={nationality}
              onChange={(event) => setNationality(event.target.value)}
              placeholder={t('IntroduceNationality')}
            />
          </div>
        </div>

        {userType !== userTypes.USER && userType !== userTypes.ADMIN && (
          <>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="phone-no">
                  {t('Telephone')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                  id="phone-no"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  placeholder={t('IntroducePhoneNumber')}
                />
              </div>
            </div>
            {userType === userTypes.PARTNER && (
              <>
                {isAdmin && (
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="default-01">
                      {t('Category')}
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        closeMenuOnSelect
                        className="react-select react-select-lg"
                        value={selectedPartnerCategoryOption}
                        options={partnerCategoriesOptions}
                        components={animatedComponents}
                        onChange={handlePartnerCategoryOptionChange}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="sindication-shares"
                    >
                      {t('SindicationShares')}
                    </label>

                    <div className="form-control-wrap">
                      <Select
                        closeMenuOnSelect
                        value={sindicationOptionValue}
                        className="react-select react-select-lg"
                        options={sindicationPartnerOptions}
                        components={animatedComponents}
                        onChange={handleChangeSelectedPartner}
                      />
                    </div>
                  </div>
                </div>
                {naturalPerson && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label w-100"
                        htmlFor="sindication-shares"
                      >
                        {t('CivilStatus')}
                      </label>

                      <div className="form-control-wrap">
                        <Select
                          closeMenuOnSelect
                          value={civilStatusOptionValue}
                          className="react-select react-select-lg"
                          options={Object.entries(civilStatus).map(
                            ([, value]) => ({
                              value,
                              label: t(value),
                            })
                          )}
                          components={animatedComponents}
                          onChange={handleChangeCivilStatus}
                          placeholder={t('SelectCivilStatus')}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div className="col-md-3 col-8">
          <div className="form-group">
            <label className="form-label" htmlFor="imageInput">
              {t('Image')}
            </label>
            <input
              type="file"
              id="imageInput"
              style={{ display: 'none' }}
              onChange={fileSelected}
              accept={ALLOWED_IMAGE_TYPES}
            />
            <label
              htmlFor="imageInput"
              className="btn btn-primary form-control-lg"
            >
              {t('Choose')}
            </label>
          </div>
        </div>
        <div className="col-md-3 col-4">
          <div
            className="user-avatar mx-auto lg bg-primary-dim"
            style={{
              maxWidth: '80px',
              maxHeight: '80px',
            }}
          >
            {image ? (
              <img
                id="user-image"
                src={image}
                alt={name}
                style={{
                  maxWidth: '80px',
                  maxHeight: '80px',
                }}
              />
            ) : (
              <span>{name ? name.slice(0, 2).toUpperCase() : ''}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPersonalInformationForm;
