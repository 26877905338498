/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

import EncumbrancesTableRow from './EncumbrancesTableRow';

type Props = {
  encumbrances: string[];
  // eslint-disable-next-line no-unused-vars
  handleRemoveEncumbrance: (index: number) => void;
};

const EncumbrancesTableBody: FC<Props> = ({
  encumbrances,
  handleRemoveEncumbrance,
}) => (
  <>
    <tbody>
      {encumbrances?.map((encumbrance: string, index: number) => (
        <tr className="nk-tb-item" key={`encumbrance-row-${index}`}>
          <EncumbrancesTableRow
            index={index}
            encumbrance={encumbrance}
            handleRemoveEncumbrance={handleRemoveEncumbrance}
          />
        </tr>
      ))}
    </tbody>
  </>
);

export default EncumbrancesTableBody;
