import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Tooltip } from 'react-tooltip';

type Props = {
  page: number;
  handleChangePage: (page: number) => void; // eslint-disable-line
};

type NavigationOption = {
  key: string;
  title: string;
  enabled: boolean;
  tooltip?: boolean;
};

const getTabStyle = (page: number, option: NavigationOption, index: number) => {
  const baseStyle = 'nav-link';
  const activeStyle = page === index ? 'active' : '';
  const disabledStyle = option.enabled ? '' : 'disabled text-muted';

  return `${baseStyle} ${activeStyle} ${disabledStyle}`;
};

const CouncilDetailsNavigationHeader: FC<Props> = ({
  page,
  handleChangePage,
}: Props) => {
  const { t } = useTranslate();

  const navigationOptions: NavigationOption[] = [
    { key: 'announcement', title: t('Announcement'), enabled: true },
    {
      key: 'attendance',
      title: t('Attendance'),
      enabled: true,
    },
    { key: 'act', title: t('Act'), enabled: true },
    { key: 'certification', title: t('Certification'), enabled: true },
    { key: 'signatures', title: t('Signatures'), enabled: true },
    { key: 'documents', title: t('Documents'), enabled: true },
  ];

  return (
    <ul className="nk-nav nav nav-tabs">
      {navigationOptions.map((option, index) => (
        <li
          key={option.key}
          className={`nav-item ${option.tooltip ? 'tab-with-tooltip' : ''}`}
        >
          <p
            className={getTabStyle(page, option, index)}
            onClick={() => handleChangePage(index)}
          >
            <span>{option.title}</span>
          </p>
        </li>
      ))}

      <Tooltip anchorSelect=".tab-with-tooltip" content={t('TabComingSoon')} />
    </ul>
  );
};

export default CouncilDetailsNavigationHeader;
