import { FC } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { dateFormat } from 'constants/formats';

import { SocietyDirector } from 'types';

import { useTranslate } from 'hooks/useTranslate';

import CellData from 'modules/_shared/components/Tables/CellData';
import DocumentsColumn from 'modules/_shared/components/DocumentsColumn';
import ToggleMenu from 'modules/_shared/components/ToggleMenu';
import { setModal } from 'modules/_shared/redux/modalActions';
import AddSocietyDirector from 'modules/_shared/components/Modals/AddSocietyDirector';

import Swal from 'sweetalert2';
import successSwal from 'utils/successSwal';

import useGetAdminMemberDocuments from '../hook/useGetAdminMemberDocuments';
import useGetSelectedValues from '../hook/useGetSelectedValues';
import { removeAdminMember } from '../utils/removeAdminMember';

type Props = {
  index: number;
  societyId: string;
  member: SocietyDirector;
  isReadOnly?: boolean;
};

const AdminMemberTableRow: FC<Props> = ({
  index,
  societyId,
  member,
  isReadOnly,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);

  const memberId = member?._id;
  const memberName = member?.socialDenomination || '-';
  const memberImage = member?.image;
  const memberEmail = member?.email;
  const memberInitDate = member?.initDate;
  const memberEndDate = member?.endDate;

  const { memberStatusLabel, memberStatusBadge, memberTypeLabel } =
    useGetSelectedValues({ member }).data;

  const { memberDocuments } = useGetAdminMemberDocuments({
    societyId,
    memberId,
  }).data;

  const handleRemoveAdminMember = () => {
    Swal.fire({
      icon: 'warning',
      title: `<h4 className="nk-block-title page-title">${t(
        'AreYouSureAboutDelMember'
      )}</h4>`,
      html: `<h3 class="text-primary fw-normal">${memberName} </h3><br /><div class="caption-text">${t(
        'ThisProcedureCantUndo'
      )}</div>`,
      confirmButtonText: t('OkDelete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await removeAdminMember(societyId, memberId, memberEmail);
        successSwal(t('MemberSuccessfullyRemoved'));
      }
    });
  };

  const handleEditAdminMember = () => {
    const directorData = {
      socialDenomination: memberName,
      cif: member?.cif,
      nationality: member?.nationality,
      birthDate: member?.birthDate,
      address: member?.address,
      email: memberEmail,
      directorType: member?.directorType,
      initDate: memberInitDate,
      endDate: memberEndDate,
      documents: memberDocuments,
      isNaturalPerson: member?.isNaturalPerson,
      representative: member?.representative,
      image: memberImage,
    };

    dispatch(
      setModal(
        <AddSocietyDirector
          societyId={societyId}
          action="EDIT"
          directorData={directorData}
          directorId={memberId}
          userId={user['_id']}
        />
      )
    );
  };

  const toggleOptions = [
    <a
      className="d-flex cursor-pointer"
      key={`member-edit-option-${index}`}
      onClick={handleEditAdminMember}
    >
      <em className="icon ni ni-edit fs-20px" />
      <span className="ml-1">{t('Edit')}</span>
    </a>,
    <a
      className="d-flex cursor-pointer"
      key={`member-delete-option-${index}`}
      onClick={handleRemoveAdminMember}
    >
      <em className="icon ni ni-trash fs-20px" />
      <span className="ml-1">{t('Delete')}</span>
    </a>,
  ];

  return (
    <>
      <td className="nk-tb-col" key={`member-name-${index}`}>
        <span className="text-primary font-weight-bold cursor-pointer">
          {CellData.Adminstrative({
            name: memberName,
            image: memberImage,
            email: memberEmail,
            url: `/detalle-miembro/${societyId}/${memberId}`,
          })}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl" key={`member-initdate-${index}`}>
        <span>
          {memberInitDate ? format(new Date(memberInitDate), dateFormat) : '-'}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl" key={`member-enddate-${index}`}>
        <span>
          {memberEndDate
            ? format(new Date(memberEndDate), dateFormat)
            : t('Indefinite')}
        </span>
      </td>

      <td className="nk-tb-col" key={`member-type-${index}`}>
        <span>{t(memberTypeLabel)}</span>
      </td>

      <td className="nk-tb-col" key={`member-status-${index}`}>
        <span className={`badge badge-dot badge-${memberStatusBadge}`}>
          {t(memberStatusLabel)}
        </span>
      </td>

      <DocumentsColumn
        index={index}
        documentsCount={memberDocuments?.length || 0}
        documents={memberDocuments}
      />

      {!isReadOnly && (
        <td className="nk-tb-col" key={`member-options-${index}`}>
          <ToggleMenu toggleOptions={toggleOptions} />
        </td>
      )}
    </>
  );
};

export default AdminMemberTableRow;
