import i18n from 'i18n/config';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import { Society } from 'types';
import { translate } from 'hooks/useTranslate';
import { getSelectorOptions } from './getSelectorOptions';

const presidentType = directorTypes(i18n).var.COUNCIL_PRESIDENT;
const secretaryType = directorTypes(i18n).var.COUNCIL_SECRETARY;
const secretaryTypeAlt = directorTypes(i18n).var.SECRETARY_NO_DIRECTOR;

export const getExistentPresidentAndSecretary = (society: Society) => {
  const { directorOptions } = getSelectorOptions(society, translate);

  const president = society.directors.find(
    (director) => director.directorType === presidentType
  );

  const secretary = society.directors.find(
    (director) =>
      director.directorType === secretaryType ||
      director.directorType === secretaryTypeAlt
  );

  const selectedPresidentOption = president
    ? directorOptions.find((option) => option.value === president._id)
    : null;

  const selectedSecretaryOption = secretary
    ? directorOptions.find((option) => option.value === secretary._id)
    : null;

  return {
    selectedPresidentOption,
    selectedSecretaryOption,
    president: selectedPresidentOption?.value || '',
    secretary: selectedSecretaryOption?.value || '',
  };
};
