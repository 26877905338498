import { translate } from 'hooks/useTranslate';
import { getUserSocieties } from 'modules/society/redux/societyActions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import {
  CommitteeMember,
  SocietyAuditor,
  SocietyDirector,
  SocietyRepresentative,
} from 'types';

type SocietyEntities =
  | 'directors'
  | 'representatives'
  | 'auditors'
  | 'committeeMembers';

type SocietyForSearch = {
  _id: string;
  name: string;
  directors: SocietyDirector[];
  representatives: SocietyRepresentative[];
  auditors: SocietyAuditor[];
  committeeMembers: CommitteeMember[];
};

const getUrlByRole = (
  role: string,
  societyId: string,
  groupId: string | null,
  memberId: string
) => {
  switch (role) {
    case 'directors':
      return `/detalle-miembro/${societyId}/${memberId}`;
    case 'representatives':
      return `/detalle-apoderado/${societyId}/${memberId}`;
    case 'auditors':
      return null;
    case 'committeeMembers':
      return `/detalle-miembro-comite/${societyId}/${groupId}/${memberId}`;
    default:
      return null;
  }
};

const getRoleLabel = (role: string) => {
  switch (role) {
    case 'directors':
      return translate('AdminOrgan');
    case 'representatives':
      return translate('Representative');
    case 'auditors':
      return translate('Auditor');
    case 'committeeMembers':
      return translate('CommitteeMember');
    default:
      return '';
  }
};

const getGroupIdFromItem = (role: SocietyEntities, item: any) => {
  if (role === 'committeeMembers') {
    return item.committee;
  }

  return null;
};

const parseEntityToTable = (
  entity: any,
  society: SocietyForSearch,
  role: SocietyEntities
) =>
  entity.map((item: any) => ({
    id: item._id,
    name: item?.socialDenomination || item?.name || '-',
    email: item?.email || '-',
    cif: 'cif' in item ? item.cif : item.nif,
    society: society.name,
    societyId: society._id,
    groupId: getGroupIdFromItem(role, item),
    role: getRoleLabel(role),
    link: getUrlByRole(
      role,
      society._id,
      getGroupIdFromItem(role, item),
      item._id
    ),
  }));

export const getEntitiesFromSocieties = (
  societies: SocietyForSearch[],
  entity: SocietyEntities
) => {
  const entities: Record<string, any>[] = [];

  societies?.forEach((society) => {
    if (!society?.[entity]) {
      return;
    }

    const currentEntity = society[entity];
    const parsedEntity = parseEntityToTable(currentEntity, society, entity);

    entities.push(...parsedEntity);
  });

  return entities;
};

export const useGlobalSearch = () => {
  const user = useSelector((state: State) => state.user);

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchUserSocieties = async () => {
    if (!user || !user?.societies?.administrated?.length) {
      return;
    }

    try {
      setIsLoading(true);
      setIsError(false);

      const societies = (await getUserSocieties(
        user['_id'],
        'admin',
        null,
        'search'
      )) as SocietyForSearch[];

      const directors = getEntitiesFromSocieties(societies, 'directors');
      const representatives = getEntitiesFromSocieties(
        societies,
        'representatives'
      );
      const auditors = getEntitiesFromSocieties(societies, 'auditors');
      const committeeMembers = getEntitiesFromSocieties(
        societies,
        'committeeMembers'
      );

      setData({
        directors,
        representatives,
        auditors,
        committeeMembers,
      });

      setIsLoading(false);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserSocieties();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    isLoading,
    isError,
  };
};
