import { FC } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { dateFormat } from 'constants/formats';

import { Committee } from 'types';

type Props = {
  index: number;
  committee: Committee;
};

const CommitteesTableRow: FC<Props> = ({ index, committee }) => {
  const societyId = committee?.society;
  const committeeId = committee?._id;

  return (
    <>
      <td className="nk-tb-col" key={`committee-name-${index}`}>
        <Link to={`/comite-miembros/${societyId}/${committeeId}`}>
          <span className="text-primary font-weight-bold cursor-pointer">
            {committee?.name}
          </span>
        </Link>
      </td>

      <td className="nk-tb-col tb-col-xl" key={`committee-members-${index}`}>
        <span>{committee?.members?.length || 0}</span>
      </td>

      <td className="nk-tb-col tb-col-xl" key={`committee-date-${index}`}>
        <span>
          {committee?.createdAt
            ? format(new Date(committee.createdAt), dateFormat)
            : '-'}
        </span>
      </td>
    </>
  );
};

export default CommitteesTableRow;
