import { useEffect, useState } from 'react';
import {
  Alignment,
  Autoformat,
  BlockQuote,
  Bold,
  CloudServices,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Base64UploadAdapter,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  ListProperties,
  MediaEmbed,
  Mention,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  PictureEditing,
  RemoveFormat,
  SpecialCharacters,
  SpecialCharactersEssentials,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Underline,
} from 'ckeditor5';
import { ImportWord, ExportWord, ExportPdf } from 'ckeditor5-premium-features';

import coreTranslations from '../translations/core-translations-es';
import premiumFeaturesTranslations from '../translations/premium-translations-es';

import getTags from '../utils/getTags';
import addHeaderClassesToHtml from '../utils/addHeaderClassesToHtml';
// import { TagsDropdownPlugin } from '../components/TagsDropdownPlugin';

type Props = {
  documentName: string;
  documentModel: string;
  enableMentions: boolean;
  tagsToShow?: any;
};

const getPlugins = (enableMentions: boolean) => {
  const plugins = [
    Alignment,
    Autoformat,
    BlockQuote,
    Bold,
    CloudServices,
    Essentials,
    ExportPdf,
    ExportWord,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    Heading,
    HorizontalLine,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImportWord,
    Base64UploadAdapter,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    ListProperties,
    MediaEmbed,
    PageBreak,
    Paragraph,
    PasteFromOffice,
    PictureEditing,
    RemoveFormat,
    SpecialCharacters,
    SpecialCharactersEssentials,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Underline,
  ];

  if (enableMentions) {
    // plugins.push(Mention, TagsDropdownPlugin);
    // @ts-ignore
    plugins.push(Mention);
  }

  return plugins;
};

const getToolbar = (enableMentions: boolean) => {
  const toolbar = {
    shouldNotGroupWhenFull: true,
    items: [
      'undo',
      'redo',
      '|',
      'importWord',
      'exportWord',
      'exportPdf',
      '|',
      'findAndReplace',
      'selectAll',
      '|',
      'link',
      'insertImage',
      'blockQuote',
      'mediaEmbed',
      'pageBreak',
      'horizontalLine',
      'specialCharacters',
      '|',
      'fontSize',
      'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'heading',
      '|',
      'removeFormat',
      '|',
      'alignment',
      '|',
      'bulletedList',
      'numberedList',
      'outdent',
      'indent',
    ],
  };

  // if (enableMentions) {
  //   toolbar.items.push('tags');
  // }

  return toolbar;
};

const exportConfig = {
  tokenUrl: process.env.REACT_APP_CKEDITOR_TOKEN_URL, // This expires 30 days after creation and its only for development.
  dataCallback: (editor: any) => {
    const editorData = editor.getData();
    const resultHtml = addHeaderClassesToHtml(editorData);
    return resultHtml;
  },
  stylesheets: [
    '/styles/CKEditor/ckeditor5.css',
    '/styles/CKEditor/ckeditor5-premium-features.css',
    '/styles/CKEditor/ckeditor5-content.css',
  ],
  converterOptions: {
    format: 'A4',
    margin_top: '20mm',
    margin_bottom: '20mm',
    margin_right: '12mm',
    margin_left: '12mm',
    page_orientation: 'portrait',
  },
};

const importConfig = {
  tokenUrl: process.env.REACT_APP_CKEDITOR_TOKEN_URL,
  stylesheets: [
    '/styles/CKEditor/ckeditor5.css',
    '/styles/CKEditor/ckeditor5-premium-features.css',
    '/styles/CKEditor/ckeditor5-content.css',
  ],
  converterOptions: {
    format: 'A4',
    margin_top: '20mm',
    margin_bottom: '20mm',
    margin_right: '12mm',
    margin_left: '12mm',
    page_orientation: 'portrait',
  },
};

const heading = {
  options: [
    {
      model: 'paragraph',
      title: 'Párrafo',
      class: 'ck-heading_paragraph',
    },
    {
      model: 'heading1',
      view: 'h1',
      title: 'Encabezado 1',
      class: 'ck-heading_heading1',
    },
    {
      model: 'heading2',
      view: 'h2',
      title: 'Encabezado 2',
      class: 'ck-heading_heading2',
    },
    {
      model: 'heading3',
      view: 'h3',
      title: 'Encabezado 3',
      class: 'ck-heading_heading3',
    },
    {
      model: 'heading4',
      view: 'h4',
      title: 'Encabezado 4',
      class: 'ck-heading_heading4',
    },
    {
      model: 'heading5',
      view: 'h5',
      title: 'Encabezado 5',
      class: 'ck-heading_heading5',
    },
    {
      model: 'heading6',
      view: 'h6',
      title: 'Encabezado 6',
      class: 'ck-heading_heading6',
    },
  ],
};

const fontFamily = {
  options: [
    'default',
    'Arial,Helvetica,sans-serif',
    'Courier New, Courier, monospace',
    'Georgia, serif',
    'Lucida Sans Unicode, Lucida Grande, sans-serif',
    'Tahoma, Geneva, sans-serif',
    'Times New Roman, serif',
    'Trebuchet MS, Helvetica, sans-serif',
    'Verdana, Geneva, sans-serif',
    'Ubuntu,Arial,sans-serif',
    'Ubuntu Mono, Courier New, Courier,monospace',
    'Comic Sans MS,Comic Sans MS,cursive',
  ],
  supportAllValues: true,
};

const image = {
  toolbar: [
    'imageTextAlternative',
    'toggleImageCaption',
    '|',
    'imageStyle:inline',
    'imageStyle:wrapText',
    'imageStyle:breakText',
  ],
};

const table = {
  contentToolbar: [
    'tableColumn',
    'tableRow',
    'mergeTableCells',
    'tableProperties',
    'tableCellProperties',
    'toggleTableCaption',
  ],
};

function getEditorConfig({
  documentName,
  documentModel,
  enableMentions,
  tagsToShow,
}: Props) {
  const toolbar = getToolbar(enableMentions);
  const plugins = getPlugins(enableMentions);

  const tagsValues = getTags({ tagsToShow, groupTags: true });

  return {
    language: {
      ui: 'es',
      content: 'es',
    },
    plugins,
    toolbar,
    importWord: {
      ...importConfig,
      converterUrl: 'https://docx-converter.cke-cs.com/v2/convert/docx-html',
    },
    exportWord: {
      ...exportConfig,
      converterUrl: 'https://docx-converter.cke-cs.com/v2/convert/html-docx',
      fileName: `${documentName}.docx`,
    },
    exportPdf: {
      ...exportConfig,
      converterUrl: 'https://pdf-converter.cke-cs.com/v1/convert',
      fileName: `${documentName}.pdf`,
    },
    heading,
    fontFamily,
    fontSize: {
      options: [10, 12, 14, 'default', 18, 20, 22],
      supportAllValues: true,
    },

    image,
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    table,
    menuBar: {
      isVisible: false,
    },
    mention: enableMentions
      ? {
          feeds: [
            {
              marker: '{',
              feed: tagsValues.filter((tag) => !tag?.disabled),
            },
          ],
          dropdownLimit: tagsValues.length,
        }
      : undefined,
    translations: [coreTranslations, premiumFeaturesTranslations],
    licenseKey: process.env.REACT_APP_CKEDITOR_LICENSE_KEY,
    initialData: documentModel,
  };
}

export function useGetEditorConfig({
  documentName,
  documentModel,
  enableMentions,
  tagsToShow,
}: Props) {
  const [editorConfig, setEditorConfig] = useState<any>(null);

  useEffect(() => {
    const config = getEditorConfig({
      documentName,
      documentModel,
      enableMentions,
      tagsToShow,
    });
    setEditorConfig(config);
  }, [documentName, documentModel, enableMentions, tagsToShow]);

  return {
    data: { editorConfig },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
