import { useCallback, useEffect, useState } from 'react';
import { store } from 'redux/store';

import { Envelope, EnvelopeSigner } from 'types';

import { getSignersStatus } from 'modules/_shared/redux/envelopeActions';

import { getSignedSignersCount } from '../utils/getSignedSignersCount';
import { getEnvelopeFromType } from '../utils/getEnvelopeFromType';

export default function useGetSignersFromType(
  envelopes: Envelope[],
  type: string
) {
  const [signers, setSigners] = useState<EnvelopeSigner[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUpdatedSigners = useCallback(async () => {
    try {
      setIsLoading(true);
      const envelope = getEnvelopeFromType(envelopes, type);
      if (envelope) {
        const signedSignersCount = getSignedSignersCount(envelope?.signers);
        const envelopeSigners = envelope?.signers || [];
        if (
          signedSignersCount === envelopeSigners.length &&
          signedSignersCount > 0
        ) {
          setSigners(envelopeSigners);
        } else {
          const updatedSigners = await store.dispatch(
            getSignersStatus(envelope.envelopeId)
          );
          setSigners(updatedSigners);
        }
      } else {
        setSigners([]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [envelopes, type]);

  useEffect(() => {
    getUpdatedSigners();
  }, [getUpdatedSigners]);

  return { signers, isLoading, refetchSigners: getUpdatedSigners };
}
