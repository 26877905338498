import { FC } from 'react';
import { useDispatch } from 'react-redux';

import 'assets/styles/EmailTemplate.scss';

import { Document } from 'types';
import { setModal } from 'modules/_shared/redux/modalActions';
import { DocumentType } from 'modules/administratives/pages/CouncilMembers/components/CouncilMembersDocumentsCard';

import '../Modals.scss';
import TemplateViewerHeader from './components/TemplateViewerHeader';
import TemplateViewerBody from './components/TemplateViewerBody';

type TemplateViewerProps = {
  template: Document;
  contentType: DocumentType;
  size: string;
};

// eslint-disable-next-line
const TemplateViewer: FC<TemplateViewerProps> = ({
  template,
  contentType,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  return (
    <>
      <TemplateViewerHeader
        template={template}
        handleCloseModal={handleCloseModal}
      />
      <TemplateViewerBody template={template} contentType={contentType} />
    </>
  );
};

export default TemplateViewer;
