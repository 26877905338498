import { FC, ReactNode } from 'react';
import { TranslateFn } from 'hooks/useTranslate';

import councilStatus from 'modules/administratives/constants/councilStatus';

type CouncilBadgesProps = {
  children: ReactNode;
};

type StatusProps = {
  t: TranslateFn;
  style: string;
  text: string;
  status: keyof typeof councilStatus;
};

const badgeClass = 'badge badge-pill badge-sm';

const CouncilBadges: FC<CouncilBadgesProps> & { Status?: FC<StatusProps> } = ({
  children,
}) => <>{children}</>;

CouncilBadges.Status = ({ t, style, text, status }) => {
  const statusData = councilStatus.find(
    (councilStatus) => councilStatus.value === status
  );

  return (
    <span className={`${badgeClass} badge-${style}`}>
      <em className={`icon mr-1 ${statusData?.icon}`} />
      {text ? t(text) : ''}
    </span>
  );
};

CouncilBadges.displayName = 'CouncilBadges';

CouncilBadges.Status.displayName = 'Status';

export default CouncilBadges;
