import i18n from 'i18n/config';
import { useEffect, useState } from 'react';
import { Document, SelectorOption } from 'types';

import { getSocietyDocuments } from 'utils/getSocietyDocuments';

const isDocumentAllowed = (document: Document) =>
  document?.category === 'TEMPLATES' &&
  document?.subcategory !== 'DOCUMENTS' &&
  document?.subcategory !== 'GENERATED';

const getOptions = (documents: Document[]) => {
  const options = documents
    ?.filter((document) => isDocumentAllowed(document))
    ?.map((document) => ({
      value: document._id,
      label: document.name,
    }));

  return options;
};

export const useGetTemplateOptions = (societyId: string) => {
  const [templateOptions, setTemplateOptions] = useState<SelectorOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    try {
      setIsLoading(true);

      const getTemplateDocuments = async () => {
        const document = await getSocietyDocuments(societyId);
        const options = getOptions(document);
        setTemplateOptions(options);
        setIsLoading(false);
      };

      getTemplateDocuments();
    } catch {
      setIsError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId, i18n.language]);

  return { data: { templateOptions }, isLoading, isError };
};
