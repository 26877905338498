/* eslint-disable no-unused-vars */
import { store } from 'redux/store';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch } from 'react-redux';
import { FC, useState } from 'react';

import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import successSwal from 'utils/successSwal';
import errorSwal from 'utils/errorSwal';
import actionTypes from 'constants/actionTypes';

import {
  createRealty,
  updateRealty,
} from 'modules/administratives/redux/realtyActions';
import AddRealtyModalHeader from './components/AddRealtyModalHeader';
import { useAddRealtyModal } from './context/AddRealtyModalContext';
import AddRealtyModalBody from './components/AddRealtyModalBody';
import AddRealtyModalFooter from './components/AddRealtyModalFooter';

type Props = {
  societyId: string;
  action?: keyof typeof actionTypes;
  realtyId?: string;
  size?: string;
};

const AddRealtyModal: FC<Props> = ({
  societyId,
  action = actionTypes.ADD,
  realtyId,
  ...props
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const context = useAddRealtyModal();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleSaveRealty = async () => {
    try {
      setIsLoading(true);
      const { data } = context;

      const newRealty = {
        name: data.name,
        area: { total: data.totalArea, built: data.builtArea },
        plots: data.plots,
        location: {
          address: data.address,
          city: data.city,
          zipCode: data.zipCode,
          province: data.province,
        },
        ownership: data.owner,
        lessee: data.lessee,
        salePriceAmount: data.salePriceAmount,
        titles: data.titles,
        usage: data.usage,
        encumbrances: data.encumbrances,
        cadastralValue: {
          amount: data.cadastralAmount,
          currency: 'EUR',
          year: data.cadastralYear,
        },
        references: {
          cadastral: data.cadastralReference,
          registry: data.registryReference,
        },
        salePrice: {
          amount: data.salePriceAmount,
          currency: data.salePriceCurrency,
        },
        licenses: data.licenses,
        lastUpdated: new Date(),
      };

      if (action === actionTypes.EDIT) {
        await store.dispatch(updateRealty({ _id: realtyId, ...newRealty }));
        successSwal(t('RealtyUpdatedSuccessfully'));
      } else {
        await store.dispatch(
          createRealty({
            ...newRealty,
            society: societyId,
            startDate: new Date(),
          })
        );
        successSwal(t('RealtyCreatedSuccessfully'));
      }
    } catch {
      if (action === actionTypes.EDIT) {
        errorSwal(t('ErrorUpdatingRealty'));
      } else {
        errorSwal(t('ErrorCreatingRealty'));
      }
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <AddRealtyModalHeader handleCloseModal={handleCloseModal} />
      <AddRealtyModalBody />
      <AddRealtyModalFooter handleSaveRealty={handleSaveRealty} />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default AddRealtyModal;
