import { Society } from 'types';
import { getOperationAmount } from 'utils/filters';
import operationTypes from 'constants/operationTypes';
import { getFilteredOperations } from './getFilteredOperations';

type GetOperationCapitalSocialProps = {
  operationId: string;
  society: Society;
};

export const getOperationCapitalSocial = ({
  operationId,
  society,
}: GetOperationCapitalSocialProps): number => {
  if (!society.operations || !operationId) {
    return 0;
  }

  const includedOps = [
    operationTypes.CONSTITUTION,
    operationTypes.CAPITAL_INCREASE,
    operationTypes.CAPITAL_DECREASE,
  ];

  const substractionOps = [operationTypes.CAPITAL_DECREASE];

  const operations = getFilteredOperations(society, includedOps).reverse();

  const currentIndex = operations.findIndex((op) => op._id === operationId);

  const relevantOperations =
    currentIndex !== -1 ? operations.slice(0, currentIndex + 1) : operations;

  const socialCapital = relevantOperations.reduce((acc, operation) => {
    const currentOperationAmount = getOperationAmount(operation, society) || 0;

    if (substractionOps.includes(operation.operationType)) {
      return acc - currentOperationAmount;
    }

    return acc + currentOperationAmount;
  }, 0);

  return socialCapital;
};
