import { FC } from 'react';
import { Document } from 'types';
import MenuDots from '../../MenuDots';

type Props = {
  documents: Document[];
};

const DocumentsOptionsList: FC<Props> = ({ documents }) => {
  const options = documents.map((document) => ({
    icon: 'ni-file',
    text: document.name,
    download: true,
    documentId: document._id,
  }));

  return (
    <MenuDots
      menuOptions={options}
      direction="left"
      customIcon="ni-files"
      params={undefined}
      downloadURL={undefined}
      disabled={undefined}
    />
  );
};

export default DocumentsOptionsList;
