import { useEffect, useState } from 'react';

import { Envelope } from 'types';
import { getEnvelopeFromType } from '../utils/getEnvelopeFromType';

export default function useGetEnvelopeFromType(
  envelopes: Envelope[],
  type: string
) {
  const [envelope, setEnvelope] = useState<Envelope>();

  useEffect(() => {
    const envelopeFromType = getEnvelopeFromType(envelopes, type);
    setEnvelope(envelopeFromType);
  }, [envelopes, type]);

  return { envelope };
}
