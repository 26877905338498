import { FC } from 'react';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { Council } from 'types';
import { State } from 'redux/initialState';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/defaultConstants';
import { getCouncilDetailsUrlByRole } from 'modules/administratives/utils/getCouncilDetailsUrl';
import CouncilBadges from 'modules/administratives/components/CouncilBadges';
import councilStatus from 'modules/administratives/constants/councilStatus';
import { getCouncilStatusData } from '../../CouncilDetails/utils/getCouncilStatusData';

type CouncilsRecentsSectionRowProps = {
  council: Council;
  index: number;
};

const CouncilsRecentsSectionRow: FC<CouncilsRecentsSectionRowProps> = ({
  council,
  index,
}) => {
  const { t } = useTranslate();
  const history = useHistory();
  const { memberId } = useParams();

  const { isAdmin, isDirector } = useSelector(
    (state: State) => state.society?.role || {}
  );

  const { statusLabel, statusBadge, statusValue } =
    getCouncilStatusData(council);

  const handleClickRow = () => {
    const newRoute = getCouncilDetailsUrlByRole(
      council,
      isAdmin,
      isDirector,
      memberId || ''
    );
    if (newRoute) {
      history.push(newRoute);
    }
  };

  return (
    <tr className="nk-tb-item cursor-pointer" onClick={handleClickRow}>
      <td className="nk-tb-col nk-tb-col-check d-none">
        <div className="custom-control custom-control-sm custom-checkbox notext">
          <input type="checkbox" className="custom-control-input" id="did-01" />
          <label className="custom-control-label" htmlFor="did-01" />
        </div>
      </td>

      <td className="nk-tb-col w-50px">
        <div className="caption-text sub-text fs-12px mt-1 bg-white w-20px h-20px rounded-circle text-center mx-auto lh-20px">
          {index + 1}
        </div>
      </td>

      <td className="nk-tb-col">
        <div className="caption-text fs-14px mt-1">{council.name}</div>
      </td>

      <td className="nk-tb-col tb-col-sm">
        {/* {t(getCouncilStatusData(council).statusLabel)} */}
        {CouncilBadges.Status && (
          <CouncilBadges.Status
            t={t}
            style={statusBadge}
            text={statusLabel}
            status={statusValue as keyof typeof councilStatus}
          />
        )}
      </td>

      <td className="nk-tb-col tb-col-md">
        <div className="caption-text fs-14px mt-1 text-soft d-inline-flex flex-wrap gx-2">
          <div>{format(new Date(council.date), DATE_FORMAT)}</div>
          <div>{format(new Date(council.date), TIME_FORMAT)}</div>
        </div>
      </td>

      <td className="nk-tb-col tb-col-end py-0 d-none">
        <div className="dropdown">
          <button
            className="btn btn-sm btn-icon btn-trigger me-n1"
            type="button"
            data-bs-toggle="dropdown"
          >
            <em className="icon ni ni-more-h" />
          </button>
          <div className="dropdown-menu dropdown-menu-end">
            <div className="dropdown-content">
              <ul className="link-list-opt no-bdr">
                <li>
                  <a href="#">
                    <em className="icon ni ni-eye" />
                    <span>Ver Consejo</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <em className="icon ni ni-share" />
                    <span>Mandar Convocatoria</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <em className="icon ni ni-edit" />
                    <span>Cambiar Nombre</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <em className="icon ni ni-trash" />
                    <span>Eliminar</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default CouncilsRecentsSectionRow;
