import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import errorSwal from 'utils/errorSwal';
import { allowedFileImage } from 'utils/allowedFileImage';

type Props = {
  value: string;
  onChange: any;
  name: string;
};

const imageStyle = { maxWidth: '80px', maxHeight: '80px' };

const ImageInput: FC<Props> = ({ value, onChange, name }) => {
  const { t } = useTranslate();

  const ALLOWED_IMAGE_TYPES = 'image/jpeg, image/png';

  const handleChangeImage = async (
    // eslint-disable-next-line no-undef
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];

      const isAllowedImage = await allowedFileImage(file);

      if (!isAllowedImage) {
        errorSwal(t('InvalidFile'));
      } else {
        onChange(event);
      }
    }
  };

  return (
    <>
      <div className="col-md-3 col-4">
        <label className="form-label mr-2" htmlFor="imageInput">
          {t('Image')}
        </label>
        <input
          type="file"
          id="imageInput"
          className="d-none"
          onChange={handleChangeImage}
          accept={ALLOWED_IMAGE_TYPES}
        />
        <label
          htmlFor="imageInput"
          className="btn btn-primary form-control-lg m-0"
        >
          {t('Choose')}
        </label>
      </div>

      <div className="col-md-3 col-4">
        <div
          className="user-avatar mx-auto lg bg-primary-dim"
          style={imageStyle}
        >
          {value ? (
            <img id="user-image" src={value} alt={name} style={imageStyle} />
          ) : (
            <span>{name ? name.slice(0, 2).toUpperCase() : ''}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageInput;
