import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import BuildingView from './BuildingView';
import LocationView from './LocationView';
import TitlesView from './TitlesView';
import ReferencesView from './ReferencesView';
import PlotsView from './PlotsView';
import EncumbrancesView from './EncumbrancesView';
import LicensesView from './LisencesView';
import OwnershipView from './OwnershipView';
import CadastralView from './CatastralView';

const AddRealtyModalBody: FC = () => (
  <Modal.Body>
    <div className="nk-modal gy-4">
      <BuildingView />
      <hr />
      <PlotsView />
      <hr />
      <EncumbrancesView />
      <hr />
      <LocationView />
      <hr />
      <TitlesView />
      <hr />
      <OwnershipView />
      <hr />
      <LicensesView />
      <hr />
      <CadastralView />
      <ReferencesView />
    </div>
  </Modal.Body>
);

export default AddRealtyModalBody;
