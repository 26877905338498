export default [
  {
    value: 'GENERAL',
    label: 'General',
  },
  {
    value: 'SPECIAL',
    label: 'Special',
  },
];
