export default [
  {
    value: 'ATTEND',
    label: 'Attend',
    badge: 'success',
  },
  {
    value: 'NOT_ATTEND',
    label: 'NotAttend',
    badge: 'danger',
  },
];
