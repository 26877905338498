import { format, isValid, Locale } from 'date-fns';
import i18n from 'i18n/config';
import {
  es as localeES,
  enUS as localeEN,
  fr as localeFR,
} from 'date-fns/locale';
import { SocietyAdditional } from 'types';
import { LegalDashboardSummary } from '../types';

type Language = 'es' | 'en' | 'fr';

const dateLocales: Record<Language, Locale> = {
  es: localeES,
  en: localeEN,
  fr: localeFR,
};

export const mapAdditionalToLegalDashboard = (
  additional: SocietyAdditional
): LegalDashboardSummary => {
  const summaryData = { ...additional };

  if (additional?.fiscalYear) {
    summaryData.fiscalYear = isValid(new Date(additional?.fiscalYear))
      ? format(new Date(additional.fiscalYear), 'dd MMMM', {
          locale: dateLocales[i18n.language as Language],
        })
      : null;
  }

  return {
    ...summaryData,
  };
};
