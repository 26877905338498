import { useDispatch, useSelector } from 'react-redux';
import { setModal } from 'modules/_shared/redux/modalActions';
import i18n from 'i18n/config';

import 'assets/styles/EmailTemplate.scss';

import { createAnnouncementEmailData } from 'modules/_shared/utils';
import { Board, Council } from 'types';
import { State } from 'redux/initialState';
import { FC } from 'react';
import { createCouncilAnnouncementEmailData } from 'modules/administratives/modals/CouncilAnnouncementModal/utils/createCouncilAnnouncementEmailData';
import useGetAnnouncementData from './hooks/useGetAnnouncementData';
import useGetAnnouncementCommunication from './hooks/useGetAnnouncementCommunication';
import AnnouncementTemplateViewerHeader from './components/AnnouncementTemplateViewerHeader';
import AnnouncementTemplateViewerBody from './components/AnnouncementTemplateViewerBody';

import SendTestEmail from '../SendTestEmail';

import '../Modals.scss';
import useGetCouncilAnnouncementData from './hooks/useGetCouncilAnnouncementData';

export enum AnnouncementType {
  BOARD = 'BOARD',
  COUNCIL = 'COUNCIL',
}

type AnnouncementTemplateViewerProps = {
  meeting: Board | Council;
  announcementType: AnnouncementType;
  size: string;
};

const AnnouncementTemplateViewer: FC<AnnouncementTemplateViewerProps> = ({
  meeting,
  announcementType,
  ...props
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state?.user);

  const actualSociety = useSelector(
    (state: State) => state?.society?.actualSociety
  );

  const { announcementData: boardAnnouncementData } = useGetAnnouncementData(
    meeting as Board,
    actualSociety
  );
  const { announcementData: councilAnnouncementData } =
    useGetCouncilAnnouncementData(meeting as Council, actualSociety);

  const announcementData =
    announcementType === AnnouncementType.BOARD
      ? boardAnnouncementData
      : councilAnnouncementData;

  const { announcementCommunication } = useGetAnnouncementCommunication(
    i18n,
    announcementData,
    actualSociety,
    announcementType
  );

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleSendTest = () => {
    const emailData =
      announcementType === AnnouncementType.BOARD
        ? createAnnouncementEmailData({
            announcementData,
            userId: user?._id,
            userEmail: user?.email,
            society: actualSociety,
            societyId: actualSociety?._id,
          })
        : createCouncilAnnouncementEmailData({
            announcementData,
            userId: user?._id,
            userEmail: user?.email,
            society: actualSociety,
            societyId: actualSociety?._id,
          });

    dispatch(setModal(<SendTestEmail emailData={emailData} />));
  };

  return (
    <>
      <AnnouncementTemplateViewerHeader
        meetingName={meeting?.name || ''}
        handleCloseModal={handleCloseModal}
      />
      <AnnouncementTemplateViewerBody
        announcementCommunication={announcementCommunication}
        handleSendTest={handleSendTest}
      />
    </>
  );
};

export default AnnouncementTemplateViewer;
